import React from 'react';

const TermsOfService = () => {
  return (
    <div className="legal-section p-6">
      <h2>Terms of Service</h2>
      <p>By using .mixtape, you agree to:</p>
      <ul>
        <li>Respect copyright laws and not upload infringing content.</li>
        <li>Follow our community standards and refrain from abusive behavior.</li>
        <li>Acknowledge that .mixtape may terminate accounts for ToS violations.</li>
      </ul>
      <p>For full Terms of Service, please <a href="/terms">read here</a>.</p>
    </div>
  );
};

export default TermsOfService;