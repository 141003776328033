import React, { Component } from "react";
// import ReactTerminal from "react-terminal-component";
const Terminal = () => {
  return (
    <div>
      {/* <ReactTerminal /> */}
    </div>
  );
};

export default Terminal;
