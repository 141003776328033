// SignupHeading.jsx
import React from "react";

const SignupHeading = () => {
  return (
    <h1 className="text-2xl font-semibold text-gray-800 mb-6 text-center">
sign up
    </h1>
  );
};

export default SignupHeading;
