import React, { useState, useEffect } from "react";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import { Link } from "react-router-dom";
import storage from "../../../services/storage";
import Uploader from "../../file_mgmt/uploader/Uploader";

import MyTapesView from "../../file_mgmt/MyTapesView";
import AuthProvider from "../../../provider/AuthProvider";
import Container from "../../themes/Container";
import FileSystemContainer from "../../themes/FileSystemContainer";
import UploadContainer from "../../themes/UploadContainer";
import GridContainer from "../../themes/GridContainer";
import DriveFilesView from "../../file_mgmt/DriveFilesView";
// import Merch from "../Merch";
// import storage from "../../services/storage.js";

const Dash = (props) => {
  const [user = {}, setUser] = useState();

  function authStateListener() {
    // [START auth_state_listener]
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        var uid = user.uid;
        setUser(user);
        storage.myTapesObserver(uid);
      } else {
        // try to refresh the token
        storage.myTapesObserver(uid);
        AuthProvider.refreshToken();
      }
    });
    // [END auth_state_listener]
  }
  useEffect(() => {
    authStateListener();
    // Update the document title using the browser API
    // document.title = `You clicked ${count} times`;
  });
  const userGreetingView = () => {
    return (
      <div>
        <p className="font-scopeMonoMediumExpanded">hello, {user.displayName}</p>
        {/* <button onClick={logout}>Log out</button> */}
      </div>
    );
  };
  return (
    <div>
      {/* <FileSystemContainer className="center"> */}

      {userGreetingView()}
      <Uploader></Uploader>
      {/* <MyTapesView></MyTapesView> */}
      <DriveFilesView></DriveFilesView>

    </div>
  );
};

export default Dash;
