// ResponsiveImageTextGroupList.jsx

// Path: src/components/landing/ResponsiveImageTextGroupList.jsx

import React, { useEffect, useState } from "react";
import ResponsiveImageTextGroup from "./ResponsiveImageTextGroup";

// takes a list of images and text as input and returns a list of responsive image/text groups that is a vertical stack on mobile and a horizontal stack on desktop

// this is a component that is used in many places in the app

// it is used in the splash page, the studio plans page, and the studio plus upgrade page

// it is also used in the studio plus upgrade page

// it is written in tailwind css

// it has to be idiomatic tailwind

const ResponsiveImageTextGroupList = (props) => {
  const img0 =
    "https://firebasestorage.googleapis.com/v0/b/tuareg.appspot.com/o/images%2Fstudio-splash-images%2Fstudio-phone-0.svg?alt=media&token=647560d9-d35c-4705-908a-0cb77a37a34e";
  const img1 =
    "https://firebasestorage.googleapis.com/v0/b/tuareg.appspot.com/o/images%2Fstudio-splash-images%2Fstudio-phone-1.svg?alt=media&token=6d018359-addc-45ae-ab05-17354c11f162";
  const img2 =
    "https://firebasestorage.googleapis.com/v0/b/tuareg.appspot.com/o/images%2Fstudio-splash-images%2Fstudio-phone-2.svg?alt=media&token=775ef644-6cfd-400e-a0ad-abff986c653e";
  const img3 =
    "https://firebasestorage.googleapis.com/v0/b/tuareg.appspot.com/o/images%2Fstudio-splash-images%2Fstudio-phone-3.svg?alt=media&token=7a5cc03b-8070-4de5-a383-65e941c96d38";
  const img4 =
    "https://firebasestorage.googleapis.com/v0/b/tuareg.appspot.com/o/images%2Fstudio-splash-images%2Fstudio-phone-3.svg?alt=media&token=7a5cc03b-8070-4de5-a383-65e941c96d38";
  const img5 =
    "https://firebasestorage.googleapis.com/v0/b/tuareg.appspot.com/o/images%2Fstudio-splash-images%2Fstudio-phone-5.svg?alt=media&token=7e1844dc-f43e-4d3f-9996-1cae3f75954c";

  const grenade1 =
    "https://firebasestorage.googleapis.com/v0/b/tuareg.appspot.com/o/images%2Fstudio-splash-images%2Fgrenades%2Fgrenade-01.svg?alt=media&token=7a51d1f9-827f-4b97-846c-1e36c1103877";
  const grenade2 =
    "https://firebasestorage.googleapis.com/v0/b/tuareg.appspot.com/o/images%2Fstudio-splash-images%2Fgrenades%2Fgrenade-02.svg?alt=media&token=3a5626c9-dea9-446b-a6b7-d90e042a8f03";

  const grenade3 =
    "https://firebasestorage.googleapis.com/v0/b/tuareg.appspot.com/o/images%2Fstudio-splash-images%2Fgrenades%2Fgrenade-03.svg?alt=media&token=686bbe8d-38c6-4ed4-b67d-51b64ae6f9cb";
  const grenade4 =
    "https://firebasestorage.googleapis.com/v0/b/tuareg.appspot.com/o/images%2Fstudio-splash-images%2Fgrenades%2Fgrenade-04.svg?alt=media&token=264cf12b-6858-4386-aeae-ac47162c94db";
  const grenade5 =
    "https://firebasestorage.googleapis.com/v0/b/tuareg.appspot.com/o/images%2Fstudio-splash-images%2Fgrenades%2Fgrenade-05.svg?alt=media&token=322b3b4d-3097-4d9d-bd00-47b6d11d4cbd";

  const Positioning = {
    LEFT: "left",
    RIGHT: "right",
    LEFT_A: "left_a",
    RIGHT_A: "right_a",
  };
  const left = Positioning.LEFT;
  const right = Positioning.RIGHT;
  const left_a = Positioning.LEFT_A;
  const right_a = Positioning.RIGHT_A;

  const data = [
    {
      image: img1,
      image_under: grenade1,
      text: "sell merch without fees",
      positioning: { overlay: left, underlay: left },
      textColor: "text-lime_green_p1-50",
    },
    {
      image: img2,
      image_under: grenade2,
      text: "designed with multitasking in mind",
      positioning: { overlay: right, underlay: right },
      underlay_positioning: right_a,
      textColor: "text-hot_pink_p1-50",
    },
    {
      image: img3,
      image_under: grenade3,
      text: "edit and preview album drafts online and off",
      positioning: { overlay: left, underlay: left },
      textColor: "text-turquoise_p1-50",
    },
    {
      image: img4,
      image_under: grenade4,
      text: "stay on top of your music using drive",
      positioning: { overlay: right, underlay: right },
      textColor: "text-orange_p1-50",
    },
    {
      image: img5,
      image_under: grenade5,
      text: "what will you create?",
      positioning: { overlay: left, underlay: left_a },
      textColor: "text-hot_pink_p1-50",
    },
    {
      image: img0,
      image_under: grenade1,
      text: "",
      positioning: { overlay: left, underlay: right },
      textColor: "text-hot_pink_p1-50",
    },
  ];

  return (
    // center the list of responsive image/text groups
    // a translucent background is applied to the list
    // to make the text more readable

    // make opacity 0.5 for the background
    <div className="flex flex-col items-center justify-center w-full h-full  ">
      {/* <div className="flex flex-col md:flex-col items-center justify-center"> */}
      {data.map((d) => {
        return (
          <ResponsiveImageTextGroup 
            image={d.image}
            text={d.text}
            positioning={d.positioning}
            textColor={d.textColor}
            imageUnder={d.image_under}
          ></ResponsiveImageTextGroup>
        );
      })}
    </div>
  );
};

export default ResponsiveImageTextGroupList;
