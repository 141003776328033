// AddButton.js

import WideButton from "../../../buttons/WideButton";

const AddButton = ({ label, onClick }) => {
  return (
    <div>
      <WideButton lambda={onClick} text={label} />
    </div>
    // <button
    //   onClick={onClick}

    // >
    //   {label}
    // </button>
  );
};

export default AddButton;
