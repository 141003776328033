// useFetchArtistData.js
import { useState, useEffect } from 'react';
import APIConfig from '../../../APIConfig'; // Adjust the path as needed
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';

const useFetchArtistData = (artistId) => {
  const [artistData, setArtistData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchArtistData = async () => {
      if (!artistId) return;
      
      setLoading(true);
      try {
        const idToken = await firebase.auth().currentUser.getIdToken(true);
        const response = await fetch(APIConfig.artists(artistId), { // Pass artistId to the artists() function
            headers: {
            'Content-Type': 'application/json',
            'Authorization': `JWT ${idToken}`,
          },
        });
        
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        console.log("response artist: ", response)
        const data = await response.json();
        console.log("data artist: ", data)
        setArtistData(data);
      } catch (e) {
        setError(e);
      } finally {
        setLoading(false);
      }
    };

    fetchArtistData();
  }, [artistId]);

  return { artistData, loading, error };
};

export default useFetchArtistData;
