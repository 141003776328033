// AirtrakSplash.jsx
import React from "react";
import WideLinkButton from "../../buttons/WideLinkButton";
import WaveformVisualizer from "./WaveformVisualizer";
import LogoView from "../../LogoView";

const AirtrakSplash = () => {
  return (
    <div className={`w-full h-full rounded-md p-6 flex flex-col items-center justify-center h-screen`}>
      <LogoView />
      <WideLinkButton path="/signin" text="get started" />
    </div>
  );
};

export default AirtrakSplash;
