import React from 'react';
import DriveItem from './DriveItem';

const DriveItemsList = ({ items, openItem, updateFolderName, setItems }) => (
  <ul className="grid grid-cols-1 gap-2 ">
    {items.map((item) => (
      <DriveItem
        key={item.id}
        item={item}
        openItem={openItem}
        updateFolderName={updateFolderName}
        items={items} // Pass the items prop
        setItems={setItems} // Pass the setItems prop
      />
    ))}
  </ul>
);

export default DriveItemsList;