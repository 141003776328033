import React from "react";
import { Link } from "react-router-dom";
import nyWomanPhaze from "./ny_woman_phaze.png"; // Import the image
import AppStoreButton from "./AppStoreButton";
import BetaDownloadButtonView from "../../landing/BetaDownloadButtonView";

const PhazeSplashPage = ({ useGradient = false }) => {
  const bgClass = useGradient
    ? "bg-gradient-to-r from-blue-50 to-purple-50 dark:from-gray-900 dark:to-gray-800"
    : "";

  return (
    <div
      className={`w-full h-screen ${bgClass} flex items-center justify-center px-6 sm:px-12 lg:px-24 py-12 text-gray-900 dark:text-gray-200`}
    >
      <div className="grid grid-cols-1 md:grid-cols-2 max-w-7xl gap-8">
        <div className="flex flex-col justify-center items-center md:items-start text-center md:text-left space-y-6 md:space-y-8">
          <h2 className="text-3xl sm:text-4xl md:text-5xl font-black tracking-tighter">
            PHAZE
          </h2>
          <p className="text-sm sm:text-base font-mono leading-relaxed w-full sm:max-w-md">
            late nights, city lights
          </p>
          {/* social utilities and photo sharing on mobile */}
          {/* <AppStoreButton /> */}
          <BetaDownloadButtonView />
        </div>
        <div className="flex items-center justify-center relative">
          <div className="relative w-72 h-72 sm:w-96 sm:h-96 lg:w-112 lg:h-112 bg-white dark:bg-gray-700 rounded-full shadow-lg overflow-hidden">
            <img
              src={nyWomanPhaze} // Use the imported image
              alt="Event Image"
              className="object-cover w-full h-full"
            />
            <div className="absolute -inset-10 bg-gradient-to-r from-transparent via-purple-300 to-transparent opacity-50 blur-lg transform translate-x-10 translate-y-10"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PhazeSplashPage;
