// ImageHandler.jsx


import React, { useEffect, useState } from "react"; 

const ImageHandler = (props) => {
    const { image } = props;

    return (
        <div className="flex flex-col items-center justify-center">
            <img src={image} className="w-64 h-64 md:w-96 md:h-96"></img>
        </div>
    );
}


export default ImageHandler;
