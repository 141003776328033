// ImageViewer.jsx
// ImageViewer.jsx

import React from 'react';
import Modal from 'react-modal';

Modal.setAppElement('#root'); // Set the app element for accessibility purposes

const ImageViewer = ({ isOpen, onRequestClose, imageUrl }) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center p-4"
      overlayClassName="fixed inset-0"
    >
      <button
        className="absolute top-4 right-10 w-32  text-red-700 text-2xl font-bold p-2 rounded-full bg-gray-800 bg-opacity-50 hover:bg-opacity-75"
        onClick={onRequestClose}
      >
        &times;
      </button>
      <img src={imageUrl} alt="Selected memory" className="max-w-full max-h-full" />
    </Modal>
  );
};

export default ImageViewer;
