import React, { useState } from "react";
import useAudioStorage from "./useAudioStorage";
import RotatingSlash from "../loading/RotatingSlash";
import { TrashIcon } from '@heroicons/react/24/outline';

const DeleteFileButton = ({
  track,
  albumId,
  onDeleteComplete,
  variant = "ascii",
}) => {
  const { deleteFile } = useAudioStorage();
  const [isLoading, setIsLoading] = useState(false);

  const handleDelete = async () => {
    setIsLoading(true);
    try {
      await deleteFile(track.content, albumId);
      onDeleteComplete && onDeleteComplete(track);
    } catch (error) {
      console.error("Error deleting track:", error);
    } finally {
      setIsLoading(false);
    }
  };

  if (variant === "ascii") {
    return (
      <button
        onClick={handleDelete}
        disabled={isLoading}
        className="font-theFutureMonoRegular text-xs text-red-300 p-2 hover:border-1 hover:border-red-500 hover:bg-slate-800 hover:text-red-500 py-1"
      >
        {isLoading ? <RotatingSlash /> : "x"}
      </button>
    );
  }

  // Default button rendering (non-ASCII variant)
  return (
    <div className="flex justify-end">
      <button
        onClick={handleDelete}
        disabled={isLoading}
        className="text-gray-500 hover:text-red-300 p-2 rounded transition-colors duration-200"
      >
        {isLoading ? <RotatingSlash /> : <TrashIcon className="h-6 w-6" />}
      </button>
    </div>
  );
};

export default DeleteFileButton;
