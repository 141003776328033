import APIConfig from "./APIConfig";
// import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import { authMethods } from "./firebase/authmethods";
import { firebaseAuth } from "./provider/AuthProvider";
import auth from "./services/auth";
// import { authMethods } from "../../firebase/authmethods";
const token = localStorage.getItem("token");
// const b = APIConfig.baseURL();
// ${APIConfig.baseURL()}
// const base = APIConfig.baseURL()
var db = firebase.firestore();

const createPaymentIntent = (options) => {
  return window
    .fetch(`${APIConfig.payments()}create-payment-intent/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "JWT " + token,
      },
      body: JSON.stringify(options),
    })
    .then((res) => {
      if (res.status === 200) {
        return res.json();
      } else {
        return null;
      }
    })
    .then((data) => {
      if (!data || data.error) {
        throw new Error("PaymentIntent API Error");
      } else {
        return data.client_secret;
      }
    });
};

const sendEmailVerification = () => {
  firebase.auth().onAuthStateChanged(function (user) {
    if (user) {
      //("signed in");
      firebase.auth().currentUser.sendEmailVerification();
    }
  });
};

const sendPasswordResetLink = (emailAddress) => {
  var auth = firebase.auth();
  // var emailAddress = "user@example.com";

  auth
    .sendPasswordResetEmail(emailAddress)
    .then(function () {
      // Email sent.
      alert("email sent");
    })
    .catch(function (error) {
      // An error happened.
      alert("oops something went wrong");
    });
};
const getProductDetails = (options) => {
  return window
    .fetch(`${APIConfig.payments()}product-details`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "JWT " + token,
      },
    })
    .then((res) => {
      if (res.status === 200) {
        return res.json();
      } else {
        return null;
      }
    })
    .then((data) => {
      if (!data || data.error) {
        throw Error("API Error");
      } else {
        return data;
      }
    });
};

const getPublicStripeKey = (options) => {
  return window
    .fetch(`${APIConfig.payments()}public-key`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "JWT " + token,
      },
    })
    .then((res) => {
      if (res.status === 200) {
        return res.json();
      } else {
        return null;
      }
    })
    .then((data) => {
      if (!data || data.error) {
        throw Error("API Error");
      } else {
        return data.publicKey;
      }
    });
};

const setStudioData = (downloadURL, uid, filename) => {
  db.collection("studio")
    .doc(uid)
    .collection("files")
    .add({
      filename: filename,
      url: downloadURL,
    })
    .then(function (docRef) {})
    .catch(function (error) {
      console.error("Error adding document: ", error);
    });
};

const setMTRStudioData = (tapeRef, track) => {
  if (!tapeRef || typeof tapeRef.collection !== 'function') {
    console.error('Invalid tape reference:', tapeRef);
    return;
  }
  tapeRef.collection("tracks")
    .add({
      channel: track.channel,
      filename: track.filename,
      url: track.url,
    })
    .then(function (docRef) {})
    .catch(function (error) {
      console.error("Error adding document: ", error);
    });
};


const tape = {
  name: "Tape 1",
  uid: "10923uusadyasoialkisjda",
  description: "This is a tape",
  artist: "Artist 1",
  tracks: [
    {
      number: 1,
      filename: "file_track_1.wav",
      url: "https://firebasestorage.googleapis.com/v0/b/tape-studio.appspot.com/o/file_track_1.wav?alt=media&token=f8f8f8f8-f8f8f8f8-f8f8f8f8-f8f8f8f8",
      tape_uid: "10923uusadyasoialkisjda",
    },
    {
      number: 2,
      filename: "file_track_2.wav",
      url: "https://firebasestorage.googleapis.com/v0/b/tape-studio.appspot.com/o/file_track_2.wav?alt=media&token=f8f8f8f8-f8f8f8f8-f8f8f8f8-f8f8f8f8",
      tape_uid: "10923uusadyasoialkisjda",
    },
    {
      number: 3,
      filename: "file_track_3.wav",
      url: "https://firebasestorage.googleapis.com/v0/b/tape-studio.appspot.com/o/file_track_3.wav?alt=media&token=f8f8f8f8-f8f8f8f8-f8f8f8f8-f8f8f8f8",
      tape_uid: "10923uusadyasoialkisjda",
    },
    {
      number: 4,
      filename: "file_track_4.wav",
      url: "https://firebasestorage.googleapis.com/v0/b/tape-studio.appspot.com/o/file_track_4.wav?alt=media&token=f8f8f8f8-f8f8f8f8-f8f8f8f8-f8f8f8f8",
      tape_uid: "10923uusadyasoialkisjda",
    },
  ],
};

// test createnewtape functionality with tape object async

const testCreateNewTape = (user_uid) => {

  var result = createNewTape(tape, user_uid);
  return result;
}

const createNewTape = async (tape, user_uid) => {
  const tape_name = tape.name;
  const tape_uid = tape.uid;
  const tape_description = tape.description;
  const tape_artist = tape.artist;
  const collectionRef = db.collection("studio").doc(user_uid).collection("drive");
  const docRef = collectionRef.doc();
  await docRef.set({
    name: tape_name,
    uid: tape_uid,
    description: tape_description,
    artist: tape_artist,
  });
  const tapeRef = collectionRef.doc(tape_uid); // create a document reference object
  for (const track of tape.tracks) {
    await setMTRStudioData(tapeRef, track);
  }
  return tapeRef; // return the document reference object
};
const createBlankTape = async (tape, user_uid) => {
  const tape_name = tape.name;
  const tape_description = tape.description;
  const collectionRef = db.collection("studio").doc(user_uid).collection("drive");
  const docRef = collectionRef.doc(); // Create a document reference object
  await docRef.set({
    name: tape_name,
    description: tape_description,
    tracks: [],
  });
  return docRef; // Return the document reference object
};


const handleResult = () => {};

async function submitArtistName(name) {
  const response = await submit(name);
  return response;
}

const me = () => {
  fetch(`${APIConfig.profiles()}me/`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "JWT " + token,
    },
  });
};

const newArtist = async (name, fs_uid) => {
  const artist_data = { name: name, fs_uid: fs_uid };
  const d = JSON.stringify(artist_data);
  // const token = localStorage.getItem("token");
  const headers = await auth.getAuthHeaders();
  const r = await fetch(`${APIConfig.profiles()}new_artist/`, {
    method: "POST",
    headers,
    body: d,
  });

  return r;
};

const updateArtistName = async (artistId, name) => {
  const artist_data = { artist_id: artistId, name: name };
  const d = JSON.stringify(artist_data);
  const headers = await auth.getAuthHeaders();

  return fetch(`${APIConfig.profiles()}update_artist/`, {
    method: "POST",
    headers,
    body: d,
  })
  .then((res) => {
    if (res.status === 200) {
      return res.json();
    } else {
      throw new Error('Error updating artist');
    }
  });
};

const deleteArtist = async (artistId) => {  
  const artist_data = { artist_id: artistId };
  const d = JSON.stringify(artist_data);
  const headers = await auth.getAuthHeaders();

  return fetch(`${APIConfig.profiles()}delete_artist/`, {
    method: "POST",
    headers,
    body: d,
  })
  .then((res) => {
    if (res.status === 200) {
      return res.json();
    } else {
      throw new Error('Error deleting artist');
    }
  });
}

const newLabel = async (name, fs_uid) => {
  const label_data = { name: name, fs_uid: fs_uid };
  const d = JSON.stringify(label_data);
  const token = localStorage.getItem("token");
  const r = await fetch(`${APIConfig.profiles()}create_label/`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "JWT " + token,
    },
    body: d,
  });

  return r;
};

const newAlbum = async (draft_uid) => {
  
  const album_data = { draft_uid: draft_uid};
  const d = JSON.stringify(album_data);
  // const token = localStorage.getItem("token");
  firebase.auth().onAuthStateChanged(function (user) {
    if (user) {
      //("signed in");
      firebase
        .auth()
        .currentUser.getIdToken(/* forceRefresh */ true)
        .then(function (token) {
          fetch(`${APIConfig.profilesURI()}new_album/`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: "JWT " + token,
            },
            body: d,
          }).then(function (res) {});
        });
    }
  });

  // return r;
};

const checkout = async (stripe, merch_item_id, quantity, user_id) => {
  var stripe = window.Stripe(APIConfig.stripePublicKey());
  firebase.auth().onAuthStateChanged(function (user) {
    if (user) {
      firebase
        .auth()
        .currentUser.getIdToken(/* forceRefresh */ true)
        .then(function (token) {
          const data = {
            merch_item_id: merch_item_id,
            quantity: quantity,
            user_id,
          };
          const d = JSON.stringify(data);
          fetch(`${APIConfig.payments()}create-checkout-session/`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: "JWT " + token,
            },
            body: d,
          }).then(
            function (res) {
              res.json().then(function (data) {
                stripe.redirectToCheckout({
                  sessionId: data.sessionId,
                });
              });
              //
            },
            function (value) {}
          );
        });
    }
  });
};

//does not work
function submit(name) {
  const profile_id = localStorage.getItem("profile_id");
  const token = localStorage.getItem("token");
  return new Promise((resolve, reject) => {
    fetch(`${APIConfig.profiles()}new_artist/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "JWT " + token,
      },
      body: { name: name },
    }).then((res) => {
      if (res.status === 200) {
        resolve(res.json());
      } else {
        resolve(res.json());
        return null;
      }
    });
  });
}
const api = {
  createPaymentIntent,
  me,
  newArtist,
  newLabel,
  submitArtistName,
  updateArtistName,
  checkout,
  submit,
  getPublicStripeKey: getPublicStripeKey,
  getProductDetails: getProductDetails,
  setStudioData,
  newAlbum,
  sendEmailVerification,
  sendPasswordResetLink,
  testCreateNewTape,
  setMTRStudioData,
  createBlankTape,
  deleteArtist,
  
};

export default api;
