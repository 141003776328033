import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ArrowLeftIcon } from '@heroicons/react/24/outline'; // Assuming HeroIcons is being used

const BackToEventButton = ({ eventId }) => {
  const history = useNavigate();

  const goBackToEvent = () => {
    history.push(`/e/${eventId}`);
  };

  return (
    <button 
      onClick={goBackToEvent} 
      className="absolute top-4 left-4 flex items-center p-2 text-white bg-black bg-opacity-50 rounded-md hover:bg-opacity-70 transition-opacity"
    >
      <ArrowLeftIcon className="h-5 w-5 mr-1" />
      Return to Event
    </button>
  );
};

export default BackToEventButton;
