import React from "react";
import moment from "moment-timezone";

function prettyPrintDate(dateString) {
  var date = moment(dateString).tz("America/New_York"); // Convert to New York time zone, or use moment.tz.guess() for browser's time zone
  var formattedDate = date.format("dddd, MMMM D"); // Formats date in 'Day, Month Day' format
  return formattedDate;
}

function getDay(dateString) {
  var date = moment(dateString).tz("America/New_York");
  return date.format("D");
}

function getMonth(dateString) {
  var date = moment(dateString).tz("America/New_York");
  return date.format("MMM").toUpperCase();
}

const EventDateTime = ({ event }) => {
  const getTime = (timeString) => {
    if (!timeString) return "";
    var time = new Date("1/1/2021 " + timeString);
    var hours = time.getHours();
    var minutes = time.getMinutes();
    var ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12;
    minutes = minutes < 10 ? "0" + minutes : minutes;
    var strTime = hours + ":" + minutes + " " + ampm;
    return strTime;
  };

  return (
    <div className="flex items-center space-x-4 py-2 text-base font-sans">
      <div className="flex flex-col items-center justify-center bg-gray-100 dark:bg-neutral-800 w-16 h-16 rounded-md p-2">
        <span className="text-lg font-semibold dark:text-gray-200">{getMonth(event.date)}</span>
        <span className="text-2xl font-bold dark:text-gray-100">{getDay(event.date)}</span>
      </div>
      <div>
        <div className="text-lg font-semibold dark:text-gray-200">{prettyPrintDate(event.date)}</div>
        <div className="text-gray-500 dark:text-gray-400">{getTime(event.time)}</div>
      </div>
    </div>
  );
};

export default EventDateTime;