import React from 'react';

const AcceptableUsePolicy = () => {
  return (
    <div className="legal-section p-6">
      <h2>Acceptable Use Policy</h2>
      <p>Users may not engage in unauthorized access, abusive behavior, or illegal activities on .mixtape.</p>
      <p>For full details, please <a href="/acceptable-use">see our Acceptable Use Policy</a>.</p>
    </div>
  );
};

export default AcceptableUsePolicy;