// mastering.js;

// import APIConfig from "./APIConfig";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import APIConfig from "../APIConfig";
import utils from "../components/artist_dashboard/utilities/utils";
import { authMethods } from "../firebase/authmethods";

// import { authMethods } from "./firebase/authmethods";

// import { authMethods } from "../../firebase/authmethods";
const token = localStorage.getItem("token");
// const b = APIConfig.baseURL();
// ${APIConfig.baseURL()}
// const base = APIConfig.baseURL()
let db = firebase.firestore();
var user = firebase.auth().currentUser;
// var tapes_ref = firebase
//   .firestore()
//   .collection("studio")
//   .doc(firebase.auth().currentUser.uid)
//   .collection("tapes");
const authUser = Object.keys(window.localStorage).filter((item) =>
  item.startsWith("firebase:authUser")
)[0];

async function validateMaster(album) {
  var promise = new Promise((resolve, reject) => {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        var uid = user.uid;
        let albumCoverQuery = db
          .collection(`studio/${uid}/drafts/${album.docId}/images/`)
          .orderBy("last_updated", "desc")
          .limit(1);
        let albumTracksQuery = db
          .collection(`studio/${uid}/drafts/${album.docId}/tracks/`)
          .orderBy("index", "desc");

        var _album = {
          name: album.name,
        };
        var tracks = [];

        albumCoverQuery
          .get()
          .then(function (querySnapshot) {
            querySnapshot.forEach(function (doc) {
              var image = {
                url: doc.data().public_url,
                docId: doc.id,
              };
              _album.image_url = image.url;
            });
            albumTracksQuery
              .get()
              .then(function (querySnapshot) {
                if (querySnapshot.empty) {
                  alert("no audio added");
                  reject("no tracks");
                } else {
                  var validTracks = true;
                  querySnapshot.forEach(function (doc) {
                    let _name = doc.data().track_name;
                    if (_name != "") {
                      var track = {
                        name: _name,
                        docId: doc.id,
                      };
                      tracks.push(track);
                    } else {
                      validTracks = false;
                    }
                  });
                  if (!validTracks) {
                    alert(
                      "all tracks must have a title. click edit button to rename tracks"
                    );
                    reject("empty track name");
                  } else {
                    _album.docId = album.docId;
                    _album.tracks = tracks;
                    _album.isValid = true;
                    resolve(_album);
                  }
                }
              })
              .catch(function (error) {
                console.log("Error getting documents: ", error);
              });
          })
          .catch(function (error) {
            console.log("Error getting documents: ", error);
          });
      } else {
        ////("signed out....");
        reject("error signed out");
      }
    });
  });

  return promise;
}
const mastering = {
  validateMaster,
};

export default mastering;
