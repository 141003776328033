import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import LightSmallSpinner from "../../loading/LightSmallSpinner";
import { useProfile } from "./context/ProfileContext";
import UsernameEditView from "../../../auth/UsernameEditView";

const SetUsernameView = ({ onCancel }) => {
  const [user, loading, error] = useAuthState(firebase.auth());
  const {
    profileInfo,
    loading: profileLoading,
    error: profileError,
  } = useProfile();
  const [initialUsername, setInitialUsername] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    if (profileInfo && profileInfo.username) {
      setInitialUsername(profileInfo.username);
    }
  }, [profileInfo]);

  if (loading || profileLoading) {
    return <LightSmallSpinner />;
  }

  return (
    <div className="flex justify-center items-center bg-gray-100 dark:bg-gray-900 h-screen">
      <div className="w-full max-w-md p-6 bg-white dark:bg-black shadow-lg">
        <h1 className="text-3xl font-bold text-center mb-4 uppercase text-black dark:text-white">
          Update Username
        </h1>
        <div className="h-6 mb-4">
          {(error || profileError) && (
            <div className="text-red-600 text-center">
              {error?.message || profileError?.message}
            </div>
          )}
        </div>
        {user && !loading && <UsernameEditView />}
        <button
          onClick={onCancel}
          className="w-full mt-4 py-2 font-bold text-center bg-black dark:bg-white text-white dark:text-black border-2 border-black dark:border-white"
        >
          Cancel
        </button>
      </div>
    </div>
  );
};

export default SetUsernameView;
