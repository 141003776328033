import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import firebase from "firebase/compat/app";
import { getEventDetails, getUserAuthToken } from "../services/eventService";

import EventContent from "./EventContent";
import Loading from "./Loading";

const EventView = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const eventId = location.pathname.split("/")[2];

  const [loading, setLoading] = useState(true);
  const [event, setEvent] = useState({});
  const [user, setUser] = useState(null);
  const [token, setToken] = useState(null);
  const [isIOS, setIsIOS] = useState(false);

  useEffect(() => {
    const checkIfiOS = () => {
      const userAgent = navigator.userAgent || navigator.vendor || window.opera;
      setIsIOS(/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream);
    };

    const fetchEventDetails = async (eventId) => {
      setLoading(true);
      const user = firebase.auth().currentUser;

      if (user) {
        const token = await getUserAuthToken();
        const event = await getEventDetails(eventId, token);
        setEvent(event);
        setUser(user);
        setToken(token);
      } else {
        const event = await getEventDetails(eventId);
        setEvent(event);
      }

      setLoading(false);
    };

    checkIfiOS();
    fetchEventDetails(eventId);
  }, [eventId]);

  if (loading) {
    return <Loading isIOS={isIOS} />;
  }

  return <EventContent event={event} user={user} token={token} />;
};

export default EventView;