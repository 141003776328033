// TrackList.jsx

// TrackList.js

import React from "react";
import ChannelControls from "./ChannelControls";
const TrackList = ({ tracks }) => {
  return (
    <div className="flex">
      {tracks.map((track, index) => (
        <div className="p-2" key={index}>
          <ChannelControls track={track} channel={index} />;
        </div>
      ))}
    </div>
  );
};

export default TrackList;
