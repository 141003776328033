import React, { useState } from 'react';
import TrackList from './TrackList';

const AlbumList = ({ albums }) => {
  const [expandedAlbums, setExpandedAlbums] = useState({}); // State is now an object

  const toggleExpand = (albumId) => {
    setExpandedAlbums(prevExpandedAlbums => ({
      ...prevExpandedAlbums,
      [albumId]: !prevExpandedAlbums[albumId]
    }));
  };

  return (
    <div>
      {albums.map((album) => (
        <div key={album.id} className="album-item">
          <div className="flex items-center">
            <span 
              className="mr-2 cursor-pointer" 
              onClick={() => toggleExpand(album.id)}
            >
              {expandedAlbums[album.id] ? '-' : '+'}
            </span>
            {album.name}
          </div>
          {expandedAlbums[album.id] && <TrackList tracks={album.tracks} />}
        </div>
      ))}
    </div>
  );
};

export default AlbumList;
