import React, { useState, useEffect, useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { PhotoIcon } from "@heroicons/react/24/solid";
import useImageUpload from "../../hooks/useImageUpload";
import useAlbumDraftCover from "../../hooks/useAlbumDraftCover";

const isValidImageUrl = (url) => {
  return url !== "" && url !== null;
};

const AlbumDraftImageUploader = ({ album }) => {
  const [files, setFiles] = useState([]);
  const { uploadImage, progress, uploadStatus } = useImageUpload(album);
  const {
    url: albumCoverUrl,
    loading: coverLoading,
    refetch: refetchAlbumCover,
  } = useAlbumDraftCover(album);

  useEffect(() => {
    if (uploadStatus === "success") {
      refetchAlbumCover();
    }
  }, [uploadStatus, refetchAlbumCover]);

  const onDrop = useCallback(
    async (acceptedFiles) => {
      setFiles(acceptedFiles);
      for (const file of acceptedFiles) {
        await uploadImage(file, album);
      }
      setFiles([]);
    },
    [uploadImage, album]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: "image/*",
    multiple: true,
  });

  // Format progress to remove extra decimal places
  const formattedProgress = Math.round(progress);

  return (
    <div className="flex justify-center items-center py-8 px-4">
      <form className="w-full max-w-md" {...getRootProps()}>
        <label
          htmlFor="file-upload"
          className={`cursor-pointer border-4 rounded-lg h-64 w-64 flex items-center justify-center transition duration-300 ${
            isDragActive
              ? "border-indigo-500 bg-indigo-50"
              : "border-dashed border-gray-300"
          }`}
        >
          {isValidImageUrl(albumCoverUrl) && !coverLoading ? (
            <img
              src={albumCoverUrl}
              alt="Album Cover"
              className="object-cover rounded-lg shadow-lg mx-auto h-full w-full"
            />
          ) : coverLoading ? (
            <div className="flex flex-col items-center justify-center h-full w-full">
              <span className="text-gray-500 text-lg animate-pulse">
                Loading cover...
              </span>
            </div>
          ) : (
            <div className="flex flex-col items-center justify-center h-full w-full">
              <PhotoIcon className="h-10 w-10 text-gray-500" />
              <span className="text-gray-500 text-base mt-2">
                {isDragActive ? "Drop to upload" : "Upload Cover"}
              </span>
            </div>
          )}
          <input {...getInputProps()} id="file-upload" className="hidden" />
        </label>
        {uploadStatus === "uploading" && (
          <div className="mt-4">
            <div className="relative w-full h-2 bg-gray-200 rounded-full overflow-hidden">
              <div
                className="absolute top-0 left-0 h-full bg-indigo-500 transition-all duration-200"
                style={{ width: `${formattedProgress}%` }}
              />
            </div>
            <p className="text-center text-gray-600 text-sm mt-2">
              Uploading... {formattedProgress}%
            </p>
          </div>
        )}
      </form>
    </div>
  );
};

export default AlbumDraftImageUploader;