import React from "react";
import MixerPot from "./MixerPot";
import LimitedKnob from "./LimitedKnob";

const AudioChannel = ({ audioData }) => {
  const { volume, pan } = audioData;

  const handleVolumeChange = (value) => {
    console.log("Volume:", value);
  };

  const handlePanChange = (value) => {
    console.log("Pan:", value);
  };
  const knobstyle = {
    width: "150px",
    height: "150px"
  };

  return (
    <div className="flex flex-col items-center mx-4">
      {/* <MixerPot
        label="Volume"
        onChange={handleVolumeChange}
        min={0}
        max={1}
        unlockDistance={0}
      /> */}
      <LimitedKnob
        style={knobstyle}
        //   min={0}
        //   max={100}
        //   unlockDistance={0}
        //   preciseMode={false}
        //   width={200}
        //   height={200}
      />
      {/* <MixerPot
        label="Pan"
        onChange={handlePanChange}
        min={-1}
        max={1}
        unlockDistance={0}
      /> */}
    </div>
  );
};

export default AudioChannel;
