import React from "react";
import { MapPinIcon } from "@heroicons/react/24/solid";
import APIConfig from "../../APIConfig";

const AddressIcon = () => (
  <div className="flex items-center justify-center w-16 h-16 border dark:border-none border-gray-200 dark:border-neutral-600 rounded-lg overflow-hidden bg-white dark:bg-neutral-800">
    <MapPinIcon className="w-8 h-8 text-gray-600 dark:text-gray-300" />
  </div>
);

const VenueAddress = ({ venue }) => {
  if (!venue || !venue.address) {
    return null;
  }

  const { address_line_1, address_line_2, city, state, zip_code } = venue.address;

  return (
    <>
      <p className="font-semibold dark:text-gray-100">{venue.name}</p>
      <p className="text-sm dark:text-gray-300">{address_line_1} {address_line_2}</p>
      <p className="text-sm dark:text-gray-300">{city}, {state} {zip_code}</p>
    </>
  );
};

const EventAddressView = ({ event, isRSVPed }) => {
  const AddressView = ({ children }) => (
    <div className="flex flex-row items-start mt-2 space-x-3">
      <AddressIcon />
      <div className="text-base font-sans text-gray-700 dark:text-gray-300">
        {children}
      </div>
    </div>
  );

  const renderAddress = (venue) => (
    <VenueAddress venue={venue} />
  );

  const renderRSVPRequiredMessage = (city, state) => (
    <div className="font-light px-1.5 dark:text-gray-300">
      <p className="py-0.5">RSVP to see address</p>
      <p className="text-sm py-0.5">{city}, {state}</p>
    </div>
  );

  const googleMapsLink = event.venue ? `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(`${event.venue.address?.address_line_1 || ''}, ${event.venue.address?.city || ''}, ${event.venue.address?.state || ''}, ${event.venue.address?.zip_code || ''}`)}` : null;

  if (APIConfig.studio()) {
    return (
      <AddressView>
        {event.venue ? renderAddress(event.venue) : <p className="text-sm py-0.5 dark:text-gray-300">DM for address</p>}
      </AddressView>
    );
  } else {
    return isRSVPed ? (
      <AddressView>
        {event.venue ? (
          <a href={googleMapsLink} target="_blank" rel="noopener noreferrer">
            {renderAddress(event.venue)}
          </a>
        ) : (
          <p className="text-sm py-0.5 dark:text-gray-300">DM for address</p>
        )}
      </AddressView>
    ) : (
      <AddressView>
        {event.venue?.address ? renderRSVPRequiredMessage(event.venue.address.city, event.venue.address.state) : null}
      </AddressView>
    );
  }
};

export default EventAddressView;