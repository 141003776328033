import {
  OPEN_PUBLISHER,
  ADDING_TO_ORDERS,
  CLOSE_PUBLISHER, REDIRECT_TO_CHECKOUT_SCREEN, RESET_CHECKOUT_STATE
} from "../actions/modalActions";

export default (state = {isPublisherOpen: false, orders:[], redirectToCheckOut: false}, action = {}) => {
  switch (action.type) {
    case OPEN_PUBLISHER:
      return {...state, isPublisherOpen: true}
    case CLOSE_PUBLISHER:
      return {...state, isPublisherOpen: false}
    case ADDING_TO_ORDERS:
      return {...state, orders: [...state.orders, action.orders]}
    case REDIRECT_TO_CHECKOUT_SCREEN:
      return {...state, redirectToCheckOut: true}
    case RESET_CHECKOUT_STATE:
      return {...state, redirectToCheckOut: false}
    default:
      return state
  }
}
