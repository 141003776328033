// AudioWaveform.jsx

import React, { useState, useEffect, useContext, useRef } from "react";
import WaveSurfer from 'wavesurfer.js';
import TimelinePlugin from 'wavesurfer.js/dist/plugins/timeline.js';
import RegionsPlugin from 'wavesurfer.js/dist/plugins/regions.js';

import { FileContext } from "../contexts/fileContext";
import { FaSave } from "react-icons/fa";

// import downloadicon from heroicons
import {
  CloudArrowDownIcon,
  CloudArrowUpIcon,
} from "@heroicons/react/24/outline";
import wavesurfer from "wavesurfer.js";

import { useAudioControls } from "../hooks/useAudioControls";
import {
  PlayIcon,
  PauseIcon,
  ArrowPathRoundedSquareIcon,
  ScissorsIcon,
  SpeakerWaveIcon,
  SpeakerXMarkIcon,
  MinusCircleIcon,
  PlusCircleIcon,
  ArrowDownTrayIcon,
} from "@heroicons/react/24/outline";
import useHandleTrim from "../hooks/useHandleTrim";
import useSaveTrimmedAudio from "../hooks/useSaveTrimmedAudio";
import UploadContext from "../contexts/UploadContext";
import EditedAudioUploadStatusView from "./EditedAudioUploadStatusView";
import PathContext from "../contexts/PathContext";
import PlayPauseButton from "./controls/PlayPauseButton";

const AudioWaveform = ({ item }) => {
  const wavesurferRef = useRef(null);
  const timelineRef = useRef(null);
  //   const currentPath = useContext(PathContext);

  const { fileURL, setFileURL } = useContext(FileContext);
  const [wavesurferObj, setWavesurferObj] = useState();
  const [playing, setPlaying] = useState(false);
  const [volume, setVolume] = useState(1);
  const [zoom, setZoom] = useState(1);
  const [duration, setDuration] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const {
    handleTrim,
    downloadTrimmedAudio,
    uploadTrimmedAudio,
    hasStartedTrimming,
  } = useHandleTrim(wavesurferObj, item.name);
  //   const { handleTrim, downloadTrimmedAudio, uploadTrimmedAudio } =
  // useHandleTrim(wavesurferObj, item.name);

  useEffect(() => {
    if (wavesurferRef.current && !wavesurferObj) {
      setWavesurferObj(
        wavesurfer.create({
          container: "#waveform",
          scrollParent: true,
          autoCenter: true,
          cursorColor: "violet",
          loopSelection: true,
          waveColor: "#211027",
          progressColor: "#69207F",
          responsive: true,
          plugins: [
            TimelinePlugin.create({
              container: "#wave-timeline",
            }),
            RegionsPlugin.create({}),
          ],
        })
      );
    }
  }, [wavesurferRef, wavesurferObj]);

  useEffect(() => {
    if (fileURL && wavesurferObj) {
      wavesurferObj.load(fileURL);
    }
  }, [fileURL, wavesurferObj]);

  useEffect(() => {
    if (wavesurferObj) {
      wavesurferObj.on("loading", () => {
        setIsLoading(true);
      });

      wavesurferObj.on("ready", () => {
        // wavesurferObj.play();
        wavesurferObj.enableDragSelection({});
        setDuration(Math.floor(wavesurferObj.getDuration()));
        setIsLoading(false);
      });

      wavesurferObj.on("play", () => {
        setPlaying(true);
      });

      wavesurferObj.on("finish", () => {
        setPlaying(false);
      });

      wavesurferObj.on("region-updated", (region) => {
        const regions = region.wavesurfer.regions.list;
        const keys = Object.keys(regions);
        if (keys.length > 1) {
          regions[keys[0]].remove();
        }
      });
    }
  }, [wavesurferObj]);

  useEffect(() => {
    if (wavesurferObj) wavesurferObj.setVolume(volume);
  }, [volume, wavesurferObj]);

  useEffect(() => {
    if (wavesurferObj) wavesurferObj.zoom(zoom);
  }, [zoom, wavesurferObj]);

  useEffect(() => {
    if (duration && wavesurferObj) {
      wavesurferObj.addRegion({
        start: Math.floor(duration / 2) - Math.floor(duration) / 5,
        end: Math.floor(duration / 2),
        color: "hsla(265, 100%, 86%, 0.4)",
      });
    }
  }, [duration, wavesurferObj]);

  const handlePlayPause = (e) => {
    wavesurferObj.playPause();
    setPlaying(!playing);
  };

  const handleReload = (e) => {
    wavesurferObj.stop();
    wavesurferObj.play();
    setPlaying(true);
  };

  const handleVolumeSlider = (e) => {
    setVolume(e.target.value);
  };

  const handleZoomSlider = (e) => {
    setZoom(e.target.value);
  };
  return (
    <section className="waveform-container">
      {isLoading && <div className="loading-indicator">Loading...</div>}
      <div ref={wavesurferRef} id="waveform" />
      <div ref={timelineRef} id="wave-timeline" />
      <div>{item.name}</div>
      {/* <div>
        currentPath: {currentPath}
      </div> */}
      <div className="all-controls flex justify-between mt-4">
        <div className="left-container flex items-center space-x-4">
          <PlayPauseButton
            playing={playing}
            handlePlayPause={handlePlayPause}
          />
          <EditedAudioUploadStatusView />
          <button
            className="trim p-2 rounded-md hover:bg-gray-300 dark:hover:bg-gray-700 focus:outline-none"
            onClick={handleTrim}
          >
            <ScissorsIcon className="h-5 w-5" />
          </button>
          {hasStartedTrimming && (
            <>
              <button
                className="download p-2  rounded-md hover:bg-gray-300 dark:hover:bg-gray-700 focus:outline-none"
                onClick={downloadTrimmedAudio}
              >
                <ArrowDownTrayIcon className="h-5 w-5" />
              </button>
              <button
                className="p-2  rounded-md hover:bg-gray-300 dark:hover:bg-gray-700 focus:outline-none"
                onClick={uploadTrimmedAudio}
              >
                <FaSave className="h-5 w-5" />
              </button>
            </>
          )}
        </div>
        <div className="right-container flex items-center space-x-4">
          <div className="zoom-slide-container flex items-center space-x-2">
            <MinusCircleIcon className="h-5 w-5" />
            <input
              type="range"
              min="1"
              max="1000"
              value={zoom}
              onChange={handleZoomSlider}
              className="slider zoom-slider"
            />
            <PlusCircleIcon className="h-5 w-5" />
          </div>
          <div className="volume-slide-container flex items-center space-x-2">
            {volume > 0 ? (
              <SpeakerWaveIcon className="h-5 w-5" />
            ) : (
              <SpeakerXMarkIcon className="h-5 w-5" />
            )}
            <input
              type="range"
              min="0"
              max="1"
              step="0.05"
              value={volume}
              onChange={handleVolumeSlider}
              className="slider volume-slider"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default AudioWaveform;
