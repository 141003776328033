import { useState, useEffect } from "react";
import firebase from "firebase/compat/app";

const useRSVPStatus = (event) => {
  const [going, setGoing] = useState(false);

  useEffect(() => {
    const checkGoingStatus = () => {
      const uid = firebase.auth().currentUser?.uid; // Optional chaining in case currentUser is null
      const isGoing = event?.attending?.fs_uids?.includes(uid); // Optional chaining for safety
      setGoing(!!isGoing);
    };

    checkGoingStatus();
  }, [event]);

  return [going, setGoing]; // Return both going and setGoing
};

export default useRSVPStatus;
