// eventService.js
import firebase from "firebase/compat/app";
import events, { default as event_service } from "../../../services/events";

export const getUserAuthToken = async () => {
  const user = firebase.auth().currentUser;
  if (user) {
    const token = await user.getIdToken(true);
    return token;
  }
  return null;
};

export const getEventDetails = async (eventId, token = null) => {
  if (token) {
    return await event_service.get(eventId, token);
  } else {
    return await event_service.get_no_auth(eventId);
  }
};
