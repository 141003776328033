// FileSystemContainer.js;
// LogoContainer.js;
import styled from "styled-components";

const FileSystemContainer = styled.div`
  /* display: flex; */
  .container {
    /* display: flex; */
    /* or inline-flex */
    /* flex-basis: auto; */
    /* default auto */
  }
  .center {
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* padding-top: 20vh; */
    align-items: center;
    /* height: 50vh; */
  }
`;

export default FileSystemContainer;
