import React, { useEffect, useState } from 'react';
import PanKnob from './PanKnob';

const PanControl = ({ track }) => {
  const [panValue, setPanValue] = useState(0);

  const handlePanChange = (value) => {
    setPanValue(value);
  };

  useEffect(() => {
    if (track.panNode) {
      track.panNode.pan.value = -1 * (panValue * 2 - 1);
    }
  }, [track, panValue]);  

  return (
    <div className="flex flex-col items-center space-y-2">
      <label htmlFor={`pan-${track.name}`} className="text-xs">
      </label>
      <PanKnob track={track} onChange={handlePanChange} />
    </div>
  );
};

export default PanControl;
