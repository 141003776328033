import React from 'react';
import { NavLink } from 'react-router-dom';

const NavLinkItem = ({ to, isActive, children }) => {
  return (
    <NavLink
      to={to}
      className={({ isActive }) =>
        `font-mono text-sm ${isActive ? "text-blue-600 dark:text-blue-400" : "text-gray-800 dark:text-gray-200"}`
      }
    >
      {children}
    </NavLink>
  );
};

export default NavLinkItem;