import React from "react";
import { Droppable } from "react-beautiful-dnd";
import Task from "./task";
import { TaskTypes } from "./TaskTypes";

const Column = ({ column, tasks, album, isDropDisabled }) => {
  const taskType =
    column.title === "/files" ? TaskTypes.STANDARD : TaskTypes.WITH_UPLOAD;
  const columnStyle =
    "flex flex-col flex-grow rounded-lg shadow-xl transition-all duration-300 ease-in-out";

  return (
    <div className={columnStyle}>
      <Droppable
        droppableId={column.id}
        type={column.id === "column-4" ? "done" : "active"}
        isDropDisabled={isDropDisabled}
      >
        {(provided, snapshot) => (
          <div
            ref={provided.innerRef}
            {...provided.droppableProps}
            className={`mt-2 flex-grow transition-colors duration-200 ease-in-out ${
              snapshot.isDraggingOver ? "border-green-600 border-1" : (column.title === "/files" ? "bg-black" : "border-1 border-gray-400 rounded")
            }  p-2 `}
          >
            {tasks.map((task, index) => (
              <Task
                key={task.id}
                task={task}
                album={album}
                index={index}
                column_id={column.id}
                taskType={taskType}
              />
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </div>
  );
};

export default Column;
