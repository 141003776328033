import { useCallback, useEffect } from 'react';
import drafting from '../../../../../services/drafting';

const useFileAndDraftSubscriptions = (album, setData) => {
  const updateDataWithFileChanges = useCallback((fileData) => {
    setData((prevData) => ({
      ...prevData,
      tasks: { ...prevData.tasks, ...fileData.tasks },
      columns: {
        ...prevData.columns,
        "column-1": fileData.columns["column-1"],
      },
      columnOrder: ["column-1", "column-2"],
    }));
  }, [setData]);

  const subscribeToFileChanges = useCallback(() => {
    return drafting.getFiles(updateDataWithFileChanges);
  }, [updateDataWithFileChanges]);

  const updateDataWithDraftChanges = useCallback((draftData) => {
    setData((prevData) => ({
      ...prevData,
      tasks: { ...prevData.tasks, ...draftData.tasks },
      columns: {
        ...prevData.columns,
        "column-2": draftData.columns["column-2"],
      },
      columnOrder: ["column-1", "column-2"],
    }));
  }, [setData]);

  const subscribeToAlbumDraft = useCallback(async () => {
    if (!album) return null;
    try {
      return await drafting.getAlbumDraft(album, updateDataWithDraftChanges);
    } catch (error) {
      console.error("Error subscribing to album draft: ", error);
      return null;
    }
  }, [album, updateDataWithDraftChanges]);

  useEffect(() => {
    const unsubscribeFiles = subscribeToFileChanges();
    let unsubscribeDraft = null;
    subscribeToAlbumDraft().then((unsub) => {
      unsubscribeDraft = unsub;
    });

    return () => {
      unsubscribeFiles?.();
      if (typeof unsubscribeDraft === "function") unsubscribeDraft();
    };
  }, [subscribeToFileChanges, subscribeToAlbumDraft]);
};

export default useFileAndDraftSubscriptions;
