import React, { useState, useEffect } from "react";
import BillingButton from "./BillingButton";

function Account() {

  return (
    <BillingButton></BillingButton>
  );
}

export default Account;
