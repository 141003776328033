import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import APIConfig from "../APIConfig";
import { authMethods } from "../firebase/authmethods";

const token = localStorage.getItem("token");

const loadProfilePicForUser = async (user) => {
  console.log("Loading profile picture for user:", user);
  if (!user || !user.fs_uid) {
    console.log("No user provided");
    return null;
  }

  const db = firebase.firestore();
  try {
    const doc = await db.collection('users').doc(user.fs_uid).get();
    if (doc.exists && doc.data().photoURL) {
      console.log("Found profile picture for user:", user.id);
      console.log("Profile picture:", doc.data().photoURL);
      return doc.data().photoURL;
    } else {
      console.log("No profile picture found for user:", user.id);
      return null;
    }
  } catch (error) {
    console.error("Error fetching profile picture:", error);
    return null;
  }
};

const loadProfilePicForUsers = async (users) => {
  return await Promise.all(users.map(async (user) => {
    const profilePicUrl = await loadProfilePicForUser(user);
    console.log("Profile picture for user:", user.username, " + " , user.id, " + ", profilePicUrl);
    return { ...user, profilePicUrl };
  }));
};

async function parseUser(user) {
  const userWithPic = await loadProfilePicForUser(user);
  return {
    ...user,
    profilePicUrl: userWithPic,
  };
}

async function parseUsers(users) {
  if (!users) {
    return {
      usernames: [],
      user_ids: [],
      fs_uids: [],
      profilePics: [],
      users: [],
    };
  }

  const usersWithPics = await loadProfilePicForUsers(users);
  return {
    usernames: usersWithPics.map(user => user.username),
    user_ids: usersWithPics.map(user => user.id),
    fs_uids: usersWithPics.map(user => user.fs_uid),
    profilePics: usersWithPics.map(user => user.profilePicUrl),
    users: usersWithPics.map(user => ({
      username: user.username,
      profilePicUrl: user.profilePicUrl,
      id: user.id,
      fs_uid: user.fs_uid,
    })),
  };
}


async function parseEventJson(event) {
  if (!event) {
    return null;
  }
  if (!event.attending) {
    event.attending = {};
  }
  if (!event.invitees) {
    event.invitees = {};
  }
  var dummyEvent = {
    id: event.id,
    name: event.name,
    description: event.description,
    date: event.date,
    time: event.time,
    creator: "test",
    creator_username: "test",
    invitees: {},
    attending: {},
    admins: [],
    venue: {
      name: "test",
      owner: "test",
      id: "test",
      address: {
        id: "test",
        address_line_1: "test",
        address_line_2: "test",
        city: "test",
        state: "test",
        zip_code: "test",
        country: "test",
      },
    },
  };
  return dummyEvent;

  // const eventObj = {
  //   id: event.id,
  //   name: event.name,
  //   description: event.description,
  //   date: event.date,
  //   time: event.time,
  //   attendee_count: Object.keys(event.attending).length ?? 0,
  //   invited_count: Object.keys(event.invitees).length,
  //   creator_username: event.creator.username,
  //   creator: await parseUser(event.creator),
  //   invitees: await parseUsers(event.invitees),
  //   attending: await parseUsers(event.attending),
  //   admins: await parseUsers(event.admins || []),
  //   venue: parseVenue(event.venue),
  // };

  // console.log("eventObj: ", eventObj);
  // return eventObj;
}

function parseVenue(venue) {
  return {
    name: venue.name,
    owner: venue.owner,
    id: venue.id,
    address: parseAddress(venue.address),
  };
}

function parseAddress(address) {
  return {
    id: address.id,
    address_line_1: address.address_line_1,
    address_line_2: address.address_line_2,
    city: address.city,
    state: address.state,
    zip_code: address.zip_code,
    country: address.country,
  };
}

function parseEventsJson(eventJson) {
  var events = [];
  console.log("the json: ", eventJson);

  for (var i = 0; i < eventJson.length; i++) {
    var event = eventJson[i];
    var eventObj = parseEventJson(event);
    events.push(eventObj);
  }
  return events;
}

const loadToken = async () => {
  const token = localStorage.getItem("token");
  if (token) {
    const user = await authMethods.getUser();
    if (user) {
      return token;
    }
  }
  return null;
};

const load = async (token) => {
  var token = await firebase.auth().currentUser.getIdToken(true);
  var parties = loadParties(token);
  var data = await parties;
  var json = await data.json();
  var events = parseEventsJson(json);
  return events;
};

const get = async (id, token) => {
  var rawEventData = await fetchEvent(id, token);
  console.log("rawEventData: ", rawEventData);
  var eventJSON = await rawEventData.json();
  var data = await eventJSON;
  if (data["no_name"] === true) {
    return "no_name";
  }
  var event = parseEventJson(data);
  return event;
};

const get_no_auth = async (id) => {
  var rawEventData = await fetchEvent_no_auth(id);
  var eventJSON = await rawEventData.json();
  var data = await eventJSON;
  var event = parseEventJson(data);
  return event;
};

const fetchEvent_no_auth = async (id) => {
  const body = {
    id: id,
  };
  const url = APIConfig.event_no_auth();
  const r = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "JWT " + token,
    },
    body: JSON.stringify(body),
  });
  return r;
};

const fetchEvent = async (id, token) => {
  const body = {
    id: id,
  };
  const url = APIConfig.event();
  const r = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "JWT " + token,
    },
    body: JSON.stringify(body),
  });
  return r;
};

const loadParties = async (token) => {
  const url = APIConfig.events();
  const r = await fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "JWT " + token,
    },
  });
  return r;
};

const loadInvites = async (token) => {
  const url = APIConfig.event_invites();
  const r = await fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "JWT " + token,
    },
  });
  console.log("the invites: ", r);
  const inviteJSON = await r.json();

  var events = parseEventsJson(inviteJSON);
  return events;
};

const getEvents = async () => {
  var events = await load();
  return events;
};
const rsvp = async (id, token, action) => {
  const url = APIConfig.rsvp();
  let params = {
    action: action,
    party_id: id,
  };

  const response = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "JWT " + token,
    },
    body: JSON.stringify(params),
  });

  if (!response.ok) {
    throw new Error('RSVP action failed');
  }

  return response.json();
};

const formatDateTime = (date, time) => {
  var d = new Date(date);
  var t = new Date(time);
  var year = d.getFullYear();
  var month = d.getMonth();
  var day = d.getDate();
  var hour = t.getHours();
  var minute = t.getMinutes();
  var second = t.getSeconds();
  var dt = new Date(year, month, day, hour, minute, second);
  return dt;
};

const events = {
  load,
  getEvents,
  loadParties,
  get,
  get_no_auth,
  rsvp,
  formatDateTime,
  loadInvites,
};

export default events;
