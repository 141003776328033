// PublishedMerchView.js;

import React, { useEffect, useState } from "react";
import inventory from "../../../../services/inventory";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import GridContainer from "../../../themes/GridContainer";
import StandardButton from "../../../buttons/StandardButton";
const PublishedMerchView = ({ merchItem }) => {
  const [data, setData] = useState([]);
  const [_token, setToken] = useState("");
  useEffect(() => {
    firebase.auth().onAuthStateChanged(function (user) {
      if (user) {
        //("signed in");
        firebase
          .auth()
          .currentUser.getIdToken(true)
          .then(function (idToken) {
            setToken(idToken);
            var i = inventory.queryMerchItems(idToken);
            
            // var s = profile_service.getProfileInfo(idToken);
            i.then(function (res) {
              res.json().then(function (data) {

                setData(data);
                
       
              });
            });
          });
      }
    });

    // merch_db.getPublishedMerchItems();
  }, [_token]);
  function showMerch() {
    data.array.forEach((element) => {
      return (
        <div>
          <div>{data["name"]}</div>
        </div>
      );
    });
  }

  const merch_list = data.map((file) => (
    <li key={file.name}>
      <span className="link">
        {file.name}
        <StandardButton mxt_red onClick={() => 
          {
            inventory.deleteMerchItem(_token, file.pk)
            alert("deleted")
            window.location.reload()
            // alert("item is being deleted, check back in a few.")
          }
          // {alert(_token)
          // alert(file.pk)
          // }
          }>
          x
        </StandardButton>
        {/* { deleteBtn(file)} */}
      </span>
      <br></br>
    </li>
  ));

  function deleteMerchItem() {
    inventory.deleteMerchItem(data.id);
  }

  const deleteBtn = (item) => {
    return (
      <StandardButton mxt_red onClick={deleteMerchItem(item.id)}>
        x
      </StandardButton>
    );
  };
  return (
    <GridContainer>
      <div className="padded">
        <h1>inventory</h1>
        {merch_list}
      </div>
    </GridContainer>
  );
};

export default PublishedMerchView;
