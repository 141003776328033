import {combineReducers} from 'redux'
import feedReducer from './feedReducer'
import driveReducer from './driveReducer'
import userSessionInfoReducer from './userSessionInfoReducer'
import mainViewReducer from "./mainViewReducer";


export default combineReducers({
  drive: driveReducer,
  feed: feedReducer,
  userSessionInfo: userSessionInfoReducer,
  mainView: mainViewReducer,
})