// useUserProfile.js

import { useState, useEffect } from "react";
import firebase from "firebase/compat/app";
import profile_service from "../services/profile_service";

const useUserProfile = () => {
  const [artistName, setArtistName] = useState("");
  const [isArtist, setArtistStatus] = useState(false);
  const [hasStudio, setStudioStatus] = useState(false);
  const [artistLink, setArtistLink] = useState("");
  const [stripeDashLink, setStripeDashLink] = useState(false);
  const [isSeller, setSellerStatus] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const user = firebase.auth().currentUser;
      if (user) {
        const idToken = await user.getIdToken(true);
        const s = await profile_service.getProfileInfo(idToken);
        const data = await s.json();

        if (data["artists"] && data["artists"].length > 0) {
          setArtistName(data["artists"][0]["name"]);
          setArtistStatus(true);
        } else {
          setArtistName("no artist");
        }

        setStudioStatus(data["has_studio"]);
        setSellerStatus(data["is_seller"]);

        setArtistLink(data["artist_profile_link"]);

        if (data["is_seller"] && data["seller_info"]) {
          setStripeDashLink(
            data["seller_info"]["stripe_acct_login_link"] || ""
          );
          localStorage.setItem(
            "seller_info",
            data["seller_info"]["seller_acct_id"]
          );
        }
      }
    };

    fetchData();
  }, []);

  return {
    artistName,
    isArtist,
    hasStudio,
    artistLink,
    stripeDashLink,
    isSeller,
  };
};

export default useUserProfile;
