// PublicFolderView.jsx

// import React, { useState } from "react";
// import { useParams } from "react-router-dom";
// import { useFetchSharedFolders } from "./useDriveHooks";

// const PublicFolderView = () => {
//   const { publicLinkId } = useParams();
//   const [items, setItems] = useState([]);

//   useFetchSharedFolders(setItems, publicLinkId);

//   return (
//     <div>
//       <h1>Public Folder View</h1>
//       <p>Public Link ID: {publicLinkId}</p>
//       <h2>Items:</h2>
//       <ul>
//         {items.map((item) => (
//           <li key={item.id}>
//             {item.name} - {item.type}
//           </li>
//         ))}
//       </ul>
//     </div>
//   );
// };

// export default PublicFolderView;

// PublicFolderView.jsx

import React, { useState } from "react";
import { useParams } from "react-router-dom";

import {
  useFetchSharedFolders,
  useOpenSharedItem,
} from "./hooks/useSharingHooks";
import { useUpdateFolderName, useOpenItem } from "./hooks/useDriveHooks";
import DriveItems from "./DriveItems";

const PublicFolderView = () => {
  const { publicLinkId } = useParams();
  const [loading, setLoading] = useState(false);
  const [currentPath, setCurrentPath] = useState("");
  const [items, setItems] = useState([]);
  //   const [items, setItems]  = []
  const [rootPath, setRootPath] = useState("");
  useFetchSharedFolders(setItems, setRootPath, publicLinkId);
  // const openItem = useOpenItem(setCurrentPath, currentPath, rootPath);
  const openItem = useOpenItem(setCurrentPath);
  const updateFolderName = useUpdateFolderName(items, setItems);
  // const openItem = useOpenSharedItem(setCurrentPath, currentPath, rootPath);

  return (
    <div>
      <h1>Public Folder View</h1>
      <p>Public Link ID: {publicLinkId}</p>
      <h2>Items:</h2>
      <ul>
        <DriveItems
          items={items}
          openItem={openItem}
          updateFolderName={updateFolderName}
        />
      </ul>
    </div>
  );
};

export default PublicFolderView;
