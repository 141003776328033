// Dropdown.jsx
import React from "react";

const Dropdown = ({ children, isOpen, setIsOpen }) => {
  return (
    <div className="relative">
      {React.Children.map(children, (child) =>
        React.cloneElement(child, { isOpen, setIsOpen })
      )}
    </div>
  );
};

Dropdown.Toggle = ({ children, setIsOpen }) => {
  return (
    <button
      onClick={() => setIsOpen((prevState) => !prevState)}
      className="focus:outline-none"
    >
      {children}
    </button>
  );
};

Dropdown.Menu = ({ children, isOpen }) => {
  return (
    <div
      className={`absolute bg-white mt-2 py-2 w-48 rounded-md shadow-xl ${
        isOpen ? "block" : "hidden"
      }`}
    >
      {children}
    </div>
  );
};

export default Dropdown;