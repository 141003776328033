import React from 'react';

const RadioGroup = ({ name, options, selectedValue, onChange, label }) => {
  return (
    <div className="bg-gray-100 dark:bg-gray-900 p-4 rounded-lg">
      <label className="block text-sm font-medium mb-1">{label}</label>
      <div className="flex items-center">
        {options.map((option) => (
          <label key={option.value} className="mr-4 flex items-center">
            <input
              type="radio"
              id={`${name}-${option.value}`}
              name={name}
              value={option.value}
              checked={selectedValue === option.value}
              onChange={onChange}
              className="mr-2"
            />
            {option.label}
          </label>
        ))}
      </div>
    </div>
  );
};

export default RadioGroup;