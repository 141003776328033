// SearchBar.js
import React from "react";
import SearchInput from "./SearchInput";
import SearchResults from "./SearchResults";
import Spinner from "../loading/Spinner";
import { getSearchBarStyles } from "./styles/styleUtils"; // Adjust the import path as needed
import LightSpinner from "../loading/LightSpinner";

const SearchBar = ({
  searchTerm,
  setSearchTerm,
  searchResults,
  loading,
  error,
  onSelect,
  placeholder,
  renderResult,
  styleVariant = "default",
}) => {
  const styles = getSearchBarStyles(styleVariant);

  return (
    <div className={styles.container}>
      <SearchInput
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        placeholder={placeholder}
        className={styles.input}
      />
      {loading ? (
        <div className="pt-4">
          <LightSpinner />
        </div>
      ) : (
        <SearchResults
          searchResults={searchResults}
          hasSearched={!!searchTerm}
          renderResult={renderResult}
          onSelect={onSelect}
          className={styles.results}
        />
      )}
      {error && <div>Error: {error.message}</div>}
    </div>
  );
};

export default SearchBar;
