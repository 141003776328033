import React from "react";
import useFetchProfileData from "../hooks/useFetchProfileData";
import ProfileDetailsView from "./ProfileDetailsView";
import Spinner from "../../loading/Spinner";

const ProfileView = () => {
  const { data, loading, error } = useFetchProfileData();

  if (loading) {
    return (
      <div className="flex items-center justify-center h-full p-4  bg-gray-100 dark:bg-gray-900">
        <div className="flex flex-col items-center ">
          <Spinner size={10} color="black" darkColor="white" borderWidth={3} />
          <p className="text-gray-600 dark:text-gray-300 mt-4 uppercase tracking-widest">
            Loading your profile...
          </p>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="text-center text-red-600 border border-red-600 p-4">
        Error: {error}
      </div>
    );
  }

  if (!data) {
    return (
      <div className="text-center text-gray-500 dark:text-gray-300">
        No profile data available.
      </div>
    );
  }

  return (
    <div className="p-4 border-2 border-black dark:border-white bg-gray-100 dark:bg-gray-900">
      <ProfileDetailsView data={data} />
    </div>
  );
};

export default ProfileView;
