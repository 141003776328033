import React from 'react';

const AgeRestrictionPolicy = () => {
  return (
    <div className="legal-section p-6">
      <h2>Age Restriction Policy</h2>
      <p>.mixtape is intended for users aged 13 and older. Users under 18 require parental consent.</p>
    </div>
  );
};

export default AgeRestrictionPolicy;