// ModalHeader.jsx

// ModalHeader.jsx

import React from "react";

const ModalHeader = ({ track, onClose }) => {
  return (
    <div className="bg-purple-700 px-6 py-4">
      <div className="flex justify-between items-center">
        <h3
          className="text-lg leading-6 font-medium text-gray-100"
          id="modal-headline"
        >
          {track.name}
        </h3>
        <button
          onClick={onClose}
          type="button"
          className="text-gray-300 hover:text-gray-100 focus:outline-none focus:text-gray-100"
        >
          <svg
            className="h-6 w-6"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            aria-hidden="true"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>
      </div>
    </div>
  );
};

export default ModalHeader;
