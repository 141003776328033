import { useState, useEffect } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import firebase from "firebase/compat/app";
import APIConfig from "../../../APIConfig";


const auth = firebase.auth();

const fetchEventsFromAPI = async (token) => {
  const url = APIConfig.event_invites();
  const response = await fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "JWT " + token,
    },
  });

  if (!response.ok) {
    throw new Error("Failed to fetch events");
  }

  return response.json();
};

const parseEventJson = (data) => {
  return {
    id: data.id,
    name: data.name,
    description: data.description,
    date: data.date,
    time: data.time,
    attendee_count: Object.keys(data.attending).length,
    invited_count: Object.keys(data.invitees).length,
    creator_username: data.creator.username,
    creator: data.creator,
    invitees: data.invitees,
    attending: data.attending,
    admins: data.admins || [],
    venue: data.venue,
  };
};

const useEvents = () => {
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [user] = useAuthState(auth);

  useEffect(() => {
    const fetchEvents = async () => {
      if (user) {
        setLoading(true);
        setError(null);
        try {
          const token = await user.getIdToken(true);
          const eventsData = await fetchEventsFromAPI(token);
          const parsedEvents = eventsData.map(parseEventJson);
          setEvents(parsedEvents);
        } catch (err) {
          setError(err.message);
        }
        setLoading(false);
      }
    };

    if (user) {
      fetchEvents();
    } else {
      setLoading(false);
    }
  }, [user]);

  return {
    events,
    loading,
    error,
  };
};

export default useEvents;