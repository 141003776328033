import { useState } from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/storage';
import api from '../../api';
import drafting from '../../services/drafting';
import { sanitizeFilename } from '../artist_dashboard/utilities/fileUtils';

const MAX_FILE_SIZE_MB = 2000;

const useAudioStorage = () => {
    const [files, setFiles] = useState([]);
    const [progress, setProgress] = useState(0);
    const user = firebase.auth().currentUser;

    const onFileChange = (e) => {
        return new Promise((resolve) => {
            const newFiles = Array.from(e.target.files).map(file => ({
                ...file,
                id: Math.random(),
                progress: 0
            }));
            setFiles(newFiles);
            resolve(); // Resolve the promise after setting the files
        });
    };

    const isFileSizeValid = (file) => Math.round(file.size / 1000000) <= MAX_FILE_SIZE_MB;

    const uploadFile = (file) => new Promise((resolve, reject) => {
        const sanitizedFilename = sanitizeFilename(file.name);

        if (!isFileSizeValid(file)) {
            alert("Error: File too large");
            reject(new Error("File too large"));
            return;
        }

        const uploadTask = firebase
            .storage()
            .ref(`studio/user_storage/${user.uid}/files/audio/${sanitizedFilename}`)
            .put(file);

        uploadTask.on(
            firebase.storage.TaskEvent.STATE_CHANGED,
            (snapshot) => {
                const currentProgress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                setProgress(currentProgress);
            },
            (error) => reject(error),
            async () => {
                const downloadURL = await uploadTask.snapshot.ref.getDownloadURL();
                api.setStudioData(downloadURL, user.uid, sanitizedFilename); // Use sanitized filename
                resolve(downloadURL);
            }
        );
    });

    const uploadFiles = () => {
        const uploadPromises = files.map(file => uploadFile(file));
        return Promise.all(uploadPromises);
    };


    // Inside useAudioStorage hook
    const uploadAndReplaceAudioForTrack = async (file, track, album) => {
        const sanitizedFilename = sanitizeFilename(file.name);
        const fileRef = firebase.storage().ref(`studio/user_storage/${user.uid}/files/audio/${sanitizedFilename}`);

        const uploadTask = fileRef.put(file);

        uploadTask.on(
            firebase.storage.TaskEvent.STATE_CHANGED,
            (snapshot) => {
                const currentProgress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                setProgress(currentProgress); // Updates the progress bar in the UI
            },
            (error) => {
                console.error("Upload error:", error);
                throw error;
            },
            async () => {
                try {
                    const downloadURL = await uploadTask.snapshot.ref.getDownloadURL();
                    await api.setStudioData(downloadURL, user.uid, sanitizedFilename); // Use sanitized filename
                    await updateTrack(track, album, downloadURL, sanitizedFilename); // Use sanitized filename
                    setProgress(0); // Reset progress after the upload is complete
                } catch (error) {
                    console.error("Error getting download URL:", error);
                    throw error;
                }
            }
        );
    };


    const updateTrack = async (track, album, fileURL, sanitizedFilename) => {
        const trackRef = firebase.firestore()
            .collection("studio")
            .doc(user.uid)
            .collection("drafts")
            .doc(album.docId)
            .collection("tracks")
            .doc(track.id);

        await trackRef.update({
            filename: sanitizedFilename,
            url: fileURL
        });
    };
    const uploadAndAddToAlbum = async (file, album) => {
        if (!isFileSizeValid(file)) {
            alert("Error: File too large");
            throw new Error("File too large");
        }

        const uploadTask = firebase
            .storage()
            .ref(`studio/user_storage/${user.uid}/files/audio/${file.name}`)
            .put(file);

        return new Promise((resolve, reject) => {
            uploadTask.on(
                firebase.storage.TaskEvent.STATE_CHANGED,
                (snapshot) => {
                    const currentProgress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                    setProgress(currentProgress);
                },
                (error) => reject(error),
                async () => {
                    const downloadURL = await uploadTask.snapshot.ref.getDownloadURL();
                    api.setStudioData(downloadURL, user.uid, file.name);

                    const trackData = {
                        content: file.name,
                        compressed_filename: '',
                        url: downloadURL,
                        index: Date.now(),
                        trackName: file.name
                    };

                    try {
                        const trackId = await drafting.getNewTrackId(album, trackData);
                        resolve(trackId);
                    } catch (error) {
                        reject(error);
                    }
                }
            );
        });
    };
    const addFileToAlbum = async (file, index, downloadURL, album) => {
        const trackData = {
            content: file.name,
            compressed_filename: '', // Modify as needed
            url: downloadURL,
            index: index, // Or calculate based on existing tracks
            trackName: file.name // Modify as needed
        };
        return drafting.getNewTrackId(album, trackData);
    };

    const updateFileProgress = (fileId, snapshot) => {
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setFiles(prevFiles => prevFiles.map(
            file => file.id === fileId ? { ...file, progress } : file
        ));
        updateOverallProgress();
    };

    const updateOverallProgress = () => {
        const totalProgress = files.reduce((acc, file) => acc + file.progress, 0);
        setProgress(Math.trunc(totalProgress / files.length));
    };

    const deleteFile = async (fileName) => {
        try {
            // Delete file from Firebase Storage
            const fileRef = firebase.storage().ref(`studio/user_storage/${user.uid}/files/audio/${fileName}`);
            await fileRef.delete();
    
            // Delete corresponding document from Firestore
            const fileDocRef = firebase.firestore().collection("studio").doc(user.uid).collection("files").where("filename", "==", fileName);
            const snapshot = await fileDocRef.get();
            snapshot.forEach(doc => {
                doc.ref.delete();
            });
    
            // Scan through user's albums to update any track reference to this file
            const albumsRef = firebase.firestore().collection("studio").doc(user.uid).collection("drafts");
            const albumsSnapshot = await albumsRef.get();
            albumsSnapshot.forEach(async albumDoc => {
                const tracksRef = albumsRef.doc(albumDoc.id).collection("tracks");
                const tracksSnapshot = await tracksRef.where("filename", "==", fileName).get();
                tracksSnapshot.forEach(trackDoc => {
                    trackDoc.ref.update({
                        filename: "", 
                        url: "", 
                        compressed_filename: ""
                    }); // Update the track reference with blank file fields
                });
            });
    
            console.log("File and associated document deleted, track references updated successfully.");
        } catch (error) {
            console.error("Error deleting file and updating its references: ", error);
        }
    };
    
    return {
        files, progress, onFileChange, uploadFiles, uploadFile, uploadAndAddToAlbum, deleteFile, uploadAndReplaceAudioForTrack
    };
};

export default useAudioStorage;
