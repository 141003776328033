import Spinner from "../loading/Spinner";

// SearchResults.jsx

const ResultItem = ({ result, renderResult, onSelect }) => {
    const handleClick = () => {
      if (onSelect) {
        onSelect(result);
      }
    };
  
    return (
      <li onClick={handleClick} className="search-result-item">
        {renderResult(result)}
      </li>
    );
  };

  const SearchResults = ({
    isSearching,
    searchResults,
    hasSearched,
    renderResult,
    onSelect,
  }) => {
  if (isSearching) return <Spinner />;
  if (hasSearched && searchResults.length === 0) {
    return <div>No results found.</div>;
  }

 return (
    <ul>
      {searchResults.map((result) => (
        <ResultItem
          key={result.id}
          result={result}
          renderResult={renderResult}
          onSelect={onSelect}
        />
      ))}
    </ul>
  );
};


export default SearchResults;
