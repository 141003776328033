import React, { useState } from 'react';
import useWaitlist from './useWaitlist';

const JoinWaitlistForm = () => {
  const [email, setEmail] = useState('');
//   const { addToWaitlist } = useWaitlist();

  const handleSubmit = async (e) => {
    e.preventDefault();
    // await addToWaitlist(email);
    setEmail('');
  };

  return (
    <form onSubmit={handleSubmit}>
      <label htmlFor="email">Email:</label>
      <input
        type="email"
        id="email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        required
      />
      <button type="submit">Join Waitlist</button>
    </form>
  );
};

export default JoinWaitlistForm;
