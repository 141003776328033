// styleUtils.js
export const getSearchBarStyles = (styleVariant) => {
    switch (styleVariant) {
      case 'compact':
        return {
          container: "max-w-sm mx-auto",
          input: "text-sm",
          results: "text-sm",
          
          // More compact styles...
        };
      case 'default':
      default:
        return {
          container: "max-w-lg mx-auto",
          input: "text-base",
          results: "text-base",
          // Default styles...
        };
    }
  };
  