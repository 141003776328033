import React, { useState, useEffect, useContext } from "react";
import { useFetchPublicSharedFolders } from "./hooks/useDriveHooks";
import {
  FolderOpenIcon,
  DocumentIcon,
  UsersIcon,
} from "@heroicons/react/24/solid";

import AudioPlayerContext from "./AudioPlayerContext";
import SkeletonLoader from "./indicators/SkeletonLoader";
import DriveItem from "./DriveItem";

const SharedFolderViewer = ({ match }) => {
  const [publicSharedItems, setPublicSharedItems] = useState([]);
  const [folderInfo, setFolderInfo] = useState({});
  const [currentPath, setCurrentPath] = useState("");
  const [loading, setLoading] = useState(true); // Add this line
  const publicLinkId = match.params.publicLinkId;
  const { playAudio } = useContext(AudioPlayerContext);
  const [fileNotFound, setFileNotFound] = useState(false); // Add this

  // Fetch the public shared folder content
  useFetchPublicSharedFolders(
    setFolderInfo,
    setPublicSharedItems,
    publicLinkId,
    currentPath,
    setLoading,
    setFileNotFound
  );

  const handleFolderClick = (folderName) => {
    setCurrentPath((prevPath) => prevPath + "/" + folderName);
  };

  const handleItemClick = (item) => {
    if (item.type === "audio") {
      playAudio({
        title: item.name,
        artist: "Unknown",
        color: "",
        image: "",
        audioSrc: item.downloadURL,
      });
    }
  };

  const getMainSharedFolerView = () => {
    return (
      <div>
        {loading ? ( // Add this line
          <SkeletonLoader /> // Replace this line
        ) : (
          //   <ul className="list-none space-y-2">
          <div className="max-w-5xl mx-auto py-10 px-4 min-h-screen">
            <ul className="grid grid-cols-1 gap-4 ">
              {folderInfo.name && (
                <li
                  key={folderInfo.id}
                  className="flex items-center space-x-2 "
                  onClick={() => {
                    if (folderInfo.type === "folder") {
                      handleFolderClick(folderInfo.name);
                    }
                  }}
                >
                  <span>
                    {folderInfo.type === "folder" ? (
                      <FolderOpenIcon className="h-5 w-5" />
                    ) : (
                      <span role="img" aria-label="File">
                        📄
                      </span>
                    )}
                  </span>
                  <span>
                    {folderInfo.type === "folder" ? (
                      <UsersIcon className="h-5 w-5" />
                    ) : (
                      <span role="img" aria-label="File">
                        📄
                      </span>
                    )}
                  </span>
                  <span>{folderInfo.name}</span>
                </li>
              )}
              <div class="">
                {publicSharedItems.map((item) => (
                  <li
                    key={item.id}
                    className="py-1.5 cursor-pointer"
                    onClick={() => {
                      if (item.type === "folder") {
                        handleFolderClick(item.name);
                      } else {
                        handleItemClick(item);
                      }
                    }}
                  >
                    <span>
                      {item.type === "audio" ? (
                        <DriveItem
                          item={item}
                          handleItemClick={handleItemClick}
                        />
                      ) : (
                        //   <MusicalNoteIcon className="h-5 w-5" aria-label="Audio" />
                        <DocumentIcon className="h-5 w-5" aria-label="File" />
                      )}
                    </span>

                    {/* <span>{item.name}</span> */}
                  </li>
                ))}
              </div>
            </ul>
          </div>
        )}
      </div>
    );
  };
  // Render the shared folder content
  return (
    <div className="shared-folder-viewer">
      {fileNotFound ? <div>File not found</div> : getMainSharedFolerView()}
    </div>
  );
};

export default SharedFolderViewer;
