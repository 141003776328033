import React, { useState } from "react";
import { TrashIcon } from "@heroicons/react/24/outline";
import tape_service from "../../../services/tape_service";

const DeleteTapeButton = ({ tapeId, onDelete, isDeleting }) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleDelete = async (event) => {
    event.preventDefault();
    event.stopPropagation();

    setIsLoading(true);

    const response = await tape_service.deleteTape(tapeId);
    setIsLoading(false);

    if (response.status === 200 && onDelete) {
      onDelete(tapeId);
    } else {
      alert("Error deleting tape. Please try again later.");
      // Handle deletion error
    }
  };

  return (
    <>
      {isLoading ? (
        <div className="flex justify-center items-center">
          <svg
            className="animate-spin h-5 w-5 text-gh_black-100"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle
              className="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              strokeWidth="4"
            ></circle>
            <path
              className="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l1-1.647z"
            ></path>
          </svg>
        </div>
      ) : (
        <button
          className="text-gh_black-100 hover:text-red-500 focus:outline-none"
          onClick={handleDelete}
          disabled={isDeleting}
        >
          <TrashIcon className="w-5 h-5" />
        </button>
      )}
    </>
  );
};

export default DeleteTapeButton;
