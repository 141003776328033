import React from 'react';

const Textarea = ({ id, name, value, onChange, label, rows }) => {
  return (
    <div className="flex flex-col">
      <label htmlFor={id} className="block text-sm font-medium mb-1">
        {label}
      </label>
      <textarea
        id={id}
        name={name}
        value={value}
        onChange={onChange}
        className="w-full px-3 py-2 border bg-inherit border-gray-300 rounded-md"
        rows={rows}
      ></textarea>
    </div>
  );
};

export default Textarea;