import React from "react";
import PhotoNavigation from "./PhotoNavigation";

const ImageDisplay = ({ photo, currentIndex, memories, navigateToPhoto }) => (
  <div className="flex justify-center items-center bg-black h-screen md:min-h-500">
    <img
      src={photo.downloadURL}
      alt={photo.name}
      className="w-full h-auto max-h-screen p-4 object-contain"
    />
    <PhotoNavigation
      currentIndex={currentIndex}
      totalCount={memories.length}
      onNavigate={navigateToPhoto}
    />
  </div>
);

export default ImageDisplay;
