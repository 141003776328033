import React, { useState } from "react";
import { Link } from "react-router-dom";
import APIConfig from "../../APIConfig"; // Import the APIConfig module
import useDarkMode from "../../hooks/useDarkMode";

const WideLinkButton = (props) => {
  const [loading, setLoading] = useState(false);
  const isAirtrak = APIConfig.airtrak();
  const isDarkMode = useDarkMode();

  if (isAirtrak) {
    return (
      <Link to={props.path}>
        <div
          className={`pb-2 pt-2 border-2 px-4 rounded-md justify-center font-theFutureMonoRegular py-8 transition duration-300 ease-in-out transform ${"border-white text-white hover:bg-white hover:text-gh_black-100"
              // : "border-gh_black-100 text-gh_black-100  hover:border-gh_blue-100 hover:bg-gh_blue-100 hover:text-white"
}`}
        >
          <div className="-translate-y-0.5">{props.text}</div>
        </div>
      </Link>
    );
  }

  return (
    <Link to={props.path}>
      <div className="text-center w-40 h-7 bg-slate-700 text-slate-200 font-theFutureMonoRegular hover:bg-violet-400 hover:text-slate-300 hover:border-slate-300 dark:text-violet-300 dark:border-violet-300 border-2 col-span-1">
        {props.text}
      </div>
    </Link>
  );
};

export default WideLinkButton;
