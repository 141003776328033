// GridContainer.js;

import styled from "styled-components";

const GridContainer = styled.div`
  /* display: flex; */
  .container {
    display: "flex";
    justify-content: "center";
    align-items: "center";
    background-color: "blue";
  }
  .center {
    flex-direction: row;
    justify-content: space-space-around;
    align-items: center;
    padding-top: 0.4vh;
    padding-bottom: 0.4vh;
    padding-left: 1vh;
    padding-right: 1vh;
  }
  .leading {
    align-items: flex-start;
  }
  .active_program {
    display: flex;
    flex-direction: row;
    /* justify-content: space-around; */
    align-items: center;
    /* width: 20em; */
    opacity: 0.6;

    padding-left: 2em;
    color: #00c929;
    padding-top: 40em;
  }

  .link {
    color: blue;
  }

  .active_program_higher {
    display: flex;
    flex-direction: row;
    /* justify-content: space-around; */
    align-items: center;
    /* width: 20em; */
    opacity: 0.6;

    padding-left: 2em;
    color: #00c929;
    padding-top: 30em;
  }
  .row {
    display: flex;
    flex-direction: row;
  }

  .row2 {
    display: flex;
    flex-direction: row;
    justify-content: space-space-around;

  }
  .grey {
    color: grey;
  }
  .column {
    display: flex;
    flex-direction: column;
  }
  .padding-top-5 {
    padding-top: 5px;
  }
  .padding-top-10 {
    padding-top: 10px;
  }
  .flex-container {
    flex-direction: row;
  }
  .vertical-center {
  // half way down the screen but also horizontally centered
    
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    

  }

  .horizontal-stack {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    padding-top: 0.4vh;
    padding-bottom: 0.4vh;
    padding-left: 1vh;
    padding-right: 1vh;
    // padding between elements is 1vh


  }

  .h-stack {
    // padding between elements
    
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    padding-top: 0.4vh;
    padding-bottom: 0.4vh;
    padding-left:5 vh;
    padding-right: 5vh;
    // padding between elements is 1vh


  }
  .vertically-centered-row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  .grid {
    display: grid;
    gap: 1rem;
    grid-auto-flow: column;
    grid-template-columns: repeat(auto-fit, minmax(min-content, 1fr));
  }
  .padded {
    padding: 30px;
  }
  .padded-15 {
    padding: 15px;
  }

  .padded-50 {
    padding: 50px;
  }
  .horizontal {
    flex-direction: row;
  }
  .center {
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-top: 2vh;
    align-items: center;
    /* height: 50vh; */
  }

  .dark {
    background-color: black;
    color: white;
    font-family: "Menlo", monospace;
    font-size: 1.2em;
  }
  .light {
    /* color: black; */
    /* background: white; */
  }

  .text-area {
    width: 30em;
    height: 5em;
  }

  .text-box {
    border-radius: 0;
    width: 30em;
  }
  .left {
    padding: 20px;
    padding-right: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-top: 2vh;
    align-items: flex-start;
    width: 80vh;
    /* height: 50vh; */
  }
  .lavender {
    background: cornflowerblue;
  }
  .bordered {
    padding: 5px;
    border: 0.5px solid cornflowerblue;
  }
  .right {
    
      float: right;
      padding-right: 2em;
      padding-top: 0.5em;

  }
  .bordered-green {
    padding: 15px;
    border: 0.1px solid #00c929;
  }
  .bordered-blue {
    padding: 15px;
    border: 5px solid blue;
  }

  .bordered-red {
    padding: 15px;
    border: 5px solid red;
  }
  .green {
    color: #00c929
  }
  .padded-left {
    padding-left: 1em;
  }
  .padded-left-m {
    padding-left: 7em;
  }
  .padded-5 {
    padding: 5px;
  }
  .bordered-light {
    border: 0.5px solid lightgray;
  }
  .grid-button {
    color: cornflowerblue;
    font-size: 1em;
    margin: 1em;
    padding: 0.25em 1em;
    border: 2px solid cornflowerblue;
    /* border-radius: 3px; */
  }
  .line {
    /* font-size: 2em; */
    /* padding: 1em; */
    padding: 10px;
    border-bottom: 1px solid blue;
    width: 20em;
    padding-left: 1em;
  }
`;

export default GridContainer;
