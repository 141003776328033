import React, { createContext, useState } from "react";
import AudioFacade from "./audioFacade";

const MultiTrackContext = createContext({});

export const MultiTrackProvider = ({ children }) => {
  const [isPaused, setIsPaused] = useState(true);
  const [progress, setProgress] = useState(0);
  const [tracks, setTracks] = useState([]);
  const [loadingTracks, setLoadingTracks] = useState(false);

  const audioFacade = new AudioFacade();

  const togglePause = () => {
    setIsPaused((prevIsPaused) => {
      audioFacade.playTracks(!prevIsPaused);
      return !prevIsPaused;
    });
  };

  const updateProgress = (value) => {
    setProgress(value);
  };

  const initTracks = async (urls) => {
    setLoadingTracks(true);
    const buffers = await audioFacade.loadAndSaveAudio(urls);
    const tracksArr = buffers.map((buffer, index) => {
      return {
        buffer,
        url: urls[index],
        hasStarted: false,
        volume: 1,
        pan: 0,
      };
    });

    audioFacade.setSources(tracksArr, () => {
      setIsPaused(false);
      audioFacade.playTracks(false);
      setTracks(tracksArr);
      setLoadingTracks(false);
    });
  };

  return (
    <MultiTrackContext.Provider
      value={{
        isPaused,
        progress,
        tracks,
        loadingTracks,
        context: audioFacade.context,
        masterGainNode: audioFacade.masterGainNode,
        togglePause,
        updateProgress,
        initTracks,
      }}
    >
      {children}
    </MultiTrackContext.Provider>
  );
};

export default MultiTrackContext;
