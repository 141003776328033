import React, { useState, useEffect, useContext } from "react";
import { AudioContext } from "standardized-audio-context";
import { useParams } from "react-router-dom";
import { ArrowDownIcon } from "@heroicons/react/24/solid";
import Slider from "./Slider";
import ChannelControls from "./ChannelControls";
import { loadAudio, loadAndSaveAudio } from "./audioHelpers";
import RecordButton from "./recorder/RecordButton";
import AudioRecorder from "./recorder/AudioRecorder";
import PlayPauseButton from "./PlayPauseButton";
import TrackList from "./TrackList";
import Playback from "./Playback";
import MultiTrackContext from "../../../file_mgmt/multi_uploader/MultiTrackContext";
import DownloadButton from "./DownloadButton";
const MultiPlayerView = (props) => {
  const [loading, setLoading] = useState(false);
  const [playerAvailable, setPlayerAvailable] = useState(false);

  const { isPaused, togglePause, tracks, loadingTracks, initTracks } =
    useContext(MultiTrackContext);

  const handleInitTracks = () => {
    const urls = props.tracks.map((track) => track.url);
    setLoading(true);
    initTracks(urls).then(() => {
      setLoading(false);
      setPlayerAvailable(true);
    });
  };

  return (
    <div className="flex flex-col items-center">
      {loadingTracks ? (
        <div>loading...</div>
      ) : ( 
        <>
          <div className="flex mb-4">
            {playerAvailable && (
              <PlayPauseButton
                isPlaying={!isPaused}
                onClick={togglePause}
              ></PlayPauseButton>
            )}
          </div>
          {!playerAvailable && <DownloadButton onClick={handleInitTracks} />}
          <TrackList tracks={tracks} />
        </>
      )}
    </div>
  );
};

export default MultiPlayerView;
