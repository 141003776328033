// ArtistPublicProfile.js;
import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import firebase from "firebase/compat/app";
import "firebase/compat/auth";

import { firebaseAuth } from "../provider/AuthProvider";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import DemoText from "./DemoText";
import CheckoutForm from "./CheckoutForm";
import api from "../api";
import profile_service from "../services/profile_service";
import Product from "./subscriptions/Product";
import SubscriptionPaymentForm from "./subscriptions/SubscriptionCheckoutForm";
import Player from "./media_player/Player";

const stripePromise = api.getPublicStripeKey().then((key) => loadStripe(key));

const ArtistPublicProfile = () => {
  let location = useLocation();
  //(location);

  function handleClick(key) {
    setProduct(products[key]);
  }
  var artist_slug = location.pathname.split("/").pop();
  const [theme, setTheme] = useState("light");
  const [isArtist, setArtistStatus] = useState(false);

  const [hasStudio, setStudioStatus] = useState(false);
  const [artistName, setArtistName] = useState("");
  const [isSeller, getSellerStatus] = useState(false);
  const [email, setEmail] = useState("");
  const [customer, setCustomer] = useState(null);
  const [productSelected, setProduct] = useState(null);
  const [artistId, setArtistId] = useState(0);
  const [subName, setSubName] = useState("...");
  const [subPrice, setSubPrice] = useState("");

  const products = [
    {
      key: 0,
      price: subPrice,
      name: subName,
      interval: "month",
      billed: "monthly",
    },
    // {
    //   key: 1,
    //   price: "$15.00",
    //   name: "pro studio",
    //   interval: "month",
    //   billed: "monthly",
    // },
  ];

  useEffect(() => {
    var name, email, photoUrl, uid, emailVerified;
    var user = firebase.auth().currentUser;

    firebase.auth().onAuthStateChanged(function (user) {
      if (user) {
        //("signed in");
        firebase
          .auth()
          .currentUser.getIdToken(/* forceRefresh */ true)
          .then(function (idToken) {
            getCustomerInfo(idToken);
            var s = profile_service.getArtistProfileInfo(idToken, artist_slug);
            // //("the res: ", s);
            s.then(function (res) {
              res.json().then(function (data) {
                //(data);
                var artist_name = data["artist"].name;
                setArtistName(artist_name);
                var _artistId = data["artist"].id;
                setArtistId(_artistId);
                var sub_name = data["subscription"].name;
                setSubName(sub_name);
                // var sub_price = data["subscription"].price;
                // setSubPrice(sub_price);
                setSubPrice("$2.99");
                // if (data["artists"].length > 0) {
                //   var artist_name = data["artists"][0]["name"];
                //   // if data[""]

                //   //(artist_name);
                //   setArtistName(artist_name);
                //   // setArtistStatus(true);
                // } else {
                //   setArtistName("no artist");
                // }
                // if (data["customer_info"]) {
                //   // var payment_method =
                //   //   data["customer_info"]["invoice_settings"][
                //   //     "default_payment_method"
                //   //   ];
                //   var cus_data = JSON.stringify(data["customer_info"]);
                //   localStorage.setItem("customer_info", cus_data);
                //   // //(payment_method);
                // }
                // // if (data["has_studio"]) {
                // setStudioStatus(data["has_studio"]);
                // // }
                // if (data["is_seller"]) {
                //   //(data["is_seller"]);
                //   getSellerStatus(data["is_seller"]);
                // }
              });
              // alert("artist created");
            });
          })
          .catch(function (error) {
            // Handle error
          });
        email = user.email;
        photoUrl = user.photoURL;
        emailVerified = user.emailVerified;
        uid = user.uid; // The user's ID, unique to the Firebase project. Do NOT use
      } else {
        return "___";
      }
    });
  });
  function getCustomerInfo(token) {
    if (!customer) {
      var ci = profile_service.getCustomerInfo(token);
      ci.then((res) => {
        res.json().then(function (data) {
          setCustomer(data.customer);
        });
      });
    }
  }
  
  return (
    <div>
      <h1>{artistName == "" ? "" : artistName}</h1>
      {/* artists */}

      {/* {JSON.stringify(customer)} */}
      <div className="p-6">
        <div className="flex flex-wrap justify-center">
          <div className="md:w-1/3 w-full mr-4 md:mb-8">
            <div className="text-center text-pasha font-bold text-2xl mt-4 mb-6"></div>
            
            {artistName == ""
              ? "loading..."
              : "sign up for " + artistName + "'s .mixtape"}
              
            <div className="flex justify-between mb-8">
              {products.map((product, index) => {
                return (
                  <Product
                    key={index}
                    product={product}
                    handleClick={handleClick}
                  />
                );
              })}
            </div>
            {productSelected ? (
              <SubscriptionPaymentForm
                productSelected={productSelected}
                artistId={artistId}
                customer={customer}
              />
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};
export default ArtistPublicProfile;
