import React from 'react';

const EventImage = () => {
  const imageUrl = 'https://unsplash.it/600/600?random';

  return (
    <div className="relative">
      <img
        src={imageUrl}
        alt="Event"
        className="w-full h-full object-cover rounded-lg shadow-lg"
      />
      <div className="absolute bottom-2 right-2 bg-white dark:bg-gray-700 rounded-full p-2 shadow-lg">
        <svg
          className="w-6 h-6 text-gray-700 dark:text-gray-300"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <circle cx="12" cy="12" r="10" />
        </svg>
      </div>
    </div>
  );
};

export default EventImage;