// EditableText.jsx
import React, { useState } from "react";

const EditableText = ({ text, onSave, isEditing, setIsEditing }) => {
  const [inputValue, setInputValue] = useState(text);

  const handleSave = () => {
    onSave(inputValue);
    setIsEditing(false);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleSave();
    } else if (e.key === "Escape") {
      setIsEditing(false);
    }
  };

  return isEditing ? (
    <input
      value={inputValue}
      onChange={(e) => setInputValue(e.target.value)}
      onBlur={handleSave}
      onKeyDown={handleKeyDown}
      autoFocus
    />
  ) : (
    <span onClick={() => setIsEditing(true)}>{text}</span>
  );
};

export default EditableText;
