// db.collection("cities").where("state", "==", "CA")
//     .onSnapshot((snapshot) => {
//         snapshot.docChanges().forEach((change) => {
//             if (change.type === "added") {
//                 console.log("New city: ", change.doc.data());
//             }
//             if (change.type === "modified") {
//                 console.log("Modified city: ", change.doc.data());
//             }
//             if (change.type === "removed") {
//                 console.log("Removed city: ", change.doc.data());
//             }
//         });
//     });



// import APIConfig from "./APIConfig";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import APIConfig from "../APIConfig";
import utils from "../components/artist_dashboard/utilities/utils";
import { authMethods } from "../firebase/authmethods";

// import { authMethods } from "./firebase/authmethods";

// import { authMethods } from "../../firebase/authmethods";
const token = localStorage.getItem("token");
// const b = APIConfig.baseURL();
// ${APIConfig.baseURL()}
// const base = APIConfig.baseURL()
let db = firebase.firestore();
var user = firebase.auth().currentUser;
const authUser = Object.keys(window.localStorage).filter((item) =>
  item.startsWith("firebase:authUser")
)[0];

async function getOrdersRef() {
    var promise = new Promise((resolve, reject) => {
      firebase.auth().onAuthStateChanged(function (user) {
        if (user) {
          firebase
            .auth()
            .currentUser.getIdToken(true)
            .then(function (idToken) {
            //   setToken(idToken);
              resolve(user.uid);
            });
        }
      });
    });

    return promise;
  }
const orders = {
  getOrdersRef
};

export default orders;
