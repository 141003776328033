// DownloadButton.jsx
import React from "react";

const DownloadButton = ({ onClick }) => {
  return (
    <button
      className="p-2 bg-blue-500 text-white rounded"
      onClick={onClick}
    >
      load audio
    </button>
  );
};

export default DownloadButton;
