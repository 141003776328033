import React, { useContext, Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import { CogIcon, UserIcon, ArrowLeftIcon } from "@heroicons/react/24/outline";
import { NavLink } from "react-router-dom";
import { firebaseAuth } from "../../../provider/AuthProvider";

const ProfileMenu = ({ user }) => {
  const { handleSignout } = useContext(firebaseAuth);

  return (
    <Menu as="div" className="relative inline-block text-left">
      <Menu.Button className="flex items-center space-x-2 border-2 border-black dark:border-white px-4 py-2 bg-gray-100 dark:bg-gray-900 text-black dark:text-white hover:bg-gray-200 dark:hover:bg-gray-800 font-mono font-bold shadow-none">
        <UserIcon className="h-6 w-6" />
        <span className="text-sm">{user.displayName || "Profile"}</span>
      </Menu.Button>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 mt-2 w-64 bg-white dark:bg-black border-2 border-black dark:border-white shadow-none">
          <div className="py-1 text-left">
            <Menu.Item>
              {({ active }) => (
                <NavLink
                  to="/settings"
                  className={`${
                    active ? "bg-gray-200 dark:bg-gray-800" : ""
                  } block px-5 py-3 text-sm font-bold text-black dark:text-white uppercase tracking-wide border-b-2 border-black dark:border-gray-700 flex items-center space-x-2`}
                >
                  <CogIcon className="h-5 w-5" />
                  <span>Settings</span>
                </NavLink>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <button
                  onClick={handleSignout}
                  className={`${
                    active ? "bg-gray-200 dark:bg-gray-800" : ""
                  } block px-5 py-3 text-sm font-bold text-black dark:text-white uppercase tracking-wide w-full text-left border-b-2 border-black dark:border-gray-700 flex items-center space-x-2`}
                >
                  <ArrowLeftIcon className="h-5 w-5" />
                  <span>Sign Out</span>
                </button>
              )}
            </Menu.Item>
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

export default ProfileMenu;
