import React from 'react';

const CopyrightPolicy = () => {
  return (
    <div className="legal-section p-6">
      <h2>Copyright Policy</h2>
      <p>.mixtape will terminate accounts of users who repeatedly infringe copyright.</p>
      <p>Infringing content may be removed immediately. Users agree not to upload copyrighted materials without authorization.</p>
    </div>
  );
};

export default CopyrightPolicy;