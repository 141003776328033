import React, { useState } from "react";
import Spinner from "../../loading/Spinner";
import storage from "../../../services/storage";
import { PencilIcon, CheckIcon, XMarkIcon } from "@heroicons/react/24/outline";

export default function EditAlbumMetadataView({ album }) {
  const [isEditingAlbumName, setEditingAlbumName] = useState(false);
  const [albumName, setAlbumName] = useState(album.name);
  const [isSaving, setIsSaving] = useState(false);

  const saveAlbumName = async () => {
    setIsSaving(true);
    try {
      await storage.setAlbumName(albumName, album);
      setEditingAlbumName(false);
    } catch (error) {
      console.error("Error saving album name:", error);
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <div className="flex flex-col py-5">
      {!isEditingAlbumName ? (
        <div className="flex items-center">
          <span className="text-lg  mr-2">{albumName}</span>
          <PencilIcon
            className="h-5 w-5 text-gray-500 cursor-pointer"
            onClick={() => setEditingAlbumName(true)}
          />
        </div>
      ) : (
        <div className="flex flex-col space-y-2">
          <input
            type="text"
            value={albumName}
            onChange={(e) => setAlbumName(e.target.value)}
            className="p-2 border-2 border-gray-300 bg-inherit shadow-sm focus:border-blue-500 transition-all duration-200"
          />
          <div className="flex space-x-2">
            <button
              className="bg-green-500 text-white px-2 py-1 border-2 border-green-500 rounded-lg flex items-center"
              onClick={saveAlbumName}
              disabled={isSaving}
            >
              {isSaving ? <Spinner /> : <CheckIcon className="h-5 w-5" />}
              Save
            </button>
            <button
              className="text-red-500 border-2 border-red-500 px-2 py-1 rounded-lg flex items-center"
              onClick={() => setEditingAlbumName(false)}
              disabled={isSaving}
            >
              <XMarkIcon className="h-5 w-5" />
              Cancel
            </button>
          </div>
        </div>
      )}
    </div>
  );
}
