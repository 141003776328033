// LabelRegistrationView.jsx
import React, { useState, useEffect } from "react";

import { useForm } from "react-hook-form";
import api from "../../api";
import { fb } from "../../firebase/fb";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import storage from "../../services/storage";
import studio_api from "../../studio_api";
import StandardButton from "../buttons/StandardButton";
import Container from "../themes/Container";
import GridContainer from "../themes/GridContainer";
import label_api from "../../services/label_api";
var fs_uid = "";
async function onSubmitForm(formData) {
  // //(formData);
  var s = await api.newLabel(formData["labelName"], fs_uid);
  s.json().then(function (data) {
    //(data["artistName"]);
    window.location.reload();
    alert("label created");
  });
}

// function submit(name) {
// api.submitArtistName("sammydelila");
//   api.me();
//   // api.dothing();
//   // alert("Hi your artist name is: " + formData.artistName);
// }

const connectStripe = () => {
  fb.connect();
};

export default function LabelRegistrationView() {
  const { register, handleSubmit } = useForm();
  const [label, setLabel] = useState("");
  const [artists, setArtists] = useState([]);
  // Similar to componentDidMount and componentDidUpdate:
  // setLabel() {

  // }
  useEffect(() => {
    // Update the document title using the browser API
    // studio_api.login();
    // api.me();
   
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        fs_uid = user.uid;
        
        firebase
        .auth()
        .currentUser.getIdToken(/* forceRefresh */ true)
        .then(function (token) {
          var a = label_api.getLabelArtists(token)
          console.log("aaaa", a)
          a.then(function (info) {
            console.log("a<", info)
      
          })
        })



        //("loggedin");
      } else {
        //("signed out....");
      }
    });
  },[]);
  return (
    <GridContainer className="center padded ">
      <div className="center padded ">
        <div className="center padded ">register as a label</div>
        <form onSubmit={handleSubmit(onSubmitForm)}>
          <label>
            <input
              className="text-box bordered-light padded-5 dark"
              placeholder="label name"
              type="text"
              name="labelName"
              ref={register}
            />
          </label>
          <StandardButton mxt_primary onSubmit={handleSubmit}>
            {" "}
            submit
            {/* <input style={{ backgroundColor: "white" }} type="submit" value="submit" /> */}
          </StandardButton>
        </form>
        {/* <StandardButton mxt_primary onClick={() => connectStripe()}>
        connect stripe
      </StandardButton> */}
      </div>
    </GridContainer>
  );
}
