import React from "react";
import ArtistsList from "./views/ArtistsList";
import useProfileInfo from "../hooks/useProfileInfo";
import LightSpinner from "../../../loading/LightSpinner";

const LinkedArtistsView = () => {
  const { artists, loading, error } = useProfileInfo();

  if (loading) {
    return (
      <div className="font-mono text-lg text-green-300 py-10">
        <LightSpinner />
      </div>
    );
  }
  if (error) {
    return <div className="text-red-500">Error: {error.message}</div>;
  }
  if (!artists.length) {
    return <div className="text-yellow-500">No linked artists found.</div>;
  }

  return (
    <div className="p-4  border-black dark:border-white">
      <h2 className="font-bold text-lg text-green-400 mb-4 uppercase tracking-widest">
        Linked Artists
      </h2>
      <ArtistsList artists={artists} />
    </div>
  );
};

export default React.memo(LinkedArtistsView);
