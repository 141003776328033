import React, { useState } from "react";
import VerticalFader from "./VerticalFader";

const VolumeControl = ({ track }) => {
  const [volume, setVolume] = useState(1);

  const handleVolumeChange = (value) => {
    setVolume(value);
    track.gainNode.gain.value = value;
  };

  return (
    <div>
      <div class="p-2">
        <VerticalFader 
          id={`volume-${track.name}`}
          min={0}
          max={1}
          step={0.01}
          value={volume}
          onChange={handleVolumeChange}
        />
      </div>
      <label htmlFor={`volume-${track.name}`}></label>
    </div>
  );
};

export default VolumeControl;
