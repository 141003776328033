import React, { useState } from "react";
import { Knob } from "react-rotary-knob";

const MixerPot = ({ label, onChange, ...rest }) => {
  const [value, setValue] = useState(0);

  const handleOnChange = (val) => {
    const maxDistance = 200;
    let distance = Math.abs(val - value);
    if (distance > maxDistance) {
      return;
    } else {
      setValue(val);
      onChange(val);
    }
  };

  return (
    <div className="flex flex-col items-center">
      <Knob
        value={value}
        onChange={handleOnChange}
        angleOffset={225}
        angleRange={270}
        {...rest}
      />
      <label className="mt-1 text-white text-sm">{label}</label>
    </div>
  );
};

export default MixerPot;
