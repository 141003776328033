import React, { useState } from "react";
import { ArrowUpIcon, ChevronDownIcon } from "@heroicons/react/24/solid";

const UploadButton = ({ uploadFile }) => {
  const [isOpen, setIsOpen] = useState(false);

  const onFileChange = (e) => {
    const files = e.target.files;
    if (files.length > 0) {
      for (let i = 0; i < files.length; i++) {
        uploadFile(files[i]);
      }
    }
    setIsOpen(false);
  };

  return (
    <div className="relative">
      <button
        onClick={() => setIsOpen((prevState) => !prevState)}
        className="flex p-1 items-center text-blue-400 dark:bg-gh_charcoal_secondary-100 bg-white hover:bg-gray-200 dark:hover:bg-gh_charcoal_tertiary-100 font-bold py-2 px-4 mr-3 shadow-lg rounded"
      >
        <ArrowUpIcon className="h-5 w-5" />
        <span className="ml-1 font-theFutureMonoRegular mb-1">upload</span>
        <ChevronDownIcon className="h-5 w-5 ml-1" />
      </button>
      <div
        className={`absolute bg-white dark:bg-gh_charcoal_secondary-100 mt-2 py-2 w-48 rounded-md shadow-xl ${
          isOpen ? "block" : "hidden"
        }`}
      >
        <input
          id="file-upload"
          type="file"
          multiple
          accept="audio/*,image/*,.txt"
          // style={{ display: "none" }}
          onChange={onFileChange}
        />
      </div>
    </div>
  );
};

export default UploadButton;
