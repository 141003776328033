import React, { useState, useEffect, useContext } from "react";
import EditableText from "./EditableText";
import FolderActionsMenu from "./FolderActionsMenu";
import FolderIcon from "./icons/FolderIcon";
import DocumentIcon from "./icons/DocumentIcon";
import { useDeleteFolder, useMoveItem } from "./hooks/useDriveHooks";
import { File, Folder } from "./models/FileSystemClasses"; // Import File and Folder classes
import HandlerAdapter from "./fileHandlers/HandlerAdapter";
import ModalContext from "./contexts/ModalContext";
import {
  PlayIcon,
  PhotoIcon,
  MusicalNoteIcon,
} from "@heroicons/react/24/outline";
import AudioPlayerContext from "./AudioPlayerContext";
import EditAudioModal from "./editing/EditAudioModal";
import ImageViewer from "../../events/memories/ImageViewer";

const DriveItem = ({ item, openItem, updateFolderName, items, setItems }) => {
  const moveItem = useMoveItem(items, setItems);
  const deleteFolder = useDeleteFolder(items, setItems);
  const [deleted, setDeleted] = useState(false);
  const [editingItemId, setEditingItemId] = useState(null);
  const { playAudio } = useContext(AudioPlayerContext);
  const [showEditModal, setShowEditModal] = useState(false); // Add this line
  const { isModalOpened } = useContext(ModalContext); // Get the isModalOpened state from the context
  const [selectedImage, setSelectedImage] = useState(null); // Add this line

  const openImageViewer = (imageUrl) => {
    setSelectedImage(imageUrl);
  };

  const closeImageViewer = () => {
    setSelectedImage(null);
  };

  const handleItemClick = (item) => {
    if (editingItemId !== item.id) {
      if (item.type === "folder" && !isModalOpened) {
        openItem(item);
      } else if (item.type === "audio" && !isModalOpened) {
        playAudio({
          title: item.name,
          artist: "Unknown",
          color: "",
          image: "",
          audioSrc: item.downloadURL,
        });
      } else if (item.type === "image" && !isModalOpened) {
        openImageViewer(item.downloadURL); // Open image viewer when image item is clicked
      } else {
        console.warn(
          `No specific handler implemented for file type: ${item.type}. Doing nothing.`
        );
      }
    }
  };

  /*
  const handlerAdapter = HandlerAdapter();
  // Get the handler hook based on the item type
  const handler = handlerAdapter.getHandler(item.type);
  // Use the handler hook
  const { handleFile } = handler(item);

  const handleItemClick = (item) => {
    console.log("handleItemClick", item);
    if (editingItemId !== item.id) {
      if (item.type === "folder") {
        openItem(item);
      } else {
        handleFile(item);
      }
    }
  };
  */

  const renderIcon = () => {
    if (item.type === "folder") {
      return <FolderIcon className="h-6 w-6 text-blue-500 mr-4" />;
    } else if (item.type === "audio") {
      return <MusicalNoteIcon className="mr-4 w-6 h-6" />;
    } else if (item.type === "image") {
      return <PhotoIcon className="mr-4 w-6 h-6" />;
    } else {
      return <DocumentIcon className="h-6 w-6 text-gray-500 mr-4" />;
    }
  };

  useEffect(() => {
    if (deleted) {
      const timeoutId = setTimeout(() => {
        const updatedItems = items.filter((i) => i.id !== item.id);
        setItems(updatedItems);
      }, 300);

      return () => {
        clearTimeout(timeoutId);
      };
    }
  }, [deleted, items, setItems, item]);
  return (
    <li
      key={item.id}
      className={`flex justify-between items-center p-2 rounded shadow ${
        deleted ? "animate-slide-delete" : ""
      } transition-colors duration-200 ease-in-out bg-white hover:bg-gray-100 dark:hover:bg-gh_charcoal_secondary-200 dark:bg-gh_charcoal_secondary-100 dark:text-gh_charcoal_foreground_primary-100`}
    >
      <div
        className="w-full cursor-pointer flex justify-between items-center"
        onClick={() => handleItemClick(item)}
      >
        <div className="flex items-center">
          {renderIcon()}
          {editingItemId === item.id ? (
            <EditableText
              text={item.name}
              onSave={(newName) => {
                updateFolderName(item.id, newName);
                setEditingItemId(null);
              }}
              isEditing={editingItemId === item.id}
              setIsEditing={() => setEditingItemId(null)}
            />
          ) : (
            <span className="font-medium text-gray-700  dark:text-gh_charcoal_foreground_primary-100">
              {item.name}
            </span>
          )}
        </div>
        <div className="mr-4 p-1 rounded-lg hover:bg-gray-200 dark:hover:bg-gray-700">
          <FolderActionsMenu
            item={item}
            onMove={moveItem}
            onRename={() => {
              setEditingItemId(item.id);
            }}
            onDelete={() => {
              setDeleted(true);
              setTimeout(() => {
                deleteFolder(item);
              }, 300);
            }}
          />
        </div>
      </div>
      <ImageViewer
        isOpen={!!selectedImage}
        onRequestClose={closeImageViewer}
        imageUrl={selectedImage}
      />{" "}
      {/* Add this line */}
    </li>
  );
};

export default DriveItem;
