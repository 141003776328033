const AudioContext = window.AudioContext || window.webkitAudioContext;

class AudioFacade {
  constructor() {
    this.context = new AudioContext();
    this.masterGainNode = this.context.createGain();
    this.masterGainNode.connect(this.context.destination);
    this.tracks = [];
  }

  async loadAndSaveAudio(urls) {
    const buffers = await Promise.all(
      urls.map(async (url) => {
        const response = await fetch(url);
        const arrayBuffer = await response.arrayBuffer();
        const audioBuffer = await this.context.decodeAudioData(arrayBuffer);
        return audioBuffer;
      })
    );

    return buffers;
  }

  setSources(tracksArr, callback) {
    tracksArr.forEach((track) => {
      const gainNode = this.context.createGain();
      const panNode = this.context.createStereoPanner
        ? this.context.createStereoPanner()
        : this.context.createPanner();
      const source = this.context.createBufferSource();

      gainNode.gain.value = track.volume || 1;
      
      if (this.context.createStereoPanner) {
        panNode.pan.value = track.pan || 0;
      } else {
        panNode.panningModel = "equalpower";
        panNode.setPosition(track.pan || 0, 0, 1 - Math.abs(track.pan || 0));
      }

      source.buffer = track.buffer;

      source.connect(gainNode);
      gainNode.connect(panNode);
      panNode.connect(this.masterGainNode);

      track.source = source;
      track.gainNode = gainNode;
      track.panNode = panNode;
    });

    this.tracks = tracksArr;

    if (callback) {
      callback();
    }
  }

  playTracks(isPaused) {
    this.tracks.forEach((track) => {
      if (!isPaused && !track.hasStarted) {
        console.log("PLAYING");
        console.log(track);
        track.source.start(0);
        track.hasStarted = true;
      } else if (isPaused && track.hasStarted) {
        console.log("PAUSED");
        track.source.stop(0);
        track.hasStarted = false;
        // Create a new source node
        const newSource = this.context.createBufferSource();
        newSource.buffer = track.buffer;
        newSource.connect(track.gainNode);
        track.source = newSource;
      }
    });
  }

  updateProgress(value) {
    // Logic to update the progress, e.g., seek to a specific point in the audio
  }
}

export default AudioFacade;
