import React from "react";
import LogoView from "../../LogoView";
import { AppStoreButton } from "react-mobile-app-button";

const MXTSplashPage = () => {
  const svgLogo = "https://storage.googleapis.com/tuareg/media/mxt-logo.svg";
  const logoText = ".mixtape";

  return (
    <div className="w-full h-screen bg-white dark:bg-black text-black dark:text-white flex flex-col items-center justify-center px-6 sm:px-12 lg:px-24 space-y-8">
      
      {/* Logo Section */}
      <LogoView svgLogo={svgLogo} text={logoText} />
      
      {/* Main Content */}
      <div className="text-center space-y-6">
        <h1
          className="text-6xl font-bold leading-none tracking-tight"
          style={{ fontFamily: '"OCR A Std", monospace' }}
        >
          90s Techno & Jungle
        </h1>
        {/* <p
          className="text-lg font-medium max-w-md mx-auto"
          style={{ fontFamily: '"OCR A Std", monospace' }}
        >
          Digital crate digging. Tracks you won’t find anywhere else.
        </p> */}

        {/* Centered App Store Button */}
        <div className="flex justify-center items-center">
          <AppStoreButton
            store="ios"
            url="https://apps.apple.com/us/app/mixtape/id123454321" // Replace with actual URL
            width={200}
            height={60}
          />
        </div>
      </div>
    </div>
  );
};

export default MXTSplashPage;