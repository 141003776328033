import React, { createContext, useContext, useState, useEffect } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import firebase from 'firebase/compat/app';
import { ProfileProvider } from './components/account/profile/context/ProfileContext';


const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const auth = firebase.auth();
  const [user, loading, error] = useAuthState(auth);
  const [redirectPath, setRedirectPath] = useState('/');

  useEffect(() => {
    if (!loading && user) {
      const storedRedirectPath = sessionStorage.getItem('redirectPath');
      if (storedRedirectPath) {
        sessionStorage.removeItem('redirectPath');
        setRedirectPath(storedRedirectPath);
    }
    }
  }, [loading, user]);

  return (
    <AuthContext.Provider value={{ user, loading, error, redirectPath, setRedirectPath }}>
      <ProfileProvider>
        {children}
      </ProfileProvider>
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};