import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import firebase from "firebase/compat/app";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import configureStore from "./store/configureStore";
import NavBar from "./components/navigation/NavBar";
import Main from "./Main";
import { MultiTrackProvider } from "./components/file_mgmt/multi_uploader/MultiTrackContext";
import { AudioPlayerProvider } from "./components/file_mgmt/drive/AudioPlayerContext";
import { FileContextProvider } from "./components/file_mgmt/drive/contexts/fileContext";
import UploadContextProvider from "./components/file_mgmt/drive/contexts/UploadContextProvider";
import PathContextProvider from "./components/file_mgmt/drive/contexts/PathContextProvider";
import { ModalProvider } from "./components/file_mgmt/drive/contexts/ModalContext";
import { AuthProvider } from "./AuthContext";
import { ProfileProvider } from "./components/account/profile/context/ProfileContext";

const store = configureStore();

export default function App() {
  const auth = firebase.auth();
  const [user] = useAuthState(auth);
  const isDarkMode = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
  
  const [useGradient, setUseGradient] = useState(false); // Toggle for gradient background

  useEffect(() => {
    if (isDarkMode) {
      document.body.classList.add('dark-mode');
    } else {
      document.body.classList.remove('dark-mode');
    }
  }, [isDarkMode]);

  const backgroundClass = useGradient 
    ? 'bg-gradient-to-r from-blue-50 to-purple-50 dark:from-gray-900 dark:to-gray-800'
    : 'bg-gray-100 dark:bg-black';

  return (
    <div className={`min-h-screen font-sans text-black dark:text-white ${backgroundClass} ring-slate-900/5 shadow-xl`}>
      <AuthProvider>
        <ProfileProvider>
          <Provider store={store}>
            <ModalProvider>
              <AudioPlayerProvider>
                <PathContextProvider>
                  <FileContextProvider>
                    <UploadContextProvider>
                      <MultiTrackProvider>
                        <BrowserRouter>
                          <NavBar />
                          <ToastContainer />
                          <Main token={user ? user.uid : null} />
                        </BrowserRouter>
                      </MultiTrackProvider>
                    </UploadContextProvider>
                  </FileContextProvider>
                </PathContextProvider>
              </AudioPlayerProvider>
            </ModalProvider>
          </Provider>
        </ProfileProvider>
      </AuthProvider>
    </div>
  );
}