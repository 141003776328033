
export const SET_CURRENT_TRACK = 'SET_CURRENT_TRACK'
export const UPLOADING_FILE = 'UPLOADING_FILE'
//
// export const setCurrentTrack = track => ({
//   type: SET_CURRENT_TRACK,
//   currentTrack: track.info.file,
//   currentTrackName: track.info.name,
//   currentTrackArtist: track.info.artist
// })

export const setCurrentTrack = track => ({
  type: SET_CURRENT_TRACK,
  currentTrack: track.compressed_file,
  currentTrackName: track.name,
  currentTrackArtist: track.artist
})

export const setCurrentAlbum = track => ({
  type: SET_CURRENT_TRACK,
  currentTrack: track,
  currentTrackName: track.name,
  currentTrackArtist: track.artist
})
