import React, { useEffect, useState } from "react";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import { onSnapshot } from "firebase/firestore";
import { Link } from "react-router-dom";

import TapeView from "./TapeView";
import tape_service from "../../../services/tape_service";

const MultiTrackTapeView = ({ tape }) => {
  const [loading, setLoading] = useState(true);
  const [tracks, setTracks] = useState([]);

  useEffect(() => {
    const fetchData = () => {
      const user = firebase.auth().currentUser;
      if (user) {
        const user_uid = user.uid;
        const tape_uid = tape.id;
    
        const tapeUnsub = listenForTapeChanges(user_uid, tape_uid);
        const tracksUnsub = listenForTrackChanges(user_uid, tape_uid);
        return () => {
          tapeUnsub();
          tracksUnsub();
        };
      }
      return () => {};
    };    

    const unsubscribe = fetchData();
    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };  
  }, [tape]);

  const listenForTapeChanges = (user_uid, tape_uid) => {
    try {
      const tape_ref = tape_service.getTapeRef(user_uid, tape_uid);
  
      const unsub = onSnapshot(
        tape_ref,
        { includeMetadataChanges: true },
        (tape_doc_snap) => {
          setLoading(false);
        }
      );
  
      return unsub;
    } catch (error) {
      console.error(error);
    }
  };
  
  const listenForTrackChanges = (user_uid, tape_uid) => {
    try {
      const tape_tracks_ref = tape_service.getTapeTracksRef(user_uid, tape_uid);
      const unsub = tape_service.handleTrackChanges(tape_tracks_ref, setTracks);
  
      return unsub;
    } catch (error) {
      console.error(error);
    }
  };
  
  
  

  if (loading) {
    return <p>Loading tape...</p>;
  }

  return (
    <Link to={`/tapes/${tape.id}`}>
      <TapeView tape={tape} tracks={tracks} />
    </Link>
  );
};

export default MultiTrackTapeView;
