import { useState, useEffect } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import firebase from "firebase/compat/app";

export const useFetchWithAuth = (url, options = {}) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [fetched, setFetched] = useState(false);
  const [user, userLoading, userError] = useAuthState(firebase.auth());

  useEffect(() => {
    // Reset fetched state whenever the URL changes
    setFetched(false);
  }, [url]);

  useEffect(() => {
    const fetchData = async () => {
      if (userLoading || userError || !user || fetched) {
        setLoading(false);
        return;
      }

      setLoading(true);
      try {
        const idToken = await user.getIdToken(true);
        const response = await fetch(url, {
          ...options,
          headers: {
            ...options.headers,
            "Content-Type": "application/json",
            Authorization: `JWT ${idToken}`,
          },
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const responseData = await response.json();
        setData(responseData);
        setFetched(true); // Data has been successfully fetched
      } catch (e) {
        setError(e);
      } finally {
        setLoading(false);
      }
    };

    // Only fetch data if it has not been fetched before
    if (!fetched) {
      fetchData();
    }
  }, [url, user, userLoading, userError, fetched, options]);

  return { data, loading, error };
};
