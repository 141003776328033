// MoveItemModal.js
import React, { useState } from "react";
import FolderTree from "../navigation/folder_tree/FolderTree";

const MoveItemModal = ({ item, onMove, onClose }) => {
  const [selectedNode, setSelectedNode] = useState(null);

  const handleFolderSelect = (node) => {
    console.log("node boy:", node);
    setSelectedNode(node); // Set the selected node state
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log("item:", item);
    // const destPath = selectedNode.data.path + "/" + selectedNode.data.name;
    const destPath = (selectedNode.data.path + "/" + selectedNode.data.name).replace(/\/{2,}/g, '/');

    if (item && selectedNode) {
      console.log("moving item at path: ", item.path, " to  ", destPath);
      // onMove(item, destPath, selectedNode);
      onMove(item, item.path, destPath);

      onClose();
    } else {
      console.error("Item, item path, or selected node is missing");
    }
  };

  return (
    <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex items-center justify-center">
      <div className="bg-white dark:bg-gh_charcoal_secondary-100 w-1/2 p-6 rounded shadow-md">
        <h2 className="text-2xl mb-4">Move item to a new location </h2>
        <form onSubmit={handleSubmit}>
          {/* <label htmlFor="destinationPath" className="block mb-2">
            Destination path:
          </label> */}
          <div className=" dark:bg-gh_charcoal_secondary-100 border border-gray-300 rounded p-2 mb-4">
            <FolderTree
              onSelect={handleFolderSelect}
              selectedPath={selectedNode}
            />
          </div>
          <button
            type="submit"
            className="bg-blue-500 text-white px-4 py-2 rounded mr-2"
          >
            Move
          </button>
          <button
            type="button"
            className="bg-gray-200 text-gray-700 px-4 py-2 rounded"
            onClick={onClose}
          >
            Cancel
          </button>
        </form>
      </div>
    </div>
  );
};

export default MoveItemModal;
