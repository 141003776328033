import React from "react";
import { Link } from "react-router-dom";
import Menu from "./Menu";

const TapePreview = ({ tape, onDelete, isDeleting }) => {
  return (
    <div
      className={`w-full h-full rounded-md p-6 dark:bg-gh_charcoal_secondary-100 dark:border-0.3 border-1 border-gh_light_outline-100 dark:border-gh_charcoal_foreground_secondary-100 ${isDeleting ? "cursor-not-allowed" : ""}`}
      // style={{
      //   boxShadow:
      //     "0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24)",
      // }}
    >
      <div className="relative h-full">
        <Link
          to={`/tapes/${tape.id}`}
          className="block hover:text-gh_blue transition-colors duration-150"
          style={{ pointerEvents: isDeleting ? "none" : "auto" }}
        >
          <h1 className="text-xl text-gh_black-100 tape-name hover:text-gh_blue-100 transition-colors duration-150">
            {tape.name}
          </h1>
          <p className=" dark:text-gh_charcoal_foreground_secondary-100 text-gh_black-100 text-sm">
            {tape.description}
          </p>
        </Link>
        <div className="absolute top-2 right-2">
          <Menu tapeId={tape.id} onDelete={onDelete} isDeleting={isDeleting} />
        </div>
      </div>
    </div>
  );
};

export default TapePreview;