import React, { useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { getAuth } from "firebase/auth";
import api from "../../../api";
import { PlusIcon, MinusIcon } from "@heroicons/react/24/solid";
import { Transition } from "@headlessui/react";

const AddArtistComponent = () => {
  const [artistName, setArtistName] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [user] = useAuthState(getAuth());
  const [isOpen, setIsOpen] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);

  const handleAddArtist = async () => {
    if (!artistName.trim()) return;
    setIsLoading(true);
    try {
      const response = await api.newArtist(artistName, user?.uid);
      await response.json();
      setIsLoading(false);
      setArtistName("");
      setIsOpen(false);
      setShowSuccess(true);
      setTimeout(() => setShowSuccess(false), 3000);
    } catch (error) {
      console.error("Error adding new artist:", error);
      setIsLoading(false);
    }
  };

  return (
    <div className="mt-4 mb-6">
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="flex items-center px-4 py-2 bg-black text-white font-bold border-2 border-black dark:border-white uppercase text-lg dark:bg-white dark:text-black hover:bg-gray-300 dark:hover:bg-gray-800"
      >
        {isOpen ? (
          <MinusIcon className="h-4 w-4 mr-2" />
        ) : (
          <PlusIcon className="h-4 w-4 mr-2" />
        )}
        {isOpen ? "Cancel" : "Add New Artist"}
      </button>

      <Transition
        show={isOpen}
        enter="transition-all duration-300"
        enterFrom="opacity-0 max-h-0"
        enterTo="opacity-100 max-h-screen"
        leave="transition-all duration-300"
        leaveFrom="opacity-100 max-h-screen"
        leaveTo="opacity-0 max-h-0"
      >
        <div className="mt-4">
          <input
            type="text"
            value={artistName}
            onChange={(e) => setArtistName(e.target.value)}
            placeholder="New artist name"
            className="w-full p-3 text-black dark:text-white bg-gray-100 dark:bg-gray-800 border-2 border-black dark:border-white"
            disabled={isLoading || !user}
          />
          <button
            onClick={handleAddArtist}
            className={`mt-2 px-6 py-2 bg-black text-white font-bold border-2 border-black dark:border-white dark:bg-white dark:text-black ${
              isLoading || !artistName.trim() || !user
                ? "opacity-50 cursor-not-allowed"
                : "hover:bg-gray-300 dark:hover:bg-gray-700"
            }`}
            disabled={isLoading || !artistName.trim() || !user}
          >
            {isLoading ? (
              <div className="animate-spin rounded-full h-5 w-5 border-t-2 border-white"></div>
            ) : (
              "Add Artist"
            )}
          </button>
        </div>
      </Transition>

      {showSuccess && (
        <p className="mt-2 text-green-500">Artist added successfully!</p>
      )}
    </div>
  );
};

export default AddArtistComponent;
