import styled from "styled-components";

const Container = styled.div`
  text-align: center;
  /* display: flex; */
  .container {
    /* display: flex; */
    /* or inline-flex */
    /* flex-basis: auto; */
    /* default auto */
  }
  .center {
    /* display: flex;
    /* flex-direction: column;
    justify-content: left;
    /* align-items: left; */
    /* height: 50vh; */
    /* align-items: flex-start;  */
    /*align-self: center;*/
    /* flex-direction: column; */
    /* flex-wrap: wrap; */
  }
  ul {
    display: flex;

    /* align-items: stretch; */
    /* Default */
    /* justify-content: space-between; */
    /* width: 100%; */
    /* background: #cacaca; */
    border: 1px solid #91b3ff;
    flex: 0 1 auto; /* Default */

    /* 
    margin-left: 0;
    padding: 40; */
  }
  .form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 50vh;
  }
  .logo {
    /* padding: 5vh; */

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 40vh;
  }
  .box {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    /* width: 200px; */
  }
  .box > *:first-child {
    align-self: stretch;
  }
  .box .selected {
    align-self: center;
  }
  .edit-name-field {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 5vh;
  }
  .input {
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
    padding-left: 5px;

    height: 5vh;
    width: 37vh;
    padding-top: 7px;

    border: 1px solid #91bfef;
  }
  .input_box {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    /* background-color: black; */
    padding: 5px;
  }
  .title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 5vh;
    /* padding: 20px */
  }

  .title2 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 5vh;
    color: blue;
    /* padding: 20px */
  }
  .link {
    text-decoration: underline;
  }
  .menu {
    flex-direction: row;
    justify-content: space-space-around;
    align-items: center;
    padding-top: 0.4vh;
    padding-bottom: 0.4vh;
    padding-left: 1vh;
    padding-right: 1vh;

    /* border: 1px solid #91b3ff; */
  }
  li {
    display: block;
    flex: 0 1 auto; /* Default */
    list-style-type: none;
    /* background: #fafafa; */
    padding: 20px;
  }
  .item {
    order: 5; /* default is 0 */
  }
  .subscribe {
    display: flex;
    /* height: 50px; */
    padding: 20px;
  }
`;

export default Container;
