import React, { useState, useEffect } from "react";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";

import storage from "../../../services/storage";
import Uploader from "../../file_mgmt/uploader/Uploader";

import MyTapesView from "../../file_mgmt/MyTapesView";

import UploadContainer from "../../themes/UploadContainer";
import GridContainer from "../../themes/GridContainer";
import CreateCassetteView from "../dashboard/CreateCassetteView";
import OtariFilesView from "./OtariFilesView";
// import Merch from "../Merch";
// import storage from "../../services/storage.js";

const Otari = (props) => {
  function activeProgram() {
    return (
      <GridContainer className="box">
        <div>
          <div className="active_program_higher">
            running: {"  "}/{"  "}
            <div style={{ color: "cornflowerblue" }}> files.x</div>
          </div>
        </div>
      </GridContainer>
    );
  }
  function authStateListener() {
    // [START auth_state_listener]
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/firebase.User
        var uid = user.uid;

        storage.myTapesObserver(uid);
        // ...
      } else {
        // User is signed out
        // ...
      }
    });
    // [END auth_state_listener]
  }
  useEffect(() => {
    authStateListener();
    // Update the document title using the browser API
    // document.title = `You clicked ${count} times`;
  });
  return (
    <div>
      {/* <FileSystemContainer className="center"> */}
      <UploadContainer className="horizontal">
        <Uploader></Uploader>
        <MyTapesView></MyTapesView>
<OtariFilesView></OtariFilesView>
      </UploadContainer>
    </div>
  );
};

export default Otari;
