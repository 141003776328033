import EventLinkButton from "../EventLinkButton";

import { useEffect } from "react";
import React from "react";

import EventAddressView from "../EventAddressView";

import EventDescriptionView from "./EventDescriptionView";


import AddToCalendarButton from "../AddToCalendarButton";
import EventDateTime from "./EventDateTime";


const EventDetailsView = ({
  event,
  linkCopied,
  presentEventCopySuccessToast,
  loadAttendingView,
  isAttending,
  eventTime,
  address,
}) => {
  useEffect(() => {
    console.log("EventDetailsView mounted.");
    console.log("EventDetailsView props:", event);
    return () => {
      console.log("EventDetailsView unmounted.");
    };
  }, [event]);

  return (
    <div className="font-sans text-black dark:text-white">
      
      <EventDateTime event={event} />
      <EventAddressView event={event} isRSVPed={isAttending} />
      <EventDescriptionView description={event.description} />
      <div className="mt-4">
        <EventLinkButton
          event={event}
          linkCopied={linkCopied}
          presentEventCopySuccessToast={presentEventCopySuccessToast}
        />
      </div>
      <div className="mt-4">
        <AddToCalendarButton event={event} />
      </div>
      {/* <div className="mt-4">
        {loadAttendingView()}
      </div> */}
    </div>
  );
};

export default EventDetailsView;
