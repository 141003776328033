import { Formik, Form, useField } from "formik";
import React, { useState } from "react";
import MerchFormInput from "../dashboard/MerchFormInput";

const MerchQuantityBox = ({ status }) => {
  //   const [shipsIntl, setShipping] = useState(status);

  return (
    <div>
      <br className="padded"></br>
      <h1 className="padded-5">quantity</h1>
      <div className="padded-5 bordered-light">
        <div>
          <div className="padded">
            {/* quantity:{"  "} */}
            <MerchFormInput
              className="bordered-light padded-5"
              name="quantity"
              type="number"
              placeholder="unlimited"
            ></MerchFormInput>{" "}
            remaining in the United States
            <div style={{ color: "gray" }} className="padded-5">
              we automatically decrement the quantity for you as the item sells
              — you only need to edit it if you’re also selling elsewhere (like
              at your shows, or another website).
            </div>
          </div>
          <div className="padded column">
            <div className="">sku:</div>
            <MerchFormInput
              className="bordered-light padded-5"
              name="sku"
              type="text"
              placeholder="MX-123"
            ></MerchFormInput>{" "}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MerchQuantityBox;
