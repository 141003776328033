import { Formik, Form, useField } from "formik";
import React, { useState, useEffect } from "react";
import MerchFormInput from "../dashboard/MerchFormInput";
import MerchTextInputView from "../dashboard/MerchTextInputView";
import OptionsView from "../dashboard/OptionsView";

const MerchOptionsBox = ({ _options }) => {
  const [options, setOptions] = useState(_options);
  //   useEffect(() => {
  // setOptions(_options)
  //   }, [options])
  function getOptions1() {
    return (
      <div style={{ paddingLeft: 30 }} className="padded-5">
        <div className="row">
          <div>
            <div>option:</div>
            <MerchFormInput
              className="bordered-light padded-5"
              name="option_1"
              type="text"
              placeholder='e.g. "small"'
            ></MerchFormInput>{" "}
          </div>
          <div>
            <div>quantity:</div>
            <MerchFormInput
              className="bordered-light padded-5"
              name="option_1_quantity"
              type="number"
              placeholder="unlimited"
            ></MerchFormInput>
          </div>
          <div>
            <div>sku:</div>
            <MerchFormInput
              className="bordered-light padded-5"
              name="option_1_sku"
              type="text"
              placeholder="MX-123"
            ></MerchFormInput>{" "}
          </div>
        </div>
      </div>
    );
  }
  function getOptions2() {
    return (
      <div style={{ paddingLeft: 30 }} className="padded-5">
        <div className="row">
          <div>
            <div>option:</div>
            <MerchFormInput
              className="bordered-light padded-5"
              name="option_2"
              type="text"
              placeholder='e.g. "medium"'
            ></MerchFormInput>{" "}
          </div>
          <div>
            <div>quantity:</div>
            <MerchFormInput
              className="bordered-light padded-5"
              name="option_2_quantity"
              type="number"
              placeholder="unlimited"
            ></MerchFormInput>
          </div>
          <div>
            <div>sku:</div>
            <MerchFormInput
              className="bordered-light padded-5"
              name="option_2_sku"
              type="text"
              placeholder="MX-123"
            ></MerchFormInput>{" "}
          </div>
        </div>
      </div>
    );
  }

  function getOptions3() {
    return (
      <div style={{ paddingLeft: 30 }} className="padded-5">
        <div className="row">
          <div>
            <div>option:</div>
            <MerchFormInput
              className="bordered-light padded-5"
              name="option_3"
              type="text"
              placeholder='e.g. "large"'
            ></MerchFormInput>{" "}
          </div>
          <div>
            <div>quantity:</div>
            <MerchFormInput
              className="bordered-light padded-5"
              name="option_3_quantity"
              type="number"
              placeholder="unlimited"
            ></MerchFormInput>
          </div>
          <div>
            <div>sku:</div>
            <MerchFormInput
              className="bordered-light padded-5"
              name="option_3_sku"
              type="text"
              placeholder="MX-123"
            ></MerchFormInput>{" "}
          </div>
        </div>
      </div>
    );
  }
  return (
    <div>
      {/* options */}
      <br className="padded"></br>
      <h1 className="padded-5">options</h1>
      <div className="padded-5 bordered-light">
        <div>
          <div className="padded">
            option name:{"  "}
            <MerchTextInputView
              name="option_name"
              type="text"
              placeholder='e.g. "shirt size"'
              // style={{ color: "lightGray" }}
            />
          </div>
        </div>
        {getOptions1()}
        {options ? (
          <div>
            {getOptions2()}
            {getOptions3()}
          </div>
        ) : null}
        <div className="padded">
          {/* <label className="padded-5" name="ships_intl">
            more options{" "}
            <input
              name="isGoing"
              type="checkbox"
              checked={options}
              onChange={() => setOptions(!options)}
            />
          </label> */}
        </div>
      </div>
    </div>
  );
};

export default MerchOptionsBox;
