// FinalizeDraftV2.jsx

import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  useLocation,
  useNavigate,
} from "react-router-dom";
import api from "../../../api";
import storage from "../../../services/storage";
import StandardButton from "../../buttons/StandardButton";
import WideLinkButton from "../../buttons/WideLinkButton";
import WideButton from "../../buttons/WideButton";
import DynamicRotatingSlash from "../../loading/DynamicRotatingSlash";
const FinalizeDraft = () => {
  const [isLoading, setLoading] = useState(true);
  const [album, setAlbum] = useState({});
  const [published, setPublished] = useState(false);
  let location = useLocation();

  const getTracks = () => {
    return (
      <ol className="list-decimal list-inside">
        {album.tracks
          .slice(0)
          .reverse()
          .map((track, index) => (
            <li key={track.name} className="text-lg py-1">
              {track.name}
            </li>
          ))}
      </ol>
    );
  };
  useEffect(() => {
    //   get most recently edited draft on refresh

    if (location.album == null) {
      storage.getRecentDrafts().then((album) => {
        storage.validateDraft(album).then((res) => {
          setAlbum(res);
          setLoading(false);
        });
      });
    } else {
      setAlbum(location.album);
      setLoading(false);
    }
  }, []);
  function publish() {
    api.newAlbum(album.docId).then((res) => {
      console.log("res", res);
    });

    setPublished(true);
  }
  const albumView = () => (
    <div className="container mx-auto my-10 p-5 bg-black text-green-400 rounded-lg shadow-md">
      {isLoading ? (
        <div className="text-center text-xl">
          <DynamicRotatingSlash />
        </div>
      ) : (
        <div>
          <h1 className="text-2xl font-bold mb-3">{album.name}</h1>
          <img
            src={album.image_url}
            alt="Album Cover"
            className="w-32 h-32 object-cover rounded-lg mb-5"
          />
          <div className="mb-5">{getTracks()}</div>
          <WideButton lambda={() => publish()} text={"publish"}></WideButton>
        </div>
      )}
    </div>
  );

  const successView = () => (
    <div className="text-center">
      <p className="text-lg mb-3">
        Success! Your album will be available shortly.
      </p>
      <WideLinkButton path="/konsole" text="back to konsole"></WideLinkButton>
    </div>
  );
  return (
    <div className="min-h-screen flex items-center justify-center ">
      {published ? successView() : albumView()}
    </div>
  );
};

export default FinalizeDraft;
