// ArtistsList.jsx
import React from "react";
import ArtistListElement from "./ArtistListElement"; // Make sure the path is correct
import ArtistDetailsView from "./ArtistDetailsView";
// ArtistsList.jsx
const ArtistsList = ({ artists }) => {
  return (
    <div className="bg-black text-green-400 font-mono p-4">
      <ul className="list-none list-inside">
        {artists.map((artist) => (
          <ArtistListElement key={artist.id} artistId={artist.id} artistName={artist.name} />
        ))}
      </ul>
    </div>
  );
};

export default ArtistsList;
