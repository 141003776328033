// useRSVPToast.js
import toast from "react-hot-toast";

const useRSVPToast = () => {
  const getEmoji = (goingStatus) => (goingStatus ? "🎉" : "😢");

  const getStyle = (goingStatus) => ({
    border: `1px solid ${goingStatus ? "white" : "red"}`,
    backgroundColor: "black",
    padding: "16px",
    color: goingStatus ? "white" : "red",
    opacity: "0.75",
  });

  const notify = (message, goingStatus) => {
    toast(message, {
      duration: 1500,
      position: "top-center",
      style: getStyle(goingStatus),
      icon: getEmoji(goingStatus),
      iconTheme: { primary: "#000", secondary: "#fff" },
      ariaProps: { role: "status", "aria-live": "polite" },
    });
  };

  return { notify, getStyle };
};

export default useRSVPToast;
