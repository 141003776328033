// merch_db.js;

// merch.js;
// mastering.js;

// import APIConfig from "./APIConfig";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";

import APIConfig from "../APIConfig";
import utils from "../components/artist_dashboard/utilities/utils";
import { authMethods } from "../firebase/authmethods";

// import { authMethods } from "./firebase/authmethods";

// import { authMethods } from "../../firebase/authmethods";
// const token = localStorage.getItem("token");
// const b = APIConfig.baseURL();
// ${APIConfig.baseURL()}
// const base = APIConfig.baseURL()
let db = firebase.firestore();
var user = firebase.auth().currentUser;
const authUser = Object.keys(window.localStorage).filter((item) =>
  item.startsWith("firebase:authUser")
)[0];

const queryMerchItems = async (token) => {
  const r = await fetch(`${APIConfig.profiles()}merch/`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "JWT " + token,
    },
    // body: d,
  });
  return r;
};

const deleteMerchItem = async (token, merch_item_id) => {

  const data = { merch_item_id: merch_item_id};
  const d = JSON.stringify(data);
  const r = await fetch(`${APIConfig.profiles()}delete_merch_item/`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "JWT " + token,
    },
    body: d,
  });
  return r;
};

const getPublishedMerchItems = async (token) => {
  const merch_items = await queryMerchItems(token);
  
  return merch_items
};

const inventory = {  queryMerchItems, deleteMerchItem };

export default inventory;
