import APIConfig from "./APIConfig";
// import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import { authMethods } from "./firebase/authmethods";
import { firebaseAuth } from "./provider/AuthProvider";
// import { authMethods } from "../../firebase/authmethods";
const token = localStorage.getItem("token");
// const b = APIConfig.baseURL();
// ${APIConfig.baseURL()}
// const base = APIConfig.baseURL()
var db = firebase.firestore();

function submitArtist(name) {
  return new Promise((resolve, reject) => {
    fetch(`${APIConfig.profiles()}new_artist/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "JWT " + token,
      },
      body: { name: name },
    }).then((res) => {
      if (res.status === 200) {
        resolve("google says hi");
        // //(
        //   res.json().then(function (data) {
        //     //(data);
        //   })
        // );
        //   resolve(res.json());
        //   return res.json();
      } else {
        reject("error");
      }
    });

    // //(`Making request to ${location}`);
    // if (location === "google") {

    //   resolve("google says hi");
    // } else {
    //   reject("we can only talk to google");
    // }
  });
}

function login() {
  firebase.auth().onAuthStateChanged((user) => {
    if (user) {
      //("loggedin");
    } else {
      //("signed out....");
    }
  });
}
const studio_api = {
  submitArtist,

  login,
};

export default studio_api;
