// SignupForm.jsx
import React from "react";
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import APIConfig from "../../APIConfig";

const emailProviderDict = {
  provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
  requireDisplayName: true,
};

const phoneProviderDict = {
  provider: firebase.auth.PhoneAuthProvider.PROVIDER_ID,
  requireDisplayName: true,
};

const getAuthProvider = () => {
  if (APIConfig.studio() === true) {
    return [emailProviderDict, phoneProviderDict];
  } else {
    return [phoneProviderDict];
  }
};

const uiConfig = {
  signInFlow: "popup",
  signInOptions: getAuthProvider(),
  signInSuccessUrl: "/", // Navigate to home view upon successful sign-in
};

const SignupForm = () => {
  return <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()} />;
};

export default SignupForm;
