import React, { useState, useEffect } from "react";
import VolumeControl from "./VolumeControl";
import PanControl from "./PanControl";
import ChannelLabel from "./ChannelLabel";
import TrackInfoModal from "./TrackInfoModal";
const ChannelControls = ({ track, channel }) => {
  const [showModal, setShowModal] = useState(false);
  const [showFullCaption, setShowFullCaption] = useState(false);
  const [isCaptionLong, setIsCaptionLong] = useState(false);
  const maxCaptionLength = 10;

  const handleCaptionLength = (trackName) => {
    setIsCaptionLong(trackName.length > maxCaptionLength);
  };

  useEffect(() => {
    if (track.name) {
      handleCaptionLength(track.name);
    }
  }, [track]);

  const toggleCaption = () => {
    setShowFullCaption(!showFullCaption);
  };

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  const getCaption = () => {
    if (isCaptionLong) {
      return showFullCaption
        ? track.name
        : track.name.slice(0, maxCaptionLength) + "...";
    }
    return track.name;
  };

  const caption = getCaption();

  return (
    <div className="flex flex-col items-center space-y-2">
      <VolumeControl track={track} />
      <PanControl track={track} />
      <div
        className={`p-1 rounded-md ${
          isCaptionLong ? "bg-blue-600 cursor-pointer" : ""
        }`}
        onClick={toggleModal}
      >
        <h3 className="text-sm">{caption}</h3>
      </div>
      {typeof channel === "string" ? (
        <ChannelLabel label={channel} />
      ) : (
        <ChannelLabel label={`${channel + 1}`} />
      )}
      {showModal && (
        <TrackInfoModal track={track} onClose={toggleModal} channel={channel} />
      )}
    </div>
  );
};

export default ChannelControls;
