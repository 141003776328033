// PublishedMerchView.js;

import React, { useEffect, useState } from "react";

import GridContainer from "../themes/GridContainer";
import StandardButton from "../buttons/StandardButton";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import orders from "../../services/orders";
import OptionsView from "../artist_dashboard/dashboard/OptionsView";

let db = firebase.firestore();

const OrdersView = ({ merchItem }) => {
  const [data, setData] = useState([]);
  const [_token, setToken] = useState("");
  const [loaded, setLoaded] = useState(false);
  // useEffect(() => {
  //   firebase.auth().onAuthStateChanged(function (user) {
  //     if (user) {
  //       firebase
  //         .auth()
  //         .currentUser.getIdToken(true)
  //         .then(function (idToken) {
  //           setToken(idToken);

  //         });
  //     }
  //   });

  //   // merch_db.getPublishedMerchItems();
  // }, [_token]);
  function markAsShipped(docId) {

  }
  function showMerch() {
    data.array.forEach((element) => {
      return (
        <div>
          <div>{data["name"]}</div>
        </div>
      );
    });
  }

  const orders_list = data.map((order) => (
    <div className="padded-5">


      <div key={order.name} className="padded bordered">
        <div className="padded">


          <span className="">
            {order.name}
            <div className="padded" >
              {/* <li> */}
              {/* {order.order_date.toDate()} */}
              {/* </li> */}
              <div className="padded-5 column grey">
                <div>{order.shipping.name}</div>
                <div>{order.shipping.address.city}</div>
                <div>{order.shipping.address.country}</div>
                <div>{order.shipping.address.line1}</div>

                <div>{order.shipping.address.line2}</div>
                <div>{order.shipping.address.postal_code}
                  {",  "}{order.shipping.address.state}
                </div>
                
<div>

---------------
</div>
<div className="padded-5">

    quantity: {'  '} {order.quantity}
</div>
    <div className="padded-5">

    total: {'  $'} {order.total/100}
    </div>

    {optionsView(order)}

              </div>
            </div>

          </span>
          <div className="row">

            {/* ☒ */}
            {/* <StandardButton mxt_red onClick={() => {markAsShipped()}}>
        mark as shipped
      </StandardButton> */}
          </div>
        </div>
      </div>
    </div>
  ));

  function optionsView(order) {
    if (order.metadata != "") {

      return (

        <div>

          <div>
            ---------------
    </div>
          <div className="row padded-5">
            <div >
              options: {'  '}
    </div>
            <div className="padded-left">
              {order.metadata}
            </div>
          </div>
        </div>
      )
    } else {
      return null
    }
  }

  async function getOrdersRef() {
    var promise = new Promise((resolve, reject) => {
      firebase.auth().onAuthStateChanged(function (user) {
        if (user) {
          firebase
            .auth()
            .currentUser.getIdToken(true)
            .then(function (idToken) {
              setToken(idToken);
              resolve(user.uid);
            });
        }
      });
    });

    return promise;
  }

  async function loadOrders() {
    setLoaded(true);
    const orders_ref = await orders.getOrdersRef().then(function (fs_user_uid) {
      const unsubscribe = db
        .collection(`studio/${fs_user_uid}/storefront/fulfillment/orders`).orderBy('order_date', "desc")
        .onSnapshot((snap) => {
          const data = snap.docs.map((doc) => doc.data());
          console.log(data)
          setData(data);
        });
      return () => unsubscribe();
    });
  }

  useEffect(() => {
    if (!loaded) {
      loadOrders();
    }
  }, [data]);

  function deleteMerchItem() {
    // inventory.deleteMerchItem(data.id);
  }

  const deleteBtn = (item) => {
    return (
      <StandardButton mxt onClick={deleteMerchItem(item.id)}>
        del
      </StandardButton>
    );
  };
  return (
    <GridContainer>
      <div className="padded">
        <h1>orders</h1>
        {orders_list}
      </div>
    </GridContainer>
  );
};

export default OrdersView;
