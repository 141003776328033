import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {setCurrentAlbum, setCurrentTrack} from "../actions/trackActions";
import {exitSession, grantLoginSession} from "../actions/loginActions";
import Albums from '../components/mxt/music/Albums.jsx';


const mapStateToProps = state => ({
  currentTrack: state.drive.currentTrack,
  sessionToken: state.userSessionInfo.sessionToken
})

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    setCurrentTrack,
    setCurrentAlbum,
    exitSession,
    grantLoginSession,
  }, dispatch)


export default connect(mapStateToProps, mapDispatchToProps)(Albums)

