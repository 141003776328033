import { applyMiddleware, createStore } from 'redux';
import rootReducer from '../reducers/rootReducer';
import { composeWithDevTools } from 'redux-devtools-extension';
import { thunk } from 'redux-thunk';

export default function configureStore(defaultState = {}) {
  const store = createStore(
    rootReducer,
    defaultState,
    composeWithDevTools(applyMiddleware(thunk))
  );

  return store;
}