import React, { useState, useContext } from "react";

import MoveItemModal from "./modals/MoveItemModal"; // Import the MoveItemModal component
import { useCreateOrCopySharedLink } from "./hooks/useSharingHooks";
import ModalContext from "./contexts/ModalContext";
import EditAudioModal from "./editing/EditAudioModal";
import {
  PencilIcon,
  TrashIcon,
  ShareIcon,
  ArrowsPointingOutIcon,
  ScissorsIcon,
} from "@heroicons/react/24/outline";
import { ReelIcon } from "./icons/ReelIcon";
import DubbingModal from "./DubbingModal";
// const FolderActionsMenu = ({ item, onMove, onRename, onDelete }) => {
const FolderActionsMenu = ({
  item,
  onMove,
  onRename,
  onDelete, // Add this prop
}) => {
  const [showMenu, setShowMenu] = useState(false);
  const [showMoveModal, setShowMoveModal] = useState(false); // Add this line
  const [showEditModal, setShowEditModal] = useState(false); // Add this line
  const [showDubbingModal, setShowDubbingModal] = useState(false); // New State

  const createOrCopySharedLink = useCreateOrCopySharedLink(item); // Add this line
  const { showModal, closeModal } = useContext(ModalContext); // Get the showModal and closeModal methods
  const MenuItem = ({ children, onClick, icon }) => {
    return (
      <button
        className="text-sm text-gray-700 hover:text-gray-900 px-2 py-1 w-full text-left rounded transition-colors duration-200 ease-in-out hover:bg-gray-200 dark:text-gray-300 dark:hover:text-gray-100 dark:hover:bg-gray-700 whitespace-nowrap flex items-center"
        onClick={onClick}
      >
        {icon && <span className="mr-2">{icon}</span>}
        {children}
      </button>
    );
  };

  const handleDubbing = (event) => {
    event.stopPropagation();
    showModal();
    setShowMenu(false);
    setShowDubbingModal(true);
  };

  const handleEdit = (event) => {
    event.stopPropagation();
    showModal(); // Update the modal state in the context
    setShowMenu(false);
    setShowEditModal(true); // Add this line
  };
  const renderEditModal = () => {
    return (
      <div>
        {showEditModal && (
          <EditAudioModal
            item={item}
            onClose={() => {
              closeModal(); // Close the modal
              setShowEditModal(false);
            }}
          />
        )}
      </div>
    );
  };
  const handleCreateOrCopySharedLink = (event) => {
    event.stopPropagation();
    createOrCopySharedLink();
    setShowMenu(false);
  };
  // FolderActionsMenu.js

  const handleMove = (event) => {
    event.stopPropagation();
    console.log("handleMove item:", item); // Add this line to log the item
    console.log("handleMove item.path:", item); // Add this line to log the item's path
    setShowMoveModal(true);
    showModal(); // Update the modal state in the context
    setShowMenu(false);
  };
  

  const handleDelete = (event) => {
    event.stopPropagation();
    onDelete();
    setShowMenu(false);
  };
  const toggleMenu = (event) => {
    event.stopPropagation();
    setShowMenu(!showMenu);
  };

  const handleRename = (event) => {
    event.stopPropagation();
    onRename();
    setShowMenu(false);
  };

  return (
    <div className="relative inline">
      <button
        className="border-none px-2 bg-transparent cursor-pointer text-xl text-gray-700 dark:text-gh_charcoal_foreground_primary-100 dark:hover:text-gray-100 hover:text-gray-900"
        onClick={toggleMenu}
      >
        &#x22EE;
      </button>

      {showMenu && (
        <div className="absolute right-0 bg-white dark:bg-gh_charcoal_foreground_secondary-100 shadow-md rounded p-2 z-10">
          <MenuItem
            onClick={handleRename}
            icon={<PencilIcon className="h-4 w-4" />}
          >
            rename
          </MenuItem>
          <MenuItem
            onClick={handleMove}
            icon={
              <ArrowsPointingOutIcon
                className="h-4 w-4"
                style={{ transform: "rotate(45deg)" }}
              />
            }
          >
            move
          </MenuItem>
          <MenuItem
            onClick={handleDelete}
            icon={<TrashIcon className="h-4 w-4" />}
          >
            delete
          </MenuItem>
          <MenuItem
            onClick={handleCreateOrCopySharedLink}
            icon={<ShareIcon className="h-4 w-4" />}
          >
            {item.publicLinkId && item.publicLinkEnabled
              ? "copy shared link"
              : "create shared link"}
          </MenuItem>
          {item.type === "audio" && (
            <MenuItem
              onClick={handleEdit}
              icon={<ScissorsIcon className="h-4 w-4" />}
            >
              edit
            </MenuItem>
          )}
{/* 
        {item.type === "audio" && (
            <MenuItem
              onClick={handleDubbing}
              icon={<ReelIcon className="h-4 w-4" />}
            >
              dub to tape
            </MenuItem>
          )} */}
        </div>
      )}
      {renderEditModal() /* Add this line */}

      {showDubbingModal && (
        <DubbingModal
          item={item}
          onClose={() => {
            closeModal();
            setShowDubbingModal(false);
          }}
        />
      )}
      {showMoveModal && (
        <MoveItemModal
          item={item}
          onMove={onMove}
          onClose={() => {
            closeModal(); // Update the modal state in the context
            setShowMoveModal(false);
          }}
        />
      )}
    </div>
  );
};

export default FolderActionsMenu;
