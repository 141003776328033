import React, { useState, useEffect } from "react";
import { useSetAlbumArtist } from "../hooks/useSetAlbumArtist";
import useProfileInfo from "../../hooks/useProfileInfo";
import useAlbumInfo from "../hooks/useAlbumInfo";
import LightSpinner from "../../../../loading/LightSpinner";

const SetAlbumArtistView = ({ albumId }) => {
  const {
    artists,
    loading: artistsLoading,
    error: artistsError,
  } = useProfileInfo();
  const {
    albumInfo,
    artistId,
    loading: albumInfoLoading,
  } = useAlbumInfo(albumId);
  const [selectedArtistId, setSelectedArtistId] = useState("");
  const [initialArtistId, setInitialArtistId] = useState(""); // New state for initial artist ID
  const {
    setAlbumArtist,
    loading: settingArtistLoading,
    error: settingArtistError,
  } = useSetAlbumArtist();
  const [successMessage, setSuccessMessage] = useState("");

  useEffect(() => {
    setSelectedArtistId(artistId || "");
    setInitialArtistId(artistId || ""); // Set initial artist ID when artist ID changes
  }, [artistId]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    await setAlbumArtist(albumId, selectedArtistId);
    setSuccessMessage(`Artist set successfully.`);
  };

  if (artistsLoading || albumInfoLoading) return <LightSpinner />;
  if (artistsError || settingArtistError)
    return (
      <div>Error: {artistsError?.message || settingArtistError?.message}</div>
    );

  const isArtistChanged = initialArtistId !== selectedArtistId; // Check whether the selected artist has changed

  return (
    <div className="max-w-md mx-auto">
      
      <form onSubmit={handleSubmit} className="space-y-4">
        <select
          className="w-full p-2 border border-gray-300 text-black bg-inhherit  focus:ring-blue-500 focus:border-blue-500"
          value={selectedArtistId}
          onChange={(e) => setSelectedArtistId(e.target.value)}
          disabled={artistsLoading}
        >
          <option value="">Select Artist</option>

          {artists.map((artist) => (
            <option key={artist.id} value={artist.id}>
              {artist.name}
            </option>
          ))}
        </select>
        <div className="text-xs pb-2  font-theFutureMonoRegular">
            {"*leave artist blank for compilations"}
          </div>
        {isArtistChanged && (
          <button
            type="submit"
            className="w-full bg-blue-500 hover:bg-blue-700 text-white font-theFutureMonoRegular py-2 px-4 rounded disabled:opacity-50"
            disabled={settingArtistLoading}
          >
            set artist
          </button>
        )}
      </form>
      {successMessage && (
        <div className="mt-4 text-green-500">{successMessage}</div>
      )}
    </div>
  );
};

export default React.memo(SetAlbumArtistView);
