import {LOGGING_OUT, LOGIN_GRANTED, RETRIEVING_TOKEN, STORING_REFRESHED_TOKEN} from "../actions/loginActions";

export default (state = {isAuthenticated: false},  action = {}) => {
  switch (action.type) {
    case LOGIN_GRANTED:
      return {...state, isAuthenticated: true}
    case LOGGING_OUT:
      return {...state, isAuthenticated: false}
    default:
      return state
  }
}