import { Formik, Form, useField } from "formik";
import React, { useState } from "react";
import ProductImageUploader from "../../file_mgmt/ProductImageUploader";
import MerchFormInput from "../dashboard/MerchFormInput";
import MerchTextAreaView from "../dashboard/MerchTextAreaView";
import MerchTextInputView from "../dashboard/MerchTextInputView";

const MerchDetailsBox = ({ merchItem }) => {
  //   const [shipsIntl, setShipping] = useState(status);

  return (
    <div>
      <br className="padded"></br>
      <h1 className="padded-5">details</h1>
      <div className="bordered-light">
        <div className="padded">
          <div>
            <MerchTextInputView
              // label="product name"
              name="name"
              type="text"
              placeholder="item title"
              // style={{ color: "lightGray" }}
            />
          </div>
          <div className="padding-top-10">
            <MerchTextAreaView
              // label="product name"
              name="description"
              type="text"
              placeholder="description"
              // style={{ color: "lightGray" }}
            />
          </div>
          <div className="padded">
            <ProductImageUploader
              className="padded"
              merchItem={merchItem}
            ></ProductImageUploader>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MerchDetailsBox;
