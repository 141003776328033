// ModalFooter.jsx

import React from "react";

const ModalFooter = ({ onClose }) => {
  return (
    <div className="bg-purple-500 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
      <button
        onClick={onClose}
        type="button"
        className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-purple-600 text-base font-medium text-white hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500 sm:ml-3 sm
:w-auto sm:text-sm"
      >
        Close
      </button>
    </div>
  );
};

export default ModalFooter;

