import React from "react";
import EmptyState from "./EmptyState";
import DriveItemsList from "./DriveItemsList";

const DriveItems = ({
  items,
  openItem,
  updateFolderName,
  setItems,
  loading,
}) => {
  if (loading) {
    return (
      <div className="flex items-center justify-center h-full">
        <p className="text-lg font-semibold">Loading drive items...</p>
      </div>
    );
  }

  return (
    <ul className="grid grid-cols-1 gap-4 ">
      <ul className="grid grid-cols-1 gap-4 ">
        {items.length === 0 && !loading ? (
          <EmptyState />
        ) : (
          <DriveItemsList
            items={items}
            openItem={openItem}
            updateFolderName={updateFolderName}
            setItems={setItems} // Pass the setItems prop
          />
        )}
      </ul>
    </ul>
  );
};

export default DriveItems;
