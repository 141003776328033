import { useState, useEffect } from 'react';
import { useGenKit } from './useGenKit';

export const useTrackNameInference = () => {
    const [trackName, setTrackName] = useState('');
    const { response, loading, error, generateResponse } = useGenKit();
  
    const runInference = async (fileName) => {
      try {
        if (fileName) {
          // Sanitize the file name to avoid special characters
          const sanitizedFileName = fileName.replace(/[^a-zA-Z0-9\s]/g, '');
          const inferredPrompt = `Suggest a track name based on the file name: ${sanitizedFileName}.  Only provide the name without any additional context. It should be suitable for a track name on an album. Like what you'd expect to see on a streaming service like spotify or apple music.`;
          await generateResponse(inferredPrompt);
        }
      } catch (err) {
        console.error("Error running inference:", err);
      }
    };
  
    // Update the track name once the response is received
    useEffect(() => {
      if (response && response.result) {
        console.log("AI Response:", response); // Debugging response
        setTrackName(response.result);  // Ensure you're extracting the 'result' field
      }
    }, [response]);
  
    return { trackName, loading, error, runInference };
  };