// src/components/Track.js
import React, { useState } from 'react';

const Track = ({ track, index, onUpdate }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editedTrack, setEditedTrack] = useState({ ...track });

  const handleDoubleClick = () => {
    setIsEditing(true);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEditedTrack({ ...editedTrack, [name]: value });
  };

  const handleBlur = () => {
    onUpdate(editedTrack);
    setIsEditing(false);
  };

  if (isEditing) {
    return (
      <div className="flex items-center justify-between">
        <input
          type="text"
          className="text-lg p-1"
          name="title"
          value={editedTrack.title}
          onChange={handleChange}
          onBlur={handleBlur}
          autoFocus
        />
        <input
          type="text"
          className="text-sm p-1"
          name="duration"
          value={editedTrack.duration}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      </div>
    );
  }

  return (
    <div className="flex items-center justify-between" onDoubleClick={handleDoubleClick}>
      <span className="text-lg">{index + 1}. {track.title}</span>
      <span className="text-sm">{track.duration}</span>
    </div>
  );
};

export default Track;
