// Loading.jsx
import React from "react";
import GridContainer from "../../themes/GridContainer";
import LightSmallSpinner from "../../loading/LightSmallSpinner";

const Loading = ({ isIOS }) => {
  return (
    <GridContainer>
      <div className="center vertical-center">
        <div className="horizontal-stack">
          {/* {isIOS && <iOSDownloadPrompt />} */}
          {/* <div className="dark:text-green-300 font-theFutureMonoRegular">loading...</div> */}
          <LightSmallSpinner />
        </div>
      </div>
    </GridContainer>
  );
};

export default Loading;