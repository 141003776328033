// Splash.jsx

// StudioPlusUpgradeView.jsx

// StudioPlans.js;
import React, { useEffect, useState } from "react";
import APIConfig from "../APIConfig";
import SmallLogoView from "../components/aesthetic/SmallLogoView";
import WideLinkButton from "../components/buttons/WideLinkButton";
import phaze_logo from "../assets/phaze-svg-1.svg";
import scan1 from "../assets/scans/Texture_02.png";
import ResponsiveImageTextGroup from "../components/landing/ResponsiveImageTextGroup";
import ResponsiveImageTextGroupList from "../components/landing/ResponsiveImageTextGroupList";
import ImageHandler from "../components/landing/ImageHandler";
import BetaDownloadButtonView from "../components/landing/BetaDownloadButtonView";
function Splash() {
  // if (APIConfig.phaze() === true) {
  //   svg_logo = phaze_logo;
  //   text = "phaze";
  // }

  // list of images and text
  // const [imageTextList, setImageTextList] = useState([]);

  // useEffect(() => {
  //   // fetch the list of images and text
  //   setImageTextList();
  // }, [ imageTextList ]);

  const imageTextList = [
    {
      image: "https://unsplash.it/200/300",
      text: "sell merch without fees from your phone",
    },

    {
      image: "https://unsplash.it/200/300",
      text: "sell merch without fees from your phone",
    },

    {
      image: "https://unsplash.it/200/300",
      text: "sell merch without fees from your phone",
    },
  ];

  // style var
  // body {
  //   background-size: 40px 40px;
  //   background-image:
  //     linear-gradient(to right, grey 1px, transparent 1px),
  //     linear-gradient(to bottom, grey 1px, transparent 1px);
  // }

  const gridBackgroundStyle = {
    // make the grid background appear more transparent

    backgroundImage: `linear-gradient(to right, #217F8E 0.5px, transparent 1px), linear-gradient(to bottom,  #217F8E 1px, transparent 1px)`,
    backgroundSize: "35px 35px",
  };

  const getGridBackgroundStyle = () => {
    return (
      <div style={gridBackgroundStyle} className="w-auto h-auto  z-0">
        <div class=" flex justify-center items-center z-20 flex-col p-10">
          <ResponsiveImageTextGroupList
            imageTextList={imageTextList}
          ></ResponsiveImageTextGroupList>
          {/* grid backgroudn behind the responsive image/text group list */}
          <div class="py-5 flex flex-row justify-center items-center">
{/* apple logo */}
            <BetaDownloadButtonView class=" p-10 px-10 py-96"></BetaDownloadButtonView>
          </div>
          <div class="py-96"></div>
        </div>
        {/* use the tailwind z-index to make the logo appear behind the responsive image/text group list  */}
        {/* center-vertical */}
        {/* center of the screen */}
      </div>
    );
  };

  return <div>{getGridBackgroundStyle()}</div>;
}

export default Splash;
