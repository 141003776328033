import { Formik, Form, useField } from "formik";
import React, { useState } from "react";
import MerchFormInput from "../dashboard/MerchFormInput";
import MerchSelectView from "../MerchSelectView";

const MerchSelectBox = () => {
  return (
    <div className="">
      <h1 className="padded-5">about</h1>
      <div className="padded-15 bordered-light">
        <div className="padded-5 ">what is it?</div>
        <div className="padded">
          <div className=" bordered" style={{ width: 120 }}>
            <MerchSelectView className="dark" name="category">
              <option value="">select</option>
              <option value="cassette">cassette</option>
              <option value="vinyl">vinyl</option>
              <option value="shirt">shirt</option>
              <option value="other">other</option>
            </MerchSelectView>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MerchSelectBox;
