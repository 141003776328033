// MemoriesList.jsx

// MemoriesList.jsx

import React, { useState } from "react";
import ImageViewer from "./ImageViewer";

const MemoriesList = ({ memories }) => {
  const [selectedImage, setSelectedImage] = useState(null);

  const openImageViewer = (imageUrl) => {
    setSelectedImage(imageUrl);
  };

  const closeImageViewer = () => {
    setSelectedImage(null);
  };

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 p-4 dark:bg-gray-800">
      {memories.map((memory, index) => (
        <div
          key={index}
          className="bg-white dark:bg-gray-700 rounded-lg p-4 shadow-md cursor-pointer hover:bg-gh_blue-500 hover:shadow-lg transition-shadow duration-300 ease-in-out"
          onClick={() => openImageViewer(memory.downloadURL)}
        >
          <h2 className="text-lg font-semibold mb-2 text-gray-900 dark:text-gray-100">
            {memory.title}
          </h2>
          <p className="text-sm mb-4 text-gray-800 dark:text-gray-300">
            {memory.description}
          </p>
          <img
            src={memory.downloadURL}
            alt={memory.title}
            className="w-full h-auto"
          />
        </div>
      ))}
      <ImageViewer
        isOpen={!!selectedImage}
        onRequestClose={closeImageViewer}
        imageUrl={selectedImage}
      />
    </div>
  );
};

export default MemoriesList;
