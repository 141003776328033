import React from "react";

const EventTitleView = ({ title }) => {
  return (
    <div className="w-full">
      <h1 className="font-sans text-3xl sm:text-4xl md:text-5xl font-bold text-gray-900 dark:text-white">
        {title}
      </h1>
    </div>
  );
};

export default EventTitleView;