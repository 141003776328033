import React, { useState, useContext, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import { useAuthState } from "react-firebase-hooks/auth";
import { toast } from "react-toastify";
import { useNavigate, useLocation } from "react-router-dom";
import { useUploadFile } from "./hooks/useStorageHooks";
import {
  useCreateSharedFolder,
  useActivatePublicLink,
} from "./hooks/useSharingHooks";
import {
  useDriveItems,
  useCreateFolder,
  useDeleteAll,
  useUpdateFolderName,
  useOpenItem,
  useGoBack,
} from "./hooks/useDriveHooks";

import Toolbar from "./Toolbar/Toolbar";
import DriveItems from "./DriveItems";
import UploadProgress from "./Toolbar/UploadProgress";
import AirtrakSplash from "../../artist_dashboard/drive/AirtrakSplash";
import PathContext from "./contexts/PathContext";
import PhazeSplash from "../../../services/PhazeSplash";
import APIConfig from "../../../APIConfig";
import PhazeSplashPage from "../../artist_dashboard/drive/PhazeSplashPage";

const DriveView = () => {
  const auth = firebase.auth();
  const [user, loading] = useAuthState(auth);
  const { currentPath, setCurrentPath } = useContext(PathContext);
  const [items, setItems] = useDriveItems(loading, currentPath);

  const [uploadFile, uploading, uploadProgress] = useUploadFile(
    items,
    setItems,
    currentPath
  );

  const deleteAll = useDeleteAll(items, setItems, currentPath);
  const navigate = useNavigate();
  const location = useLocation();

  const onDrop = async (acceptedFiles) => {
    if (!user) return;

    for (const file of acceptedFiles) {
      try {
        await uploadFile(file);
      } catch (error) {
        console.error("Error uploading file:", error);
        toast.error("Error uploading file.");
      }
    }
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    noClick: true,
    noKeyboard: true,
  });

  const dropzoneMessage = isDragActive && (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-40 transition-opacity duration-200">
      <div className="text-lg font-semibold text-white p-4 rounded bg-gray-800 bg-opacity-60">
        Drop files here to upload
      </div>
    </div>
  );

  useEffect(() => {
    const unlisten = () => {
      const searchParams = new URLSearchParams(location.search);
      const path = searchParams.get("path") || "";
      setCurrentPath(path);
    };

    unlisten(); // Call unlisten once to handle the initial load
    return () => {}; // Return a cleanup function that does nothing
  }, [location, setCurrentPath]);

  const createFolder = useCreateFolder(items, setItems, currentPath);
  const createSharedFolder = useCreateSharedFolder(
    items,
    setItems,
    currentPath,
    []
  ); // Pass an empty array or a list of shared users

  const updateFolderName = useUpdateFolderName(items, setItems);
  const openItem = useOpenItem(setCurrentPath);
  const goBack = useGoBack(setCurrentPath, currentPath);
  const activatePublicLink = useActivatePublicLink();

  const renderLoading = () => {
    return (
      <div className="flex items-center justify-center h-screen">
        <p className="text-lg font-semibold">Loading...</p>
      </div>
    );
  };

  if (loading) {
    return renderLoading();
  }

  const handleCreateSharedFolder = async () => {
    if (!user) return;

    try {
      // Create the shared folder first
      const folderId = await createSharedFolder(); // Use the new createSharedFolder hook

      // Activate the public link for the created folder
      const publicLinkId = await activatePublicLink(folderId);
      if (!publicLinkId) {
        toast.error("Error activating public link.");
        return;
      }

      // Copy the public link to the clipboard
      const publicLinkUrl = `${window.location.origin}/public/${publicLinkId}`;
      try {
        await navigator.clipboard.writeText(publicLinkUrl);
        toast.success(
          "Shared folder created. Public link copied to clipboard."
        );
      } catch (error) {
        console.error("Error copying public link to clipboard:", error);
        toast.success(
          `Shared folder created. Please copy the public link manually: ${publicLinkUrl}`
        );
      }
    } catch (error) {
      console.error("Error creating shared folder:", error);
      toast.error("Error creating shared folder.");
    }
  };

  if (!user) {
    if (APIConfig.airtrak()) {
      return <AirtrakSplash />;
    }
    if (APIConfig.phaze()) {
      return <PhazeSplashPage />;
    }
  }

  return (
    <div
      {...getRootProps()}
      className={`min-h-screen transition-colors duration-200 ${
        isDragActive
          ? "bg-gray-200 dark:bg-gh_charcoal-200"
          : "bg-gray-100 dark:bg-gh_charcoal-100"
      }`}
    >
      <input {...getInputProps()} />
      {dropzoneMessage}

      <div className="bg-gray-100 dark:bg-gh_charcoal-100 min-h-screen">
        <div className="max-w-5xl mx-auto py-10 px-4">
          <Toolbar
            goBack={goBack}
            createFolder={createFolder}
            createSharedFolder={handleCreateSharedFolder} // Pass the handleCreateSharedFolder function
            currentPath={currentPath}
            setCurrentPath={setCurrentPath}
            uploadFile={uploadFile}
            uploading={uploading}
            uploadProgress={uploadProgress}
          />
          <UploadProgress
            uploading={uploading}
            uploadProgress={uploadProgress}
          />
          <DriveItems
            items={items}
            openItem={openItem}
            updateFolderName={updateFolderName}
            setItems={setItems}
            loading={loading} // Pass the loading value
          />
        </div>
      </div>
    </div>
  );
};

export default DriveView;