// Toolbar/index.js
import React from "react";
import BackButtonIcon from "../icons/BackButtonIcon";
import PathBreadcrumbs from "../navigation/PathBreadcrumbs";
import UploadButton from "./UploadButton";
import CreateButton from "./CreateButton";

const Toolbar = ({
  goBack,
  createFolder,
  createSharedFolder,
  uploadFile,
  uploading,
  uploadProgress,
}) => {
  return (
    <div className="flex items-center mb-6">
      <button
        onClick={goBack}
        className="text-blue-500 hover:text-blue-700 font-bold py-2 mr-3"
      >
        <BackButtonIcon className="h-6 w-6" />
      </button>
      <UploadButton
        uploadFile={uploadFile}
        uploading={uploading}
        uploadProgress={uploadProgress}
      />

      <CreateButton
        createFolder={createFolder}
        createSharedFolder={createSharedFolder}
      />

      <PathBreadcrumbs />
    </div>
  );
};

export default Toolbar;
