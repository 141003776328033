// ArtistSearch.js
import React, { useState } from 'react';
import SearchBar from './SearchBar';
import { useSearchArtists } from '../../data layer/useSearchArtists';

const ArtistSearch = ({ onSelect, renderResult, placeholder = "Search artists..." }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const { artists, loading, error } = useSearchArtists(searchTerm);

  return (
    <SearchBar
      searchTerm={searchTerm}
      setSearchTerm={setSearchTerm}
      searchResults={artists}
      loading={loading}
      error={error}
      onSelect={onSelect || ((artist) => console.log("Selected artist ID:", artist.id))}
      placeholder={placeholder}
      renderResult={renderResult || ((artist) => <li className="search-result-item">{artist.name}</li>)}
    />
  );
};

export default ArtistSearch;
