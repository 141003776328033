import React, { useHook, useEffect, useState, useContext } from "react";
import { useParams } from "react-router-dom";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
// useauth
import { useAuthState } from "react-firebase-hooks/auth";
import storage from "../../services/storage";
import Container from "../themes/Container";
import FileSystemContainer from "../themes/FileSystemContainer";

import { getAuth, signInWithEmailAndPassword, signOut } from "firebase/auth";
const auth = getAuth(firebase.app());
function MyTapesView() {
  const [user, loading, error] = useAuthState(auth);
  // const [loading, setLoading] = useState(true);
  const [files, setFiles] = useState([]);
  let { slug } = useParams();
  let db = firebase.firestore();

  const fetchData = async (uid) => {
    // console.log("fetching data, uid: " + uid);
    var files_ref = db.collection("studio").doc(uid).collection("files");
    files_ref.onSnapshot(function (querySnapshot) {
      var files = [];
      querySnapshot.forEach(function (doc) {
        files.push(doc.data().filename);
      });
      setFiles(files);
    });
  };
  useEffect(() => {
    

  }, []);

  if (loading) {
    return (
      <div>
        <p>Initialising User...</p>
      </div>
    );
  }
  if (error) {
    return (
      <div>
        <p>Error: {error}</p>
      </div>
    );
  }
  if (user) {

      fetchData(user.uid);
    
    return (
      <div>
        <p>Current User: {user.email}</p>
        {/* <button onClick={logout}>Log out</button> */}
        <div>
          <h1 className="font-theFutureMonoRegular dark:text-slate-200">/files</h1>
          <div className="px-10 font-theFutureMonoRegular text-blue-200 py-1">
            {files.map((file, index) => (
              <div className="border-2 border-blue-200">

              <p className="p-2" key={index}>{file}</p>
              </div>

            ))}
          </div>
        </div>
      </div>
    );
  }
  // return (
  //   <FileSystemContainer className="center">
  //     <div>
  //       <h1>/files</h1>
  //       <div>
  //         {files.map((file, index) => (
  //           <p key={index}>{file}</p>
  //         ))}
  //       </div>
  //     </div>
  //   </FileSystemContainer>
  // );
}
export default MyTapesView;

