// DriveViewPanel.jsx
import React from 'react';

const DriveViewPanel = ({ children }) => {
  return (
    <div className="dark:bg-gh_charcoal_secondary-100 p-6 rounded-md border-1 bg-gh_white-100 border-gh_light_outline-100 h-full">
      <div className="">{children}</div>
    </div>
  );
};

export default DriveViewPanel;
