// LinkCopyView.js;

import React from "react";
import ReactDOM from "react-dom";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Container from "../themes/Container";
import StandardButton from "../buttons/StandardButton";
import ClipboardCopy from "./ClipboardCopy";

class ShareLinkCopyView extends React.Component {
  state = {
    value: "",
    copied: false,
  };
  
  render() {
    return (
      
      <ClipboardCopy copyText={this.props.link} ></ClipboardCopy>
    );
  }
}

export default ShareLinkCopyView;
