import React from "react";
import ReactDOM from "react-dom";

import AudioPlayer from "./AudioPlayer";

import "./styles.css";

// const AUDIO_LINK =
  // "https://storage.googleapis.com/backtolisten2/Yagazie-lesson-v2.mp3";

function MXAudioPLayer() {
  const img = "https://storage.googleapis.com/tuareg_music/b62228cc-08fe-4486-9eb8-eab22a65961c417A4EAB-EB27-4C26-B04C-E668A232B174.png"
  const AUDIO_LINK = "https://storage.googleapis.com/tuareg_music/x.mp3"
  return (
    <div className="MXAudioPlayer">
      {/* <h1>Lesson</h1> */}
      <div className="song-container" style={{ maxWidth: 600 }}>
        
        {/* <img
          alt="cover"
          src={img}
        /> */}
        <div className="song-content">
          <div className="song-title">x</div>
          {/* <div className="">san delila</div> */}
          <AudioPlayer src={AUDIO_LINK} />
        </div>
      </div>
    </div>
  );
}

export default MXAudioPLayer;
