import React, { useEffect } from "react";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";


import LogoView from "../../components/LogoView";
import SignupHeading from "./SignupHeading";
import SignupForm from "./SignupForm";

const SignupView = () => {
  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged(
      (user) => {
        if (user) {
          console.log("User signed in: ", user);
        } else {
          console.log("No user signed in.");
        }
      },
      (error) => {
        console.error("Error in onAuthStateChanged: ", error);
      }
    );

    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <div className="bg-gradient-to-br from-blue-500 to-indigo-600 min-h-screen flex items-center justify-center">
      <div className="bg-white p-8 rounded-lg shadow-md w-full max-w-md">
        <div className="py-5">
          <LogoView />
        </div>
        <SignupHeading />
        <SignupForm />
      </div>
    </div>
  );
};

export default SignupView;
