import React, { useContext } from "react";
import { useLocation } from "react-router-dom";
import { firebaseAuth } from "../../provider/AuthProvider";
import { useAuthState } from "react-firebase-hooks/auth";
import { Title } from "./Title";
import MenuItems from "./menu/MenuItems";
import firebase from "firebase/compat/app";

const NavBar = () => {
  const { token } = useContext(firebaseAuth);
  const location = useLocation();
  const auth = firebase.auth();
  const [user] = useAuthState(auth);
  const isRoot = location.pathname === "/";

  let altAuthPath;
  if (location.pathname === "/signin") {
    altAuthPath = { path: "/signup", text: "sign up" };
  } else if (location.pathname === "/signup") {
    altAuthPath = { path: "/signin", text: "sign in" };
  }

  const useGradient = false; // Toggle for gradient background
  const backgroundClass = useGradient
    ? "bg-gradient-to-r from-blue-50 to-purple-50 dark:from-gray-900 dark:to-gray-800"
    : "bg-white dark:bg-black";

  return (
    <nav
      className={`w-full z-10 ${backgroundClass} border-b-2 border-black dark:border-gray-800`}
    >
      <div className="container mx-auto flex justify-between items-center py-2 px-4">
        <Title />
        <MenuItems user={user} altAuthPath={altAuthPath} isRoot={isRoot} />
      </div>
    </nav>
  );
};

export default NavBar;
