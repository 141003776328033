import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import initialData from "./initial-data";
import Column from "./column";
import { DragDropContext } from "react-beautiful-dnd";
import styled from "styled-components";
import storage from "../../../services/storage";
import APIConfig from "../../../APIConfig";

const Container = styled.div`
  display: flex;
`;

const data = {
  tasks: {
    "task-1": { id: "task-1", content: "", url: "" },
  },
  columns: {
    "column-1": {
      id: "column-1",
      title: "/files",
      taskIds: ["task-1"],
    },
  },
  // Facilitate reordering of the columns
  columnOrder: ["column-1"],
};
class DND extends React.Component {
  state = data;
  componentDidMount() {
    // APIConfig.headers
    const token = localStorage.getItem("token");
    APIConfig.token = token;

    storage.getFiles().then((res) => {
      data.tasks = res.tasks;
      data.columns = res.columns;
      data.columnOrder = res.columnOrder;
      this.setState({
        state: data,
      });
    });
    storage.getMXT().then((res) => {
      const column = res.columns["column-2"];
      const tasks = column.taskIds.map((taskId) => res.tasks[taskId]);

      data.columns["column-2"] = column;

      tasks.map((task) => (data.tasks[task.id] = task));
      // data.tasks.push(res.tasks);
      // data.columns["column-2"] = res.columns["column-2"];
      this.setState({
        state: data,
      });

      // res.tasks;
      // data.columns = res.columns;
      // data.columnOrder = res.columnOrder;
      // this.setState({
      //   state: data.tasks,
      // });
    });
  }

  onDragStart = (start) => {
    const homeIndex = this.state.columnOrder.indexOf(start.source.droppableId);
    this.setState({
      homeIndex,
    });
  };

  onDragEnd = (result) => {
    this.setState({
      homeIndex: null,
    });

    const { destination, source, draggableId } = result;

    if (!destination) {
      return;
    }

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    const home = this.state.columns[source.droppableId];
    const foreign = this.state.columns[destination.droppableId];

    if (home === foreign) {
      const newTaskIds = Array.from(home.taskIds);
      newTaskIds.splice(source.index, 1);
      newTaskIds.splice(destination.index, 0, draggableId);

      const newHome = {
        ...home,
        taskIds: newTaskIds,
      };

      const newState = {
        ...this.state,
        columns: {
          ...this.state.columns,
          [newHome.id]: newHome,
        },
      };

      this.setState(newState);
      return;
    }

    // moving from one list to another
    const homeTaskIds = Array.from(home.taskIds);
    homeTaskIds.splice(source.index, 1);
    const newHome = {
      ...home,
      taskIds: homeTaskIds,
    };

    const foreignTaskIds = Array.from(foreign.taskIds);
    foreignTaskIds.splice(destination.index, 0, draggableId);
    const newForeign = {
      ...foreign,
      taskIds: foreignTaskIds,
    };

    const newState = {
      ...this.state,
      columns: {
        ...this.state.columns,
        [newHome.id]: newHome,
        [newForeign.id]: newForeign,
      },
    };

    this.setState(newState);
    var _tasks = [];
    var new_tasks_ids = newState.columns["column-2"].taskIds;
    const column = newState.columns["column-2"];
    const tasks = column.taskIds.map((taskId) => newState.tasks[taskId]);

    if (!Array.prototype.last) {
      Array.prototype.last = function () {
        return this[this.length - 1];
      };
    }
    storage.setMXTFiles(tasks.last());
  };

  render() {
    return (
      <DragDropContext
        onDragStart={this.onDragStart}
        onDragEnd={this.onDragEnd}
      >
        <Container>
          {this.state.columnOrder.map((columnId, index) => {
            const column = this.state.columns[columnId];
            const tasks = column.taskIds.map(
              (taskId) => this.state.tasks[taskId]
            );
            const isDropDisabled = index < this.state.homeIndex;

            return (
              <Column
                key={column.id}
                column={column}
                tasks={tasks}
                //uncomment below to make it impossible to move task from right to left
                isDropDisabled={isDropDisabled}
              />
            );
          })}
        </Container>
      </DragDropContext>
    );
  }
}

// ReactDOM.render(<DND />, document.getElementById("root"));
export default DND;
