import React, { useState, useEffect } from 'react';
import { SparklesIcon } from '@heroicons/react/24/solid';
import { useTrackNameInference } from '../drafting/hooks/useTrackNameInference';
import useTrackEditor from './useTrackEditor'; // Import the editor hook

const InferTrackNameButton = ({ task, album, index }) => {
  const { runInference, trackName: inferredTrackName, loading } = useTrackNameInference(); // Add loading state from hook
  const { saveTrackName } = useTrackEditor(task, album, index); // Use the editor hook to save track name

  const handleInference = async () => {
    if (task.url) {
      await runInference(task.url); // Run inference
      // Automatically save the inferred track name
      if (inferredTrackName) {
        saveTrackName(inferredTrackName); // Save the inferred name
      }
    }
  };

  // Update the track name if inference succeeds
  useEffect(() => {
    if (inferredTrackName) {
      saveTrackName(inferredTrackName); // Automatically save the inferred name
    }
  }, [inferredTrackName, saveTrackName]);

  return (
    <button
      onClick={handleInference}
      className="flex items-center space-x-1 bg-gray-700 hover:bg-gray-800 text-white p-2 rounded-md"
      disabled={loading} // Disable button while loading
    >
      {loading ? (
        // Display loading spinner when AI is processing
        <svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
          <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
          <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"></path>
        </svg>
      ) : (
        <SparklesIcon className="h-5 w-5" />
      )}
    </button>
  );
};

export default InferTrackNameButton;