import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import CreateAlbumView from "./album_creator/CreateAlbumView";
import AlbumDraftImageUploader from "./album_creator/views/images/AlbumDraftImageUploader";
import EditAlbumMetadataView from "./EditAlbumMetadataView";
import SetAlbumArtistView from "./album_creator/views/SetAlbumArtistView";
import ModularSidebar from "../views/ModularSidebar";
import WideButton from "../../buttons/WideButton";
import LightSpinner from "../../loading/LightSpinner";
import DeleteAlbumButton from "./DeleteAlbumButton";
import useAlbumLoader from "./hooks/useAlbumLoader";

function DraftEditView() {
  const location = useLocation();
  const history = useNavigate();

  const [isSidebarOpen, setSidebarOpen] = useState(true);
  const { album, isLoading, finalize, loadAlbum } = useAlbumLoader({}, location);

  const toggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
  };

  useEffect(() => {
    loadAlbum();
  }, [loadAlbum]);

  useEffect(() => {
    const checkIfLargeScreen = () => {
      setSidebarOpen(window.innerWidth >= 768);
    };

    checkIfLargeScreen();
    window.addEventListener("resize", checkIfLargeScreen);
    return () => window.removeEventListener("resize", checkIfLargeScreen);
  }, []);

  const SHOW_SIDEBAR = false;

  return (
    <div className="flex w-full h-screen bg-gray-100 pb-5">
      {SHOW_SIDEBAR && (
        <ModularSidebar isOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
      )}
      <div className={`flex-1 flex flex-col ${SHOW_SIDEBAR && isSidebarOpen ? "lg:ml-64" : ""}`}>
        <main className="flex-grow px-4 sm:px-6 lg:px-8 py-8 overflow-auto w-full">
          {isLoading ? (
            <div className="flex items-center justify-center p-10 w-full">
              <LightSpinner />
            </div>
          ) : (
            <div className="w-full mx-auto flex flex-col items-center space-y-6">
              
              {/* Album Image Uploader */}
              <div className="w-full p-4 bg-white rounded-lg shadow-md">
                <AlbumDraftImageUploader album={album} />
              </div>

              {/* Album Metadata Editor */}
              <div className="w-full p-4 bg-white rounded-lg shadow-md">
                <EditAlbumMetadataView album={album} />
              </div>

              {/* Set Album Artist */}
              <div className="w-full p-4 bg-white rounded-lg shadow-md">
                <SetAlbumArtistView albumId={album?.docId} />
              </div>

              {/* Create Album */}
              <div className="w-full p-4 bg-white rounded-lg shadow-md">
                <CreateAlbumView album={album} />
              </div>

              {/* Actions Row */}
              <div className="w-full flex flex-col sm:flex-row justify-between items-center p-4 bg-white rounded-lg shadow-md space-y-4 sm:space-y-0">
                <DeleteAlbumButton album={album} />
                <WideButton lambda={() => finalize()} text={"Next"} />
              </div>

              {/* Sidebar Toggle for Mobile */}
              <button
                onClick={toggleSidebar}
                className="p-2 mt-4 bg-blue-500 text-white rounded-lg hover:bg-blue-600 focus:outline-none lg:hidden"
              >
                {isSidebarOpen ? "Close Sidebar" : "Open Sidebar"}
              </button>
            </div>
          )}
        </main>
      </div>
    </div>
  );
}

export default DraftEditView;