// Splash.jsx

// StudioPlusUpgradeView.jsx

// StudioPlans.js;
import React, { useEffect, useState } from "react";
import APIConfig from "../APIConfig";
import SmallLogoView from "../components/aesthetic/SmallLogoView";
import WideLinkButton from "../components/buttons/WideLinkButton";

import phaze_logo from "../assets/phaze-svg-1.svg";
import scan1 from "../assets/scans/Texture_02.png";
import BetaDownloadButtonView from "../components/landing/BetaDownloadButtonView";
function PhazeSplash() {
  var svg_logo = "https://storage.googleapis.com/tuareg/media/mxt-logo.svg";
  var text = ".mixtape";
  const [splashText, setSplashText] = useState("seize the night");
  if (APIConfig.phaze() === true) {
    svg_logo = phaze_logo;
    text = "phaze";
  }
  useEffect(() => {}, []);
  
  function getGradient() {
    return (

      
      <div className="py-10 border-2 border-pink-700 rounded ">
        <div
          className=" text-slate-100 font-scopeMonoBoldExpanded text-3xl px-5 py-1"
        >
          {splashText}
        </div>
        <div className=" font-scopeMonoBoldExpanded  text-slate-200 text-3xl px-5 py-1">
          {splashText}
        </div>
        <div className="font-scopeMonoBoldExpanded  text-slate-300 text-3xl px-5 py-1">
          {splashText}
        </div>

        <div className="font-scopeMonoBoldExpanded  text-slate-400 text-3xl px-5 py-1">
          {splashText}
        </div>
        <div className="font-scopeMonoBoldExpanded  text-3xl text-slate-500 px-5 py-1">
          {splashText}
        </div>
        <div className=" font-scopeMonoBoldExpanded  text-3xl text-slate-600 px-5 py-1">
          {splashText}
        </div>
        <div className="font-scopeMonoBoldExpanded  text-3xl text-slate-700 px-5 py-1">
          {splashText}
        </div>
        <div className=" font-scopeMonoBoldExpanded  text-3xl text-slate-800 px-5 py-1">
          {splashText}
        </div>
      </div>
    );
  }
  function getRainbow() {
    return (
      <div className="max-w-sm">
        <div>
          <div className="bg-red-600 font-scopeMonoBoldExpanded  text-slate-300 text-3xl px-5 py-1">
            {splashText}
          </div>
          <div className="bg-blue-600 font-scopeMonoBoldExpanded  text-slate-300 text-3xl px-5 py-1">
            {splashText}
          </div>
          <div className="bg-yellow-600 font-scopeMonoBoldExpanded  text-slate-300 text-3xl px-5 py-1">
            {splashText}
          </div>
          <div className="bg-green-600 font-scopeMonoBoldExpanded  text-slate-300 text-3xl px-5 py-1">
            {splashText}
          </div>
          <div className="bg-purple-600 font-scopeMonoBoldExpanded  text-slate-300 text-3xl px-5 py-1">
            {splashText}
          </div>
        </div>
      </div>
    );
  }
  if (APIConfig.phaze() === true) {
    return (
      <div>
        {/* <img src={scan1} alt="scan1" className="absolute z-0 w-screen h-screen opacity-30" /> */}
        {/* </svg> */}
        {/* {getGradient()} */}
        {/* {getRainbow()} */}
      </div>
    );
  }
  return (
    // take up whole available width
    // stack elements vertically
    <div>
      {/* scan1 over whole page */}
    {/* <img src={scan1} alt="scan1" className="absolute z-0 w-screen h-screen" /> */}
    <div className="flex flex-col items-center justify-center h-screen w-screen">
      {/* </svg> */}
      {/* {getGradient()} */}
      {/* {getRainbow()} */}
<BetaDownloadButtonView></BetaDownloadButtonView>      
    </div>
    </div>
  );
}

export default PhazeSplash;
