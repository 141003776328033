// useTrackManager.js
import { useCallback } from 'react';
import drafting from '../../../../../services/drafting';

const useTrackManager = (album, artistId, data, setData) => {
  const createNewTrackObject = useCallback((trackCount, artistId) => ({
    content: "",
    trackName: `Track ${trackCount + 1}`,
    compressed_filename: "",
    url: "",
    index: trackCount,
    metadata: {
      artist_id: artistId || 0,
      featured_artists: [],
    },
  }), []);

  const updateColumnWithNewTrack = useCallback((column, newTrackId) => {
    const newTaskIds = [...column.taskIds, newTrackId];
    return {
      ...column,
      taskIds: newTaskIds,
    };
  }, []);

  const addNewTrack = useCallback(async () => {
    if (!album) {
      console.error("Error: Album is not defined.");
      return;
    }

    const trackCount = Object.keys(data.tasks).length;
    try {
      const newTrack = createNewTrackObject(trackCount, artistId);
      const newTrackId = await drafting.manageTrack(album, newTrack, "add");
      newTrack.id = newTrackId;

      const firstColumnId = data.columnOrder[1];
      const column = data.columns[firstColumnId];
      const updatedColumn = updateColumnWithNewTrack(column, newTrackId);

      const newData = {
        ...data,
        tasks: { ...data.tasks, [newTrackId]: newTrack },
        columns: { ...data.columns, [firstColumnId]: updatedColumn },
      };

      setData(newData);
    } catch (error) {
      console.error("Error adding new track: ", error);
    }
  }, [album, artistId, data, setData]);

  return addNewTrack;
};

export default useTrackManager;
