import { useState, useEffect, useContext, useCallback } from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import { useAuthState } from 'react-firebase-hooks/auth';

const useAlbumDraftCover = (album) => {
  const [user] = useAuthState(firebase.auth());
  const [albumCover, setAlbumCover] = useState({ url: '', loading: true, error: null });

  const fetchAlbumCover = useCallback(() => {
    if (!user || !album?.docId) {
      console.log('No user or album provided. Setting default state.');
      setAlbumCover({ url: '', loading: false, error: null });
      return;
    }

    console.log(`Fetching album cover for ${album.docId}`);
    const imagesRef = firebase.firestore()
      .collection(`studio/${user.uid}/drafts/${album.docId}/images/`)
      .orderBy('last_updated', 'desc')
      .limit(1);

    const unsubscribe = imagesRef.onSnapshot(querySnapshot => {
      if (querySnapshot.empty) {
        console.log('No images found. It might be a new draft.');
        setAlbumCover({ url: '', loading: false, error: null });
      } else {
        querySnapshot.forEach(doc => {
          setAlbumCover({ url: doc.data().public_url, loading: false, error: null });
        });
      }
    }, error => {
      console.error('Error fetching album cover:', error);
      setAlbumCover({ url: '', loading: false, error });
    });

    return () => {
      console.log('Unsubscribing from album cover updates');
      unsubscribe();
    };
  }, [user, album]);

  useEffect(() => {
    fetchAlbumCover();
  }, [fetchAlbumCover]);

  return { ...albumCover, refetch: fetchAlbumCover };
};

export default useAlbumDraftCover;
