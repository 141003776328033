import { useState } from 'react';
import drafting from '../../../../../services/drafting';

const useDragDrop = (data, album, setData, artistId = 0) => {
  const [homeIndex, setHomeIndex] = useState(null);

  const onDragStart = (start) => {
    const index = data.columnOrder.indexOf(start.source.droppableId);
    setHomeIndex(index);
  };

  const onDragEnd = async (result) => {
    const { destination, source, draggableId } = result;

    // If there is no destination or the destination is the same as the source, return early
    if (!destination || (destination.droppableId === source.droppableId && destination.index === source.index)) return;

    const start = data.columns[source.droppableId];
    const finish = data.columns[destination.droppableId];

    // Moving within the same column
    if (start === finish) {
      const newTaskIds = Array.from(start.taskIds);
      newTaskIds.splice(source.index, 1);
      newTaskIds.splice(destination.index, 0, draggableId);

      const newColumn = {
        ...start,
        taskIds: newTaskIds,
      };

      const newState = {
        ...data,
        columns: {
          ...data.columns,
          [newColumn.id]: newColumn,
        },
      };

      // Immediate state update for smooth UI
      setData(newState);

      // Only update Firestore if it's in the "tracks" column
      if (finish.id === 'column-2') {
        newTaskIds.forEach(async (taskId, index) => {
          const taskToUpdate = newState.tasks[taskId];

          // Check if taskToUpdate has a valid ID before updating
          if (!taskToUpdate.id) {
            console.error('Task ID is undefined, cannot update task without a valid ID.');
            return;
          }

          taskToUpdate.index = index;
          await drafting.manageTrack(album, taskToUpdate, 'update');
        });

        // Update album indices if needed
        if (album && Array.isArray(album.songs)) {
          drafting.updateAlbumIndices(album);
        }
      }

      return; // Return early for same-column reordering
    }

    // Moving from the "files" column to the "tracks" column
    if (start.id === 'column-1' && finish.id === 'column-2') {
      const newFinishTaskIds = Array.from(finish.taskIds);
      const fileBeingDragged = data.tasks[draggableId];

      // Create a new track object based on the file details
      const newTrack = {
        content: fileBeingDragged.content,
        trackName: fileBeingDragged.content, // Default to content as track name, can be customized
        compressed_filename: fileBeingDragged.compressed_filename,
        url: fileBeingDragged.url,
        index: destination.index,
        metadata: {
          artist_id: artistId, // Make sure artist ID is preserved
          featured_artists: [],
        },
      };

      // Add the new track to Firestore and get the new track ID
      const newTrackId = await drafting.manageTrack(album, newTrack, 'add');

      // Ensure newTrackId is valid before proceeding
      if (!newTrackId) {
        console.error('Failed to create new track. Track ID is undefined.');
        return;
      }

      // Insert the new track ID at the dropped position
      newFinishTaskIds.splice(destination.index, 0, newTrackId);

      const newFinish = {
        ...finish,
        taskIds: newFinishTaskIds,
      };

      const newState = {
        ...data,
        tasks: {
          ...data.tasks,
          [newTrackId]: {
            ...newTrack,
            id: newTrackId, // Make sure the new track has the ID assigned
          },
        },
        columns: {
          ...data.columns,
          [newFinish.id]: newFinish,
        },
      };

      setData(newState);

      // Update track indices for all tasks in the new column
      newFinishTaskIds.forEach(async (taskId, index) => {
        const taskToUpdate = newState.tasks[taskId];

        // Check if taskToUpdate has a valid ID before updating
        if (!taskToUpdate.id) {
          console.error('Task ID is undefined, cannot update task without a valid ID.');
          return;
        }

        taskToUpdate.index = index;
        await drafting.manageTrack(album, taskToUpdate, 'update');
      });

      // Update album indices if needed
      if (album && Array.isArray(album.songs)) {
        drafting.updateAlbumIndices(album);
      }
    }
  };

  return { onDragStart, onDragEnd, homeIndex };
};

export default useDragDrop;