import React, { useRef, useState, useEffect } from "react";
import useAudioStorage from "../../file_mgmt/useAudioStorage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileAudio } from "@fortawesome/free-regular-svg-icons";

const TrackFileEditor = ({ track, album, onReplaceComplete }) => {
  const { uploadAndReplaceAudioForTrack, progress, setProgress } =
    useAudioStorage();
  const [uploadComplete, setUploadComplete] = useState(false);
  const fileInputRef = useRef(null);

  useEffect(() => {
    let timeout;
    if (progress === 100) {
      // Show the confirmation when the upload is complete
      setUploadComplete(true);
      // Call the completion callback
      onReplaceComplete && onReplaceComplete();
      // Set a timeout to reset state after 3 seconds
      timeout = setTimeout(() => {
        setUploadComplete(false);
        setProgress(0);
      }, 3000);
    }
    return () => clearTimeout(timeout);
  }, [progress, onReplaceComplete]);

  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      try {
        await uploadAndReplaceAudioForTrack(file, track, album);
      } catch (error) {
        console.error("Error replacing file:", error);
      }
    }
  };

  return (
    <div className="flex flex-col items-center space-y-2  pb-4">
      <input
        ref={fileInputRef}
        type="file"
        accept="audio/*"
        onChange={handleFileChange}
        className="hidden"
      />
      <FontAwesomeIcon
        icon={faFileAudio}
        className="h-6 w-6 text-blue-400 hover:text-blue-500 cursor-pointer"
        onClick={() => fileInputRef.current.click()}
      />

      {/* Progress bar with percentage */}
      {progress > 0 && progress < 100 && (
        <>
          <div className="w-full bg-gray-200 h-1.5">
            <div
              className="bg-blue-600 h-1.5"
              style={{ width: `${progress}%` }}
            ></div>
          </div>
          <div className="text-xs text-gray-600">{`${progress.toFixed(
            0
          )}%`}</div>
        </>
      )}
      {/* Confirmation message */}
      {uploadComplete && (
        <div className="text-xs text-green-500">Upload complete!</div>
      )}
    </div>
  );
};

export default TrackFileEditor;
