import React, { useState } from "react";
import AvatarView from "../AvatarView";

const HostedByView = ({ event }) => {
  const [showAll, setShowAll] = useState(false);
  const admins = event?.admins?.users?.length ? event.admins.users : [event.creator];
  const maxVisibleAdmins = 5;

  if (!admins?.length) {
    return null;
  }

  const handleSeeMoreClick = () => {
    setShowAll(!showAll);
  };

  const displayAdmins = showAll ? admins : admins.slice(0, maxVisibleAdmins);

  return (
    <div className="flex flex-col items-start w-full">
      <div className="text-sm font-sans text-gray-500 dark:text-gray-300">
        {displayAdmins.map((admin, index) => (
          <div key={index} className="flex items-center space-x-2">
            <AvatarView
              username={admin?.username || "Unknown"}
              profilePic={admin?.profilePicUrl}
              showUsername={false}
            />
            <span className="font-mono">@{admin?.username || "Unknown"}</span>
          </div>
        ))}
      </div>
      {admins.length > maxVisibleAdmins && (
        <button
          className="mt-2 px-4 bg-gray-700 text-white text-xs hover:bg-gray-600 rounded"
          onClick={handleSeeMoreClick}
        >
          {showAll ? "See Less" : "See More"}
        </button>
      )}
    </div>
  );
};

export default HostedByView;