// PlayPauseButton.jsx

// PlayPauseButton.js

import React from "react";
import { PauseIcon, PlayIcon } from "@heroicons/react/24/solid";

const PlayPauseButton = ({ isPlaying, onClick }) => {
  return (
    <>
      {isPlaying ? (
        <PauseIcon className="h-10 w-10 text-white" onClick={onClick} />
      ) : (
        <PlayIcon className="h-10 w-10 text-white" onClick={onClick} />
      )}
    </>
  );
};

export default PlayPauseButton;
