// billing.js


// checkout.js


// checkout.js



import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import APIConfig from "../APIConfig";
import { authMethods } from "../firebase/authmethods";
import { firebaseAuth } from "../provider/AuthProvider";

// const token = localStorage.getItem("token");
const dev_stripe_pk = "pk_test_51E1LQfJoqPTP5BBTNvUUzbXU9D2Y52poGmONgDZjAXY6aoba267rqipiHbP2rIWZmr9Qs2eufxrnKKVUVVuQUw7d00yxjSc4vJ"
const live_stripe_pk = "pk_live_51E1LQfJoqPTP5BBT8FmomEx14op9sfSaRWCdHnUa1pln7dvIGUWjnnKoKsUZlwGAvmr6dlxal824GEupMRrAkFla00eW3El7b4"
const stripe_pk = dev_stripe_pk

// const base = APIConfig.baseURL()
// var db = firebase.firestore();

const open_billing = async () => {
    var stripe = window.Stripe(stripe_pk)
    firebase.auth().onAuthStateChanged(function (user) {
        if (user) {
            //("signed in");
            firebase
                .auth()
                .currentUser.getIdToken(/* forceRefresh */ true)
                .then(function (token) {
                    const data = { fs_uid: user.uid };
                    const d = JSON.stringify(data);
                    fetch(`${APIConfig.payments()}billing/`, {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: "JWT " + token,
                        },
                        body: d,
                    }).then(
                        function (res) {
                            res.json().then(function (data) {
                                console.log("session: ", data.sessionURL);
                                window.location.replace(data.sessionURL);
                                // stripe.redirectToCheckout({
                                //     sessionId: data.sessionId,

                                // });
                            });
                            //
                        },
                        function (value) {
                            // not called
                        }
                    );


                });
        }
    });

    // return r;
};



const billing = {
    open_billing
};

export default billing;
