import { useState, useEffect } from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';

const db = firebase.firestore();

const useCollectionData = (collectionName) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const q = firebase.firestore().collection(collectionName).orderBy('timestamp');
    const unsubscribe = q.onSnapshot((querySnapshot) => {
      const items = [];
      querySnapshot.forEach((doc) => {
        items.push({ id: doc.id, ...doc.data() });
      });
      setData(items);
      setLoading(false);
    });

    return () => {
      unsubscribe();
    };
  }, [collectionName]);

  return { data, loading };
};

export default useCollectionData;
