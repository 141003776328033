// FolderNode.js
import React, { useState } from "react";
import { FolderOpenIcon, FolderIcon } from "@heroicons/react/24/outline";

const FolderNode = ({ folder, onFolderClick, children, selected }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpand = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setIsExpanded(!isExpanded);
  };

  return (
    <div>
      {folder && (
        <div
          className={`flex items-center cursor-pointer ${
            selected
              ? "text-white bg-blue-500 dark:bg-blue-700"
              : "text-yellow-500"
          }`}
          onClick={() => {
            console.log("Clicked folder with id:", folder.data.id);
            onFolderClick(folder);
          }}
        >
          {isExpanded ? (
            <FolderOpenIcon className="w-5 h-5" />
          ) : (
            <FolderIcon className="w-5 h-5" />
          )}
          <span className="ml-2">{folder.data.name}</span>
          {folder.children.length > 0 && (
            <button
              className={`ml-2 focus:outline-none ${
                selected ? "text-white" : "text-gray-500"
              }`}
              onClick={toggleExpand}
            >
              [{isExpanded ? "-" : "+"}]
            </button>
          )}
        </div>
      )}
      {isExpanded && <div className="ml-6">{children}</div>}
    </div>
  );
};

export default FolderNode;
