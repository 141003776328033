import React from 'react';

const ReportContent = () => {
  return (
    <div className="legal-section p-6">
      <h2>Report Content</h2>
      <p>If you encounter abusive or unauthorized content, please report it to: <a href="mailto:samir@dotmixtape.com">report@dotmixtape.com</a></p>
    </div>
  );
};

export default ReportContent;