// Menu.jsx
import React, { useState } from "react";
import { Menu as HeadlessMenu, Transition } from "@headlessui/react";
import { EllipsisVerticalIcon } from "@heroicons/react/24/outline";
import DeleteTapeButton from "./DeleteTapeButton";
import ShareButton from "./ShareButton";
import MTRModal from "./MTRModal";

const Menu = ({ tapeId, onDelete, isDeleting }) => {
  const [isShareModalOpen, setIsShareModalOpen] = useState(false);

  const handleShare = () => {
    setIsShareModalOpen(true);
  };

  const closeModal = () => {
    setIsShareModalOpen(false);
  };

  const copyLink = () => {
    navigator.clipboard.writeText(`https://yourapp.com/tapes/${tapeId}`);
    alert("Link copied!");
  };

  return (
    <>
      <HeadlessMenu as="div" className="relative inline-block text-left">
        {({ close }) => (
          <>
            <div>
              <HeadlessMenu.Button
                className={`inline-flex justify-center w-full ${
                  isDeleting ? "cursor-not-allowed" : ""
                }`}
                disabled={isDeleting}
              >
                <EllipsisVerticalIcon className="w-5 h-5 text-gh_black-100 dark:from-gh_charcoal_foreground_primary-100" />
              </HeadlessMenu.Button>
            </div>
            <Transition
              as={React.Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <HeadlessMenu.Items className="absolute right-0 w-56 mt-2 origin-top-right bg-gh_white_secondary-100 text-gh_black-100 divide-y divide-gh_light_outline-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                <div className="py-1 px-2">
                  <HeadlessMenu.Item class="">
                    {({ active }) => (
                      <ShareButton
                        onClick={() => {
                          close();
                          handleShare();
                        }}
                      />
                    )}
                  </HeadlessMenu.Item>
                </div>
                <div className="py-1 px-2 ">
                  <DeleteTapeButton
                    tapeId={tapeId}
                    onDelete={onDelete}
                    isDeleting={isDeleting}
                    onCloseMenu={close}
                  />
                </div>
              </HeadlessMenu.Items>
            </Transition>
          </>
        )}
      </HeadlessMenu>
      <MTRModal isOpen={isShareModalOpen} onClose={closeModal}>
        <h2 className="text-lg mb-4">Share Tape</h2>
        <p>Click the button below to copy the share link:</p>
        <button
          className="mt-2 py-2 px-4 bg-blue-500 text-white rounded-lg focus:outline-none"
          onClick={copyLink}
        >
          Copy link
        </button>
      </MTRModal>
    </>
  );
};

export default Menu;
