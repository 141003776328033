import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { BsHouseFill } from "react-icons/bs";
import MenuButton from "../../buttons/MenuButton";
import styled from "styled-components";
import StandardButton from "../../buttons/StandardButton";

const Container = styled.div`
  text-align: center;
  /* display: flex; */
  .container {
    display: flex; /* or inline-flex */
    flex-basis: auto; /* default auto */
  }
  ul {
    display: flex;

    /* align-items: stretch; */
    /* Default */
    /* justify-content: space-between; */
    /* width: 100%; */
    /* background: #cacaca; */
    /* border: 2px solid green; */

    margin: 0;
    padding: 0;
  }
  li {
    display: block;
    flex: 0 1 auto; /* Default */
    list-style-type: none;
    /* background: #fafafa; */
    padding: 20;
  }
  .item {
    order: 5; /* default is 0 */
  }
  .message {
    padding: 20px;
    color: blue;
  }
`;
const StripeConnectRedirect = () => {
  return (
    <Container>
      <br></br>
      <ul>
        <div className="message">success, your order has been processed!</div>
        {/* <StandardButton mxt_green> create a subscription</StandardButton> */}
      </ul>
    </Container>
  );
};

export default StripeConnectRedirect;
