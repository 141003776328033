import React, { useContext } from "react";
import { firebaseAuth } from "../../provider/AuthProvider";
import useUserProfile from "../../hooks/useUserProfile";
import WideButton from "../buttons/WideButton";
import ContactEmail from "./ContactEmail";
import StorageUsage from "../file_mgmt/drive/hooks/StorageUsage";
import APIConfig from "../../APIConfig";
import ProfileView from "../events/profiles/ProfileView";
import UserInfoDisplay from "./UserInfoDisplay";
import LinkedArtistsView from "../artist_dashboard/dashboard/album_creator/LinkedArtistsView";

function Settings() {
  const { handleSignout } = useContext(firebaseAuth);
  const {
    artistName,
    isArtist,
    hasStudio,
    artistLink,
    stripeDashLink,
    isSeller,
  } = useUserProfile();

  const displayStorage = () => {
    if (APIConfig.airtrak() === true || APIConfig.studio() === true) {
      return (
        <div className=" dark:border-white bg-gray-100 dark:bg-gray-900">
          <ProfileView />
          <StorageUsage />
        </div>
      );
    }
  };

  return (
    <div className="max-w-4xl mx-auto p-4 ">
      <h1 className="text-3xl font-bold text-center uppercase tracking-wide  border-black dark:border-white pb-4">
        Settings
      </h1>
      <div className="border-2 border-black dark:border-white p-4 bg-gray-100 dark:bg-gray-900">
        <LinkedArtistsView />
      </div>
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 py-4">
        <div className="p-4 border-2 border-black dark:border-white bg-gray-100 dark:bg-gray-900">
          <UserInfoDisplay
            user={{
              artistName,
              isArtist,
              hasStudio,
              isSeller,
              artistLink,
              stripeDashLink,
            }}
          />
        </div>
        {displayStorage()}
      </div>
      <div className="text-center space-y-4">
        <WideButton text={"Sign Out"} lambda={handleSignout} />
        <ContactEmail email="samir@dotmixtape.com" />
      </div>
    </div>
  );
}

export default Settings;
