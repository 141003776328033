import { useState, useEffect } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import firebase from "firebase/compat/app";
import { getAuth } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import APIConfig from "../../APIConfig"; // Ensure correct path

const auth = getAuth(firebase.app());

const fetchEvent = async (id, token) => {
  const body = { id: id };
  const url = APIConfig.event();
  const response = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "JWT " + token,
    },
    body: JSON.stringify(body),
  });
  return response.json();
};

const fetchEventNoAuth = async (id) => {
  const body = { id: id };
  const url = APIConfig.event_no_auth();
  const response = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  });
  return response.json();
};

const parseEventJson = (data) => {
  const event = {
    id: data.id,
    name: data.name,
    description: data.description,
    date: data.date,
    time: data.time,
    attendee_count: Object.keys(data.attending).length,
    invited_count: Object.keys(data.invitees).length,
    creator_username: data.creator.username,
    creator: data.creator,
    invitees: data.invitees,
    attending: data.attending,
    admins: data.admins || [],
    venue: data.venue,
    no_name: data.no_name, // Add no_name field here
  };
  return event;
};

export const useEvent = (event_id) => {
  const [user, loading, error] = useAuthState(auth);
  const [event, setEventData] = useState({});
  const [signedIn, setSignedIn] = useState(false);
  const [token, setToken] = useState(null);
  const [attendeeCount, setAttendeeCount] = useState(0);
  const [loadingState, setLoadingState] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchEventData = async (user) => {
      setLoadingState(true);
      if (user) {
        const idToken = await user.getIdToken(true);
        setToken(idToken);

        const eventData = await fetchEvent(event_id, idToken);
        if (eventData.no_name) {
          setEventData(eventData);
          setLoadingState(false);
          return;
        }

        setEventData(parseEventJson(eventData));
        setAttendeeCount(eventData.attendee_count);
        setSignedIn(true);
      } else {
        const eventData = await fetchEventNoAuth(event_id);
        setEventData(parseEventJson(eventData));
        setAttendeeCount(eventData.attendee_count);
        setSignedIn(false);
      }
      setLoadingState(false);
    };

    if (user !== undefined) {
      fetchEventData(user);
    }
  }, [user, event_id, navigate]);

  return {
    loading: loadingState,
    event,
    signedIn,
    user,
    token,
    attendeeCount,
  };
};