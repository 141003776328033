import React, { useEffect, useState } from "react";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import { useAuthState } from "react-firebase-hooks/auth";
import { useParams } from "react-router-dom";
import TapePreview from "./TapePreview";
import FileSystemContainer from "../../themes/FileSystemContainer";
import MultiTrackTapeView from "./MultitrackTapeView";
import tape_service from "../../../services/tape_service";
import AirtrakSplash from "./AirtrakSplash";
import DriveViewPanel from "./DriveViewPanel";

function MTRDriveView() {
  const [loading, setLoading] = useState(true);
  const [tapes, setTapes] = useState([]);
  const [deletingTapeId, setDeletingTapeId] = useState(null);
  const auth = firebase.auth();
  const [user, loadingAuth] = useAuthState(auth);
  const { id: tapeId } = useParams();

  const handleDeleteTape = (deletedTapeId) => {
    setDeletingTapeId(deletedTapeId);
    setTapes((prevTapes) =>
      prevTapes.filter((tape) => tape.id !== deletedTapeId)
    );
  };

  useEffect(() => {
    if (user) {
      const unsubscribe = tape_service.fetchTapes(
        user.uid,
        setTapes,
        setLoading
      );
      return () => {
        // Cleanup
        if (unsubscribe) {
          unsubscribe();
        }
      };
    } else {
      setLoading(false); // Add this line to update the loading state when the user signs out
    }
  }, [user, tapeId]);

  if (loadingAuth || loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="w-16 h-16 border-t-4 border-blue-500 rounded-full animate-spin"></div>
      </div>
    );
  }

  if (!user) {
    return <AirtrakSplash />;
  }

  if (tapeId) {
    const tape = tapes.find((t) => t.id === tapeId);
    if (tape) {
      return <MultiTrackTapeView tape={tape} />;
    } else {
      return <p>Tape not found.</p>;
    }
  }

  return (
    <DriveViewPanel className="flex-grow h-auto pb-96">
      <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5">
        {tapes.length > 0 ? (
          tapes.map((tape) => (
            <div key={tape.id} className="">
              <TapePreview tape={tape} onDelete={handleDeleteTape} />
            </div>
          ))
        ) : (
          <div className="col-span-full text-center py-8">
            <p>No tapes found. Upload your first tape to get started!</p>
          </div>
        )}
      </div>
    </DriveViewPanel>
  );
}

export default MTRDriveView;
