// useSearchArtists.jsx
import { useState, useEffect } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import firebase from 'firebase/compat/app';
import APIConfig from '../APIConfig';

export const useSearchArtists = (searchTerm) => {
  const [artists, setArtists] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState(searchTerm);
  const [user, userLoading, userError] = useAuthState(firebase.auth());

  // Debounce search term
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm);
    }, 500); // Adjust debounce time as needed

    return () => clearTimeout(handler);
  }, [searchTerm]);

  // Fetch artists based on debounced search term
  useEffect(() => {
    const fetchData = async () => {
      if (!debouncedSearchTerm || userLoading || userError || !user) {
        setArtists([]);
        return;
      }

      setLoading(true);
      const url = APIConfig.artists() + `?search=${encodeURIComponent(debouncedSearchTerm)}`;

      try {
        const idToken = await user.getIdToken(true);
        const response = await fetch(url, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `JWT ${idToken}`,
          },
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const responseData = await response.json();
        setArtists(responseData.results || []);
        setError(null);
      } catch (e) {
        setError(e);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [debouncedSearchTerm, user, userLoading, userError]);

  return { artists, loading, error };
};
