import React from 'react';

const Checkbox = ({ id, name, checked, onChange, label }) => {
  return (
    <label className="flex items-center space-x-2">
      <input
        type="checkbox"
        id={id}
        name={name}
        checked={checked}
        onChange={onChange}
        className="h-4 w-4"
      />
      <span>{label}</span>
    </label>
  );
};

export default Checkbox;