import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ChevronLeftIcon, ChevronRightIcon, ArrowLeftIcon } from '@heroicons/react/24/solid';

const PhotoNavigation = ({ currentIndex, totalCount, onNavigate, eventId }) => {
  const history = useNavigate();

  const goBackToEvent = () => {
    if (eventId) {
      history.push(`/e/${eventId}`);
    }
  };

  return (
    <div className="fixed inset-x-0 bottom-0 z-10 flex justify-between items-center p-4 bg-black bg-opacity-50">
      {eventId && (
        <button 
          onClick={goBackToEvent} 
          className="flex items-center p-2 text-white bg-black bg-opacity-50 rounded-md hover:bg-opacity-70 transition-opacity"
        >
          <ArrowLeftIcon className="h-5 w-5 mr-1" />
          Back to Event
        </button>
      )}
      <button
        onClick={() => onNavigate(currentIndex - 1)}
        disabled={currentIndex <= 0}
        aria-label="Previous Photo"
        className="disabled:opacity-50"
      >
        <ChevronLeftIcon className="h-6 w-6 text-white md:h-8 md:w-8" />
      </button>
      <span className="text-white text-xs md:text-sm">
        {currentIndex + 1} / {totalCount}
      </span>
      <button
        onClick={() => onNavigate(currentIndex + 1)}
        disabled={currentIndex >= totalCount - 1}
        aria-label="Next Photo"
        className="disabled:opacity-50"
      >
        <ChevronRightIcon className="h-6 w-6 text-white md:h-8 md:w-8" />
      </button>
    </div>
  );
};

export default PhotoNavigation;
