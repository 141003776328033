// storage_service.js

// import firebase from 'firebase/compat/app';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth'; 

const storage = firebase.storage();


export const getStorageRef = (uid, filePath) => {
  return storage.ref().child(`studio/user_storage/${uid}/drive/${filePath}`);
};


export const getProfilePictureStorageRef = (uid) => {
  return storage.ref().child(`user_data/${uid}/images/profile_pictures`);
};
