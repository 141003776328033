// UploadContextProvider.js
import React, { useState } from "react";
import UploadContext from "./UploadContext";
import { useUploadFile } from "../hooks/useStorageHooks";

const UploadContextProvider = ({ children }) => {
  const [items, setItems] = useState([]);
  const [currentPath, setCurrentPath] = useState("");
  const [uploadProgress, setUploadProgress] = useState(0); // Add this line
  const [uploadStatus, setUploadStatus] = useState(""); // Add this line

  const [
    uploadFile,
    uploading,
    cancelUpload,
    cancelAllUploads,
  ] = useUploadFile(items, setItems, currentPath);

  const sharedUploadState = {
    items,
    setItems,
    currentPath,
    setCurrentPath,
    uploadFile,
    uploading,
    uploadProgress, // Add this line
    setUploadProgress, // Add this line
    uploadStatus, // Add this line
    setUploadStatus, // Add this line
    cancelUpload,
    cancelAllUploads,
  };

  return (
    <UploadContext.Provider value={sharedUploadState}>
      {children}
    </UploadContext.Provider>
  );
};

export default UploadContextProvider;
