// Konsole.js
import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import WideButton from "../buttons/WideButton";
import DraftListView from "./DraftListView";
import LoadingSkeleton from "./LoadingSkeleton";
import LinkedArtistsView from "./dashboard/album_creator/LinkedArtistsView";
import storage from "../../services/storage";
import profile_service from "../../services/profile_service";
import useAuth from "../../hooks/useAuth"; // Updated import for useAuth
import useRealTimeDrafts from "./drafting/hooks/useRealTimeDrafts";

const Konsole = () => {
  const { user, loading: authLoading, isPrivate } = useAuth();
  const { drafts, loading, error } = useRealTimeDrafts(user);
  const navigate = useNavigate();

  const [artistName, setArtistName] = useState("");
  const [username, setUsername] = useState("");
  const [isEditMode, setIsEditMode] = useState(false);

  useEffect(() => {
    const fetchProfileInfo = async () => {
      if (user) {
        try {
          const idToken = await user.getIdToken(true);
          const response = await profile_service.getProfileInfo(idToken);

          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }

          const data = await response.json();
          if (
            data.artists &&
            Array.isArray(data.artists) &&
            data.artists.length > 0
          ) {
            setArtistName(data.artists[data.artists.length - 1].name);
            setUsername(data.username || "user");
          } else {
            setArtistName("no artist");
            setUsername("user");
          }
        } catch (err) {
          console.error("Error fetching profile info:", err);
        }
      }
    };

    fetchProfileInfo();
  }, [user]);

  const handleNewAlbum = useCallback(
    async (albumName) => {
      if (!user) {
        console.error("User is not authenticated. Cannot create new album.");
        return;
      }

      try {
        const album = await storage.initializeAlbum(albumName, user.uid); // Pass uid directly
        navigate("/create/album", { state: { album } });
      } catch (err) {
        console.error("Error creating new album:", err);
      }
    },
    [navigate, user]
  );

  const renderWelcomeMessage = useCallback(() => {
    if (!username) return null;

    return (
      <div className="text-green-400 font-mono text-2xl border-black dark:border-white mb-6 pb-2">
        Welcome, @{username}
      </div>
    );
  }, [username]);

  const renderNewAlbumButton = useCallback(
    () => (
      <WideButton
        text="Album +"
        lambda={() => handleNewAlbum(`Untitled ${drafts.length}`)}
      />
    ),
    [handleNewAlbum, drafts.length]
  );

  const renderDraftingPanel = useCallback(
    () => (
      <div className="p-4 dark:border-white rounded-none">
        <div className="flex items-center justify-between mb-6">
          <h2 className="text-xl font-bold text-black dark:text-white font-mono">
            Drafts
          </h2>
          <button
            onClick={() => setIsEditMode(!isEditMode)}
            className="px-4 py-2 font-bold text-black dark:text-white bg-gray-200 dark:bg-black border-2 border-black dark:border-white transition-colors duration-200 hover:bg-black hover:text-white dark:hover:bg-white dark:hover:text-black"
          >
            {isEditMode ? "Done" : "Edit"}
          </button>
        </div>
        {renderNewAlbumButton()}
        <DraftListView drafts={drafts} isEditMode={isEditMode} />
      </div>
    ),
    [drafts, renderNewAlbumButton, isEditMode]
  );

  const renderContent = () => {
    if (authLoading || loading) {
      return <LoadingSkeleton />;
    }

    if (error) {
      return <div className="text-red-500">Error: {error.message}</div>;
    }

    if (artistName === "no artist") {
      return (
        <div className="text-yellow-500 border-b-2 border-black dark:border-white pb-2 mb-6">
          No artist linked. Please go to settings to register an artist.
        </div>
      );
    }

    return null;
  };

  useEffect(() => {
    if (!user) {
      navigate("/home");
    }
  }, [user, navigate]);

  return (
    <div className="p-8 bg-gray-100 dark:bg-black border-t-2 border-black dark:border-white font-mono">
      {isPrivate && (
        <div className="text-red-500 mb-4">
          Warning: You are in private mode. Some features may be limited.
        </div>
      )}
      {renderContent()}
      {renderDraftingPanel()}
    </div>
  );
};

export default Konsole;