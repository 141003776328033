// EditedAudioUploadStatusView.jsx

// EditedAudioUploadStatusView.js
import React, { useContext } from "react";
import UploadContext from "../contexts/UploadContext";

const EditedAudioUploadStatusView = () => {
  const { uploadProgress, uploadStatus } = useContext(UploadContext);

  return (
    <div className="upload-progress-view">
      {uploadStatus === "uploading" ? (
        <div>
          <p>saving...</p>
          <progress value={uploadProgress} max="100"></progress>
          <p>{uploadProgress.toFixed(1)}%</p>
        </div>
      ) : (
        uploadStatus === "done" && <p>Upload completed!</p>
      )}
    </div>
  );
};

export default EditedAudioUploadStatusView;
