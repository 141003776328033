import { useState, useEffect, useCallback } from "react";
import drive_service from "../../../../services/drive_service";
import { useAuthState } from "react-firebase-hooks/auth";
import "firebase/compat/firestore";
import firebase from "firebase/compat/app";
import TreeNode from "../TreeNode";
import { useNavigate, useLocation } from "react-router-dom";
const auth = firebase.auth();

export const useDriveItems = (loading, currentPath) => {
  const [items, setItems] = useState([]);
  const [user] = useAuthState(auth);

  useEffect(() => {
    if (!user || loading) return;

    const fetchItems = () => {
      const driveRef = drive_service.getDriveRef(user.uid);
      console.log("currentPath: ", currentPath);
      const currentPathRef = driveRef.where("path", "==", currentPath);
      console.log("Fetching items for path:", currentPath);
      const unsubscribe = currentPathRef.onSnapshot((querySnapshot) => {
        const fetchedItems = [];
        querySnapshot.forEach((doc) => {
          fetchedItems.push({ ...doc.data(), id: doc.id });
        });
        console.log("Fetched items:", fetchedItems);
        setItems(fetchedItems);
      });

      return () => unsubscribe();
    };

    return fetchItems();
  }, [user, loading, currentPath]);

  return [items, setItems];
};

export const useCreateFolder = (items, setItems, currentPath) => {
  console.log("useCreateFolder"); // Add this line
  const [user] = useAuthState(auth); // Add this line

  return async () => {
    if (!user) return;

    const folderName = `New Folder ${Date.now()}`;
    const newItem = {
      name: folderName,
      type: "folder",
      userId: user.uid,
      path: currentPath,
      isRoot: currentPath === "",
    };

    const driveRef = drive_service.getDriveRef(user.uid);
    const docRef = await driveRef.add(newItem);

    setItems([...items, { ...newItem, id: docRef.id }]);
  };
};

export const useUpdateFolderName = (items, setItems) => {
  const [user] = useAuthState(auth); // Add this line

  return async (id, newName) => {
    if (!user) return;

    const itemToUpdate = items.find((item) => item.id === id);
    const oldPath = `${itemToUpdate.path}/${itemToUpdate.name}`;
    const newPath = `${itemToUpdate.path}/${newName}`;

    const updatedItems = items.map((item) => {
      if (item.id === id) {
        return { ...item, name: newName };
      }
      return item;
    });

    setItems(updatedItems);

    const driveRef = drive_service.getDriveRef(user.uid);
    await driveRef.doc(id).update({ name: newName });

    const updateChildItemsPath = async (oldParentPath, newParentPath) => {
      const childItemsRef = driveRef.where("path", "==", oldParentPath);
      const childItemsSnapshot = await childItemsRef.get();
      childItemsSnapshot.forEach(async (doc) => {
        const childItem = doc.data();
        const childItemNewPath = childItem.path.replace(
          oldParentPath,
          newParentPath
        );
        await driveRef.doc(doc.id).update({ path: childItemNewPath });

        if (childItem.type === "folder") {
          await updateChildItemsPath(
            `${oldParentPath}/${childItem.name}`,
            `${newParentPath}/${childItem.name}`
          );
        }
      });
    };

    await updateChildItemsPath(oldPath, newPath);
  };
};

export const useOpenItem = (setCurrentPath) => {
  const navigate = useNavigate();
  const location = useLocation(); // Use useLocation to get the current location

  return (item) => {
    if (item.type === "folder") {
      const newPath = `${item.path}/${item.name}`;
      setCurrentPath(newPath);
      navigate(`${location.pathname}?path=${encodeURIComponent(newPath)}`); // Use location.pathname
    } else {
      alert(`Opening file: ${item.name}`);
    }
  };
};

export const useGoBack = (setCurrentPath, currentPath) => {
  const navigate = useNavigate();
  const location = useLocation(); // Use useLocation to get the current location

  return () => {
    if (!currentPath) return;

    const newPathArray = currentPath.split("/");
    newPathArray.pop();
    const newPath = newPathArray.join("/");

    setCurrentPath(newPath);
    navigate(`${location.pathname}?path=${encodeURIComponent(newPath)}`); // Use location.pathname
  };
};
export const useDeleteFolder = (items, setItems) => {
  const [user] = useAuthState(auth);

  const deleteFolder = useCallback(
    async (folder) => {
      if (!user || !items) return;
      console.log("Deleting folder:", folder);
      console.log("Items:", items);

      const deleteSubitems = async (driveRef, parentPath) => {
        const subitemsRef = driveRef.where("path", "==", parentPath);
        const subitemsSnapshot = await subitemsRef.get();

        const subitemsDeletePromises = [];
        subitemsSnapshot.forEach(async (doc) => {
          const subitem = doc.data();
          if (subitem.type === "folder") {
            await deleteSubitems(driveRef, `${parentPath}/${subitem.name}`);
          }
          subitemsDeletePromises.push(doc.ref.delete());
        });

        await Promise.all(subitemsDeletePromises);
      };

      const driveRef = drive_service.getDriveRef(user.uid);
      console.log("folderId: ", folder);
      console.log("items: ", items);
      const folderToDelete = items.find((item) => item.id === folder.id);
      console.log("Folder to delete:", folderToDelete);
      await deleteSubitems(
        driveRef,
        `${folderToDelete.path}/${folderToDelete.name}`
      );

      const folderRef = driveRef.doc(folder.id);
      await folderRef.delete();

      setItems(items.filter((item) => item.id !== folder.id));
    },
    [user, items, setItems]
  );

  return deleteFolder;
};

// Add this import at the top
async function updateSubitemsPaths(driveRef, sourcePath, destinationPath) {
  const subitems = await driveRef
    .where("path", ">=", sourcePath)
    .where("path", "<", sourcePath + "\uf8ff")
    .get();

  subitems.forEach(async (doc) => {
    const newPath = doc.data().path.replace(sourcePath, destinationPath);
    await driveRef.doc(doc.id).update({ path: newPath });
  });
}

export const useMoveItem = (items, setItems) => {
  const [user] = useAuthState(auth);

  return async (item, sourcePath, destinationPath) => {
    if (!user || !items) return;

    console.log("Moving item:", item);
    console.log("Source path:", sourcePath);
    console.log("Destination path:", destinationPath);

    const driveRef = drive_service.getDriveRef(user.uid);
    const itemRef = driveRef.doc(item.id);

    if (destinationPath === "/") {
      destinationPath = "";
    }
    await itemRef.update({ path: destinationPath });

    await updateSubitemsPaths(
      driveRef,
      sourcePath + "/" + item.name,
      destinationPath + "/" + item.name
    );

    setItems(
      items.map((i) => (i.id === item.id ? { ...i, path: destinationPath } : i))
    );
  };
};
export const useFolderStructure = () => {
  const [folderStructure, setFolderStructure] = useState([]);
  const [user] = useAuthState(auth);

  useEffect(() => {
    if (!user) return;

    const driveRef = drive_service.getDriveRef(user.uid);
    const unsub = driveRef
      .where("type", "==", "folder")
      .onSnapshot((snapshot) => {
        const folders = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        const rootNode = new TreeNode({ name: "Root", path: "", id: "root" });

        const buildFolderStructure = (node, currentPath) => {
          const matchingFolders = folders.filter(
            (folder) => folder.path === currentPath
          );
          matchingFolders.forEach((folder) => {
            console.log("Folder before creating TreeNode:", folder);
            // Pass the entire folder object as the data property
            const childNode = new TreeNode(folder);
            node.addChild(childNode);
            buildFolderStructure(childNode, `${currentPath}/${folder.name}`);
          });
        };

        buildFolderStructure(rootNode, "");
        setFolderStructure(rootNode.children);
      });

    return () => unsub();
  }, [user]);

  return folderStructure;
};

export const useDeleteAll = (items, setItems, currentPath) => {
  const [user] = useAuthState(auth);

  const deleteAll = useCallback(async () => {
    if (!user) return;

    const driveRef = drive_service.getDriveRef(user.uid);

    const deletePromises = items.map((item) => {
      return driveRef.doc(item.id).delete();
    });

    await Promise.all(deletePromises);

    setItems([]);
  }, [user, items, setItems]);

  return deleteAll;
};
export const useFetchPublicSharedFolders = (
  setFolderInfo,
  setPublicSharedItems,
  publicLinkId = null,
  currentPath,
  setLoading,
  setFileNotFound // Add this
) => {
  useEffect(() => {
    if (!publicLinkId) {
      console.log("No publicLinkId, exiting useEffect.");
      return;
    }

    let unsubscribe;

    const fetchPublicSharedFolders = async () => {
      console.log("Using publicLinkId:", publicLinkId);

      const sharedFolderRef = await fetchSharedFolderByPublicLinkId(
        publicLinkId,
        "",
        setPublicSharedItems,
        setFileNotFound // Add this
      );

      if (sharedFolderRef) {
        unsubscribe = sharedFolderRef.onSnapshot((snapshot) => {
          const item = { id: snapshot.id, ...snapshot.data() };
          setFolderInfo(item);
          setLoading(false);
        });
      }
    };

    fetchPublicSharedFolders();

    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [
    setPublicSharedItems,
    publicLinkId,
    setFolderInfo,
    setLoading,
    setFileNotFound,
  ]); // Add setFileNotFound here
};

async function fetchSharedFolderByPublicLinkId(
  publicLinkId,
  currentPath,
  setItems,
  setFileNotFound // Add this
) {
  const publicLinksRef = firebase
    .firestore()
    .collection("public_links")
    .doc(publicLinkId);

  const publicLinkSnapshot = await publicLinksRef.get();

  if (!publicLinkSnapshot.exists) {
    console.log("Public link not found");
    setFileNotFound(true); // Set fileNotFound state to true
    return null;
  }

  const publicLinkData = publicLinkSnapshot.data();
  const { userId, folderId } = publicLinkData;

  console.log("userId: ", userId, "folderId: ", folderId);
  const folderRef = await getSharedFolderRef(publicLinkId);
  console.log("folder ref: ", folderRef);
  // get the document for folderref
  const folderRefSnapshot = await folderRef.get();
  // then get the name
  const folderName = folderRefSnapshot.data().name;
  const sharedFolderPath = currentPath + "/" + folderName;
  console.log("sharedFolderPath: ", sharedFolderPath);

  if (folderRef) {
    const driveRefCollection = drive_service.getDriveRef(userId);
    console.log("driveRef: ", driveRefCollection.path);
    const folderItems = driveRefCollection.where(
      "path",
      "==",
      sharedFolderPath
    );
    console.log("folderItems: ", folderItems);

    folderItems.onSnapshot((querySnapshot) => {
      const items = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      console.log("items: ", items);
      setItems((prevItems) => [...prevItems, ...items]);
    });
  }

  return folderRef;
}
// Now, when the SharedFolderViewer component renders, it will directly display the contents of the shared folder without requiring an additional click. The publicSharedItems state will be updated with both the folder and the items inside it.

// Helper function to get shared folder reference
const getSharedFolderRef = async (publicLinkId) => {
  const publicLinksRef = firebase.firestore().collection("public_links");
  const publicLinkDoc = await publicLinksRef.doc(publicLinkId).get();

  if (publicLinkDoc.exists) {
    const { userId, folderId } = publicLinkDoc.data();
    const driveRef = drive_service.getDriveRef(userId);
    const folderRef = driveRef.doc(folderId);
    console.log("folderRef: ", folderRef.path);

    return folderRef;
  } else {
    console.log("No such publicLinkId found");
    return null;
  }
};

// Function to display folder contents
const displayFolderContents = (folderRef) => {
  folderRef.collection("items").onSnapshot((querySnapshot) => {
    querySnapshot.forEach((doc) => {
      console.log(doc.id, " => ", doc.data());
    });
  });
};
