// checkout.js

import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import APIConfig from "../APIConfig";
import { authMethods } from "../firebase/authmethods";
import { firebaseAuth } from "../provider/AuthProvider";

// import { authMethods } from "./firebase/authmethods";
// import { firebaseAuth } from "./provider/AuthProvider";
// import { authMethods } from "../../firebase/authmethods";
const token = localStorage.getItem("token");

// const base = APIConfig.baseURL()
var db = firebase.firestore();
const dev_stripe_pk =
  "pk_test_51E1LQfJoqPTP5BBTNvUUzbXU9D2Y52poGmONgDZjAXY6aoba267rqipiHbP2rIWZmr9Qs2eufxrnKKVUVVuQUw7d00yxjSc4vJ";
const prod_stripe_pk =
  "pk_live_51E1LQfJoqPTP5BBT8FmomEx14op9sfSaRWCdHnUa1pln7dvIGUWjnnKoKsUZlwGAvmr6dlxal824GEupMRrAkFla00eW3El7b4";
//  "pk_live_51E1LQfJoqPTP5BBT8FmomEx14op9sfSaRWCdHnUa1pln7dvIGUWjnnKoKsUZlwGAvmr6dlxal824GEupMRrAkFla00eW3El7b4"

const createPaymentIntent = (options) => {
  // console.log("creating payment intent  ")
  // return window
  //   .fetch(`${APIConfig.payments()}create-payment-intent/`, {
  //     method: "POST",
  //     headers: {
  //       "Content-Type": "application/json",
  //       Authorization: "JWT " + token,
  //     },
  //     body: JSON.stringify(options),
  //   })
  //   .then((res) => {
  //     if (res.status === 200) {
  //       return res.json();
  //     } else {
  //       return null;
  //     }
  //   })
  //   .then((data) => {
  //     if (!data || data.error) {
  //       throw new Error("PaymentIntent API Error");
  //     } else {
  //       return data.client_secret;
  //     }
  //   });
};

const getProductDetails = (options) => {
  return window
    .fetch(`${APIConfig.payments()}product-details`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "JWT " + token,
      },
    })
    .then((res) => {
      if (res.status === 200) {
        return res.json();
      } else {
        return null;
      }
    })
    .then((data) => {
      if (!data || data.error) {
        throw Error("API Error");
      } else {
        return data;
      }
    });
};

const getPublicStripeKey = (options) => {
  return window
    .fetch(`${APIConfig.payments()}public-key`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "JWT " + token,
      },
    })
    .then((res) => {
      if (res.status === 200) {
        return res.json();
      } else {
        return null;
      }
    })
    .then((data) => {
      if (!data || data.error) {
        throw Error("API Error");
      } else {
        return data.publicKey;
      }
    });
};

const me = () => {
  fetch(`${APIConfig.profiles()}me/`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "JWT " + token,
    },
  });
};

const buy = async (merch_item_id, quantity, user_id) => {
  const publicKey =
    process.env.NODE_ENV === "production"
      ? process.env.STRIPE_LIVE_KEY
      : process.env.STRIPE_TEST_KEY;
  var stripe = window.Stripe(publicKey);
  firebase.auth().onAuthStateChanged(function (user) {
    if (user) {
      //("signed in");
      firebase
        .auth()
        .currentUser.getIdToken(/* forceRefresh */ true)
        .then(function (token) {
          const data = {
            merch_item_id: merch_item_id,
            quantity: quantity,
            user_id,
          };
          console.log("checkout data w user: ", data);
          const d = JSON.stringify(data);
          fetch(`${APIConfig.payments()}create-checkout-session/`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: "JWT " + token,
            },
            body: d,
          }).then(
            function (res) {
              res.json().then(function (data) {
                stripe.redirectToCheckout({
                  sessionId: data.sessionId,
                });
              });
              //
            },
            function (value) {
              // not called
            }
          );
        });
    }
  });

  // return r;
};

const buyNoAuth = async (merch_item_id, metadata) => {
  // var key = APIConfig.stripePublicKey();
  // var stripe_key = 'pk_live_51E1LQfJoqPTP5BBT8FmomEx14op9sfSaRWCdHnUa1pln7dvIGUWjnnKoKsUZlwGAvmr6dlxal824GEupMRrAkFla00eW3El7b4'
  // the actual

  // get the dev stripe key if env variable is not production
  var stripe_key =
    process.env.NODE_ENV === "production"
      ? process.env.STRIPE_LIVE_KEY
      : process.env.STRIPE_TEST_KEY;

  const stripe = window.Stripe(stripe_key);
  // var stripe = window.Stripe("pk_live_51E1LQfJoqPTP5BBT8FmomEx14op9sfSaRWCdHnUa1pln7dvIGUWjnnKoKsUZlwGAvmr6dlxal824GEupMRrAkFla00eW3El7b4");
  // var stripe = APIConfig.stripeAccountId();
  console.log("metadata: ", metadata);
  const data = {
    merch_item_id: merch_item_id,
    quantity: metadata.quantity,
    option: metadata.option,
    artist: metadata.artist,
  };
  console.log("checkout data: ", data);
  const d = JSON.stringify(data);
  // console.log(`${APIConfig.store()}checkout/`)
  fetch(`${APIConfig.payments()}create-checkout-session-no-user/`, {
    // fetch(`${APIConfig.store()}checkout/`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      // Authorization: "JWT " + token,
    },
    body: d,
  }).then(
    function (res) {
      res.json().then(function (data) {
        stripe.redirectToCheckout({
          sessionId: data.sessionId,
        });
      });
      //
    },
    function (value) {
      // not called
    }
  );

  // return r;
};

const get_studio_plus = async () => {
  // var stripe = window.Stripe(APIConfig.stripePublicKey());
  var key = APIConfig.stripePublicKey();
  // var key = APIConfig.getPublicStripeKey()
  var stripe =
    process.env.NODE_ENV === "production"
      ? process.env.STRIPE_LIVE_KEY
      : process.env.STRIPE_TEST_KEY;
  firebase.auth().onAuthStateChanged(function (user) {
    if (user) {
      //("signed in");
      firebase
        .auth()
        .currentUser.getIdToken(/* forceRefresh */ true)
        .then(function (token) {
          const data = { email: user.email };
          const d = JSON.stringify(data);
          fetch(`${APIConfig.payments()}get-studio-plus/`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: "JWT " + token,
            },
            body: d,
          }).then(
            function (res) {
              res.json().then(function (data) {
                stripe.redirectToCheckout({
                  sessionId: data.sessionId,
                });
              });
              //
            },
            function (value) {
              // not called
            }
          );
        });
    }
  });

  // return r;
};

const account_mgmt = async () => {
  // var stripe = window.Stripe(APIConfig.stripePublicKey());
  var key = APIConfig.stripePublicKey();
  // var key = APIConfig.getPublicStripeKey()
  var stripe =
    process.env.NODE_ENV === "production"
      ? process.env.STRIPE_LIVE_KEY
      : process.env.STRIPE_TEST_KEY;
  firebase.auth().onAuthStateChanged(function (user) {
    if (user) {
      //("signed in");
      firebase
        .auth()
        .currentUser.getIdToken(/* forceRefresh */ true)
        .then(function (token) {
          const data = { email: user.email };
          const d = JSON.stringify(data);
          fetch(`${APIConfig.payments()}get-studio-plus/`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: "JWT " + token,
            },
            body: d,
          }).then(
            function (res) {
              res.json().then(function (data) {
                stripe.redirectToCheckout({
                  sessionId: data.sessionId,
                });
              });
              //
            },
            function (value) {
              // not called
            }
          );
        });
    }
  });

  // return r;
};

const checkout = {
  account_mgmt,
  createPaymentIntent,
  get_studio_plus,
  me,
  buy,
  buyNoAuth,
  getPublicStripeKey: getPublicStripeKey,
  getProductDetails: getProductDetails,
};

export default checkout;
