import React from 'react';

const DMCA = () => {
  return (
    <div className="legal-section p-6">
      <h2>DMCA Notice</h2>
      <p>If you believe content on .mixtape infringes your copyright, you may submit a DMCA Takedown Notice to our designated agent:</p>
      <p>Email: samir@dotmixtape.com</p>
      <p>Mailing Address: .mixtape, DMCA Agent, Tuareg LLC, 159 S 2nd st, Brooklyn, NY USA</p>
      <p>Please include a detailed description of the copyrighted work and the specific material you claim is infringing, per DMCA guidelines.</p>
    </div>
  );
};

export default DMCA;