import React, { useState, useEffect } from "react";
import firebase from "firebase/compat/app";
import { useAuthState } from "react-firebase-hooks/auth";
import { useForm } from "react-hook-form";
import { useNavigate, useLocation } from "react-router-dom";
import auth from "../../services/auth";
import event_service from "../../services/events";
import LightSmallSpinner from "../loading/LightSmallSpinner";

const NameEntryForm = () => {
  const [user, loading, error] = useAuthState(firebase.auth());
  const [token, setToken] = useState("");
  const [loadingState, setLoadingState] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const event = location.state?.eventData;

  useEffect(() => {
    const checkAuthState = async () => {
      const currentUser = firebase.auth().currentUser;
      if (currentUser) {
        const idToken =
          location.state?.token || (await currentUser.getIdToken(true));
        localStorage.setItem("token", idToken);
        setToken(idToken);
      }
    };
    checkAuthState();
  }, [user, location.state]);

  const updateName = async (firstName, lastName) => {
    setLoadingState(true);
    const response = await auth.updateName(firstName, lastName, token);
    if (response) {
      if (event) {
        await toggleRSVP();
      }
      await continueToEvent();
    } else {
      console.log("Name update failed");
      setLoadingState(false);
    }
  };
  const handleAnonymousSubmit = async () => {
    updateName("anonymous", "anonymous", token);
  };

  const toggleRSVP = async () => {
    if (event) {
      try {
        await event_service.rsvp(event.id, token, "rsvp");
        console.log("RSVP successful");
      } catch (error) {
        console.error("RSVP action failed:", error);
      }
    }
  };

  const continueToEvent = async () => {
    if (event?.id) {
      const eventDetails = await event_service.get(event.id, token);
      if (eventDetails) {
        navigate(`/e/${eventDetails.id}`, {
          state: { eventData: eventDetails },
        });
      } else {
        alert("Event not found");
      }
    } else {
      alert("Event data is missing.");
    }
    setLoadingState(false);
  };

  const onSubmit = (data) => {
    updateName(data.firstName, data.lastName);
  };

  return (
    <div className="flex justify-center items-center min-h-screen">
      <div className="w-full max-w-md p-6">
        <h1 className="text-2xl font-semibold text-center mb-6">
          What's your name?
        </h1>
        <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
          <div className="flex flex-col space-y-2">
            <label htmlFor="firstName" className="text-sm font-medium">
              First Name
            </label>
            <input
              id="firstName"
              type="text"
              className="form-control bg-transparent border-2 border-gray-500 p-2 rounded"
              {...register("firstName", { required: true })}
            />
            {errors.firstName && (
              <span className="text-red-500 text-xs">
                This field is required
              </span>
            )}
          </div>
          <div className="flex flex-col space-y-2">
            <label htmlFor="lastName" className="text-sm font-medium">
              Last Name
            </label>
            <input
              id="lastName"
              type="text"
              className="form-control bg-transparent border-2 border-gray-500 p-2 rounded"
              {...register("lastName", { required: true })}
            />
            {errors.lastName && (
              <span className="text-red-500 text-xs">
                This field is required
              </span>
            )}
          </div>
          <div className="flex justify-center mt-6">
            <button
              type="submit"
              className="flex items-center px-4 py-2 bg-blue-500 text-white rounded"
            >
              {loadingState ? <LightSmallSpinner /> : "Continue"}
            </button>
          </div>
        </form>
        <div className="mt-6 text-center">
          <button
            className="text-sm text-blue-500 hover:underline"
            onClick={handleAnonymousSubmit}
          >
            Proceed anonymously
          </button>
        </div>
      </div>
    </div>
  );
};

export default NameEntryForm;
