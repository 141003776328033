import { useState, useCallback, useRef } from "react";
import drafting from "../../../../../services/drafting";

const useArtistChange = (track, album) => {
  // Use ref to store the current artist ID with fallback if metadata is missing
  const selectedArtistRef = useRef(track?.metadata?.artist_id || null);

  const [, forceUpdate] = useState();

  const handleArtistChange = useCallback(async (artist) => {
    if (artist.id !== selectedArtistRef.current) {
      selectedArtistRef.current = artist.id;

      const updatedTrack = {
        ...track,
        metadata: {
          ...track.metadata,
          artist_id: artist.id,
        },
      };

      try {
        await drafting.manageTrack(album, updatedTrack, "update");
        console.log("Artist updated successfully:", artist.id);
        forceUpdate({}); // Trigger re-render if needed
      } catch (error) {
        console.error("Error updating artist:", error);
      }
    }
  }, [track, album]);

  return { selectedArtist: selectedArtistRef.current, handleArtistChange };
};

export default useArtistChange;