import React, { useState } from 'react';

const TrackEditor = ({ onSave }) => {
  const [trackData, setTrackData] = useState({
    title: '',
    duration: '',
    position: 0,
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    onSave({ ...trackData, position: Date.now() }); // Position could be the timestamp for simplicity
    setTrackData({ title: '', duration: '', position: 0 });
  };

  return (
    <form onSubmit={handleSubmit} className="mb-4">
      <input
        type="text"
        value={trackData.title}
        onChange={(e) => setTrackData({ ...trackData, title: e.target.value })}
        placeholder="Track title"
        className="mr-2 p-1 border"
      />
      <input
        type="text"
        value={trackData.duration}
        onChange={(e) => setTrackData({ ...trackData, duration: e.target.value })}
        placeholder="Duration"
        className="mr-2 p-1 border"
      />
      <button type="submit" className="p-2 bg-blue-500 text-white rounded">Add Track</button>
    </form>
  );
};

export default TrackEditor;
