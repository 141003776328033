import React, { useState, useEffect } from "react";
import { authMethods } from "../firebase/authmethods";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import profile_service from "../services/profile_service";

export const firebaseAuth = React.createContext();

const AuthProvider = (props) => {
  const initState = { email: "", password: "" };
  const [inputs, setInputs] = useState(initState);
  const [errors, setErrors] = useState([]);
  // const [token, setToken] = useState(null)
  const [token, setToken] = useState(localStorage.getItem("token"));

  const handleSignup = async (props) => {
    // middle man between firebase and signup
    //("handleSignup");
    // calling signup from firebase server
    authMethods.signup(
      inputs.email,
      inputs.password,
      setErrors,
      setToken,
      props
    );

    //(errors, token);
    if (!errors) {
      return "success";
    } else {
      setErrors([]);
      return "error";
    }

    // if (errors) {
    //   //(errors);
    //   return errors;
    // }
  };
  const handleSignin = () => {
    //changed to handleSingin
    //("handleSignin!!!!");
    // made signup signin
    authMethods.signin(inputs.email, inputs.password, setErrors, setToken);
    // authListener();
    //(errors, token);
    if (!errors) {
      return "success";
    } else {
      setErrors([]);
      return "error";
    }
  };

  const authListener = () => {
    var name, email, photoUrl, uid, emailVerified;
    var user = firebase.auth().currentUser;

    firebase.auth().onAuthStateChanged(function (user) {
      if (user) {
        //("confirmed signed in");
        firebase
          .auth()
          .currentUser.getIdToken(/* forceRefresh */ true)
          .then(function (idToken) {
            localStorage.setItem("token", idToken);
            setToken(idToken);
            profile_service.getProfileInfo(idToken);
          })
          .catch(function (error) {
            // Handle error
          });
      } else {
      }
    });
  };
  const handleSignout = () => {
    authMethods.signout(setErrors, setToken);
    document.location.href = "/";
  };

  return (
    <firebaseAuth.Provider
      value={{
        //replaced test with handleSignup
        handleSignup,
        handleSignin,
        token,
        inputs,
        setInputs,
        errors,
        handleSignout,
      }}
    >
      {props.children}
    </firebaseAuth.Provider>
  );
};

export default AuthProvider;
