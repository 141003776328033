// UploadProgress.jsx
import React, { useState } from "react";
import { PlusIcon, MinusIcon, XMarkIcon } from "@heroicons/react/24/solid";

const UploadProgress = ({
  uploading,
  uploadProgress,
  cancelUpload,
  cancelAllUploads,
}) => {
  const [showIndividualProgress, setShowIndividualProgress] = useState(false);

  const combinedProgress =
    uploadProgress.length > 0
      ? uploadProgress.reduce((acc, cur) => acc + cur.progress, 0) /
        uploadProgress.length
      : 0;

  const toggleIndividualProgress = () => {
    setShowIndividualProgress(!showIndividualProgress);
  };

  return (
    <div>
      {uploading > 0 && (
        <div className="mt-4">
          <div className="flex items-center justify-between">
            <h3 className="font-semibold">Upload Progress</h3>
            <button onClick={toggleIndividualProgress}>
              {showIndividualProgress ? (
                <MinusIcon className="h-6 w-6" />
              ) : (
                <PlusIcon className="h-6 w-6" />
              )}
            </button>
          </div>
          <div className="h-1 w-full bg-gray-300 dark:bg-gray-700 rounded mt-2">
            <div
              className="h-1 bg-blue-600 rounded"
              style={{ width: `${combinedProgress}%` }}
            ></div>
          </div>
          {showIndividualProgress && (
            <div className="mt-4">
              {uploadProgress.map(({ id, progress }) => (
                <div key={id} className="mt-2">
                  <div className="flex items-center justify-between">
                    <h4 className="font-semibold">{id}</h4>
                    {/* <button onClick={() => cancelUpload(id)}>
                      <XMarkIcon className="h-6 w-6" />
                    </button> */}
                  </div>
                  <div className="h-1 w-full bg-gray-300 dark:bg-gray-700 rounded">
                    <div
                      className="h-1 bg-blue-600 rounded"
                      style={{ width: `${progress}%` }}
                    ></div>
                  </div>
                </div>
              ))}
              {/* <button
                className="mt-4 px-4 py-2 bg-red-500 text-white rounded"
                onClick={cancelAllUploads}
              >
                Cancel All Uploads
              </button> */}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default UploadProgress;
