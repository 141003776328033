import { useState } from 'react';

export const useGenKit = () => {
  const [response, setResponse] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  
  const generateResponse = async (inputText) => {
    setLoading(true);
    setError(null); // Reset error on new request
    try {
      // Fetch data from your backend API, which now calls GenKit
      const res = await fetch(`/run-ai?input=${encodeURIComponent(inputText)}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (!res.ok) {
        throw new Error('Failed to fetch response from server');
      }

      const result = await res.json();
      console.log('AI response:', result);
      setResponse(result); // Assuming the backend sends the AI result directly
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  return { response, loading, error, generateResponse };
};