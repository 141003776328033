import React from "react";
import { Component } from "react";
// import './Mixtape.css';
// import { Button } from 'reactstrap';
// import axios from 'axios';
import { Link } from "react-router-dom";
// import Image from 'react-bootstrap/Image'
// import cassette from "../../assets/cassette.png";
import WideButton from "../../buttons/WideButton";
class FeedMixtape extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: props.data,
      username: "",
      currentTrack: "",
      currentTrackName: "",
      autoPlay: true,
      artistName: "",
    };
  }

  componentWillMount() {}

  renderTracks() {
    return this.state.data.tracks.map((track) => (



      <button className="text-red-500 flex flex-row gap-5 px-5 py-1"
        onClick={() => {
          console.log("track: ", track);
          this.props.setCurrentTrack(track);
        }}
      >
        {track.name}
      </button>
    ));
  }

  render() {
    return (
      <div class="mixtape" key={this.state.data.id}>
        <div class="mixtape-name">{this.state.data.name}</div>
        <div class="mixtape-creator">{this.state.artistName}</div>
        <div class="mixtape-votes">
          <span></span>
          {this.state.data.vote_count}
        </div>

        <div class="tracks">
          {
            // this.props.showAlbums ? this.renderAlbums() : this.renderMixtapes()
            this.renderTracks()
          }
        </div>
        <div class="player">
          <div class="now-playing-track">{this.state.currentTrack}</div>
          {/* <button onClick={() => {alert("hi")}}>
                test
              </button> */}
          <Link to="/checkout">
            <button class="buy-button">
              hi
              {/* <img src={cassette} onClick={this.buy} /> */}
            </button>
          </Link>
        </div>
      </div>
    );
  }
}

export default FeedMixtape;
