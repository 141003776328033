// ArtistPublicProfile.js;
import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
// import firebase compat
import firebase from "firebase/compat/app";

import profile_service from "../services/profile_service";
import GridContainer from "./themes/GridContainer";
import PublicMerchListView from "./artist_profiles/PublicMerchListView";
import ArtistMusicView from "./artist_profiles/ArtistMusicView";
import ArtistMixtapeView from "./artist_profiles/ArtistMixtapeView";
import Player from "./media_player/Player";
import MXAudioPLayer from "./media_player/MXAudioPlayer";
import { MapPinIcon } from "@heroicons/react/24/solid";
const ArtistShopView = () => {
  let location = useLocation();

  // var artist_slug = location.pathname.split("/").pop();
  // get element from location.pathname
  var artist_slug = location.pathname.split("/")[2];
  const [artistName, setArtistName] = useState("");

  const [customer, setCustomer] = useState(null);
  const [artistId, setArtistId] = useState(0);
  const [user_fs_uid, setFSUID] = useState("");
  const [username, setUsername] = useState();
  const [user_id, setUserId] = useState();
  const [loaded, setLoaded] = useState(false);
  const [albums, setAlbums] = useState([]);
  // set mixtapes
  const [mixtapes, setMixtapes] = useState([]);
  const getData = async () => {
    var name, email, photoUrl, uid, emailVerified;
    firebase.auth().onAuthStateChanged(function (user) {
      // setArtistName(artist_name);
      var _artistId = 0;
      setArtistId(_artistId);
      var s = profile_service.getMerch(artist_slug);
      console.log("data: ", s);
      s.then(function (res) {
        res.json().then(function (data) {
          setLoaded(true);
          var artist_name = data["artist_name"];
          // alert(artist_name);
          setArtistName(artist_name);
          console.log("artist data: ", data);
          setArtistId(data["artist_id"]);
          setAlbums(data["albums"]);
          setMixtapes(data["mixtapes"]);
        });
      });
    });
  };
  useEffect(() => {
    if (!loaded) {
      // setLoaded(true);
      getData();
    }
  }, [artistId, username, artistName, loaded]);
  function getCustomerInfo(token) {
    if (!customer) {
      var ci = profile_service.getCustomerInfo(token);
      ci.then((res) => {
        res.json().then(function (data) {
          setCustomer(data.customer);
        });
      });
    }
  }

  const returnDescription = () => {
    return (
      <div className="font-theFutureExtraLight">
        Mercurial sounds pierce the deafening silence of the cosmos. Tortured
        voices, night terrors force you awake. A symbol of the dark, the
        unknown, and the unconscious, glows before you. You’ve entered the
        hypnagogic state from which brooklyn-based music producer, san delila,
        weaves tapestries of voltage and fuzzed out soliloquys without
        restraint.
        {/* artist bio */}
      </div>
    );
  };
  const geographicLocationView = () => {
    return (
      <div className="flex flex-row">
        <div className="py-1">
          <MapPinIcon className="h-5 w-5 " />
        </div>
        <div className="font-theFutureMonoRegular px-1">brooklyn, ny</div>
      </div>
    );
  };
  // function loadMixtapes() {
  //   var s = profile_service.getMixtapes(artistId);
  //   s.then(function (res) {
  //     res.json().then(function (data) {
  //       setMixtapes(data.mixtapes);

  //     });
  //   });
  // }
  // var spotify_track_link = "2m4CQmzZ2sk4J3G3dB6DVH";
  const song_url =
    "https://www.soundhelix.com/examples/mp3/SoundHelix-Song-1.mp3";
  function parse(s) {
    s.then(function (res) {
      res.json().then(function (data) {
        if (data["customer_info"]) {
          var cus_data = JSON.stringify(data["customer_info"]);
          localStorage.setItem("customer_info", cus_data);
        }
        setUserId(data.id);
        setUsername(data.username);
      });
    });
  }
  // var addy =
  // "https://firebasestorage.googleapis.com/v0/b/tuareg.appspot.com/o/studio%2Fuser_storage%2FhYk9sMeZOfbluindIkotvQ5n6sn2%2Ffiles%2Fimages%2Fandromeda_cassette.png?alt=media&token=fbd099cd-3bf2-4f62-b5d9-10185a6a58c4";
  // if loaded
  if (loaded) {
    return (
      // tailwind grid for merch page
      // <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
      <div>
        {/* tailwind title styling */}
        <div className=" grid grid-flow-row gap-4">
          <div>
            <div className="p-2 text-4xl font-theFutureBold">{artistName}</div>
          </div>
          {artistName == "san delila" ? geographicLocationView() : ""}
          <ArtistMusicView albums={albums} artistId={artistId} />
          {/* add this description if artist name is "san delila" */}
          {artistName == "san delila" ? returnDescription() : ""}

          {/* MapPinIcon */}

          {artistName == "san delila" ? (
            <div className="border-blue-400 border-2 p-2">
              <MXAudioPLayer className="grow"></MXAudioPLayer>
            </div>
          ) : (
            <div></div>
          )}
          {/* if artist name is san delila display the player */}

          {/* <div className=""> */}
          <div className="py-5">
            {artistId == 0 ? (
              ""
            ) : (
              <PublicMerchListView slug={artist_slug}></PublicMerchListView>
            )}
          </div>

          {/* <ArtistMixtapeView mixtapes={mixtapes} /> */}

          {/* <div className="flex flex-col items-center p-2 columns-2"></div> */}
        </div>

        {/* <Player song_url={song_url} /> */}
      </div>
      // </div>
    );
  } else {
    return (
      <div className="font-theFutureMonoRegular flex flex-col items-center p-20">
        loading...
      </div>
    );
  }
};
export default ArtistShopView;
