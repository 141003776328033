// useSetAlbumArtist.js
import { useState } from "react";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";

const db = firebase.firestore();
const getCurrentUser = () => firebase.auth().currentUser;

export const useSetAlbumArtist = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const setAlbumArtist = async (albumId, artistId) => {
    const user = getCurrentUser();
    if (!user) {
      setError("No user logged in");
      return;
    }

    try {
      setLoading(true);
      const albumRef = db
        .collection("studio")
        .doc(user.uid)
        .collection("drafts")
        .doc(albumId);
      await albumRef.update({ artist_id: artistId });
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  return { setAlbumArtist, loading, error };
};
