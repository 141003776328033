import React from 'react';
import Spinner from './Spinner'; // Make sure this path is correct

const LightSpinner = () => {
    // Default properties for a light-themed spinner
    const lightThemeProps = {
        color: 'blue-500', // Example light theme color
        darkColor: 'gray-300', // Dark color for contrast in dark mode
        size: 8, // Default size
        borderWidth: 2 // Default border width
    };

    return <Spinner {...lightThemeProps} />;
};

export default LightSpinner;

