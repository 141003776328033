import React, { Component } from "react";
// import AuthService from "../services/AuthService";

import FeedMixtape from "./FeedMixtape";
// import withAuth from "./withAuth";
// import config from "../config/config";
// import Album from "./Album";
// import AlbumContainer from "../containers/AlbumContainer";
// import Navigate from "react-router-dom/es/Redirect";
// import { sessionTokenIsValid } from "../utilities/verifySession";

class Albums extends Component {
  constructor() {
    super();
    this.state = {
      mixtapes: [],
      me: [],
      albums: [],
    };
    // this.Auth = new AuthService();
  }

  componentDidMount() {
    // if (!this.Auth.loggedIn()) {
    //   this.props.exitSession();
    //   return <Navigate to="/login" />;
    // } else {
    //   this.props.grantLoginSession();
      // this.Auth.fetch(config.me()).then(response => {
      //   const me = response.results;
      //   this.setState({me: me})
      // })
      // this.Auth.fetch(config.feed())
      //   .then(response => {
      //     const mixtapes = response.results;
      //     this.setState({mixtapes: mixtapes});
      //   });
      // this.Auth.fetch(config.albums()).then(response => {
      //   const albums = response.results;
      //   this.setState({albums: albums});
      // });

     this.loadAlbums()
    // }
  }

  loadAlbums() {
    let albumData = require("./album_data.json");
    // console.log("albums: ", albumData);

    // convert json data to something that can be iterated over
    let albums = [];
    for (let i = 0; i < albumData.length; i++) {
      console.log("albumData[i]: ", albumData[i]);
      albums.push(albumData[i]);
    }
    console.log("albums: ", albumData);
    this.setState({ albums: albums });
    console.log("albums: ", albums);
  }

  render() {
    let albums = this.state.albums;

    return (
      <div>
        <div className="feed">
          {albums.map((album) => (
            <FeedMixtape
              currentTrack={this.props.currentTrack}
              data={album}
              setCurrentTrack={this.props.setCurrentTrack}
              showAlbums={true}
            />
          ))}
        </div>
      </div>
    );
  }
}

export default Albums;
