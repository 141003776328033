// FolderBranch.jsx
import React from "react";
import FolderNode from "./FolderNode";
const FolderBranch = ({ node, onSelect, selectedId }) => {
  const isSelected = selectedId === node.data.id;

  return (
    <FolderNode
      folder={node}
      onFolderClick={() => {
        // node data
        console.log("Clicked folder path:", node.data.id);
        console.log("Clicked folder id:", node.data.id);
        console.log("Selected id:", selectedId);
        onSelect(node);
      }}
      selected={isSelected}
    >
      <div className="ml-6">
        {node.children.map((child) => (
          <FolderBranch
            key={child.data.id}
            node={child}
            onSelect={onSelect}
            selectedId={selectedId}
          />
        ))}
      </div>
    </FolderNode>
  );
};


export default FolderBranch;