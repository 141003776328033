// CreatePostView.js
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

const CreatePostView = () => {
  const [postContent, setPostContent] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();

  const handleChange = (e) => {
    setPostContent(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    // Simulate post submission
    setTimeout(() => {
      setIsSubmitting(false);
      console.log("Post submitted:", postContent);
      navigate("/"); // Redirect to home page or wherever you want after submission
    }, 2000);
  };

  return (
    <div className="max-w-lg mx-auto p-4 mt-8">
      <h2 className="text-2xl font-bold mb-4 text-gray-900 dark:text-gray-200 font-mono">Create a Post</h2>
      <form onSubmit={handleSubmit} className="bg-gray-900 text-gray-200 shadow-md rounded px-8 pt-6 pb-8 mb-4">
        <div className="mb-4">
          <textarea
            className="w-full h-40 px-3 py-2 text-gray-300 bg-gray-800 border border-gray-600 rounded-lg focus:outline-none focus:shadow-outline font-mono"
            placeholder="What's on your mind?"
            value={postContent}
            onChange={handleChange}
            required
          />
        </div>
        <div className="flex items-center justify-between">
          <button
            type="submit"
            className={`px-4 py-2 font-bold text-black bg-green-500 rounded-full hover:bg-green-700 focus:outline-none focus:shadow-outline font-mono ${
              isSubmitting ? "opacity-50 cursor-not-allowed" : ""
            }`}
            disabled={isSubmitting}
          >
            {isSubmitting ? "Posting..." : "Post"}
          </button>
        </div>
      </form>
    </div>
  );
};

export default CreatePostView;