// storage_utils.js

import APIConfig from "../../APIConfig";

import auth from '../auth';

const deleteTape = async (userUid, tapeUid) => {
  const url = `${APIConfig.delete_tape()}`;

  // Use the getAuthHeaders() function from auth.js to get the headers
  const headers = await auth.getAuthHeaders();

  const data = {
    user_uid: userUid,
    tape_uid: tapeUid,
  };

  try {
    const response = await fetch(url, {
      method: "POST",
      headers: headers,
      body: JSON.stringify(data),
    });

    if (response.ok) {
      return true;
    } else {
      throw new Error("Failed to delete tape");
    }
  } catch (error) {
    console.error("Error deleting tape: ", error);
    return false;
  }
};


export default {
  //... other exports
  deleteTape,
};