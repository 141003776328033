import { useEffect, useState } from "react";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";

// Custom hook to fetch and subscribe to drafts
function useRealTimeDrafts(user) {
  const [drafts, setDrafts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!user || !user.uid) {  // Check if user and user.uid are defined
      setLoading(false);
      setError("User not authenticated");
      return;
    }

    const unsubscribe = firebase
      .firestore()
      .collection(`studio/${user.uid}/drafts`)
      .orderBy("last_edited", "desc")
      .onSnapshot({
        next: (querySnapshot) => {
          const updatedDrafts = [];
          querySnapshot.forEach((doc) => {
            if (!doc.data().is_published) {
              console.log("Draft found:", doc.data());
              updatedDrafts.push({
                name: doc.data().name,
                docId: doc.id,
              });
            }
          });
          setDrafts(updatedDrafts);
          setLoading(false);
        },
        error: (error) => {
          console.error("Error fetching real-time drafts:", error);
          setError(error);
          setLoading(false);
        },
      });

    // Clean up the subscription on unmount
    return () => unsubscribe();
  }, [user]);

  return { drafts, loading, error };
}

export default useRealTimeDrafts;