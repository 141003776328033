// ArtistListElement.jsx
import React, { useState } from 'react';
import ArtistDetailsView from './ArtistDetailsView'; // Adjust the path as needed

const ArtistListElement = ({ artistId, artistName }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <li className="pl-4">
      <div className="flex items-center">
        <span className="mr-2 cursor-pointer hover:text-green-200" onClick={toggleExpand}>
          {isExpanded ? '-' : '+'}
        </span>
        {artistName}
      </div>
      {isExpanded && <ArtistDetailsView artistId={artistId} />}
    </li>
  );
};

export default ArtistListElement;
