import React from 'react';
import { ShareIcon, CheckIcon } from "@heroicons/react/24/solid";
import APIConfig from '../../APIConfig';

const EventLinkButton = ({ event, linkCopied, presentEventCopySuccessToast }) => {
  const handleButtonClick = () => {
    navigator.clipboard.writeText(APIConfig.siteBaseURL() + "/e/" + event.id);
    presentEventCopySuccessToast();
  };

  return (
    <button
      className={`flex items-center p-2 rounded-lg transition-colors duration-300 ${
        linkCopied ? 'bg-blue-300 hover:bg-blue-400 text-white' : 'bg-gray-100 hover:bg-gray-200 text-gray-700 dark:bg-gray-800 dark:hover:bg-gray-700 dark:text-gray-300'
      }`}
      onClick={handleButtonClick}
    >
      {linkCopied ? (
        <CheckIcon className="h-5 w-5 mr-2" />
      ) : (
        <ShareIcon className="h-5 w-5 mr-2" />
      )}
      <span className="text-sm font-mono">{linkCopied ? 'link copied' : 'share'}</span>
    </button>
  );
};

export default EventLinkButton;