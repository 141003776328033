import React, { useState } from "react";
import useRSVPStatus from "./useRSVPStatus";
import useRSVPToggle from "./useRSVPToggle";
import useRSVPToast from "./useRSVPToast";
import { Toaster } from 'react-hot-toast';

const RSVPButton = (props) => {
  const [isHovered, setIsHovered] = useState(false);
  const [going, setGoing] = useRSVPStatus(props.event);
  const { notify, getStyle } = useRSVPToast();
  const { toggleGoing, isLoading } = useRSVPToggle({
    going, setGoing, event: props.event, token: props.token,
    increment: props.increment, decrement: props.decrement, notify
  });

  const isNonTouchDevice = !('ontouchstart' in window || navigator.maxTouchPoints);

  const handleMouseEnter = () => {
    if (isNonTouchDevice) {
      setIsHovered(true);
    }
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <div className="relative w-full mt-6">
      <button
        disabled={isLoading}
        className={`w-full text-lg sm:text-xl font-sansBold py-3 px-6 rounded-lg transition duration-300 ease-in-out ${
          going ? "bg-blue-500 hover:bg-blue-600 text-white" : "bg-gray-200 hover:bg-gray-300 text-black"
        }`}
        onClick={toggleGoing}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onFocus={handleMouseEnter}
        onBlur={handleMouseLeave}
        aria-label={going ? "Click to cancel your RSVP" : "Click to RSVP"}
      >
        {isLoading ? "Processing..." : going ? "I'm Going - Click to Cancel" : "RSVP to Attend"}
      </button>
      <Toaster toastOptions={{ className: "", style: getStyle(going) }} />
    </div>
  );
};

export default RSVPButton;