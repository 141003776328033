// MemoriesPageView.jsx

import React, { useState, useEffect } from "react";
import { getEventMemories } from "../../../services/events";
import { useAuth } from "../../../AuthContext";
import { useParams } from "react-router-dom";
import MemoriesList from "./MemoriesList";
import useEventMemories from "../../file_mgmt/drive/hooks/useEventStorageHooks";
// MemoriesPageView.jsx

const MemoriesPageView = () => {
  const { eventId } = useParams();
  const { user } = useAuth();
  const { memories, loading } = useEventMemories(eventId);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <h1>Memories for event {eventId}</h1>
      <MemoriesList memories={memories} /> {/* Render your memories list here */}
    </div>
  );
};

export default MemoriesPageView;
