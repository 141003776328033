// services/EventDetailService.js
import firebase from "firebase/compat/app";

const db = firebase.firestore();

const loadBannerImage = async (eventId) => {
  const docRef = db.collection("events").doc(eventId);
  console.log("Attempting to load banner image for event ID:", eventId);
  const document = await docRef.get();
  if (!document.exists) {
    console.log("No such document!");
    return "";
  }

  const data = document.data();
  return data.picture ?? "";
};

export const EventDetailService = {
  loadBannerImage,
};
