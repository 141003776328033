// MTRModal.jsx
import React from "react";
import { XMarkIcon } from "@heroicons/react/24/outline";

const MTRModal = ({ isOpen, onClose, children }) => {
  if (!isOpen) return null;

  const handleBackdropClick = (event) => {
    if (event.target === event.currentTarget) {
      onClose();
    }
  };

  return (
    <div
      className="fixed z-50 inset-0 overflow-y-auto"
      onClick={handleBackdropClick}
    >
      <div className="fixed inset-0 bg-gray-900 opacity-50"></div>
      <div className="flex items-center justify-center min-h-screen">
        <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-md relative">
          <button
            className="absolute top-2 right-2 text-gray-500 hover:text-gray-900 focus:outline-none"
            onClick={onClose}
          >
            <XMarkIcon className="w-6 h-6" />
          </button>
          {children}
        </div>
      </div>
    </div>
  );
};

export default MTRModal;
