// merch.js;
// mastering.js;

// import APIConfig from "./APIConfig";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";

import APIConfig from "../APIConfig";
import utils from "../components/artist_dashboard/utilities/utils";
import { authMethods } from "../firebase/authmethods";
import auth from "./auth";

// import { authMethods } from "./firebase/authmethods";

// import { authMethods } from "../../firebase/authmethods";
const token = localStorage.getItem("token");
// const b = APIConfig.baseURL();
// ${APIConfig.baseURL()}
// const base = APIConfig.baseURL()
let db = firebase.firestore();
var user = firebase.auth().currentUser;
const authUser = Object.keys(window.localStorage).filter((item) =>
  item.startsWith("firebase:authUser")
)[0];

async function getDrafts(uid) {
  var merchItems = [];
  return new Promise((resolve, reject) => {
    var drafts_ref = db
      .collection(`studio/${uid}/merch`)
      .orderBy("last_edited", "desc")
      .get()
      // .onSnapshot((querySnapshot) => {
      .then(function (querySnapshot) {
        querySnapshot.forEach(async function (doc) {

          // if (doc.data().published != true) {
          //   var _merchItem = buildMerchItem(doc);
          //   merchItems.push(_merchItem)
          // }

          var _merchItem = buildMerchItem(doc);
          var options = await getOptions(_merchItem.docId);
          var updatedMerchItem = loadOptions(_merchItem, options);
          merchItems.push(updatedMerchItem);
          resolve(merchItems);
        });

      })
      .catch((error) => {
        console.log("Error getting documents: ", error);
        reject(error);
      });
  });
}

// broken asf uck
async function getDrasdasdafts() {
  var merchItems = [];
  const credentials = await auth.getUID().then(function (fs_user_uid) {
    const unsubscribe = db
      .collection(`studio/${fs_user_uid}/merch`)
      .onSnapshot((snap) => {
        const data = snap.docs.map(async function (doc) {


          if (doc.data().published != true) {
            var _merchItem = buildMerchItem(doc);
            var options = await getOptions(_merchItem.docId);
            var updatedMerchItem = loadOptions(_merchItem, options);
            merchItems.push(updatedMerchItem);
          }
          return merchItems

          // console.log(data);
          // setData(data);
        })
      })
    // return () => unsubscribe();
  });

}

async function getCredentials() {
  var promise = new Promise((resolve, reject) => {
    firebase.auth().onAuthStateChanged(function (user) {
      if (user) {
        firebase
          .auth()
          .currentUser.getIdToken(true)
          .then(function (idToken) {
            //   setToken(idToken);
            resolve(user.uid);
          });
      }
    });
  });

  return promise;
}

function buildMerchItem(doc) {
  var merchItem = {
    name: doc.data().name,
    description: doc.data().description,
    price: doc.data().price,
    category: doc.data().category,
    docId: doc.id,
    quantity: doc.data().quantity,
    sku: doc.data().sku,
    // ships_intl: true,
    // domestic_shipping: 20,
    // domestic_shipping_additional: 10,
    // intl_shipping: 0,
    // intl_shipping_additional: 0,
    option_name: doc.data().option_name,
    option_1: doc.data().name,
    option_1_quantity: null,
    option_1_sku: "",
    option_2: "",
    option_2_quantity: null,
    option_2_sku: "",
    option_3: "",
    option_3_quantity: null,
    option_3_sku: "",
  };
  return merchItem;
}

//
function updateMerchItem(item, uid) {
  let option1 = {
    docId: item.option_1_docId,
    name: item.option_name,
    description: item.option_1,
    quantity: item.option_1_quantity,
    sku: item.option_1_sku,
    index: 0,
  };
  let option2 = {
    docId: item.option_2_docId,
    name: item.option_name,
    description: item.option_2,
    quantity: item.option_2_quantity,
    sku: item.option_2_sku,
    index: 1,
  };
  let option3 = {
    docId: item.option_3_docId,
    name: item.option_name,
    description: item.option_3,
    quantity: item.option_3_quantity,
    sku: item.option_3_sku,
    index: 2,
  };
  console.log("sku: ", item.sku);
  var merch_ref = db.collection(`studio/${uid}/merch/`).doc(`${item.docId}`);

  merch_ref.set(
    {
      last_edited: firebase.firestore.FieldValue.serverTimestamp(),
      name: item.name,
      category: item.category,
      description: item.description,
      price: item.price,
      quantity: item.quantity,
      sku: item.sku,
      published: false,
    },
    { merge: true }
  );
  // .then((docRef) => {
  //   console.log("Document written with ID: ", docRef.id);

  merch_ref
    .collection("options")
    .doc(option1.docId)
    .set(option1, { merge: true });
  merch_ref
    .collection("options")
    .doc(option2.docId)
    .set(option2, { merge: true });
  merch_ref
    .collection("options")
    .doc(option3.docId)
    .set(option3, { merge: true });
  // })
  // .catch((error) => {
  //   console.error("Error adding document: ", error);
  // });
}

const publish = (draft_uid, token) => {
  const data = { draft_uid: draft_uid };
  const params = JSON.stringify(data);
  window
    .fetch(`${APIConfig.profiles()}new_merch_item/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "JWT " + token,
      },
      body: params,
    })
    .then((res) => {
      if (res.status === 200) {
        return res.json();
      } else {
        console.log("error");
        return null;
      }
    });
  // .then((data) => {
  //   if (!data || data.error) {
  //     throw new Error("PaymentIntent API Error");
  //   } else {
  //     return data.client_secret;
  //   }
  // });
};

function getTimeStamp() {
  var now = firebase.firestore.Timestamp.now();

  return now;
}
async function updateMerchItemImage(product, downloadURL, filename, file) {
  var promise = new Promise((resolve, reject) => {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        var uid = user.uid;
        let albumCover = db
          .collection(`studio/${uid}/merch/${product.docId}/images/`)
          .doc();
        albumCover.set({
          created: getTimeStamp(),
          last_edited: getTimeStamp(),
          public_url: downloadURL,
          filename: filename,
        });

        resolve(downloadURL);
      } else {
        ////("signed out....");
        reject("error signed out");
      }
    });
  });

  return promise;
}
function getMerchItemImage(merchItem) {
  // console.log(album);
  var promise = new Promise((resolve, reject) => {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        var uid = user.uid;
        let imageQuery = db
          .collection(`studio/${uid}/merch/${merchItem.docId}/images/`)
          .orderBy("created", "desc")
          .limit(1)
          .get()
          .then(function (querySnapshot) {
            if (querySnapshot.empty) {
              resolve("no image");
            } else {
              querySnapshot.forEach(function (doc) {
                var image = {
                  url: doc.data().public_url,
                  docId: doc.id,
                };
                // console.log(image);
                resolve(image);
              });
            }
          })
          .catch(function (error) {
            console.log("Error getting documents: ", error);
          });
      }
    });
  });
  return promise;
}

// adds options to merch items
function loadOptions(merchItem, options) {
  if (options.length > 1) {
    merchItem.hasAdditionalOptions = true;
  } else {
    merchItem.hasAdditionalOptions = false;
  }
  return mapOptions(options, merchItem);
}

function mapOptions(options, merchItem) {
  options.forEach(function (option, index) {
    merchItem.option_name = option.name;
    merchItem[`option_${index + 1}_docId`] = option.docId;
    merchItem[`option_${index + 1}`] = option.description;
    merchItem[`option_${index + 1}_quantity`] = option.quantity;
    merchItem[`option_${index + 1}_sku`] = option.sku;
  });
  return merchItem;
}
async function getRecentDrafts() {

  const merchItem = await getLatestMerchItemDraft();
  const options = await getOptions(merchItem.docId);
  
  if (options.length > 1) {
    merchItem.hasAdditionalOptions = true;
  } else {
    merchItem.hasAdditionalOptions = false;
  }
  return mapOptions(options, merchItem);
}

async function getOptions(docId) {
  return new Promise((resolve, reject) => {
    // ////("the uid: ", uid);
    var options = [];
    const uid = localStorage.getItem("uid");
    db.collection("studio")
      .doc(uid)
      .collection("merch")
      .doc(docId)
      .collection("options")
      .orderBy("index", "asc")
      .get()
      .then(function (querySnapshot) {
        querySnapshot.forEach(function (doc) {
          // doc.data() is never undefined for query doc snapshots
          ////(doc.id, " => ", doc.data());

          var option = {
            docId: doc.id,
            name: doc.data().name,
            description: doc.data().description,
            quantity: doc.data().quantity,
            sku: doc.data().sku,
          };
          // console.log(option.docId);

          options.push(option);
        });
        resolve(options);
      })
      .catch(function (error) {
        ////("Error getting documents: ", error);
      });
  });

  async function send(draft_uid) {
    const data = { draft_uid: draft_uid };
    const d = JSON.stringify(data);
    fetch(`${APIConfig.merch()}new/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "JWT " + token,
      },
      body: d,
    }).then(
      function (res) {
        res.json().then(function (data) { });
        //
      }
      // function (value) {
      //   // not called
      // }
    );
  }
}
async function getLatestMerchItemDraft() {
  return new Promise((resolve, reject) => {
    // ////("the uid: ", uid);
    const uid = localStorage.getItem("uid");
    db.collection("studio")
      .doc(uid)
      .collection("merch")
      .orderBy("last_edited", "desc")
      .limit(1)
      .get()
      .then(function (querySnapshot) {
        querySnapshot.forEach(function (doc) {
          // doc.data() is never undefined for query doc snapshots
          ////(doc.id, " => ", doc.data());

          var merchItem = {
            name: doc.data().name,
            description: doc.data().description,
            price: doc.data().price,
            category: doc.data().category,
            docId: doc.id,
            quantity: doc.data().quantity,
            sku: doc.data().sku,
            // ships_intl: true,
            // domestic_shipping: 20,
            // domestic_shipping_additional: 10,
            // intl_shipping: 0,
            // intl_shipping_additional: 0,
            option_name: "",
            option_1: "",
            option_1_quantity: null,
            option_1_sku: "",
            option_2: "",
            option_2_quantity: null,
            option_2_sku: "",
            option_3: "",
            option_3_quantity: null,
            option_3_sku: "",
          };
          resolve(merchItem);
        });
      })
      .catch(function (error) {
        ////("Error getting documents: ", error);
      });
  });
}

async function getDraft(docId) {
  return new Promise((resolve, reject) => {
    // ////("the uid: ", uid);
    const uid = localStorage.getItem("uid");
    db.collection("studio")
      .doc(uid)
      .collection("merch")
      .orderBy("last_edited", "desc")
      .limit(1)
      .get()
      .then(function (querySnapshot) {
        querySnapshot.forEach(function (doc) {
          // doc.data() is never undefined for query doc snapshots
          ////(doc.id, " => ", doc.data());

          var merchItem = {
            name: doc.data().name,
            description: doc.data().description,
            price: doc.data().price,
            category: doc.data().category,
            docId: doc.id,
            quantity: doc.data().quantity,
            sku: "",
            // ships_intl: true,
            // domestic_shipping: 20,
            // domestic_shipping_additional: 10,
            // intl_shipping: 0,
            // intl_shipping_additional: 0,
            option_name: "",
            option_1: "",
            option_1_quantity: null,
            option_1_sku: "",
            option_2: "",
            option_2_quantity: null,
            option_2_sku: "",
            option_3: "",
            option_3_quantity: null,
            option_3_sku: "",
          };
          resolve(merchItem);
        });
      })
      .catch(function (error) {
        ////("Error getting documents: ", error);
      });
  });
}

async function initialize(name) {
  return new Promise((resolve, reject) => {
    var item = createEmptyObject(name);
    const uid = localStorage.getItem("uid");
    var drafts = db.collection("studio").doc(uid).collection("drafts");

    let option1 = {
      // docId: item.option_1_id,
      name: item.option_name,
      description: item.option_1,
      quantity: item.option_1_quantity,
      sku: item.option_1_sku,
      index: 0,
    };
    let option2 = {
      // docId: item.option_2_id,
      name: item.option_name,
      description: item.option_2,
      quantity: item.option_2_quantity,
      sku: item.option_2_sku,
      index: 1,
    };
    let option3 = {
      // docId: item.option_3_id,
      name: item.option_name,
      description: item.option_3,
      quantity: item.option_3_quantity,
      sku: item.option_3_sku,
      index: 2,
    };
    var merch_ref = db.collection(`studio/${uid}/merch/`);
    merch_ref
      .add({
        last_edited: firebase.firestore.FieldValue.serverTimestamp(),
        name: item.name,
        category: item.category,
        description: item.description,
        price: item.price,
        quantity: item.quantity,
      })
      .then((docRef) => {
        console.log("Document written with ID: ", docRef.id);
        docRef.collection("options").doc().set(option1);
        docRef.collection("options").doc().set(option2);
        docRef.collection("options").doc().set(option3);
        var merchItem = createEmptyObject();
        item.docId = docRef.id;
        resolve(item);
      })
      .catch((error) => {
        console.error("Error adding document: ", error);
      });
  });
}
function createEmptyObject(name) {
  var now = firebase.firestore.Timestamp.now();

  var merchObject = {
    docId: "",
    last_updated: now,
    category: "",
    name: name,
    description: "",
    price: 0,
    quantity: "",
    sku: "",
    ships_intl: true,
    // domestic_shipping: 20,
    // domestic_shipping_additional: 10,
    // intl_shipping: 0,
    // intl_shipping_additional: 0,
    option_name: "",
    option_1: "",
    option_1_quantity: "",
    option_1_sku: "",
    option_2: "",
    option_2_quantity: null,
    option_2_sku: "",
    option_3: "",
    option_3_quantity: null,
    option_3_sku: "",
    published: false,
  };
  return merchObject;
}

// no auth needed
const getMerch = async (slug) => {
  const artist_data = { slug: slug };

  const d = JSON.stringify(artist_data);
  // console.log("d ", d);
  // const token = localStorage.getItem("token");

  const r = await fetch(`${APIConfig.store()}`, {
    method: "post",
    headers: {
      "Content-Type": "application/json",
      // Authorization: "JWT " + token,
    },
    body: d,
  });
  return r;
};

const getPublishedMerchItems = async (token) => {
  const merch_items = await queryMerchItems(token);
  // merch_items.then((res) => {
  //   if (res.status === 200) {
  //     console.log(res);
  //     return res.json();
  //   } else {
  //     console.log("errorrr");
  //     return null;
  //   }
  // });
  return merch_items;
  //   merch_items.then(function (response) {
  //     console.log(response);
  //   });
};


const queryMerchItems = async (token, artist_id) => {
  // let data =  JSON.stringify();
  const data = { artist_id: artist_id };
//   console.log("the artistid: ", artist_id)
  const d = JSON.stringify(data);
  const r = await fetch(`${APIConfig.artists()}merch/`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "JWT " + token,
    },
    body: d,
  });
  return r;
};
const merch = {
  getMerchItemImage,
  updateMerchItemImage,
  getRecentDrafts,
  getDrafts,
  createEmptyObject,
  initialize,
  updateMerchItem,
  publish,
  getMerch,
  queryMerchItems,
};

export default merch;
