import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

const Spinner = ({
  size = 8,
  color = "black",
  darkColor = "white",
  borderWidth = 3,
}) => {
  const spinnerStyle = `animate-spin rounded-full h-${size} w-${size} border-${borderWidth} border-${color} dark:border-${darkColor} border-t-transparent ease-linear`;

  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setIsVisible(true);
    return () => setIsVisible(false);
  }, []);

  return (
    <div
      className={`flex justify-center items-center ${
        isVisible ? "opacity-100" : "opacity-0"
      } transition-opacity duration-500`}
    >
      <div className={spinnerStyle}></div>
    </div>
  );
};

Spinner.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
  darkColor: PropTypes.string,
  borderWidth: PropTypes.number,
};

export default Spinner;
