import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import UsernameForm from "../../auth/onboarding/UsernameForm";
import { useAuthState } from "react-firebase-hooks/auth";
import { getAuth } from "firebase/auth";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import LightSmallSpinner from "../loading/LightSmallSpinner";
import { useEvent } from "./useEvent";

const auth = getAuth(firebase.app());

const CreateUsernameFromEventView = () => {
  const [user, loading, error] = useAuthState(auth);
  const [loadingState, setLoadingState] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { event, loading: eventLoading } = useEvent(location.state?.eventData.id);

  useEffect(() => {
    const checkUserName = async (currentUser) => {
      setLoadingState(true);
      const idToken = await currentUser.getIdToken(true);
      localStorage.setItem("token", idToken);

      if (event && event.no_name) {
        setLoadingState(false);
      } else {
        navigate(`/e/${event.id}`, { state: { eventData: event } });
      }
    };

    if (user && event && !eventLoading) {
      checkUserName(user);
    }
  }, [user, event, eventLoading, navigate]);

  const handleUsernameSubmit = async (username) => {
    navigate("/name_entry", { state: { eventData: location.state.eventData } });
  };

  if (loading || eventLoading || loadingState) {
    return <LightSmallSpinner />;
  }

  return (
    <div className="flex justify-center items-center min-h-screen dark:bg-gray-900">
      <div className="w-full max-w-md p-6 dark:bg-gray-800 rounded-lg">
        <h1 className="text-2xl font-semibold text-center">Create a Username</h1>
        <div className="h-6">
          {loading && <div className="text-center">Loading...</div>}
          {error && <div className="text-red-400 text-center">{error.message}</div>}
        </div>
        {user && !loading && (
          <UsernameForm
            user={user}
            onUsernameSubmitted={handleUsernameSubmit}
          />
        )}
      </div>
    </div>
  );
};

export default CreateUsernameFromEventView;