// useSharingHooks.jsx
import { useState, useEffect, useCallback } from "react";
import drive_service from "../../../../services/drive_service";
import { useAuthState } from "react-firebase-hooks/auth";
import "firebase/compat/firestore";
import firebase from "firebase/compat/app";
import { getStorageRef } from "../../../../services/storage_service";
const auth = firebase.auth();

export const useCreateSharedFolder = (
  items,
  setItems,
  currentPath,
  sharedUsers
) => {
  console.log("useCreateFolder"); // Add this line
  const [user] = useAuthState(auth); // Add this line

  return async () => {
    if (!user) return;

    const folderName = `New Shared Folder ${Date.now()}`;
    const relativePath = currentPath.replace(user.uid, "");
    const newItem = {
      name: folderName,
      type: "folder",
      userId: user.uid,
      path: currentPath,
      relativePath, // Add this line
      shared: true,
      admin: user.uid,
      sharedUsers: sharedUsers,
      publicLinkId: "", // Add this line
      publicLinkEnabled: false, // Add this line
    };

    const driveRef = drive_service.getDriveRef(user.uid);
    const docRef = await driveRef.add(newItem);

    setItems([...items, { ...newItem, id: docRef.id }]);
    return docRef.id; // Return the folderId here
  };
};
export const useOpenSharedItem = (setCurrentPath, currentPath, rootPath) => {
  console.log("useOpenSharedItem");

  return (item) => {
    if (item.type === "folder") {
      // Calculate the new path based on the item's relative path and the folder's name
      //   const newPath = `${rootPath}${item.relativePath}/${item.name}`;
      //   const newPath = [rootPath, item.relativePath, item.name].join("/");
    //   const testPath = "/studio/LArRv8j7TpfyvtJZPVCocQqT6Jw2/drive/38iu14SzlvIBe6xqxS02/New Shared Folder 1681987674082"
    //   const newPath = rootPath + "/" + item.name;
      setCurrentPath(`${item.path}/${item.name}`);

    } else {
      alert(`Opening file: ${item.name}`);
    }
  };
};

/*




````````````


*/

// Helper function to get the relative path of the shared folder
const getSharedFolderRelativePath = (fullPath, userId) => {
  return `/studio/${userId}/drive${fullPath.substring(
    fullPath.indexOf("/", 1)
  )}`;
};

// Helper function to get shared folder reference
const getSharedFolderRef = async (publicLinkId) => {
  const publicLinksRef = firebase.firestore().collection("public_links");
  const publicLinkDoc = await publicLinksRef.doc(publicLinkId).get();

  if (publicLinkDoc.exists) {
    const { userId, folderId } = publicLinkDoc.data();
    const driveRef = drive_service.getDriveRef(userId);
    const folderRef = driveRef.doc(folderId);
    console.log("folderRef: ", folderRef.path);

    return folderRef;
  } else {
    console.log("No such publicLinkId found");
    return null;
  }
};

// Helper function to fetch shared folder by publicLinkId
const fetchSharedFolderByPublicLinkId = async (publicLinkId, setRootPath) => {
  const sharedFolderRef = await getSharedFolderRef(publicLinkId);

  if (sharedFolderRef) {
    const sharedFolderDoc = await sharedFolderRef.get();
    //   const fullPath = sharedFolderDoc.data().path;
    // folderRef.path = + folderRef.path;
    const fullPath = "/" + sharedFolderRef.path;
    console.log("fullPath: ", fullPath);
    const userId = sharedFolderDoc.data().userId;
    console.log("userId: ", userId);
    // const relativePath = getSharedFolderRelativePath(fullPath, userId);

    setRootPath(fullPath);
  }

  return sharedFolderRef;
};

// Helper function to create shared folder items
const createSharedFolderItems = (snapshot) => {
  return snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
};

// Helper function to fetch shared folders for the current user
const fetchSharedFoldersForUser = (user) => {
  const driveRef = drive_service.getDriveRef(user.uid);
  return driveRef.where("sharedUsers", "array-contains", user.uid).get();
};

export const useFetchSharedFolders = (
  setSharedItems,
  setRootPath,
  publicLinkId = null
) => {
  const [user] = useAuthState(auth);

  useEffect(() => {
    if (!user && !publicLinkId) {
      console.log("No user and no publicLinkId, exiting useEffect.");
      return;
    }

    let unsubscribe;

    const fetchSharedFolders = async () => {
      if (publicLinkId) {
        console.log("Using publicLinkId:", publicLinkId);

        const sharedFolderRef = await fetchSharedFolderByPublicLinkId(
          publicLinkId,
          setRootPath
        );

        if (sharedFolderRef) {
          unsubscribe = sharedFolderRef.onSnapshot((snapshot) => {
            const item = { id: snapshot.id, ...snapshot.data() };
            setSharedItems([item]);
          });
        }
      } else {
        console.log("Fetching shared folders for user:", user.uid);

        const snapshot = await fetchSharedFoldersForUser(user);
        const items = createSharedFolderItems(snapshot);
        setSharedItems(items);
      }
    };

    fetchSharedFolders();

    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [user, setSharedItems, publicLinkId]);
};

/*








*/

export const addUserToSharedFolder = async (userId, folderId, newUserId) => {
  const driveRef = drive_service.getDriveRef(userId);
  const folderRef = driveRef.doc(folderId);

  const folderSnapshot = await folderRef.get();
  const folderData = folderSnapshot.data();

  if (folderData.admin === userId) {
    await folderRef.update({
      sharedUsers: firebase.firestore.FieldValue.arrayUnion(newUserId),
    });
  } else {
    throw new Error("Only the admin can add users to the shared folder.");
  }
};

export const removeUserFromSharedFolder = async (
  userId,
  folderId,
  targetUserId
) => {
  const driveRef = drive_service.getDriveRef(userId);
  const folderRef = driveRef.doc(folderId);

  const folderSnapshot = await folderRef.get();
  const folderData = folderSnapshot.data();

  if (folderData.admin === userId) {
    await folderRef.update({
      sharedUsers: firebase.firestore.FieldValue.arrayRemove(targetUserId),
    });
  } else {
    throw new Error("Only the admin can remove users from the shared folder.");
  }
};

// '(from: number, length?: number | undefined): string' is deprecated.ts(6385)
// lib.es5.d.ts(509, 8): The declaration was marked as deprecated here.
// (method) String.substr(from: number, length?: number | undefined): string
export const generatePublicLinkId = () => {
  return Math.random().toString(36).substr(2, 9);
};
export const useActivatePublicLink = () => {
  const [user] = useAuthState(auth);

  const activatePublicLink = async (folderId) => {
    console.log("Activating public link for folder:", folderId);

    if (!user || !folderId) {
      console.error("Invalid user or folderId");
      return undefined;
    }

    console.log("User is authenticated:", user.uid);

    const userId = user.uid;
    const driveRef = drive_service.getDriveRef(userId);
    console.log("Retrieved driveRef:", driveRef.path);
    const folderRef = driveRef.doc(folderId);
    const fullPath = folderRef.path;
    console.log("Retrieved folderRef:", fullPath);
    const folderSnapshot = await folderRef.get();
    console.log("Retrieved folderSnapshot:", folderSnapshot.data());

    const folder = folderSnapshot.data();
    if (!folder) {
      console.error("Folder not found");
      return undefined;
    }

    const { admin, shared, publicLinkId } = folder;

    if (admin !== userId) {
      console.error("User is not the admin of this folder");
      return undefined;
    }

    if (!shared) {
      console.error("This folder is not shared");
      return undefined;
    }

    if (publicLinkId) {
      console.log("Public link is already activated");
      return publicLinkId;
    }

    const publicLinksRef = firebase.firestore().collection("public_links");
    console.log("Retrieved publicLinksRef:", publicLinksRef.path);
    const newPublicLinkRef = await publicLinksRef.add({
      folderId: folderId,
      userId: userId,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
    });
    console.log("Created new public link:", newPublicLinkRef.path);
    const newPublicLinkId = newPublicLinkRef.id;
    await folderRef.update({
      publicLinkId: newPublicLinkId,
      publicLinkEnabled: true, // Add this line to enable the public link
    });
    console.log("Public link activated:", newPublicLinkId);
    return newPublicLinkId;
  };

  return activatePublicLink;
};

export const deactivatePublicLink = async (userId, folderId) => {
  const driveRef = drive_service.getDriveRef(userId);
  const folderRef = driveRef.doc(folderId);

  const folderSnapshot = await folderRef.get();
  const folderData = folderSnapshot.data();

  if (folderData.admin === userId) {
    await folderRef.update({
      publicLinkId: null,
      publicLinkEnabled: false, // Add this line to disable the public link
    });
  } else {
    throw new Error("Only the admin can deactivate the public link.");
  }
};
const serverRootURL = "https://airtrak.app";
export const useCreateOrCopySharedLink = (item) => {
  const [user] = useAuthState(auth);

  return async () => {
    if (!user) return;

    const driveRef = drive_service.getDriveRef(user.uid);
    const itemRef = driveRef.doc(item.id);

    // If the item has a public link, copy it to the clipboard
    if (item.publicLinkId && item.publicLinkEnabled) {
    //   const publicLink = `http://airtrak.herokuapp.com/shared/${item.publicLinkId}`;
        const publicLink = `${serverRootURL}/shared_folders/${item.publicLinkId}`;
      // navigator.clipboard.writeText(publicLink);
      copyToClipboard(publicLink);
      alert("Shared link copied to clipboard");
    } else {
      // Otherwise, create a new public link for the item
      const publicLinksRef = firebase.firestore().collection("public_links");
      const newPublicLink = {
        userId: user.uid,
        folderId: item.id,
      };

      const docRef = await publicLinksRef.add(newPublicLink);

      // Update the item with the new public link ID
      await itemRef.update({
        publicLinkId: docRef.id,
        publicLinkEnabled: true,
      });

    //   const publicLink = `http://localhost:3000/shared/${docRef.id}`;
    const publicLink = `${serverRootURL}/shared_folders/${docRef.id}`;
      // navigator.clipboard.writeText(publicLink);

      copyToClipboard(publicLink);
      alert("Shared link created and copied to clipboard");
    }
  };
};
const copyToClipboard = (text) => {
  // Create a temporary textarea element
  const tempTextarea = document.createElement("textarea");

  // Set the value of the textarea to the text to be copied
  tempTextarea.value = text;

  // Append the textarea to the document
  document.body.appendChild(tempTextarea);

  // Select the text in the textarea
  tempTextarea.select();
  tempTextarea.setSelectionRange(0, 99999); // For mobile devices

  // Copy the selected text
  try {
    const successful = document.execCommand("copy");
    if (successful) {
      console.log("Copied to clipboard:", text);
    } else {
      console.error("Could not copy text.");
    }
  } catch (err) {
    console.error("Could not copy text:", err);
  }

  // Remove the temporary textarea from the document
  document.body.removeChild(tempTextarea);
};
