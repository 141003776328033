// ProgressBar.jsx
import React from "react";

const ProgressBar = ({ progress, uploadComplete }) => {
    if (progress > 0 && progress < 100) {
        return (
            <div className="flex flex-col items-center justify-center">
                <div className="w-full bg-gray-200 h-2">
                    <div
                        className="bg-blue-600 h-2"
                        style={{ width: `${progress}%` }}
                    ></div>
                </div>
                <div className="text-sm mt-2">Uploading... {progress}%</div>
            </div>
        );
    } else if (uploadComplete) {
        return (
            <div className="flex flex-col items-center justify-center">
                <p className="text-sm text-green-500">Upload Complete!</p>
            </div>
        );
    } else {
        return null;
    }
};

export default ProgressBar;