// LoadingSkeleton.jsx
// LoadingSkeleton.jsx
import React from 'react';

const LoadingSkeleton = () => {
  return (
    <div className="animate-pulse space-y-4">
      <div className="h-8 bg-gray-700 rounded"></div>
      <div className="h-8 bg-gray-700 rounded"></div>
      <div className="h-8 bg-gray-700 rounded"></div>
      {/* Repeat the above divs as many times as needed to represent the content being loaded */}
    </div>
  );
};

export default LoadingSkeleton;
