// CreateProductView.jsx;
import React, {
  useState,
  useEffect,
  useParams,
  useContext,
  useRef,
} from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  useLocation,
  useNavigate,
} from "react-router-dom";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";

import APIConfig from "../../../APIConfig";
import ProductForm from "../../buttons/ProductForm";
import GridContainer from "../../themes/GridContainer";
import merch from "../../../services/merch";
const CreateProductView = () => {
  let location = useLocation();
  //(location);
  const [merchItem, setMerchItem] = useState({});
  const [isLoading, setLoading] = useState(true);
  let history = useNavigate();
  // const [showingUploader, showUploader] = useState(false);

  useEffect(() => {
    // page gets refreshed... get most recent draft
    if (location.merchItem == null) {
      var draft = merch.getRecentDrafts().then((merchItem) => {
        // //("the res: ", res);
        setMerchItem(merchItem);
        setLoading(false);
      });
      // storage.getLatest();
    } else {
      //setting new album
      setMerchItem(location.merchItem);
      setLoading(false);
    }
  }, []);
  function newMerch() {
    firebase.auth().onAuthStateChanged(function (user) {
      if (user) {
        //("signed in");
        firebase
          .auth()
          .currentUser.getIdToken(/* forceRefresh */ true)
          .then(function (token) {
            // const album_data = { draft_uid: draft_uid, artist_uid: user.uid };
            const album_data = { asd: "asdas" };
            const d = JSON.stringify(album_data);
            fetch(`${APIConfig.merch()}new/`, {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: "JWT " + token,
              },
              body: d,
            }).then(
              function (res) {
                res.json().then(function (data) {

                });
              },
              function (value) {
              }
            );
          });
      }
    });
  }
  return (
    <div>
      <GridContainer className="center">
        <div>
          {isLoading ? (
            "loading... "
          ) : (
            <ProductForm merchItem={merchItem}></ProductForm>
          )}
        </div>
      </GridContainer>
    </div>
  );
};

export default CreateProductView;
