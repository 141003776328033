import React, { useEffect, useState } from "react";
import APIConfig from "../../APIConfig";

const url = APIConfig.testflight();

const BetaDownloadButtonView = () => {
  const [isDarkMode, setIsDarkMode] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    const matchMedia = window.matchMedia("(prefers-color-scheme: dark)");
    setIsDarkMode(matchMedia.matches);

    const handleChange = (e) => {
      setIsDarkMode(e.matches);
    };

    matchMedia.addEventListener("change", handleChange);

    return () => {
      matchMedia.removeEventListener("change", handleChange);
    };
  }, []);

  function handleClick() {
    window.open(url, "_blank");
  }

  const appleLogo = isDarkMode
    ? isHovered
      ? "https://upload.wikimedia.org/wikipedia/commons/f/fa/Apple_logo_black.svg"
      : "https://upload.wikimedia.org/wikipedia/commons/3/31/Apple_logo_white.svg"
    : isHovered
    ? "https://upload.wikimedia.org/wikipedia/commons/3/31/Apple_logo_white.svg"
    : "https://upload.wikimedia.org/wikipedia/commons/f/fa/Apple_logo_black.svg";

  return (
    <div className="flex justify-center w-full md:w-auto my-4">
      <button
        className="flex items-center border-2 border-black dark:border-white rounded-md bg-white dark:bg-black hover:bg-black dark:hover:bg-white text-black dark:text-white hover:text-white dark:hover:text-black font-theFutureMonoRegular py-2 px-6 focus:outline-none focus:shadow-outline transition duration-300 ease-in-out"
        type="button"
        onClick={handleClick}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <img
          className="w-6 mr-3"
          src={appleLogo}
          alt="apple logo"
        />
        <span className="text-sm font-theFutureMonoRegular">Download the beta</span>
      </button>
    </div>
  );
};

export default BetaDownloadButtonView;