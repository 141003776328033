// import AudioPlayer from 'react-h5-audio-player';
// import React from "react";
// import 'react-h5-audio-player/lib/styles.css';
// import 'react-h5-audio-player/lib/styles.less' Use LESS
// import 'react-h5-audio-player/src/styles.scss' Use SASS

// const Player = ({song_url}) => (
  
  //   (song_url != null) ? <AudioPlayer
  //     autoPlay
  //     src={song_url}
  //     onPlay={e => console.log("onPlay")}
  //   // other props here
  //   /> : null
  
  
  // );
  
  // export default Player;
  
  import AudioPlayer from 'react-modern-audio-player';
  const dummyURL = "https://www.soundhelix.com/examples/mp3/SoundHelix-Song-1.mp3";

  const playList = [
    {
      name: 'name',
      writer: 'writer',
      img: 'image.jpg',
      src: dummyURL,
      id: 1,
    },
  ]
  function Player (){
    return (
      <AudioPlayer playList={playList} />
    )
  }

  export default Player;

// import AudioPlayer from 'react-modern-audio-player';

// const playList = [
//   {
//     name: 'name',
//     writer: 'writer',
//     img: 'image.jpg',
//     src: 'audio.mp3',
//     id: 1,
//   },
// ]
// function Player (){
// 	return (
// 		<AudioPlayer playList={playList} />
// 	)
// }