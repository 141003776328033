// src/components/Draft.js
import React from 'react';
import Album from './Album'; // Import the Album component

const Draft = () => {
  return (
    <div className="container mx-auto p-4 bg-white dark:bg-gray-800 text-gray-900 dark:text-gray-200">
      <h2 className="text-2xl font-bold mb-4">Drafting Area</h2>
      <div className="bg-white dark:bg-gray-700 shadow rounded p-4">
        <h3 className="text-xl mb-2">Draft Albums</h3>
        <Album />
      </div>
      {/* Future draft sections can be added here */}
    </div>
  );
};

export default Draft;
