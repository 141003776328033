// TapeView.jsx

import React from "react";
import FileSystemContainer from "../../themes/FileSystemContainer";
import StandardButton from "../../buttons/StandardButton";
import GetShareLinkButton from "./GetShareLinkButton";
import ShareLinkCopyView from "../../account/ShareLinkCopyView";
import MultiPlayerView from "./mtr_player/MultiPlayerView";
import TracksView from "./TracksView";

const TapeView = ({ tape, tracks }) => {
  const tapeLinkView = tape.public_url ? (
    <ShareLinkCopyView link={tape.public_url} />
  ) : (
    <GetShareLinkButton tape={tape} />
  );

  return (
    <div>
      <h2 className="font-sans font-bold text-xl mb-2">{tape.name}</h2>
      <p className="font-sans text-sm mb-4">{tape.description}</p>
      <TracksView tape={tape} tracks={tracks} />
      {tapeLinkView}
    </div>
  );
};

export default TapeView;
