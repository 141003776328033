// User.js;
// @flow.
import React, {
    useState,
    useEffect,
    useParams,
    useContext,
    useRef,
  } from "react";
  import storage from "../../../services/storage";
  import CreateAlbumView from "./album_creator/CreateAlbumView";
  import {
    BrowserRouter as Router,
    Routes,
    Route,
    Link,
    useLocation,
    useNavigate,
  } from "react-router-dom";
  import StandardButton from "../../buttons/StandardButton";
  import Uploader from "../../file_mgmt/uploader/Uploader";
  import mastering from "../../../services/mastering";
import CreateCassetteView from "./CreateCassetteView";
  
  function CassetteDraftEditView() {
    let location = useLocation();
    //(location);
    const [album, setAlbum] = useState({});
    const [isLoading, setLoading] = useState(true);
    let history = useNavigate();
    const [showingUploader, showUploader] = useState(false);
  
    function uploadAudio() {
      if (showingUploader == true) {
        return (
          <div>
            <Uploader></Uploader>
            <StandardButton mxt_red onClick={() => showUploader(false)}>
              close
            </StandardButton>
          </div>
        );
      } else {
        return (
          <StandardButton mxt_green onClick={() => showUploader(true)}>
            upload audio
          </StandardButton>
        );
      }
    }
    async function finalize() {
      var _album = await storage.validateDraft(album);
  
      if (_album.isValid) {
        history.push({
          pathname: "/publish/album",
          album: _album,
        });
      } else {
        alert("finish creating the album first");
      }
    }
  
    async function master() {
      var _album = await mastering.validateMaster(album);
  
      if (_album.isValid) {
        history.push({
          pathname: "/analog/master",
          album: _album,
        });
      } else {
        alert("finish creating the album first");
      }
    }
    useEffect(() => {
      // page gets refreshed... get most recent draft
      if (location.album == null) {
        var draft = storage.getRecentDrafts().then((album) => {
          // //("the res: ", res);
          setAlbum(album);
          setLoading(false);
        });
        // storage.getLatest();
      } else {
        //setting new album
        setAlbum(location.album);
        setLoading(false);
      }
    }, []);
    //todo: if empty get most recent draft
    return (
      <div>
<h2>cassette draft</h2>
        <div>{isLoading ? "loading... " : <iew album={album} />}</div>
        {/* <StandardButton mxt_primary onClick={() => master()}>
          analog master
        </StandardButton> */}
  
        <StandardButton onClick={() => finalize()}>NEXT</StandardButton>
      </div>
    );
  }
  
  export default CassetteDraftEditView;
  