import React from "react";
import { useLocation } from "react-router-dom";
import APIConfig from "../../../APIConfig";
import NavLinkItem from "./NavLinkItem";
import ProfileMenu from "./ProfileMenu";
import LegalMenu from "../../account/legal/LegalMenu";

const MenuItems = ({ user, altAuthPath, isRoot }) => {
  const location = useLocation();
  const onEventPage = location.pathname.startsWith("/e/");
  const showKonsoleButton = user && (APIConfig.airtrak() || APIConfig.studio());

  return (
    <div className="flex items-center space-x-4">
      {/* <LegalMenu /> */}
      {user && <ProfileMenu user={user} />}
      {!user && (isRoot || onEventPage) && (
        <NavLinkItem to="/signin">sign in</NavLinkItem>
      )}
      {!user && altAuthPath && !isRoot && !onEventPage && (
        <NavLinkItem to={altAuthPath.path}>{altAuthPath.text}</NavLinkItem>
      )}
    </div>
  );
};

export default MenuItems;
