// useHandleTrim.js
// hooks/useHandleTrim.js

import { useCallback, useState } from "react";
import { useContext } from "react";

import audioBufferToWav from "audiobuffer-to-wav";
import { useUploadFile } from "./useStorageHooks";
import UploadContext from "../contexts/UploadContext";
import PathContext from "../contexts/PathContext";

const removeFileExtension = (filename) => {
  const lastDotIndex = filename.lastIndexOf(".");
  return lastDotIndex === -1 ? filename : filename.slice(0, lastDotIndex);
};

const useHandleTrim = (wavesurferObj, itemName) => {
  const [trimmedAudioBuffer, setTrimmedAudioBuffer] = useState(null);
  const [hasStartedTrimming, setHasStartedTrimming] = useState(false);
  const [uploadFile] = useUploadFile();

  const { setUploadProgress, setUploadStatus } = useContext(UploadContext);

  const handleTrim = useCallback(() => {
    let newBuffer = null;

    if (wavesurferObj) {
      // get start and end points of the selected region
      const region =
        wavesurferObj.regions.list[Object.keys(wavesurferObj.regions.list)[0]];

      if (region) {
        const start = region.start;
        const end = region.end;

        // obtain the original array of the audio
        const original_buffer = wavesurferObj.backend.buffer;

        // create 2 indices:
        // left & right to the part to be trimmed
        const first_list_index = start * original_buffer.sampleRate;
        const second_list_index = end * original_buffer.sampleRate;
        const second_list_mem_alloc =
          original_buffer.length - end * original_buffer.sampleRate;

        const newBufferLength =
          parseInt(first_list_index) + parseInt(second_list_mem_alloc);

        // create a temporary new buffer array with the new length, sample rate, and number of channels as the original audio
        const new_buffer = wavesurferObj.backend.ac.createBuffer(
          original_buffer.numberOfChannels,
          newBufferLength,
          original_buffer.sampleRate
        );

        // create a new array upto the region to be trimmed
        const new_list_left = new Float32Array(parseInt(first_list_index));
        const new_list_right = new Float32Array(parseInt(first_list_index));

        // create a new array of region after the trimmed region
        const second_list_left = new Float32Array(
          parseInt(second_list_mem_alloc)
        );
        const second_list_right = new Float32Array(
          parseInt(second_list_mem_alloc)
        );

        // create an array to combine the 2 parts
        const combined_left = new Float32Array(newBufferLength);
        const combined_right = new Float32Array(newBufferLength);

        // 2 channels: 1-right, 0-left
        // copy the buffer values for the 2 regions from the original buffer

        // for the region to the left of the trimmed section
        original_buffer.copyFromChannel(new_list_left, 0, 0, first_list_index);
        original_buffer.copyFromChannel(new_list_right, 1, 0, first_list_index);

        // for the region to the right of the trimmed section
        original_buffer.copyFromChannel(
          second_list_left,
          0,
          second_list_index,
          second_list_mem_alloc
        );
        original_buffer.copyFromChannel(
          second_list_right,
          1,
          second_list_index,
          second_list_mem_alloc
        );
        // create the combined buffer for the trimmed audio
        combined_left.set(new_list_left);
        combined_left.set(second_list_left, first_list_index);
        combined_right.set(new_list_right);
        combined_right.set(second_list_right, first_list_index);

        // copy the combined array to the new_buffer
        new_buffer.copyToChannel(combined_left, 0);
        new_buffer.copyToChannel(combined_right, 1);

        // load the new_buffer, to restart the wavesurfer's waveform display
        wavesurferObj.loadDecodedBuffer(new_buffer);

        newBuffer = new_buffer;
      }
    }
    setTrimmedAudioBuffer(newBuffer);
    setHasStartedTrimming(true);
  }, [wavesurferObj]);

  const downloadTrimmedAudio = useCallback(() => {
    if (trimmedAudioBuffer) {
      const wav = audioBufferToWav(trimmedAudioBuffer, {
        float32: true,
        sampleRate: 48000,
        bitDepth: 32,
      });
      const blob = new Blob([new DataView(wav)], { type: "audio/wav" });
      const url = URL.createObjectURL(blob);
      const anchor = document.createElement("a");
      anchor.href = url;
      anchor.download = `${removeFileExtension(itemName)}_trimmed.wav`;
      anchor.click();
      URL.revokeObjectURL(url);
    }
  }, [trimmedAudioBuffer, itemName]);
  const uploadTrimmedAudio = useCallback(async () => {
    if (trimmedAudioBuffer) {
      const wav = audioBufferToWav(trimmedAudioBuffer);
      const blob = new Blob([new DataView(wav)], { type: "audio/wav" });

      // Create a File object from the Blob
      // add the date to filename to make it unique
      const date = new Date();
      const dateString = date.toISOString().slice(0, 10);
      const filename = `${removeFileExtension(
        itemName
      )}_trimmed_${dateString}.wav`;
      const file = new File([blob], filename, { type: "audio/wav" });

      // Call the uploadFile function from the useUploadFile hook
      await uploadFile(file, (progress) => {
        setUploadProgress(progress);
        setUploadStatus("uploading");
      });
    }
  }, [
    trimmedAudioBuffer,
    itemName,
    uploadFile,
    setUploadProgress,
    setUploadStatus,
  ]);

  // return { handleTrim, downloadTrimmedAudio, uploadTrimmedAudio };
  return {
    handleTrim,
    downloadTrimmedAudio,
    uploadTrimmedAudio,
    hasStartedTrimming,
  }; // Add hasStartedTrimming to the returned object
};
export default useHandleTrim;
