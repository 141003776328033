import React, { useContext } from 'react';
import AudioPlayerContext from '../../../../file_mgmt/drive/AudioPlayerContext';

const TrackList = ({ tracks }) => {
  const { playAudio } = useContext(AudioPlayerContext); // Use the context

  const handleTrackClick = (track) => {
    console.log("track", track)
    const audioSrc = {
      title: track.name,
      artist: track.artist, // Or any other detail you wish to include
      audioSrc: track.compressed_file,
      // Include other properties if needed, like color, image, etc.
    };
    console.log("audioSrc", audioSrc)
    playAudio(audioSrc); // Play the track using AudioPlayer
  };

  return (
    <div className="pl-4"> {/* Indentation for tree-like structure */}
      {tracks.map((track, index) => (
        <div 
          key={track.id} 
          onClick={() => handleTrackClick(track)} 
          className="flex items-center cursor-pointer"
        >
          <span className="mr-2">{index + 1}.</span>
          <div>{track.name}</div>
        </div>
      ))}
    </div>
  );
};

export default TrackList;
