// EmptyState.js
import React from "react";
import { FolderOpenIcon } from "@heroicons/react/24/outline";

const EmptyState = () => {
  return (
    <div className="flex flex-col items-center justify-center space-y-4 mt-10">
      <FolderOpenIcon className="w-24 h-24 text-gray-400" />
      <h2 className="text-xl font-semibold">No items in this folder</h2>
      <p className="text-gray-600">Add files or folders to get started</p>
    </div>
  );
};

export default EmptyState;
