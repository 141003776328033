// components/FolderTree.js
import { useAuthState } from "react-firebase-hooks/auth";
import firebase from "firebase/compat/app";
import FolderNode from "./FolderNode";
import FolderBranch from "./FolderBranch";
import { useFolderStructure } from "../../hooks/useDriveHooks";
import React, { useState } from "react";
// components/FolderTree.js
// components/FolderTree.js
// components/FolderTree.js
// FolderTree.js
const FolderTree = ({ onSelect }) => {
  const auth = firebase.auth();
  const [user] = useAuthState(auth);
  const folderStructure = useFolderStructure();
  const [selectedId, setSelectedId] = useState(null);
  const [selectedNode, setSelectedNode] = useState(null);

  const handleSelect = (node) => {
    console.log("Selected id at foldertree:", node.data.id);
    setSelectedId(node.data.id);
    setSelectedNode(node); // Set the selected node state
    onSelect(node);
  };

  return (
    <div className="">
      {user &&
        folderStructure.map((child) => (
          <FolderBranch
            key={child.id}
            node={child}
            onSelect={handleSelect}
            selectedId={selectedId}
          />
        ))}
    </div>
  );
};


export default FolderTree;
