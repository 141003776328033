// TrackNameEditor.jsx

import React, { useState } from 'react';

const TrackNameEditor = ({ trackName, onSave, onCancel }) => {
    const [newTrackName, setNewTrackName] = useState(trackName);

    const handleSave = () => onSave(newTrackName);
    const handleChange = (e) => setNewTrackName(e.target.value);

    return (
        <div className="flex flex-col w-full">
            <input
                type="text"
                value={newTrackName}
                onChange={handleChange}
                className="p-1 mb-2 rounded border bg-gray-700 text-white"
                autoFocus
            />
            <div className="flex justify-end space-x-2">
                <button className="bg-green-500 text-white p-1 rounded" onClick={handleSave}>
                    Done
                </button>
                <button className="bg-gray-500 text-white p-1 rounded" onClick={onCancel}>
                    Cancel
                </button>
            </div>
        </div>
    );
};

export default TrackNameEditor;