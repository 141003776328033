import { Formik, Form, useField } from "formik";
import React, { useState } from "react";
import MerchFormInput from "../dashboard/MerchFormInput";

const MerchPricingBox = ({ status }) => {
  //   const [shipsIntl, setShipping] = useState(status);

  return (
    <div>
      <br className="padded"></br>
      <h1 className="padded-5"> pricing</h1>
      <div className="padded-5 bordered-light">
        <div>
          <div className="padded">
            <MerchFormInput
              className="bordered-light padded-5"
              name="price"
              type="number"
              placeholder=""
            ></MerchFormInput>{" "}
            USD
            <div className="padded-5">
              payments will go to your linked stripe account
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MerchPricingBox;
