// hooks/usePreloadAdjacentImages.js
import { useEffect, useMemo } from 'react';

const usePreloadAdjacentImages = (currentIndex, memories) => {
  const imagesToPreload = useMemo(() => {
    let urls = [];
    if (currentIndex > 0) urls.push(memories[currentIndex - 1].downloadURL);
    if (currentIndex < memories.length - 1) urls.push(memories[currentIndex + 1].downloadURL);
    return urls;
  }, [currentIndex, memories]);

  useEffect(() => {
    imagesToPreload.forEach(src => {
      if (src) {
        const image = new Image();
        image.src = src;
      }
    });
  }, [imagesToPreload]);
};

export default usePreloadAdjacentImages;
