import React, { useState } from "react";

const WideButton = (props) => {
  const [loading, setLoading] = useState(false);

  const handleClick = () => {
    setLoading(true);
    props.lambda();
  };

  const buttonClasses = `
    flex items-center justify-center px-6 py-3 w-full font-bold font-mono text-black dark:text-white 
    bg-gray-200 dark:bg-black border-2 border-black dark:border-white 
    transition duration-200 hover:bg-black hover:text-white dark:hover:bg-white dark:hover:text-black
  `;

  return (
    <button onClick={handleClick} className={buttonClasses}>
      {loading ? "Loading..." : props.text}
    </button>
  );
};

export default WideButton;
