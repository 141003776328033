// auth.js

import firebase from "firebase/compat/app";
import "firebase/compat/auth";

import APIConfig from "../APIConfig";
import utils from "../components/artist_dashboard/utilities/utils";
import { authMethods } from "../firebase/authmethods";

// import { authMethods } from "./firebase/authmethods";

// import { authMethods } from "../../firebase/authmethods";
const token = localStorage.getItem("token");
// const b = APIConfig.baseURL();
// ${APIConfig.baseURL()}
// const base = APIConfig.baseURL()
let db = firebase.firestore();
var user = firebase.auth().currentUser;
const authUser = Object.keys(window.localStorage).filter((item) =>
  item.startsWith("firebase:authUser")
)[0];

async function getUID() {
  var promise = new Promise((resolve, reject) => {
    firebase.auth().onAuthStateChanged(function (user) {
      if (user) {
        firebase
          .auth()
          .currentUser.getIdToken(true)
          .then(function (idToken) {
            //   setToken(idToken);
            resolve(user.uid);
          });
      }
    });
  });

  return promise;
}

const me = async (token) => {
  const url = APIConfig.me();
  // unwrap token promise
  const t = await token;
  console.log("token : ");
  const r = await fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `JWT ${t}`,
    },
  });
  const data = await r.json();
  const username = data.username;
  console.log("username", username);
  return data;
};

const checkUsername = async (username) => {
  // const uid = await getUID();
  console.log("username", username);
  const url = `${APIConfig.apiURI}profiles/check_username/`;
  const body = {
    username: username,
  };
  const headers = await getAuthHeaders();
  const response = await fetch(url, {
    method: "POST",
    headers,
    body: JSON.stringify(body),
  });
  const data = await response.json();
  console.log("data", data);
  return data;
};
const updateUsername = async (username, token) => {
  const currUser = firebase.auth().currentUser;
  const parameters = {
    username: username,
    fs_uid: currUser.uid,
  };
  const url = `${APIConfig.apiURI}profiles/update_username_v2/`;
  const headers = await getAuthHeaders();
  const response = await fetch(url, {
    method: "POST",
    headers,
    body: JSON.stringify(parameters),
  });
  const data = await response.json();
  console.log("data", data);
  return data;
};
const updateName = async (firstName, lastName, token) => {
  const currUser = firebase.auth().currentUser;
  const parameters = {
    first_name: firstName,
    last_name: lastName,
    fs_uid: currUser.uid,
  };
  const url = `${APIConfig.apiURI}profiles/update_first_and_last_name/`;
  const headers = await getAuthHeaders();
  const response = await fetch(url, {
    method: "POST",
    headers,
    body: JSON.stringify(parameters),
  });
  const data = await response.json();
  console.log("data", data);
  return data;
};

async function getUserToken() {
  const user = firebase.auth().currentUser;
  if (user) {
    return await user.getIdToken(true);
  }
  return null;
}

async function getAuthHeaders() {
  const token = await getUserToken();
  if (token) {
    return {
      "Content-Type": "application/json",
      Authorization: `JWT ${token}`,
    };
  }
  return {
    "Content-Type": "application/json",
  };
}
const auth = {
  getUID,
  checkUsername,
  updateUsername,
  updateName,
  me,
  getUserToken,
  getAuthHeaders,
};

export default auth;
