// drive_service.js
// import APIConfig from "./APIConfig";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";

const db = firebase.firestore();

function getDriveRef(user_uid) {
  const tape_ref = db.collection("studio").doc(user_uid).collection("drive");
  return tape_ref;
}
// Add this function inside useUploadFile, but before the uploadFile function
const getUserStorageRef = (userId) => {
  return db.collection("studio").doc(userId).collection("usage").doc("storage");
};


const drive_service = { getDriveRef, getUserStorageRef };

export default drive_service;
