import React, { useHook, useEffect, useState, useContext } from "react";
import { useParams } from "react-router-dom";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
// useauth
import storage from "../../services/storage";
import Container from "../themes/Container";
import FileSystemContainer from "../themes/FileSystemContainer";
import AuthProvider from "../../provider/AuthProvider";
import { useAuthState } from "react-firebase-hooks/auth";
import { getAuth, signOut } from "firebase/auth";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentTrack } from "../../actions/trackActions";

const auth = getAuth(firebase.app());
function DriveFilesView() {
  const [user, loading, error] = useAuthState(auth);
  // redux stuff
  const currentTrack = useSelector((state) => state.currentTrack);
  const dispatch = useDispatch();
  const play = (track) => {
    dispatch(setCurrentTrack(track));
  };

  const [files, setFiles] = useState([]);
  let { slug } = useParams();
  let db = firebase.firestore();

  const fetchData = async (uid) => {
    var files_ref = db.collection("studio").doc(uid).collection("files");
    files_ref.onSnapshot(function (querySnapshot) {
      console.log("querySnapshot: ", querySnapshot);
      var files = [];
      querySnapshot.forEach(function (doc) {
        console.log(doc.id, " => ", doc.data());
        files.push(doc.data());
      });
      setFiles(files);
    });
  };
  useEffect(() => {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        var uid = user.uid;
        fetchData(uid);
      } else {
        AuthProvider.refreshToken();
      }
    });
  }, [user, loading, error]);

  const filesView = () => {
    const tracks = files.map((file) => {
      return {
        "compressed_file": file.url,
        "name": file.filename,
        "artist": ""
      }
    })
    if (tracks.length === 0) {
      return null;
    } else {
      return (
        <div>
          <h1 className="font-theFutureMonoRegular ">/files</h1>
          <div className="px-10 font-theFutureMonoRegular text-blue-200 py-1">
            {tracks.map((track, index) => (
              <div className="border-2 border-blue-200">
                <button
                  className="bg-blue-200 text-blue-900 p-2"
                  onClick={() => {
                    play(track);
                  }}
                >
                  play
                </button>
                <p className="p-2" key={index}>
                  {track.name}
                </p>
              </div>
            ))}
          </div>
        </div>
      );
    }
  };

  const loginView = () => {
    return (
      <div>
        <p>Log in to view files</p>
      </div>
    );
  };

  const logout = () => {
    signOut(auth)
      .then(() => {
        // Sign-out successful.
      })
      .catch((error) => {
        // An error happened.
      });
  };

  // -------

  if (loading) {
    return (
      <div>
        <p>Initialising User...</p>
      </div>
    );
  }
  if (error) {
    return (
      <div>
        <p>Error: {error}</p>
      </div>
    );
  }
  if (user) {
    return (
      <div>
        {/* <button onClick={logout}>Log out</button> */}
        {filesView()}
      </div>
    );
  } else {
    return loginView();
  }

  // return (
  //   <FileSystemContainer className="center">
  //     <div>
  //       <h1>/files</h1>
  //       <div>
  //         {files.map((file, index) => (
  //           <p key={index}>{file}</p>
  //         ))}
  //       </div>
  //     </div>
  //   </FileSystemContainer>
  // );
}
export default DriveFilesView;
