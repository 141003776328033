import React, { useState, useEffect } from "react";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import UsernameForm from "./onboarding/UsernameForm";
import auth from "../services/auth";

function UsernameEditView() {
  const [username, setUsername] = useState("");
  const [isSignedIn, setIsSignedIn] = useState(false);

  useEffect(() => {
    const unregisterAuthObserver = firebase
      .auth()
      .onAuthStateChanged((user) => {
        setIsSignedIn(!!user);
        if (user) {
          const idTokenPromise = user.getIdToken(true);
          auth.me(idTokenPromise).then((response) => {
            if (response) {
              setUsername(response.username);
            }
          });
        }
      });
    return () => unregisterAuthObserver();
  }, []);

  const handleUsernameSubmitted = (username) => {
    console.log("Username submitted:", username);
    setUsername(username);
  };

  return (
    <div className="max-w-md mx-auto p-8 bg-gray-100 dark:bg-gray-900 font-mono">
      <h1 className="text-3xl font-bold uppercase mb-6 text-black dark:text-white border-b-2 border-black dark:border-white pb-2">
        Edit Username
      </h1>
      {isSignedIn ? (
        <div className="space-y-4">
          <p className="uppercase text-lg text-black dark:text-white">
            <span className="font-bold">Current Username:</span>{" "}
            {username || <span className="italic text-gray-500">Not set</span>}
          </p>
          <div className="">
            <UsernameForm
              user={firebase.auth().currentUser}
              onUsernameSubmitted={handleUsernameSubmitted}
            />
          </div>
        </div>
      ) : (
        <p className="text-center text-red-600">
          You must be signed in to edit your username.
        </p>
      )}
    </div>
  );
}

export default UsernameEditView;
