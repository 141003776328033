import React, { useState, useContext } from "react";
import { Draggable } from "react-beautiful-dnd";
import { PlayIcon, PauseIcon } from "@heroicons/react/24/solid";
import storage from "../../../services/storage";
import TaskActions from "./TaskActions";
import TrackNameEditor from "./TrackNameEditor";
import useTrackEditor from "./useTrackEditor";
import TrackArtistEditor from "./TrackArtistEditor";
import AudioPlayerContext from "../../file_mgmt/drive/AudioPlayerContext";
import InferTrackNameButton from "./InferTrackNameButton.jsx";

const TrackObjectCellContent = ({
  task,
  isEditing,
  startEditing,
  saveTrackName,
  cancelEditing,
  inferredTrackName,
}) =>
  isEditing ? (
    <TrackNameEditor
      trackName={inferredTrackName || task.trackName}
      onSave={saveTrackName}
      onCancel={cancelEditing}
    />
  ) : (
    <div className="cursor-pointer" onClick={startEditing}>
      <p className="font-theFutureBold text-lg">
        {inferredTrackName || task.trackName}
      </p>
      <p className="text-gray-400 text-sm py-0.5">{task.content}</p>
    </div>
  );

const TrackObjectCell = ({
  task,
  index,
  album,
  column_id,
  styleVariant = "default",
}) => {
  const { isEditing, startEditing, cancelEditing, saveTrackName } = useTrackEditor(task, album, index);
  const [editMode, setEditMode] = useState(false);
  const { playAudio, pauseAudio, playing, src } = useContext(AudioPlayerContext);

  const isPlaying = playing && src.audioSrc === task.url;

  const handlePlayPause = (event) => {
    event.stopPropagation();
    isPlaying
      ? pauseAudio()
      : playAudio({
          title: task.trackName,
          artist: task.artistName || "Unknown Artist",
          audioSrc: task.url,
        });
  };

  const cellStyle =
    styleVariant === "alternate"
      ? "bg-indigo-700 hover:bg-indigo-800"
      : "bg-gray-800 hover:bg-gray-900";
  const textStyle =
    styleVariant === "alternate" ? "text-indigo-200" : "text-gray-300";
  const draggableCellStyle = `${cellStyle} p-4 mb-3 rounded shadow transition duration-200 ease-in-out transform-gpu w-full min-w-0 min-h-[4rem]`;

  return (
    <Draggable draggableId={task.id} index={index}>
      {(provided, snapshot) => (
        <div
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
          className={`${draggableCellStyle} ${
            snapshot.isDragging ? "border-blue-500" : "border-transparent"
          } border-2`}
        >
          <div className={textStyle}>
            <div className="flex justify-between items-center">
              <div>
                <TrackObjectCellContent
                  task={task}
                  isEditing={isEditing}
                  startEditing={startEditing}
                  saveTrackName={saveTrackName}
                  cancelEditing={cancelEditing}
                />
              </div>

              {task.url && (
                <button
                  onClick={handlePlayPause}
                  className="bg-blue-500 hover:bg-blue-600 text-white rounded-full p-2"
                >
                  {isPlaying ? (
                    <PauseIcon className="h-6 w-6" />
                  ) : (
                    <PlayIcon className="h-6 w-6" />
                  )}
                </button>
              )}
            </div>

            <div className="mt-2 flex items-center justify-between">
              <TrackArtistEditor track={task} album={album} />

              {/* Use the new InferTrackNameButton */}
              <InferTrackNameButton task={task} album={album} index={index} />
            </div>

            <div className="mt-2">
              {editMode && (
                <TaskActions
                  onRename={startEditing}
                  onDelete={() => storage.deleteElement(task.id, column_id, album)}
                  track={task}
                  album={album}
                />
              )}

              <button
                onClick={() => setEditMode(!editMode)}
                className="p-1 rounded hover:bg-gray-700 bg-gray-600 text-gray-300 text-sm"
              >
                {editMode ? "Done" : "Edit"}
              </button>
            </div>
          </div>
        </div>
      )}
    </Draggable>
  );
};

export default TrackObjectCell;