// ResponsiveImageTextGroup.jsx

import imageCompression from "browser-image-compression";
import React, { useEffect, useState } from "react";
import ImageHandler from "./ImageHandler";

// takes an image and text as input and returns a responsive image/text group that is a vertical stack on mobile and a horizontal stack on desktop
// this is a component that is used in many places in the app
// it is used in the splash page, the studio plans page, and the studio plus upgrade page
// it is also used in the studio plus upgrade page
// it is written in tailwind css
// it has to be idiomatic tailwind

const ResponsiveImageTextGroup = (props) => {
  // the image and text are passed in as props
  const { image, text } = props;
  const { textColor } = props;
  const { positioning } = props;
  const { imageUnder } = props;

  // positining is either left or right

  // the image and text are passed in as props

  // the image and text are passed in as props
  const getXSBlur = () => {
    // returns backdrop-blur-xs backdrop-filter bg-opacity-20   p-10 px-10 and can be passed to a div as ${getXSBlur()}
    // if color is text-turquoise_p1-50
    // then the text will be blurred
    if (textColor === "text-turquoise_p1-50") {
      return "backdrop-blur-xs backdrop-filter bg-opacity-20  p-10 px-10";
    } else {
      return "";
    }
  };

  const getCaption = () => {
    return (
      <p
        className={`text-2xl px-2 md:text-4xl ${textColor} font-soehneBreitExtraFett ${getXSBlur()}`}
      >
        {text}
      </p>
    );
  };

  const getLeftPositioning = () => {
    return (
      <div className="flex flex-col md:flex-row p-5">
        <div className="flex flex-col items-center justify-center max-w-sm">
          <div className="flex flex-col items-center justify-center">
            {getCaption()}
          </div>
        </div>
        {getMainGroupImage()}
      </div>
    );
  };

  const getMainGroupImage = () => {
    return (
      <div className="flex flex-col items-center justify-center py-2">
        <ImageHandler image={image}></ImageHandler>
      </div>
    );
  };

  const getRightPositioning = () => {
    return (
      <div className="flex flex-col md:flex-row p-5">
        {getMainGroupImage()}

        {/* make width of text constrained and have it right margin */}
        <div className="flex flex-col items-center justify-center max-w-sm">
          {getCaption()}
        </div>
      </div>
    );
  };

  const determineIfMobile = () => {
    if (window.innerWidth < 768) {
      return true;
    } else {
      return false;
    }
  };

  const returnCorrectPositioning = () => {
    if (determineIfMobile()) {
      return getRightPositioning();
    } else {
      return positioning.overlay === "left"
        ? getLeftPositioning()
        : getRightPositioning();
    }
  };

  const getUnderlayPositioning = () => {
    // if underlay_positioning is right then return right positioning
    // if underlay_positioning is left then return left positioning
    if (positioning.underlay === "right") {
      return getImageUnderlayRight();
    } else {
      console.log("left");
      return getImageUnderlayLeft();
    }
  };
  const getImageUnderlayRight = () => {
    return (
      <div class="relative -top-44 -right-72 z-[4] flex  items-center justify-center bg-turquoise_p1-50  bg-opacity-5 rounded-xl  shadow-lg">
        <div class="w-36 md:w-36 h-0 sm:w-16">
          <ImageHandler image={imageUnder}></ImageHandler>
        </div>
      </div>
    );
  };
  const getImageUnderlayLeft = () => {
    return (
      <div class="relative -top-72 right-32 z-[4] flex  items-center justify-center bg-turquoise_p1-50  bg-opacity-5 rounded-xl  shadow-lg">
        <div class="w-36 md:w-36 h-0 sm:w-16">
          <ImageHandler image={imageUnder}></ImageHandler>
        </div>
      </div>
    );
  };
  // returns tailwind style dynamically based on the positioning prop
  // const getTailwindColor Based on enum input
  const getTailwindColor = (colorEnum) => {
    switch (colorEnum) {
      case "green":
        return "bg-lime_green_p1-300 bg-opacity-10";
      case "blue":
        return "bg-blue-300 bg-opacity-10";
      case "purple":
        return "bg-purple-300 bg-opacity-10";
      case "yellow":
        return "bg-yellow-300 bg-opacity-10";
      default:
        return "bg-lime_green_p1-300 bg-opacity-10";
    }
  };

  const getTranslucentBGStyle = () => {
    return `relative z-[5] flex  items-center justify-center ${getTailwindColor(
      textColor
    )} shadow-lg rounded-xl shadow-lg rounded-xl`;
  };

  return (
    <div>
      {/* if it is mobile then just return right positioning */}
      {/* if it is desktop then return left or right positioning depending on the positioning prop */}
      {/* get translucent bg dynamically inside `${getTailwindColor(textColor)}` */}
      {/* <div class={getTranslucentBGStyle()}> */}
      <div>
        {/* </div> */}
        <div class="relative z-[5] flex items-center justify-center ">
          {returnCorrectPositioning()}
        </div>
      </div>
      {getUnderlayPositioning()}
    </div>
  );
};

export default ResponsiveImageTextGroup;
