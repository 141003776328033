import { Routes, Route } from "react-router-dom";
import React from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import Signin from "./components/Signin";
import Home from "./components/Home";
import Settings from "./components/account/Settings";
import Dash from "./components/artist_dashboard/dashboard/Dash";
import Services from "./components/artist_dashboard/Services";
import StripeConnectRedirect from "./components/account/stripe/StripeConnectRedirect";
import ArtistPublicProfile from "./components/ArtistPublicProfile";
import Account from "./components/subscriptions/Account";
import StudioPlans from "./components/subscriptions/StudioPlans";
import DraftEditView from "./components/artist_dashboard/dashboard/DraftEditView";
import MultiStepForm from "./components/artist_dashboard/dashboard/MultiStepForm";
import FinalizeDraft from "./components/artist_dashboard/dashboard/FinalizeDraft";
import FinalizeMaster from "./components/artist_dashboard/dashboard/FinalizeMaster";
import CreateProductView from "./components/artist_dashboard/dashboard/CreateProductView";
import PublishedMerchView from "./components/artist_dashboard/merch/meta_views/PublishedMerchView";
import OrdersView from "./components/inventory/OrdersView";
import StudioPlusUpgradeView from "./components/subscriptions/StudioPlusUpgradeView";
import CassetteDraftEditView from "./components/artist_dashboard/dashboard/CassetteDraftEditView";
import LabelView from "./components/label_dashboard/LabelView";
import Splash from "./services/Splash";
import LabelArtistView from "./components/label_dashboard/LabelArtistView";
import Otari from "./components/artist_dashboard/services/Otari";
import FBSigninView from "./auth/FBSigninView";
import CreateEventView from "./components/events/create/CreateEventView.jsx";
import CreateUsernameFromEventView from "./components/events/CreateUsernameFromEventView.jsx";
import ArtistShopView from "./components/ArtistShopView";
import NameEntryForm from "./components/events/NameEntryForm";
import PhazeSplash from "./services/PhazeSplash";
import Terminal from "./components/artist_dashboard/Terminal";
import AlbumsContainer from "./containers/AlbumsContainer";
import LabelCreationView from "./components/label_dashboard/LabelCreationView";
import MTRDriveView from "./components/artist_dashboard/drive/MTRDriveView";
import DriveView from "./components/file_mgmt/drive/DriveView";
import PublicFolderView from "./components/file_mgmt/drive/PublicFolderView";
import SharedFolderViewer from "./components/file_mgmt/drive/SharedFolderViewer";
import SignupView from "./auth/signup/SignupView";
import AudioMixer from "./components/artist_dashboard/drive/mtr_player/mixer/AudioMixer";
import SubscriptionCards from "./components/file_mgmt/drive/subscription/SubscriptionCards";
import JoinWaitlistForm from "./auth/onboarding/waitlist/JoinWaitlistForm";
import MemoriesPageView from "./components/events/memories/MemoriesPageView";
import ProfileView from "./components/events/profiles/ProfileView";
import APIConfig from "./APIConfig";
import PhazeSplashPage from "./components/artist_dashboard/drive/PhazeSplashPage.jsx";
import Draft from "./components/artist_dashboard/drafting/Draft.jsx";
import ArtistRegistrationForm from "./components/forms/ArtistRegistrationForm.js";
import Konsole from "./components/artist_dashboard/Konsole.jsx";
import AlbumsComponent from "./components/mxt/music/AlbumsComponent.jsx";
import MXTSplashPage from "./components/artist_dashboard/drive/MXTSplashPage.jsx";
import ArtistSearch from "./components/search/ArtistSearch.jsx";
import LightSpinner from "./components/loading/LightSpinner.jsx";
import PhotoDetailView from "./components/events/memories/PhotoDetailView.jsx";
import EventView from "./components/events/components/EventView.jsx";
import EventsView from "./components/events/EventsView.jsx";
import CreatePostView from "./components/events/create/CreatePostView.jsx";
import ContactUs from "./components/account/legal/ContactUs.jsx";
import DMCA from "./components/account/legal/DMCA.jsx";
import TermsOfService from "./components/account/legal/TermsOfService.jsx";
import PrivacyPolicy from "./components/account/legal/PrivacyPolicy.jsx";
import CopyrightPolicy from "./components/account/legal/CopyrightPolicy.jsx";
import CommunityGuidelines from "./components/account/legal/CommunityGuidelines.jsx";
import AcceptableUsePolicy from "./components/account/legal/AcceptableUsePolicy.jsx";
import AgeRestrictionPolicy from "./components/account/legal/AgeRestrictionPolicy.jsx";
import ContentDisclaimer from "./components/account/legal/ContentDisclaimer.jsx";
import ReportContent from "./components/account/legal/ReportContent.jsx";
const audioSources = [
  {
    url: "https://www.learningcontainer.com/wp-content/uploads/2020/02/Kalimba.mp3",
    volume: 0.5,
    pan: 0,
  },
  {
    url: "https://www.learningcontainer.com/wp-content/uploads/2020/02/Kalimba.mp3",
    volume: 0.75,
    pan: -0.5,
  },
  {
    url: "https://www.learningcontainer.com/wp-content/uploads/2020/02/Kalimba.mp3",
    volume: 0.25,
    pan: 0.5,
  },
];

const Main = (token) => {
  const [user, loading, error] = useAuthState(firebase.auth());

  if (loading) {
    return <LightSpinner />; // Or any other loading indicator
  }

  if (error) {
    return <div>Error: {error.message}</div>; // Handle the error as you see fit
  }

  return (
    <Routes>
      {/* legal */}
      <Route path="/contact" element={<ContactUs />} />
        <Route path="/dmca" element={<DMCA />} />
        <Route path="/terms" element={<TermsOfService />} />
        <Route path="/privacy" element={<PrivacyPolicy />} />
        <Route path="/copyright" element={<CopyrightPolicy />} />
        <Route path="/community-guidelines" element={<CommunityGuidelines />} />
        <Route path="/acceptable-use" element={<AcceptableUsePolicy />} />
        <Route path="/age-restriction" element={<AgeRestrictionPolicy />} />
        <Route path="/disclaimer" element={<ContentDisclaimer />} />
        <Route path="/report-content" element={<ReportContent />} />
      {/* legal ^ */}
      <Route path="/shared/:publicLinkId" element={<PublicFolderView />} />
      <Route path="/albums" element={<AlbumsContainer />} />
      <Route path="/subscribe" element={<SubscriptionCards />} />
      <Route path="/draft" element={<Draft />} />
      <Route
        path="/shared_folders/:publicLinkId"
        element={<SharedFolderViewer />}
      />
      <Route path="/waitlist" element={<JoinWaitlistForm />} />
      <Route
        path="/mixer"
        element={<AudioMixer audioSources={audioSources} />}
      />
      {/* label creator */}
      <Route path="/label" element={<LabelCreationView />} />

      <Route path="/drive" element={<DriveView class="min-h-screen" />} />

      <Route
        path="/orders"
        element={token === null ? <Signin /> : <OrdersView />}
      />
      {/* artist registration */}
      <Route path="/artists/register" element={<ArtistRegistrationForm />} />
      <Route
        path="/konsole"
        element={token === null ? <Signin /> : <Konsole />}
      />
      <Route
        path="/create/album"
        element={token === null ? <Signin /> : <DraftEditView />}
      />
      <Route
        path="/create/cassette"
        element={token === null ? <Signin /> : <CassetteDraftEditView />}
      />
      <Route path="/um" element={<PhazeSplash />} />
      <Route
        path="/inventory"
        element={token === null ? <Signin /> : <PublishedMerchView />}
      />
      <Route
        path="/create/merch"
        element={token === null ? <Signin /> : <CreateProductView />}
      />
      <Route
        path="/view/artist"
        element={token === null ? <Signin /> : <LabelArtistView />}
      />
      <Route
        path="/create/otari_project"
        element={token === null ? <Signin /> : <Otari />}
      />
      <Route
        path="/publish/album"
        element={token === null ? <Signin /> : <FinalizeDraft />}
      />
      <Route
        path="/analog/master"
        element={token === null ? <Signin /> : <FinalizeMaster />}
      />
      <Route
        path="/name_entry"
        element={token === null ? <NameEntryForm /> : <NameEntryForm />}
      />
      <Route
        path="/a/:id"
        element={token === null ? <ArtistShopView /> : <ArtistShopView />}
      />
      <Route
        path="/search"
        element={token === null ? <ArtistSearch /> : <ArtistSearch />}
      />
      <Route
        path="/konsole"
        element={token === null ? <Terminal /> : <Terminal />}
      />
      <Route
        path="/a/:id"
        element={token === null ? <Signin /> : <ArtistPublicProfile />}
      />
      <Route
        path="/success"
        element={token === null ? <Signin /> : <StripeConnectRedirect />}
      />
      <Route
        path="/subscribe"
        element={token === null ? <Signin /> : <ArtistPublicProfile />}
      />
      <Route
        path="/plans"
        element={token === null ? <Signin /> : <StudioPlans />}
      />
      <Route
        exact
        path="/account"
        render={(rProps) => (token === null ? <Signin /> : <Account></Account>)}
      />
      <Route exact path="/splash" render={(rProps) => <Splash />} />

      <Route
        exact
        path="/form"
        render={(rProps) =>
          token === null ? <Signin /> : <MultiStepForm></MultiStepForm>
        }
      />
      <Route
        exact
        path="/services"
        render={(rProps) =>
          token === null ? <Signin /> : <Services></Services>
        }
      />
      <Route path="/u/:id" element={<ProfileView />} />
      <Route
        exact
        path="/label"
        render={(rProps) =>
          token === null ? <Signin /> : <LabelView></LabelView>
        }
      />
      <Route
        exact
        path="/upload"
        render={(rProps) => (token === null ? <Signin /> : <Dash />)}
      />
      <Route
        exact
        path="/tapes/:id"
        render={(rProps) => (token === null ? <Signin /> : <MTRDriveView />)}
        element={<MTRDriveView />}
      />
      <Route
        exact
        path="/settings"
        render={(rProps) => (token === null ? <Signin /> : <Settings />)}
        element={<Settings />}
      />
      <Route
        exact
        path="/upgrade"
        render={(rProps) =>
          token === null ? (
            <Signin />
          ) : (
            <StudioPlusUpgradeView></StudioPlusUpgradeView>
          )
        }
      />
      <Route
        exact
        path="/signup"
        component={SignupView}
        element={<SignupView />}
      />
      <Route
        exact
        path="/signin"
        component={FBSigninView}
        element={<FBSigninView />}
      />
      <Route path="/e/:id" element={<EventView />} />
      {/* <Route
        path="/"
        element={
          APIConfig.phaze() ? (
            <PhazeSplashPage />
          ) : APIConfig.studio() ? (
            user ? (
              <AlbumsComponent />
            ) : (
              <MXTSplashPage />
            )
          ) : user ? (
            <DriveView class="min-h-screen" />
          ) : (
            <Splash />
          )
        }
      />
       */}
      <Route path="/" element={user ? <Konsole /> : <MXTSplashPage />} />
      <Route path="/create-post" element={<CreatePostView />} />

      <Route path="/e/:eventId/memories" component={MemoriesPageView} />
      <Route
        path="/events/:eventId/photos/:photoId"
        element={<PhotoDetailView />}
      />
      <Route
        path="/create"
        render={(rProps) =>
          token === null ? <CreateEventView /> : <CreateEventView />
        }
      />
      <Route
        path="/create_username_from_event"
        element={<CreateUsernameFromEventView />}
      />
      <Route path="/create" element={<CreateEventView />} />
    </Routes>
  );
};

export default Main;
