// TracksView.jsx

import React from "react";
import MultiPlayerView from "./mtr_player/MultiPlayerView";

const TracksView = ({ tape, tracks }) => {
  return (
    <div className="px-7 p-2 bg-charcoal-50">
      <div className="font-theFutureBold">tracks</div>
      <div className="px-5">
        <div className="px-5">
          <ol>
            <MultiPlayerView tracks={tracks} />
            {tracks.map((track, index) => (
              <div className="px-2 text-sm" key={track.channel}>
                <li
                  className="font-theFutureMonoRegular py-2"
                  style={{ listStyle: "decimal" }}
                >
                  {track.filename}
                </li>
              </div>
            ))}
          </ol>
        </div>
      </div>
    </div>
  );
};

export default TracksView;
