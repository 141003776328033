import React from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { firebaseAuth } from "../provider/AuthProvider";
import FBSigninView from "../auth/FBSigninView";
import firebase from "firebase/compat/app";
const Signin = () => {
  const auth = firebase.auth();
  const [user] = useAuthState(auth);

  return (
    <div className="flex flex-col">
      {!user && <FBSigninView />}
    </div>
  );
};

export default Signin;
