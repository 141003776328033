// LabelArtistView.jsx

// LabelRegistrationView.jsx
import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { useForm } from "react-hook-form";
import api from "../../api";
import { fb } from "../../firebase/fb";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import storage from "../../services/storage";
import studio_api from "../../studio_api";
import StandardButton from "../buttons/StandardButton";
import Container from "../themes/Container";
import GridContainer from "../themes/GridContainer";
import label_api from "../../services/label_api";

export default function LabelArtistView() {
  const { register, handleSubmit } = useForm();
  const [label, setLabel] = useState("");
  const [artists, setArtists] = useState([]);
  const [albums, setAlbums] = useState([]);
  let location = useLocation();
  function getAlbumsList() {
    const listItems = albums.map((album) => (
      <div>

          <StandardButton
            // onClick={() => {
            //   // console.log(artist.id);
            //   history.push({
            //     pathname: "/view/artist",
            //     artist_id: artist.id,
            //     artist_name: artist.name,
            //   });
            // }}
          >
            {album.name}
            <img src={album.compressed_artwork}style={{ height: 150 }}></img>
          </StandardButton>

        {/* {deleteArtistButton(artist)} */}
      </div>
    ));
    return <div>{listItems}</div>;
  }
  useEffect(() => {
    console.log("the id: ", location.artist_id);
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        firebase
          .auth()
          .currentUser.getIdToken(/* forceRefresh */ true)
          .then(function (token) {
            console.log("the id: ", location.artist_id);
            var data = label_api
              .getReleases(token, location.artist_id)
              .then((res) => {
                res.json().then(function (data) {
                  console.log(data);

                // var albums = []
                if (data.albums.length > 0) {
                //   albums = data.albums
                  setAlbums(data.albums)
                }

                // var dict = {
                //   id: data.id,
                //   artistName: data.name,
                //   albums: albums
                // }
                // console.log(dict)
                });
                // var albums = []
                // if (res.data.albums.length > 0) {
                //   albums = res.data.albums
                // }

                // var dict = {
                //   id: res.data.id,
                //   artistName: res.data.name,
                //   albums: albums
                // }
                // console.log(dict)
                // this.state.labelMetaData.push(dict)
                // this.api.me().then(res => {
                //   this.setState({ username: res.data.username, refresh: true })
                // })
              });
          });
      } else {
        //("signed out....");
      }
    });
  }, []);
  return (
    <GridContainer className="center padded ">
      <div className="center padded ">
        <Link to="/label">
          <StandardButton>back</StandardButton>
        </Link>
        <div className="center padded ">{location.artist_name}</div>
        {getAlbumsList()}
      </div>
    </GridContainer>
  );
}
