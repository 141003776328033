import React, { useState } from "react";

const AvatarView = ({ username, profilePic, showUsername }) => {
  const [isHovered, setIsHovered] = useState(false);
  const avatarUrl =
    profilePic ||
    `https://ui-avatars.com/api/?name=${username}&background=4B5563&color=fff`;

  return (
    <div
      className="flex items-center"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div className="w-8 h-8 sm:w-8 sm:h-8 rounded-full overflow-hidden">
        <img
          src={avatarUrl}
          alt={`Avatar of ${username}`}
          className="object-cover w-full h-full"
        />
      </div>
      {showUsername && (
        <p className="text-sm sm:text-base font-mono ml-2 text-gray-700 dark:text-gray-300">
          {username}
        </p>
      )}
    </div>
  );
};

AvatarView.defaultProps = {
  showUsername: true, // By default, show the username
};

export default AvatarView;