import React, { useState } from 'react';
import { Stage, Layer, Circle, Line } from 'react-konva';

const PanKnob = ({ track, onChange }) => {
  const [rotation, setRotation] = useState(0);
  const [isDragging, setIsDragging] = useState(false);

  const handleMouseDown = () => {
    setIsDragging(true);
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  const handleRotationChange = (e) => {
    if (!isDragging) return;

    const stage = e.target.getStage();
    const mousePos = stage.getPointerPosition();
    const layer = e.target.getParent();
    const knob = layer.findOne('.knob');
    const knobPosition = knob.getAbsolutePosition();

    const deltaX = mousePos.x - knobPosition.x;
    const deltaY = mousePos.y - knobPosition.y;
    let angle = (Math.atan2(deltaY, deltaX) * 180) / Math.PI + 90;

    if (angle < 0) {
      angle += 360;
    }
    if (angle >= 90 && angle <= 270) {
      setRotation(angle);
      onChange((angle - 90) / 180);
    }
  };

  return (
    <Stage
      width={50}
      height={50}
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
      onMouseMove={handleRotationChange}
    >
      <Layer>
        <Circle
          x={25}
          y={25}
          radius={20}
          fill="red"
          stroke="white"
          strokeWidth={2}
          draggable={false}
          name="knob"
        />
        <Line
          x={25}
          y={25}
          points={[0, -20, 0, -10]}
          stroke="white"
          strokeWidth={3}
          rotation={rotation}
          draggable={false}
        />
      </Layer>
    </Stage>
  );
};

export default PanKnob;
