import React, { useState } from 'react';

const MAX_LENGTH = 600; // Maximum number of characters to show before the "Show More"

const EventDescriptionView = ({ description }) => {
  const [showFullDescription, setShowFullDescription] = useState(false);

  if (!description || description.trim().length === 0) {
    return null; // Do not render the component if there's no description
  }

  // Determine if the description exceeds the max length
  const isTruncated = description.length > MAX_LENGTH;

  // Function to toggle the show full description state
  const toggleShowFullDescription = () => {
    setShowFullDescription(!showFullDescription);
  };

  // Truncate the description if needed
  const displayedDescription = showFullDescription ? description : description.slice(0, MAX_LENGTH).trim();

  return (
    <div className="p-4 bg-gray-50 my-4 rounded-lg font-sans dark:bg-neutral-900 text-gray-700 dark:text-gray-300">
      {displayedDescription.split('\n').map((paragraph, index) => (
        <p key={index} className="mb-4 last:mb-0 text-base leading-relaxed">
          {paragraph}
        </p>
      ))}
      {isTruncated && (
        <button
          onClick={toggleShowFullDescription}
          className="text-blue-500 dark:text-blue-400 hover:text-blue-700 dark:hover:text-blue-600 transition-colors duration-300 mt-2"
        >
          {showFullDescription ? 'show less' : 'show more...'}
        </button>
      )}
    </div>
  );
};

export default EventDescriptionView;