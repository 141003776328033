import React from "react";
import { useNavigate } from "react-router-dom";
import { EllipsisVerticalIcon } from "@heroicons/react/24/outline"; // For the ellipsis menu icon
import DeleteAlbumButton from "./dashboard/DeleteAlbumButton";

const DraftListView = ({ drafts, isEditMode }) => {
  const navigate = useNavigate();

  const draftsList = drafts.map((draft) => {
    const { docId, name } = draft; // Use `docId` as a unique identifier

    return (
      <li
        key={docId}
        className="flex items-center justify-between p-4 border-t-2 border-black dark:border-white hover:bg-gray-200 dark:hover:bg-gray-800 transition-colors duration-200 ease-in-out"
      >
        {/* Clickable area for navigation */}
        <button
          className="flex-grow py-2 px-4 text-left font-mono text-black dark:text-white font-bold tracking-widest focus:outline-none"
          onClick={() => {
            navigate("/create/album", { state: { album: draft } });
          }}
        >
          <span className="text-lg">{name}</span>
        </button>

        {/* Show delete button if in edit mode, otherwise show ellipsis icon */}
        {isEditMode ? (
          <div className="pr-4">
            <DeleteAlbumButton album={draft} />
          </div>
        ) : (
          <EllipsisVerticalIcon className="h-6 w-6 text-gray-500 dark:text-gray-300 pr-4 cursor-pointer" />
        )}
      </li>
    );
  });

  return (
    <div className="my-8">
      <ul className="bg-gray-100 dark:bg-black border-2 border-black dark:border-white shadow-lg rounded-none">
        {draftsList}
      </ul>
    </div>
  );
};

export default DraftListView;