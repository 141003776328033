// SmallLogoView.jsx;
import React, { useContext, useEffect, useState, useForm } from "react";
import logo from "../../assets/mxt_logo_extra_small.png"; // Tell webpack this JS file uses this image
import GridContainer from "../themes/GridContainer";
import LogoContainer from "../themes/LogoContainer";
import LabelArtistsView from "./LabelArtistsView";
import LabelRegistrationView from "./LabelRegistrationView";
import FileSystemView from "./FileSystemView";
// import LabelArtistsView
// import api from "../../api";
import { fb } from "../../firebase/fb";
import label_api from "../../services/label_api";

import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import api from "../../api";
import APIService from "./legacy/APIService";

const LabelView = () => {
  const [label, setLabel] = useState("");
  const [loading, setLoading] = useState(true);
  const [artists, setArtists] = useState([]);
  const [token, setToken] = useState("")
  // Similar to componentDidMount and componentDidUpdate:
  // setLabel() {

  // }
  useEffect(() => {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        firebase
          .auth()
          .currentUser.getIdToken(/* forceRefresh */ true)
          .then(function (token) {
            setToken(token)
            var label_info = label_api.getLabelArtists(token);
            label_info.then(function (info) {
              setLoading(false)
              info.json().then(function (data) {
                let label = data["label"];
                let artists = data["artist_data"]
                if (label === "") {
                  setLabel("no label")
                } else {

                  setLabel(label);
                }
                setArtists(artists)
              });
              // console.log(a)

              // setLabel(info[""])
            });
          });

        //("loggedin");
      } else {
        //("signed out....");
      }
    });
  }, []);

  function getLabelView() {
    if (label === "no label" && !loading) {
      return (
        <div>
          start a label
          <LabelRegistrationView></LabelRegistrationView>
        </div>
      )
    }
    
    if (loading) {
      return (
        <div>
          loading...
          
        </div>
      )
    }
    
    else {
      return (
        <div>
          <LabelArtistsView label={label} artists={artists} token={token}></LabelArtistsView>
          


          {/* const data = APIService.getLabelArtists().then(res => {
            this.setState({
              artists: res.data.artist_names,
              artist_data: res.data.artist_data,
              labelName: res.data.label
            }, () => {
              this.getArtistAlbums()
            })
          } */}
    
        </div>
      ) 
    }
  }
  return (
    <GridContainer>
      <div className="padded center">
        {getLabelView()}
        {/* <img
          style={{ height: 45, padding: "0.3em" }}
          src={"https://tuareg-music.s3.amazonaws.com/mxt_studio.svg"}
          className="selected"
          alt="Logo"
        /> */}
        {/* <AdvancedDnd></AdvancedDnd> */}
        {/* <MyFileBrowser></MyFileBrowser> */}
      </div>
    </GridContainer>
    // </LogoContainer>
  );
};

export default LabelView;
