// LogoContainer.js;
import styled from "styled-components";

const LogoContainer = styled.div`
  text-align: center;
  /* display: flex; */
  .container {
    /* display: flex; */
    /* or inline-flex */
    /* flex-basis: auto; */
    /* default auto */
  }
  ul {
    display: flex;

    /* align-items: stretch; */
    /* Default */
    /* justify-content: space-between; */
    /* width: 100%; */
    /* background: #cacaca; */
    border: 1px solid #91b3ff;
    flex: 0 1 auto; /* Default */

    /* 
    margin-left: 0;
    padding: 40; */
  }
  .form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 50vh;
  }
  .input {
    display: flex;
    justify-content: space-space-around;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
    height: 5vh;
    width: 50vh;
  }
  .title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 5vh;
    text-decoration: underline;
    border: 1px solid #91b3ff;

    /* padding: 20px */
  }

  .logo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 5vh;
    /* padding: 20px */
  }
  .app_title {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    font-family: "Menlo", monospace;
    height: 15vh;
    /* padding: 40px */
  }

  .link {
    text-decoration: underline;
  }
  .menu {
    flex-direction: row;
    justify-content: space-space-around;
    align-items: center;
    padding-top: 0.4vh;
    padding-bottom: 0.4vh;
    padding-left: 1vh;
    padding-right: 1vh;

    border: 1px solid #91b3ff;
  }

  .box {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    /* width: 200px; */
  }
  .box > *:first-child {
    align-self: stretch;
  }
  .box .selected {
    align-self: center;
  }
  li {
    display: block;
    flex: 0 1 auto; /* Default */
    list-style-type: none;
    /* background: #fafafa; */
    padding: 20px;
  }
  .item {
    order: 5; /* default is 0 */
  }
  .subscribe {
    display: flex;
    /* height: 50px; */
    padding: 20px;
  }
`;

export default LogoContainer;
