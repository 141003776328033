import React, { useState } from "react";
import {
  TrashIcon,
  PencilIcon,
  CheckIcon,
  XMarkIcon,
} from "@heroicons/react/24/solid";
import Spinner from "../../loading/Spinner";

const ArtistComponent = ({
  artist,
  isEditMode,
  onEditToggle,
  onNameChange,
  onUpdate,
  onDelete,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleUpdate = async (artistId) => {
    setIsLoading(true);
    await onUpdate(artistId);
    setIsLoading(false);
  };

  const handleDeleteConfirm = async (artistId) => {
    const isConfirmed = window.confirm(
      `Are you sure you want to delete ${artist.name}?`
    );
    if (isConfirmed) {
      setIsLoading(true);
      await onDelete(artistId);
      setIsLoading(false);
    }
  };

  return (
    <li
      className={`flex items-center justify-between py-3 border-t-2 border-black dark:border-white bg-gray-100 dark:bg-black text-black dark:text-white font-mono ${
        isEditMode ? "bg-yellow-100 dark:bg-gray-700" : ""
      }`}
    >
      {isEditMode ? (
        <input
          type="text"
          value={artist.name}
          className="flex-grow mr-4 p-2 bg-transparent text-lg font-bold border-b-2 border-black dark:border-white focus:outline-none"
          onChange={(e) => onNameChange(artist.id, e.target.value)}
          disabled={isLoading}
        />
      ) : (
        <span
          className={`flex-grow ${
            isLoading ? "text-gray-400" : "text-lg font-bold"
          }`}
        >
          {artist.name}
        </span>
      )}

      <div className="flex items-center space-x-2">
        {isLoading ? (
          <Spinner />
        ) : (
          <>
            <button
              onClick={() => onEditToggle(artist.id)}
              className="w-10 h-10 flex items-center justify-center transition-transform duration-150 hover:scale-105"
            >
              {isEditMode ? (
                <div className="bg-red-100 dark:bg-red-700 p-2 rounded-lg">
                  <XMarkIcon className="h-6 w-6 text-red-600 dark:text-red-400" />
                </div>
              ) : (
                <PencilIcon className="h-6 w-6 text-blue-600 dark:text-blue-400" />
              )}
            </button>
            {isEditMode && (
              <>
                <button
                  onClick={() => handleUpdate(artist.id)}
                  className="p-2 transition-transform duration-150 hover:scale-105 bg-green-100 dark:bg-green-800 rounded"
                >
                  <CheckIcon className="h-6 w-6 text-green-600 dark:text-green-400" />
                </button>
                <button
                  onClick={() => handleDeleteConfirm(artist.id)}
                  className="p-2 transition-transform duration-150 hover:scale-105 bg-red-100 dark:bg-red-800 rounded"
                >
                  <TrashIcon className="h-6 w-6 text-red-600 dark:text-red-400" />
                </button>
              </>
            )}
          </>
        )}
      </div>
    </li>
  );
};

export default ArtistComponent;