import React from "react";
import PathContext from "../contexts/PathContext";

const PathBreadcrumbs = () => {
  return (
    <PathContext.Consumer>
      {({ currentPath, setCurrentPath }) => {
        const pathSegments = currentPath
          ? currentPath.split("/").filter((segment) => segment)
          : [];

        const handleBreadcrumbClick = (index) => {
          const newPath = pathSegments.slice(0, index + 1).join("/");
          console.log("newPath", newPath);
          setCurrentPath(newPath === "" ? "/" : "/" + newPath);
        };

        return (
          <div className="inline-flex space-x-2">
            <button
              onClick={() => setCurrentPath("")}
              className="text-gray-800 dark:text-gray-300 font-mono hover:text-blue-500"
            >
              /
            </button>
            {pathSegments.map((segment, index) => (
              <React.Fragment key={segment}>
                {index > 0 && (
                  <span className="text-gray-500 dark:text-gray-400 font-mono">
                    /
                  </span>
                )}
                <button
                  onClick={() => handleBreadcrumbClick(index)}
                  className="text-gray-800 dark:text-gray-300 font-mono hover:text-blue-500"
                >
                  {segment}
                </button>
              </React.Fragment>
            ))}
          </div>
        );
      }}
    </PathContext.Consumer>
  );
};

export default PathBreadcrumbs;
