import React, { useState } from "react";

const UsernameUpdateField = (props) => {
  const [username, setUsername] = useState(props.initialUsername || "");
  const [inputValid, setInputValid] = useState(false);
  const [displayErrorCaption, setDisplayErrorCaption] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const nameRegex = /^[a-zA-Z0-9_.]{3,30}$/;

  const validateUsername = (username) => nameRegex.test(username);

  const handleUsernameChange = (event) => {
    const newValue = event.target.value.trim();
    setUsername(newValue);

    if (newValue === "") {
      setDisplayErrorCaption(false);
      props.setInputValidity(false);
      props.setAvailable(true);
    } else {
      const isValid = validateUsername(newValue);
      setInputValid(isValid);
      props.setInputValidity(isValid);
      if (newValue.length >= 3) {
        setDisplayErrorCaption(!isValid);
      } else {
        setDisplayErrorCaption(false);
      }
    }

    props.onUsernameChange(newValue);
  };

  const handleSubmit = () => {
    if (inputValid) {
      props.onUsernameSubmit(username);
      setShowSuccessMessage(true);
      setTimeout(() => setShowSuccessMessage(false), 3000);
    } else {
      setDisplayErrorCaption(true);
    }
  };

  return (
    <div className="flex flex-col items-center font-mono text-black dark:text-white bg-gray-100 dark:bg-black p-4 w-full max-w-md">
      <div className="w-full mb-4">
        <input
          type="text"
          placeholder="Enter username"
          className="w-full bg-transparent text-xl font-bold p-3 border-2 border-black dark:border-white rounded-none focus:outline-none focus:ring-0 dark:text-white text-black"
          onChange={handleUsernameChange}
          value={username}
          disabled={props.loading}
        />
      </div>
      <button
        className={`bg-black dark:bg-white text-white dark:text-black font-mono font-bold text-lg py-2 px-6 w-full transition-transform duration-300 hover:scale-105 ${
          props.loading ? "opacity-50 cursor-not-allowed" : ""
        }`}
        onClick={handleSubmit}
        disabled={props.loading}
      >
        {props.loading ? "Checking..." : "Save"}
      </button>
      <div className="mt-2 h-6">
        {displayErrorCaption && (
          <div className="text-red-500 font-bold">Invalid Username</div>
        )}
        {showSuccessMessage && (
          <div className="text-green-500 font-bold">Username updated successfully!</div>
        )}
      </div>
      {displayErrorCaption && (
        <div className="py-2 text-sm text-black dark:text-white font-mono text-center mt-4 bg-gray-200 dark:bg-gray-800 p-4">
          <p>
            Username must be 3-30 characters long and may contain letters,
            numbers, periods, and underscores. No spaces or special characters.
          </p>
        </div>
      )}
    </div>
  );
};

export default UsernameUpdateField;