// ArtistMusicView.jsx

import React, { useEffect, } from "react";


const ArtistMusicView = ({ albums, artistId }) => {

  useEffect(() => {
    console.log("music");
    console.log("id: ", artistId);
  }, [artistId, albums]);
  if (!albums) {
    return null;
  } else {
    if (albums.length === 0) {
      return <div className="p-6 font-theFutureMonoRegular blue center">loading...</div>;
    } else {
      return (
        <div>
          <div className="">
            {albums.map((album) => (
              <div className="">
                <div
                  className=" p-5 hover:opacity-75 space-y-4  flex flex-col items-center "
                  key={album.id}
                >
                  <a href={`mxt://search/albums/${artistId}`}>
                    <img
                      className="object-contain h-48 w-96"
                      src={album.raw_artwork}
                      alt={album.name}
                    />
                  </a>
                  <div className="font-theFutureBold  flex flex-col items-center">{album.name}</div>
                </div>
              </div>
            ))}
          </div>
        </div>
      );
    }
  }
};

export default ArtistMusicView;
