import React, { useState } from 'react';
import TextInput from './TextInput';
import Textarea from './Textarea';
import Checkbox from './Checkbox';
import RadioGroup from './RadioGroup';
import EventImage from './EventImage';

const CreateEventView = () => {
  const [theme, setTheme] = useState('default');
  const [eventDetails, setEventDetails] = useState({
    name: '',
    startDate: '',
    startTime: '',
    endDate: '',
    endTime: '',
    location: '',
    description: '',
    tickets: false,
    approvalRequired: false,
    capacity: '',
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setEventDetails((prevDetails) => ({
      ...prevDetails,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Event Details:', eventDetails);
  };

  const handleThemeChange = (e) => {
    setTheme(e.target.value);
  };

  return (
    <div
      className={`w-full h-screen p-6 sm:p-12 lg:p-24 ${
        theme === 'default' ? 'bg-inherit' : 'bg-blue-900 text-white'
      } ${theme === 'default' ? 'font-sans' : 'font-mono'}`}
    >
      <div className="max-w-5xl mx-auto border-2 dark:border-white border-black p-8 rounded-lg shadow-lg">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          <div className="flex flex-col space-y-6">
            <EventImage />
            <RadioGroup
              name="theme"
              options={[
                { label: 'Default', value: 'default' },
                { label: 'IBM', value: 'ibm' },
              ]}
              selectedValue={theme}
              onChange={handleThemeChange}
              label="Theme"
            />
          </div>
          <form onSubmit={handleSubmit} className="flex flex-col space-y-6">
            <TextInput
              id="name"
              name="name"
              value={eventDetails.name}
              onChange={handleChange}
              label="event name"
              required
            />
            <TextInput
              id="startDate"
              name="startDate"
              value={eventDetails.startDate}
              onChange={handleChange}
              label="date"
              required
              type="date"
            />
            <TextInput
              id="startTime"
              name="startTime"
              value={eventDetails.startTime}
              onChange={handleChange}
              label="time"
              required
              type="time"
            />
            <TextInput
              id="location"
              name="location"
              value={eventDetails.location}
              onChange={handleChange}
              label="location"
              required
            />
            <Textarea
              id="description"
              name="description"
              value={eventDetails.description}
              onChange={handleChange}
              label="description"
              rows="4"
            />
            <Checkbox
              id="approvalRequired"
              name="approvalRequired"
              checked={eventDetails.approvalRequired}
              onChange={handleChange}
              label="private"
            />
            <div className="flex justify-end">
              <button
                type="submit"
                className="px-6 py-3 bg-blue-600 text-white font-bold rounded-lg shadow-lg hover:bg-blue-700 transition-colors duration-300"
              >
                Create Event
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default CreateEventView;