import { createContext, useState, useEffect } from "react";
import AudioPlayerService from "../../artist_dashboard/services/AudioPlayerService";

const AudioPlayerContext = createContext();

export const AudioPlayerProvider = ({ children }) => {
  const [src, setSrc] = useState({
    title: "",
    artist: "",
    color: "",
    image: "",
    audioSrc: "",
  });
  const [playing, setPlaying] = useState(false);

  const playAudio = (newSrc) => {
    if (src.audioSrc !== newSrc.audioSrc) {
      // If the new track is different, load the new source
      setSrc({ ...newSrc });
      AudioPlayerService.load(newSrc.audioSrc);
      setPlaying(true);
    } else {
      // If it's the same track, just resume playback
      AudioPlayerService.play();
      setPlaying(true);
    }
  };

  const pauseAudio = () => {
    AudioPlayerService.pause();
    setPlaying(false);
  };

  const toggleAudio = () => {
    if (playing) {
      pauseAudio();
    } else {
      AudioPlayerService.play();
      setPlaying(true);
    }
  };

  return (
    <AudioPlayerContext.Provider
      value={{
        src,
        playing,
        playAudio,
        pauseAudio,
        toggleAudio,
      }}
    >
      {children}
    </AudioPlayerContext.Provider>
  );
};

export default AudioPlayerContext;