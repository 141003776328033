import React from "react";
import useFetchArtistData from "../../../../events/hooks/useFetchArtistData";
import AlbumList from "./AlbumList";
import DynamicRotatingSlash from "../../../../loading/DynamicRotatingSlash";

const ArtistDetailsView = ({ artistId }) => {
  const { artistData, loading, error } = useFetchArtistData(artistId);

  if (loading) {
    return (
      <div className="p-2">
        <DynamicRotatingSlash />{" "}
      </div>
    );
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  if (!artistData) {
    return <div>No artist data found</div>;
  }
  return (
    <div className="artist-details pl-4">
      {/* <p>Albums: {artistData.albums.length}</p> */}
      <AlbumList albums={artistData.albums} />
    </div>
  );
};

export default ArtistDetailsView;
