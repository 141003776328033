// ImageUploader.jsx;
import React, { useContext, useState, useEffect } from "react";
import { firebaseAuth } from "../../provider/AuthProvider";

import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/storage";

import { authMethods } from "../../firebase/authmethods";
import api from "../../api";
import StandardButton from "../buttons/StandardButton";
import UploadContainer from "../themes/UploadContainer";
import storage from "../../services/storage";
import { Card } from "react-bootstrap";
import GridContainer from "../themes/GridContainer";
import merch from "../../services/merch";

const ProductImageUploader = (props) => {
  const { handleSignout } = useContext(firebaseAuth);
  const [files, setFiles] = useState([]);
  var file = new File([], "");
  const [p, setProgress] = useState(0);
  const [image, setImage] = useState("");
  const [loaded, setLoaded] = useState(false);

  const [localImage, setLocalImage] = useState(false);
  const [editing, setEditingState] = useState(false);
  const [valid, setValid] = useState(false);
  var db = firebase.firestore();
  var user = firebase.auth().currentUser;
  const onFileChange = (e) => {
    for (let i = 0; i < e.target.files.length; i++) {
      const newFile = e.target.files[i];
      var _res = validateImageSize(newFile).then(function (res) {
        if (res === true) {
          newFile["id"] = Math.random();
          // add an "id" property to each File object

          setValid(true);
          setFiles((prevState) => [...prevState, newFile]);
        } else {
          setValid(false);
          setFiles([]);
          alert("maximum image size is 8000x8000");
        }
      });
      // alert(newFile.type);
    }
  };

  useEffect(() => {
    setImage("no image");
    setLocalImage(false);
    setLoaded(true);
  }, [loaded]);
  async function validateImageSize(image) {
    var img_url = URL.createObjectURL(image);
    var img = new Image();
 
    img.src = img_url;


    return new Promise((resolve, reject) => {
      img.onload = function () {
        if (
          img.height >= 128 &&
          img.height <= 8030 &&
          img.width >= 128 &&
          img.width <= 8030
        ) {
          setLocalImage(img_url);
          resolve(true);
        } else {
          resolve(false);
        }
      };
    }); //
  }
  const files_list = files.map((file) => (
    // <li key={file.name}>
    <span>
      <img src={URL.createObjectURL(file)} style={{ height: 150 }}></img>
      {/* filename: {file.name} - size: {Math.round(file.size / 1000000)} mb */}
    </span>
    // <br></br>
    // </li>
  ));
  function editImageView() {
    if (image === "no image") {
      return (
        <div>
          <div> no image</div>
          <StandardButton mxt onClick={() => setEditingState(true)}>
            {" "}
            + product image{" "}
          </StandardButton>
        </div>
      );
    } else {
      return (
        <div>
          <img src={image} style={{ height: 150 }}></img>
          <StandardButton mxt onClick={() => setEditingState(true)}>
            {" "}
            + product image{" "}
          </StandardButton>
        </div>
      );
    }
  }
  function editImageButton() {
    return (
      <div>
        {image === "" && image != "no image" ? (
          <div> loading... </div>
        ) : (
          <img src={image} style={{ height: 150 }}></img>
        )}
        <StandardButton mxt onClick={() => setEditingState(true)}>
          {" "}
          edit product image{" "}
        </StandardButton>
      </div>
    );
  }
  function getImage() {
    if (image === "loading") {
      return <div>"loading..."</div>;
    } else if (image === "no image") {
      return null;
    } else {
      return <img src={image} style={{ height: 150 }}></img>;
    }
  }
  function uploadView() {
    return (
      <GridContainer className="center">
        <div>
          <UploadContainer>
            {/* <div className="title2">upload an image</div> */}
            <form className="center">
              <label>
                <div style={{ padding: 5, color: "cornflowerblue" }}>
                  select file
                </div>
                <input type="file" accept="image/*" onChange={onFileChange} />
              </label>
              <p className="progress">progress: {p}</p>
              {valid ? (
                <StandardButton
                  mxt_green
                  onClick={onUploadSubmission}
                  disabled={!valid}
                >
                  upload image
                </StandardButton>
              ) : null}

              {files_list}
              {!localImage ? null : (
                <img src={localImage} style={{ height: 150 }}></img>
              )}
              {/* <div className="padded"></div> */}
            </form>
          </UploadContainer>
          {getImage()}
        </div>
        <StandardButton mxt_red onClick={() => setEditingState(false)}>
          done
        </StandardButton>
      </GridContainer>
    );
  }
  useEffect(() => {
    merch.getMerchItemImage(props.merchItem).then(function (image) {
      if (image.url != null) {
        setImage(image.url);
      } else {
        setImage("no image");
      }
    });
  }, [image]);
  const onUploadSubmission = (e) => {
    e.preventDefault(); // prevent page refreshing
    const promises = [];
    files.forEach((file) => {
      if (validateImageSize(file) === false) {
        // alert("image needs to be 3000 x 3000 pixels");
      } else {
        const uploadTask = firebase
          .storage()
          .ref()
          .child(`studio/user_storage/${user.uid}/files/images/${file.name}`)
          .put(file);
        promises.push(uploadTask);
        uploadTask.on(
          firebase.storage.TaskEvent.STATE_CHANGED,
          (snapshot) => {
            const progress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            if (snapshot.state === firebase.storage.TaskState.RUNNING) {
              setProgress(progress);
              if (snapshot.state === firebase.storage.TaskState.RUNNING) {
              }
            }
          },
          (error) => console.log(error.code),
          async () => {
            const downloadURL = await uploadTask.snapshot.ref.getDownloadURL();
            // if (progress == 100) {
            merch
              .updateMerchItemImage(props.merchItem, downloadURL, file.name)
              .then(function (image_url) {
                setImage(image_url);
              });
            // addImageLocation(downloadURL, file);

            //
            setFiles([]);

            // do something with the url
          }
        );
      }
    });
  };

  /* 
If we log each file ( console.log(newFile); ), this is what you'll see:
File {id: 0.2999283740324652, name: "black-and-white-maltese-puppy-1458916.jpg", lastModified: 1578950451757, lastModifiedDate: Mon Jan 13 2020 15:20:51 GMT-0600 (Central Standard Time), webkitRelativePath: "", …}
*/
  return <div>{!editing ? editImageView() : uploadView()}</div>;
};

export default ProductImageUploader;
