import React from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import firebase from "firebase/compat/app";
import { useUserStorage } from "./useStorageHooks";

const auth = firebase.auth();

const StorageUsage = () => {
  const [user] = useAuthState(auth);
  const [userStorage] = useUserStorage(user?.uid);

  const renderStorageUsage = () => {
    if (!userStorage) return null;

    const { usedStorage, storageLimit } = userStorage;
    const storagePercentage = (usedStorage / storageLimit) * 100;

    return (
      <div className="text-center space-y-4 font-theFutureMonoRegular text-lg">
        <div className="text-2xl font-bold uppercase tracking-wide">
          Storage Usage
        </div>
        <div className="w-full h-6 bg-gray-200 dark:bg-gray-800 border-2 border-black dark:border-white">
          <div
            className="h-full bg-black dark:bg-white"
            style={{ width: `${storagePercentage}%` }}
          ></div>
        </div>
        <div className="text-sm mt-2 border-t-2 border-black dark:border-white pt-2">
          {Math.round((usedStorage / 1024 / 1024) * 100) / 100} MB /{" "}
          {Math.round((storageLimit / 1024 / 1024) * 100) / 100} GB
        </div>
      </div>
    );
  };

  return (
    <div className="p-4 border-2 border-black dark:border-white bg-gray-100 dark:bg-gray-900">
      {renderStorageUsage()}
    </div>
  );
};

export default StorageUsage;
