// profile_service.js;

// import APIConfig from "./APIConfig";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import APIConfig from "../APIConfig";
import { authMethods } from "../firebase/authmethods";

// import { authMethods } from "./firebase/authmethods";

// import { authMethods } from "../../firebase/authmethods";
const token = localStorage.getItem("token");
// const b = APIConfig.baseURL();
// ${APIConfig.baseURL()}
// const base = APIConfig.baseURL()
let db = firebase.firestore();
var user = firebase.auth().currentUser;
// var tapes_ref = firebase
//   .firestore()
//   .collection("studio")
//   .doc(firebase.auth().currentUser.uid)
//   .collection("tapes");
const authUser = Object.keys(window.localStorage).filter((item) =>
  item.startsWith("firebase:authUser")
)[0];

// const getProfileInfo = () => {};

const getProfileInfo = async (token) => {
  const r = await fetch(`${APIConfig.profiles()}me/`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "JWT " + token,
    },
    // body: d,
  });
  return r;
};

const getArtistProfileInfo = async (token, slug) => {
  const artist_data = { slug: slug };
  const d = JSON.stringify(artist_data);
  //("d ", d);
  // const token = localStorage.getItem("token");

  const r = await fetch(`${APIConfig.profiles()}artist_profile/`, {
    method: "post",
    headers: {
      "Content-Type": "application/json",
      Authorization: "JWT " + token,
    },
    body: d,
  });
  return r;
};

const getCustomerInfo = async (token) => {
  var email = "test@email.com";

  const r = await fetch(`${APIConfig.profiles()}get_customer/`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "JWT " + token,
    },
    body: JSON.stringify({
      email: email,
    }),
  });

  return r;
};

const _getCustomerInfo = (token) => {
  // evt.preventDefault();

  var email = "test@email.com";
  return fetch(`${APIConfig.profiles()}get_customer/`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "JWT " + token,
    },
    body: JSON.stringify({
      email: email,
    }),
  });
};

const getMerch = async (slug) => {
  const artist_data = { slug: slug };
  const d = JSON.stringify(artist_data);
  // console.log("d ", d);
  // const token = localStorage.getItem("token");

  const r = await fetch(`${APIConfig.store()}`, {
    method: "post",
    headers: {
      "Content-Type": "application/json",
      // Authorization: "JWT " + token,
    },
    body: d,
  });
  return r;
};
// const fetchCustomer = () => {
//   getCustomerInfo();
// };
const profile_service = {
  getProfileInfo,
  getCustomerInfo,
  getArtistProfileInfo,
  getMerch,
};

export default profile_service;
