import React, { useState } from 'react';
import PathContext from './PathContext';


const PathContextProvider = ({ children }) => {
  const [currentPath, setCurrentPath] = useState('');

  return (
    <PathContext.Provider value={{ currentPath, setCurrentPath }}>
      {children}
    </PathContext.Provider>
  );
};

export default PathContextProvider;
