import firebaseconfig from "./firebaseIndex";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import profile_service from "../services/profile_service";
import storage from "../services/storage";

function updateUserProfile(name) {
  const data = {
    displayName: name,
  };

  return;
  firebase
    .auth()
    .currentUser.updateProfile(data)
    .then(() => {
      console.log("Successfully updated default user profile");

      // IMPORTANT: Force refresh regardless of token expiration
      return "success";
    })
    .then((newToken) => {
      console.log("Token refreshed!", newToken);
      return newToken;
    })
    .catch((err) => console.log(err));
}
export const authMethods = {
  // firebase helper methods go here...
  signup: (email, password, setErrors, setToken, props) => {

    firebase.auth().signInWithEmailAndPassword(email, password)
  .then((userCredential) => {
    // Signed in
        var user = userCredential.user;
        // const token = await Object.entries(res.user)[5][1].b;
        const token = user.getIdToken();
        //set token to localStorage
        localStorage.setItem("token", token);
        setToken(token);
        props.history.push("/");
        const data = {
          displayName: email,
        };
        firebase
          .auth()
          .currentUser.updateProfile(data)
          .then(() => {
            profile_service.getProfileInfo(token);
            //grab token from local storage and set to state.
            storage.getMXTData();
            storage.getFBData();
          });
      })
      .catch((err) => {
        if (err) {
          // //("there was an error");
        }
        setErrors((prev) => [...prev, err.message]);
      });
  },
  signin: (email, password, setErrors, setToken) => {
    //change from create users to...
    firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      //everything is almost exactly the same as the function above
      .then((userCredential) => {
        var user = userCredential.user;
        const token = user.getIdToken();
        //set token to localStorage
        localStorage.setItem("token", token);
        //set token to localStorage
        setToken(window.localStorage.token);
        storage.getMXTData();
        storage.getFBData();
      })
      .catch((err) => {
        setErrors((prev) => [...prev, err.message]);
      });
  },
  //no need for email and password
  signout: (setErrors, setToken) => {
    // signOut is a no argument function
    firebase
      .auth()
      .signOut()
      .then((res) => {
        //remove the token
        localStorage.removeItem("token");
        localStorage.clear();

        //set the token back to original state
        setToken(null);
      })
      .catch((err) => {
        //there shouldn't every be an error from firebase but just in case
        setErrors((prev) => [...prev, err.message]);
        //whether firebase does the trick or not i want my user to do there thing.
        localStorage.removeItem("token");
        localStorage.clear();

        setToken(null);
        console.error(err.message);
      });
  },

  printUserName: (setErrors, setToken) => {
    // signOut is a no argument function
  },
};
