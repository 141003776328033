import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useEffect, useState } from 'react';

const useAuth = () => {
  const [user, loading, error] = useAuthState(firebase.auth());
  const [isPrivate, setIsPrivate] = useState(false);

  // Function to detect private/incognito mode
  const detectPrivateMode = async () => {
    return new Promise((resolve) => {
      const on = () => resolve(true);  // Private mode
      const off = () => resolve(false); // Not private mode

      // Safari (iOS 11+)
      if (window.safariIncognito) return on();

      // Chrome & Firefox
      const fs = window.RequestFileSystem || window.webkitRequestFileSystem;
      if (!fs) return off();
      fs(window.TEMPORARY, 100, off, on);
    });
  };

  useEffect(() => {
    const checkPrivateModeAndSetPersistence = async () => {
      const isPrivateMode = await detectPrivateMode();
      setIsPrivate(isPrivateMode);

      if (isPrivateMode) {
        try {
          // Set persistence to 'session' in private mode
          await firebase.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION);
        } catch (error) {
          console.error('Error setting session persistence in private mode:', error);
        }
      } else {
        try {
          // Use 'local' persistence when not in private mode
          await firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL);
        } catch (error) {
          console.error('Error setting local persistence:', error);
        }
      }
    };

    checkPrivateModeAndSetPersistence();
  }, []);

  return { user, loading, error, isPrivate };
};

export default useAuth;