import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { Formik, Form, useField } from "formik";
import * as Yup from "yup";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import { fb } from "../../firebase/fb";

import GridContainer from "../themes/GridContainer";
import MerchPricingBox from "../artist_dashboard/merch/MerchPricingBox";
import MerchQuantityBox from "../artist_dashboard/merch/MerchQuantityBox";
import MerchOptionsBox from "../artist_dashboard/merch/MerchOptionsBox";
import MerchSelectBox from "../artist_dashboard/merch/MerchSelectBox";
import MerchDetailsBox from "../artist_dashboard/merch/MerchDetailsBox";
import merch from "../../services/merch";
import ProductImageUploader from "../file_mgmt/ProductImageUploader";
import StandardButton from "./StandardButton";
import { Link } from "react-router-dom";

// And now we can use these
const ProductForm = ({ merchItem }) => {
  const [uid, setUID] = useState();
  const [token, setToken] = useState();
  const [shipsIntl, setShipping] = useState(false);
  const [options, setOptions] = useState(true);
  const [_merchItem, setMerchItem] = useState({});
  const [loaded, setLoaded] = useState(false);
  const [docId, setDocId] = useState();
  const [publishClicked, setPublishClicked] = useState(false);
  useEffect(() => {
    var name, email, photoUrl, uid, emailVerified;
    var user = firebase.auth().currentUser;
    setOptions(merchItem.hasAdditionalOptions);
    setMerchItem(merchItem);
    setLoaded(true);
   
    firebase.auth().onAuthStateChanged(function (user) {
      if (user) {
        firebase
          .auth()
          .currentUser.getIdToken(/* forceRefresh */ true)
          .then(function (idToken) {
            setUID(user.uid);
            setToken(idToken);
          })
          .catch(function (error) {
            // Handle error
          });
        email = user.email;
        photoUrl = user.photoURL;
        emailVerified = user.emailVerified;
        uid = user.uid; // The user's ID, unique to the Firebase project. Do NOT use
      } else {
        return;
      }
    });
  }, [uid, token, loaded]);
  function successView() {
    return (
      <div>
        success! your merch is now public and ready for sale
        <Link to="/">
          <StandardButton>return to home</StandardButton>
        </Link>
      </div>
    );
  }
  function buildMerchObject() {
    // let last_updated = firebase.firestore.FieldValue.serverTimestamp();
    var now = firebase.firestore.Timestamp.now();

    var merchObject = {
      docId: docId,
      last_updated: now,
      category: "",
      name: "",
      description: "",
      price: 0,
      quantity: "",
      sku: "",
      ships_intl: true,
      // domestic_shipping: 20,
      // domestic_shipping_additional: 10,
      // intl_shipping: 0,
      // intl_shipping_additional: 0,
      option_1_id: "",
      option_2_id: "",
      option_3_id: "",
      option_name: "",
      option_1: "",
      option_1_quantity: "",
      option_1_sku: "",
      option_2: "",
      option_2_quantity: "",
      option_2_sku: "",
      option_3: "",
      option_3_quantity: "",
      option_3_sku: "",
      published: false,
    };
    return merchObject;
  }

  if (!publishClicked) {
    return (
      <>
        <GridContainer className="center">
          <h1 className="left" style={{ padding: 20, fontSize: 40 }}>
            add merch
          </h1>
          <Formik
            enableReinitialize={true}
            initialValues={_merchItem}
            validationSchema={Yup.object({
              name: Yup.string()
                .max(100, "Must be 35 characters or less")
                .required("REQUIRED"),
              description: Yup.string()
                .max(500, "Must be 500 characters or less")
                .required("REQUIRED"),
              price: Yup.number()
                .max(15000, "must be $15000 or less")
                .required("REQUIRED"),
              sku: Yup.string()
                .max(200, "must be 200 char or less")
                .required("REQUIRED"),
              // ships_intl: Yup.boolean().required("REQUIRED"),
              // .oneOf([true], "You must accept the terms and conditions."),
              category: Yup.string()
                .oneOf(
                  ["cassette", "vinyl", "shirt", "other"],
                  "Invalid Job Type"
                )
                .required("REQUIRED"),
            })}
            style={{ color: "red" }}
            onSubmit={(values, { setSubmitting }) => {
              // values.last_edited = firebase.firestore.FieldValue.serverTimestamp();
              // let product = JSON.stringify(values, null, 2);
              // alert(JSON.stringify(values, null, 2));
              // alert(product);
              // merch.newMerchItem(values, uid);

              merch.updateMerchItem(values, uid);

              // if (!publishClicked) {
                alert("saved!");
              // }
              // alert(uid)
              // merch.newProduct(values, uid);
              setTimeout(() => {
                // alert(JSON.stringify(values, null, 2));
                setSubmitting(false);
              }, 400);
            }}
          >
            <GridContainer className="center  bordered padded">
              <Form className="padded">
                <MerchSelectBox></MerchSelectBox>
                <MerchDetailsBox merchItem={merchItem}></MerchDetailsBox>

                <MerchPricingBox></MerchPricingBox>
                {/* <MerchShippingBox shipsIntl={shipsIntl}></MerchShippingBox> */}
                <MerchQuantityBox></MerchQuantityBox>
                <MerchOptionsBox _options={options}></MerchOptionsBox>
                <div className="padded">
                  <button type="submit" className="grid-button">
                    save
                  </button>

                  <StandardButton
                    onClick={() => {
                     
                      firebase.auth().onAuthStateChanged(function (user) {
                        if (user) {
                          //("signed in");
                          firebase
                            .auth()
                            .currentUser.getIdToken(/* forceRefresh */ true)
                            .then(function (idToken) {
                              setPublishClicked(true);
                              merch.publish(merchItem.docId, idToken);
                            });
                        } else {
                          alert("error, try logging out and back in");
                        }
                      });
                      // merch.publish(merchItem.docId);
                      // alert(JSON.stringify(merchItem, null, 2));
                      // alert("published!");
                    }}
                  >
                    publish
                  </StandardButton>
                </div>
              </Form>
            </GridContainer>
          </Formik>
        </GridContainer>
      </>
    );
  } else {
    return <div>{successView()}</div>;
  }
};

export default ProductForm;
