import React, { useState } from "react";
import firebase from "firebase/compat/app";
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";
import { useNavigate } from "react-router-dom";
import APIConfig from "../APIConfig";
import "firebase/compat/auth";

const FBUISignin = (props) => {
  const [signedIn, setSignedIn] = useState(false);
  let navigate = useNavigate();

  const emailProviderDict = { requireDisplayName: true, provider: firebase.auth.EmailAuthProvider.PROVIDER_ID };
  const phoneProviderDict = { requireDisplayName: true, provider: firebase.auth.PhoneAuthProvider.PROVIDER_ID };

  const getAuthProvider = () => {
    if (APIConfig.studio() === true) {
      return [emailProviderDict, phoneProviderDict];
    } else {
      return [phoneProviderDict];
    }
  }

  const uiConfig = {
    signInFlow: "popup",
    signInOptions: getAuthProvider(),
    callbacks: {
      signInSuccessWithAuthResult: (authResult, redirectUrl) => {
        console.log("signInSuccessWithAuthResult", authResult, redirectUrl);
        navigate('/create_username_from_event', { state: { eventData: props.event } });
        return false;
      },
    },
  };

  return (
    <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()} />
  );
};

export default FBUISignin;