import React, { useRef } from "react";
import { atcb_action } from "add-to-calendar-button";
import { CalendarIcon } from "@heroicons/react/24/solid";

const AddToCalendarButton = ({ event }) => {
  const addCalendarButtonRef = useRef(null);

  // Extracting event details safely
  const eventName = event?.name || "Event";
  const eventDescription = event?.description || "Event Description";
  const eventDate = event?.date || "";
  const eventTime = event?.time?.substring(0, 5) || "00:00"; // Extracting HH:MM from HH:MM:SS
  const venue = event?.venue || {};
  const venueName = venue.name || "Venue";
  const address = venue.address || {};
  const addressLine1 = address.address_line_1 || "";
  const city = address.city || "";
  const state = address.state || "";
  const zipCode = address.zip_code || "";

  const eventDetails = {
    name: eventName,
    description: `${eventDescription}\n\nMore info: https://phaze.rsvp/e/${event.id}`,
    startDate: eventDate,
    startTime: eventTime,
    endTime: "23:59", // End time as needed
    timeZone: "America/New_York", // Adjust the time zone as needed.
    location: `${venueName}, ${addressLine1}, ${city}, ${state}, ${zipCode}`,
    options: ["Outlook.com", "Apple", "Google"], // Options for the calendar services
    buttonsList: true,
    identifier: "atcb-btn-1",
    size: "4",
  };

  const handleOnClickAddCalendar = () => {
    atcb_action(eventDetails, addCalendarButtonRef.current);
  };

  return (
    <button
      id="atcb-btn-1"
      onClick={handleOnClickAddCalendar}
      ref={addCalendarButtonRef}
      className="flex items-center p-2 rounded-lg bg-gray-100 dark:bg-gray-800 hover:bg-gray-200 dark:hover:bg-gray-700 text-gray-700 dark:text-gray-300 transition-colors duration-300"
    >
      <CalendarIcon className="h-5 w-5 mr-2 text-gray-500 dark:text-gray-400 group-hover:text-gray-700 dark:group-hover:text-gray-100 transition-colors duration-300" />
      <span className="text-sm font-mono group-hover:text-gray-700 dark:group-hover:text-gray-100 transition-colors duration-300">
        add to calendar
      </span>
    </button>
  );
};

export default AddToCalendarButton;