import React, { useState, useEffect } from "react";
import { AppStoreButton as MobileAppStoreButton } from "react-mobile-app-button";

const AppStoreButton = () => {
  const IOSUrl = "https://apps.apple.com/us/app/mixtape/id1391354414"; // Replace with your actual App Store link
  const [theme, setTheme] = useState("light");

  useEffect(() => {
    const isDarkMode = window.matchMedia("(prefers-color-scheme: dark)").matches;
    setTheme(isDarkMode ? "dark" : "light");

    const themeChangeListener = (e) => {
      setTheme(e.matches ? "dark" : "light");
    };

    window.matchMedia("(prefers-color-scheme: dark)").addEventListener("change", themeChangeListener);

    return () => {
      window.matchMedia("(prefers-color-scheme: dark)").removeEventListener("change", themeChangeListener);
    };
  }, []);

  return (
    <div className="mt-4 font-sans">
      <MobileAppStoreButton url={IOSUrl} theme={theme} />
    </div>
  );
};

export default AppStoreButton;