// fileUtils.js
// fileUtils.js
export const fileSizeInMB = (size) => {
    return Math.round(size / 1000000);
  };

// filenameUtils.js

// Utility to sanitize file names
export const sanitizeFilename = (filename) => {
  // Normalize Unicode characters
  let sanitized = filename.normalize("NFKC");

  // Use a whitelist to allow alphanumeric characters, and some symbols
  sanitized = sanitized.replace(/[^a-zA-Z0-9. \-_()]/g, "_");

  // Check for reserved filenames and modify if necessary
  const reservedNames = [
    "CON", "PRN", "AUX", "NUL",
    "COM1", "COM2", "COM3", "COM4", "COM5", "COM6", "COM7", "COM8", "COM9",
    "LPT1", "LPT2", "LPT3", "LPT4", "LPT5", "LPT6", "LPT7", "LPT8", "LPT9",
  ];
  if (reservedNames.includes(sanitized.toUpperCase())) {
    sanitized = `_${sanitized}`;
  }

  // Shorten the filename if it's too long
  const maxLength = 255;
  if (sanitized.length > maxLength) {
    const extension = sanitized.lastIndexOf(".") !== -1
      ? sanitized.substring(sanitized.lastIndexOf("."))
      : "";
    sanitized = sanitized.substring(0, maxLength - extension.length) + extension;
  }

  // Ensure extension is safe
  if (sanitized.includes(".")) {
    const parts = sanitized.split(".");
    const extension = parts.pop();
    sanitized = parts.join(".") + "." + extension.replace(/[^a-zA-Z0-9]/g, "_");
  }

  return sanitized;
};
