// EditAudioModal.jsx
import React, { useContext } from "react";
import { FileContext } from "../contexts/fileContext";
import AudioWaveform from "./AudioWaveform";

const EditAudioModal = ({ item, onClose }) => {
  const { setFileURL } = useContext(FileContext);

  React.useEffect(() => {
    setFileURL(item.downloadURL);
  }, [item.downloadURL, setFileURL]);
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 z-20 flex items-center justify-center">
      <div className="bg-white dark:bg-gh_charcoal_secondary-100 p-8 rounded shadow-md w-4/5 h-3/5 max-w-6xl max-h-6xl flex flex-col justify-between">
        <div>
          <h2 className="mb-4">Edit Audio</h2>
          <AudioWaveform item={item} />
        </div>
        <div className="flex justify-end">
          <button
            className="mt-4 bg-blue-500 text-white p-2 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-50"
            onClick={onClose}
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default EditAudioModal;
