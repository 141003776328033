import React from "react";

function ContactEmail({ email }) {
  return (
    <div className="flex flex-col items-center mt-8">
      <p className="mb-2">for any issues, please contact:</p>
      <a href={`mailto:${email}`}>{email}</a>
    </div>
  );
}

export default ContactEmail;