export const OPEN_PUBLISHER = 'OPEN_PUBLISHER'
export const CLOSE_PUBLISHER = 'CLOSE_PUBLISHER'
export const ADDING_TO_ORDERS = 'ADDING_TO_ORDERS'
export const REDIRECT_TO_CHECKOUT_SCREEN = 'REDIRECT_TO_CHECKOUT_SCREEN'
export const RESET_CHECKOUT_STATE = 'RESET_CHECKOUT_STATE'

export const openPublisherModal = () => (
  dispatch => dispatch({
    type: OPEN_PUBLISHER,
  })
)

export const closePublisherModal = () => (
  dispatch => dispatch({
    type: CLOSE_PUBLISHER,
  })
)

export const addToOrders = (orders) => (
  dispatch => dispatch({
    type: ADDING_TO_ORDERS,
    orders
  })
)

export const redirectToCheckoutScreen = () =>(
  dispatch => dispatch({
    type: REDIRECT_TO_CHECKOUT_SCREEN
  })
)

export const resetCheckoutState = () => (
  dispatch => dispatch({
    type: RESET_CHECKOUT_STATE
  })
)
