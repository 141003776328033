
export const LOGIN_GRANTED = 'LOGIN_GRANTED'
export const RETRIEVING_TOKEN = 'RETRIEVING_TOKEN'
export const STORING_REFRESHED_TOKEN = 'STORING_REFRESHED_TOKEN'
export const LOGGING_OUT = 'LOGGING_OUT'


export const grantLoginSession = () => (
  dispatch => dispatch({
    type: LOGIN_GRANTED,
  })
)


export const exitSession = () => (
  dispatch => dispatch({
    type: LOGGING_OUT,
  })
)




