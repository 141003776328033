// this is the view for creating a new record label
// it contains a form for the user to fill out
// the form contains a text input for the label name
// and a text input for the label's website
// the form also contains a button for the user to submit the form

// the form is rendered by the getFormView function

// it uses the useInput hook to handle the form's state

import React, { useState } from "react";
import { useForm } from "react-hook-form";
// import useInput from "@rooks/use-input";
// import storage from "../../../services/storage";
// import StandardButton from "../../buttons/StandardButton";
// import Input from "./Input";

const LabelCreationView = () => {
  const { handleSubmit, errors } = useForm();
  const onSubmit = (data) => console.log(data);

  const [isEditingLabelName, setEditingLabelName] = useState(false);
  const [name, setLabelName] = useState("");

  const [input, setInput] = useState("");

  const onChange = (e) => {
    setInput(e.currentTarget.value);
  };
  const register = () => {
    console.log("registering");
  };
  //   const errors = () => {
  //     console.log("errors");
  //   };

  function getFormView() {
    return (
      <form onSubmit={handleSubmit(onSubmit)}>
        <Input
          name="labelName"
          label=""
          placeholder="label name"
          errors={errors}
          onChange={onChange}
          value={input}
        />
        <div className="py-2">
          <div className=" border-2 border-green-400 w-40 ">
            <button type="submit" className="px-2 text-green-400 w-40">
              submit
            </button>
          </div>
        </div>
      </form>
    );
  }

  return (
    <div className="bg-black text-blue-400">
      <h1>create a new label</h1>
      {getFormView()}
    </div>
  );
};

export default LabelCreationView;

const Input = ({ name, label, register, errors, onChange, value }) => {
  return (
    <div className="">
      <label>{label}</label>
      {/* <div className="px-5"> 
      </div> */}
      <input
        name={name}
        onChange={onChange}
        value={value}
        placeholder={"label name"}
        className="bg-black text-blue-400 border-2 border-blue-600 placeholder-neutral-600"
      />
      {/* {errors[name] && <p>{errors[name].message}</p>} */}
    </div>
  );
};
