// FinalizeMaster.jsx;
import React, { useEffect, useState } from "react";
import {
  Link,
  useLocation,
} from "react-router-dom";
import api from "../../../api";
import mastering from "../../../services/mastering";
import storage from "../../../services/storage";
import StandardButton from "../../buttons/StandardButton";
const FinalizeMaster = () => {
  const [isLoading, setLoading] = useState(true);
  const [album, setAlbum] = useState({});
  const [published, setCheckingOut] = useState(false);
  let location = useLocation();
  //   let _album = location.album;

  function getTracks() {
    const track_list = album.tracks
      .slice(0)
      .reverse()
      .map((track, index) => (
        <ol key={track.name}>
          <span>
            {index + 1}. {track.name}
          </span>
          <br></br>
        </ol>
      ));
    return track_list;

    //   const { next } = navigation;
  }
  useEffect(() => {
    //   get most recently edited draft on refresh

    if (location.album == null) {
      storage.getRecentDrafts().then((album) => {

        mastering.validateMaster(album).then((res) => {

          setAlbum(res);
          setLoading(false);
        });
      });
    } else {
      setAlbum(location.album);
      setLoading(false);
    }
  }, []);
  function checkout() {
    // api.newAlbum(album.docId);
    api.checkout();
    setCheckingOut(true);
  }
  function albumView() {
    return (
      <div>
        {isLoading ? (
          "loading... "
        ) : (
          <div className="form">
            {album.name}
            <img src={album.image_url} style={{ height: 150 }}></img>

            {getTracks()}
            <div>
              {
                <StandardButton mxt_green onClick={() => checkout()}>
                  {" "}
                  checkout
                </StandardButton>
              }

            </div>
          </div>
        )}
      </div>
    );
  }
  function successView() {
    return (
      <div>
        success! your album will be available shortly
        <Link to="/">
          <StandardButton>return to home</StandardButton>
        </Link>
      </div>
    );
  }
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "blue",
        color: "white",
      }}
    >
      {published ? successView() : albumView()}
    </div>
  );
};

export default FinalizeMaster;
