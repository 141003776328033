// BillingButton.jsx


import React, { useState } from "react";
import billing from "../../services/billing";
import StandardButton from "../buttons/StandardButton";
import WideButton from "../buttons/WideButton";


function BillingButton() {
    // const [showing, setShowing] = useState(false)

    return (
        <WideButton  lambda={() => {billing.open_billing()}} text="billing"/>
        // <StandardButton mxt onClick={() => {billing.open_billing()}}>

        //         billing

        // </StandardButton>
    );
}

export default BillingButton;
