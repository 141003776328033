// LimitedKnob.jsx
import React from "react";
import { Knob } from "react-rotary-knob";
import * as skins from "react-rotary-knob-skin-pack";

class LimitedKnob extends React.Component {
  constructor() {
    super();
    this.state = {
      value: 500, // Set the starting value to 500 (middle of the range)
    };
    this.handleOnChange = this.handleOnChange.bind(this);
  }

  handleOnChange(val) {
    const maxDistance = 200;
    let distance = Math.abs(val - this.state.value);
    if (distance > maxDistance) {
      return;
    } else {
      // Calculate the percentage of the new value within the original value range
      const percentage = val / 1000;

      // Limit the percentage to the desired rotation range (-155 to +155 degrees)
      const limitedPercentage = Math.min(Math.max(percentage, 0.155), 0.845);

      // Set the knob value based on the limited percentage
      const limitedValue = limitedPercentage * 1000;

      this.setState({ value: limitedValue });
    }
  }

  render() {
    let { value, ...rest } = this.props;

    return (
      <Knob 
        value={this.state.value}
        min = {0}
        max = {1000}
        skin={skins.s16}
        rotateDegrees={180}
        unlockDistance={50}
        preciseMode={true}
        onChange={this.handleOnChange}
        {...rest}
      />
    );
  }
}

export default LimitedKnob;
