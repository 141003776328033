import React from "react";
import useFetchArtistData from "../../events/hooks/useFetchArtistData";
import RotatingSlash from "../../loading/RotatingSlash";
import LightSmallSpinner from "../../loading/LightSmallSpinner";

const styles = {
  container: "mr-2",
  loadingText: "text-gray-500",
  errorText: "text-red-500",

  //   artistName: "font-thefut text-lg",
  // Add more styles as needed
};

const TrackCellArtistView = ({ artistId }) => {
  const { artistData, loading, error } = useFetchArtistData(artistId);

  if (loading)
    return (
      <p className={styles.loadingText}>
        <LightSmallSpinner />
      </p>
    );
  if (error)
    return (
      <p className={styles.errorText}>Error loading artist: {error.message}</p>
    );
  if (!artistData) return <p>No artist data available</p>;

  return (
    <div className={styles.container}>
      <p className={"font-theFutureLight text-gray-400"}>{artistData.name}</p>
      {/* Render more artist details here */}
    </div>
  );
};

export default TrackCellArtistView;
