import React from 'react';

const CommunityGuidelines = () => {
  return (
    <div className="legal-section p-6">
      <h2>Community Guidelines</h2>
      <p>Our goal is to foster a respectful and creative community. Users are expected to:</p>
      <ul>
        <li>Avoid hate speech, violent content, or explicit materials.</li>
        <li>Engage respectfully and responsibly with others.</li>
      </ul>
    </div>
  );
};

export default CommunityGuidelines;