import React from "react";
import { TrashIcon } from "@heroicons/react/24/outline"; // Updated to v2 icon
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import storage from "../../../services/storage";

export default function DeleteAlbumButton({ album }) {
  const handleDelete = () => {
    confirmAlert({
      title: "Confirm Deletion",
      message: `Are you sure you want to delete "${album.name}"?`,
      buttons: [
        {
          label: "Delete",
          onClick: () => {
            storage.deleteAlbum(album);
          },
          className: "btn-red",
        },
        {
          label: "Cancel",
          onClick: () => {},
          className: "btn-gray",
        },
      ],
    });
  };

  return (
    <button
      onClick={handleDelete}
      className="flex items-center text-red-500 hover:text-red-600 transition-colors duration-150 ease-in-out focus:outline-none"
    >
      <TrashIcon className="h-5 w-5 mr-1" />
      <span className="text-sm">Delete</span>
    </button>
  );
}