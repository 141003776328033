import styled, { css } from "styled-components";
const StandardButton = styled.button`
  background: transparent;
  border-radius: 0px;
  border: 2px solid white;
  /* color: palevioletred; */
  color: white;
  margin: 0.5em 1em;
  padding: 0.25em 1em;
  /* font-size: 1em; */
  font-size: 1em;
  // font roboto mono
  font-family: "menlo";
  
  ${(props) =>
    props.primary &&
    css`
      background: palevioletred;
      color: white;
    `}
  ${(props) =>
    props.mxt_primary &&
    css`
      background: cornflowerblue;
      color: white;
      border: 2px solid cornflowerblue;
    `}
    ${(props) =>
    props.mxt_red &&
    css`
      /* background: #91b3ff; */
      border: 2px solid red;
      color: red;
    `}

  ${(props) =>
    props.mxt &&
    css`
      /* background: blue; */
      /* color: #91b3ff;
      border: 2px solid #91b3ff; */
      color: cornflowerblue;
      border: 2px solid cornflowerblue;
    `}
    ${(props) =>
      props.mxt_turquoise &&
      css`
        /* background: blue; */
        /* color: #91b3ff;
        border: 1px solid #91b3ff; */
        color: paleturquoise;
        border: 1px solid paleturquoise;
      `}
      ${(props) =>
        props.mxt_light_green &&
        css`
          /* background: blue; */
          /* color: #91b3ff;
          border: 1px solid #91b3ff; */
          color: lightgreen;
          border: 1px solid lightgreen;
        `}
    ${(props) =>
    props.stripe &&
    css`
      /* background: blue; */
      color: #1cc6ff;
      border: 2px solid #1cc6ff;
    `}
    ${(props) =>
    props.mxt_green &&
    css`
      /* background: blue; */
      /* color: green; */
      color: #00c929;
      border: 2px solid #00c929;
    `}
    ${(props) =>
    props.mxt_green_wide &&
    css`
      /* background: blue; */
      width: 50px;
      color: green;
      border: 2px solid green;
    `}
    ${(props) =>
    props.light &&
    css`
      background: white;

      color: #91b3ff;
      border: 1px solid #91b3ff;
    `}
    ${(props) =>
      props.mxt_light &&
      css`
        // background: white;
  
        color: cornflowerblue;
        border: 1px solid cornflowerblue;
      `}
      ${(props) =>
        props.mxt_white &&
        css`
          // background: white;
    
          color: white;
          border: 1px solid white;
        `}
        ${(props) =>
          props.mxt_studer &&
          css`
            // background: white;
      
            color: #D1D2CE;
            border: 1px solid #D1D2CE;
          `}
`;

export default StandardButton;
