import React from "react";
import AudioChannel from "./AudioChannel";

const AudioMixer = ({ audioSources }) => {
  return (
    <div className="flex flex-row justify-center items-center bg-gray-800 p-4">
      {audioSources.map((audioData, index) => (
        <AudioChannel key={index} audioData={audioData} />
      ))}
    </div>
  );
};

export default AudioMixer;
