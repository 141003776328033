import React, { useState, useEffect } from "react";
import { useNavigate, useLocation, useParams, Link } from "react-router-dom";
import LoadingSpinner from "../loading/LoadingSpinner";
import { EventDetailService } from "../file_mgmt/drive/hooks/loadBannerImage";


const EventPreviewView = ({ event }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [bannerImage, setBannerImage] = useState(null);

  useEffect(() => {
    const loadBannerImage = async () => {
      try {
        const bannerImageUrl = await EventDetailService.loadBannerImage(
          event.id.toString()
        );
        setBannerImage(bannerImageUrl);
      } catch (error) {
        console.error("Error loading banner image:", error);
      }
    };

    loadBannerImage();
  }, [event.id]);

  const getListView = () => {
    return (
      <Link
        to={`/e/${event.id}`}
        className="block max-w-md mx-auto my-4 p-6 bg-white dark:bg-gray-800 shadow-lg rounded-lg transition transform hover:scale-105 hover:shadow-xl"
      >
        {bannerImage ? (
          <div className="relative w-full h-80 overflow-hidden rounded-lg mb-4">
            <img
              src={bannerImage}
              alt="Event Banner"
              className="object-cover w-full h-full"
            />
          </div>
        ) : (
          <div className="w-full h-80 bg-gray-200 dark:bg-gray-700 rounded-lg mb-4"></div>
        )}
        <h1 className="text-2xl font-bold text-gray-900 dark:text-gray-100 mb-2">
          {event.name}
        </h1>
        <p className="text-gray-700 dark:text-gray-300 mb-4">
          {event.description}
        </p>
        <div className="text-gray-600 dark:text-gray-400">
          <p className="mb-2">
            {/* <span className="font-semibold">date: </span> */}
            {event.date}
          </p>
          <p className="mb-2">
            {/* <span className="font-semibold">time: </span> */}
            {event.time}
          </p>
        </div>
      </Link>
    );
  };

  if (loading) {
    return <LoadingSpinner />;
  } else if (error) {
    return (
      <div className="max-w-md mx-auto my-4 p-6 bg-red-100 dark:bg-red-800 shadow-lg rounded-lg text-red-900 dark:text-red-100">
        <p>Error loading event: {error.message}</p>
      </div>
    );
  } else {
    return getListView();
  }
};

export default EventPreviewView;
