import {SET_CURRENT_TRACK} from "../actions/trackActions";

export default (state = {currentTrack: '', currentTrackName: ''}, action = {}) => {
  
  switch (action.type) {
    case SET_CURRENT_TRACK:
      // alert("setting current track")
      console.log("current drive track: ", action.currentTrack)
      return {...state, currentTrack: action.currentTrack, currentTrackName: action.currentTrackName}
    default:
      return state
  }
}
