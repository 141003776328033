// src/components/Album.js
import React, { useState, useEffect } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import TrackEditor from "./TrackEditor";
import Track from "./Track";
import storage from "../../../services/storage"; // Ensure this path is correct
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import EditAlbumMetadataView from "../dashboard/EditAlbumMetadataView";

const Album = ({ initialAlbum }) => {
  const [album, setAlbum] = useState(
    initialAlbum || { title: "New Album", tracks: [] }
  );

  useEffect(() => {
    if (initialAlbum) {
      setAlbum(initialAlbum);
    }
  }, [initialAlbum]);

  const handleAddTrack = (newTrack) => {
    const updatedTracks = [...album.tracks, { ...newTrack, id: Date.now() }];
    const updatedAlbum = { ...album, tracks: updatedTracks };
    setAlbum(updatedAlbum);
    storage.setAlbumTrack(newTrack, updatedAlbum); // Adjust this line as per your storage service
  };

  const updateTrack = (updatedTrack) => {
    const updatedTracks = album.tracks.map((track) =>
      track.id === updatedTrack.id ? updatedTrack : track
    );
    const updatedAlbum = { ...album, tracks: updatedTracks };
    setAlbum(updatedAlbum);
    storage.updateAlbumDraftTrack(updatedTrack, updatedAlbum); // Adjust this line as per your storage service
  };

  const onDragEnd = (result) => {
    if (!result.destination) return;
    const items = Array.from(album.tracks);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setAlbum({ ...album, tracks: items });
    console.log("updated album", album);
    storage.updateAlbumIndices(items); // Adjust this line as per your storage service
  };

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-3xl font-bold mb-6">
        {album?.title || "Loading..."}
      </h1>
      {album ? (
        <>
          <EditAlbumMetadataView album={album} />
          <TrackEditor onSave={handleAddTrack} />
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="tracks">
              {(provided) => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  {album.tracks.map((track, index) => (
                    <Draggable
                      key={track.id}
                      draggableId={track.id.toString()}
                      index={index}
                    >
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          className={`mb-2 p-2 bg-white dark:bg-gray-800 shadow rounded ${
                            snapshot.isDragging
                              ? "bg-blue-100 dark:bg-blue-900"
                              : ""
                          }`}
                        >
                          <Track
                            track={track}
                            index={index}
                            onUpdate={updateTrack}
                          />
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </>
      ) : (
        <p>Album data is loading...</p>
      )}
    </div>
  );
};

export default Album;
