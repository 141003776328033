import React from 'react';

const ContactUs = () => {
  return (
    <div className="legal-section p-6">
      <h2>Contact Us</h2>
      <p>If you have any inquiries or complaints, please reach out to us:</p>
      <p>Email: samir@dotmixtape.com</p>
      {/* <p>Phone: 1-800-555-0199</p> */}
      {/* <p>Mailing Address: .mixtape, Tuareg LLC, 123 Main Street, Anytown, USA</p> */}
    </div>
  );
};

export default ContactUs;