import React from "react";

const ProfileHeader = ({ username, bio, created, is_followed, is_seller }) => {
  return (
    <div className=" bg-gray-100 dark:bg-gray-900">
      <h1 className="text-4xl font-bold mb-6 text-black dark:text-white uppercase">
        Profile Details
      </h1>
      <h2 className="text-2xl font-mono font-bold text-black dark:text-white mb-4">
        @{username}
      </h2>

      <div className="space-y-2 text-lg text-black dark:text-white">
        <p className="font-mono">
          <span className="font-bold uppercase">Bio:</span>{" "}
          {bio || <span className="italic">Not provided</span>}
        </p>
        <p className="font-mono">
          <span className="font-bold uppercase">Account Created:</span>{" "}
          {new Date(created).toLocaleDateString()}
        </p>
        <p className="font-mono">
          <span className="font-bold uppercase">Seller Account:</span>{" "}
          {is_seller ? "Yes" : "No"}
        </p>
      </div>
    </div>
  );
};

export default ProfileHeader;
