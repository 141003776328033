import React, { useState } from "react";
import { useForm } from "react-hook-form";
import api from "../../api";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import { useAuthState } from "react-firebase-hooks/auth";
import { getAuth } from "firebase/auth";
import WideButton from "../buttons/WideButton";
import { ArrowPathIcon } from "@heroicons/react/24/outline";
import { useNavigate } from "react-router-dom"; // Add this import
const auth = getAuth(firebase.app());

export default function ArtistRegistrationForm() {
  const { register, handleSubmit } = useForm();
  const [artistName, setArtistName] = useState("");
  const [user, loading, error] = useAuthState(auth);
  const [errorMessage, setErrorMessage] = useState("");
  const [submitting, setSubmitting] = useState(false);
  const history = useNavigate(); // Add this line

  const onSubmitForm = async (data) => {
    setSubmitting(true);
    try {
      const response = await api.newArtist(artistName, user.uid);
      const responseData = await response.json();
      console.log(responseData);
      history.push("/artist-dashboard");
    } catch (err) {
      console.error("Error submitting artist registration form:", err);
      setErrorMessage("An error occurred during registration. Please try again.");
    } finally {
      setSubmitting(false);
    }
  };
  
  return (
    // Remove GridContainer
    <>
      <div className="center padded ">register as an artist</div>
      <input
        className="text-box bg-gray-700 dark:bg-gray-800 border-2 border-gray-300 dark:border-gray-600 rounded-lg px-3 py-2 dark:text-white"
        placeholder="artist name"
        type="text"
        name="artistName"
        {...register("artistName")}
        onChange={(e) => setArtistName(e.target.value)}
      />
      <button
        className="text-center py-0.5 w-40 h-7 font-theFutureMonoRegular text-slate-100 bg-slate-700 hover:bg-violet-400 hover:text-slate-300 hover:border-slate-300 dark:text-violet-300 dark:border-violet-400 border-2 col-span-1 mt-4"
        type="submit"
        onClick={handleSubmit(onSubmitForm)}
        disabled={artistName.length === 0}
      >
        {submitting ? (
          <ArrowPathIcon className="animate-spin h-5 w-5 mx-auto" />
        ) : (
          "submit"
        )}
      </button>
      {errorMessage && (
      <div className="mt-4 text-red-500">
        {errorMessage}
      </div>
    )}
    </>
  );
}
