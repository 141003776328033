import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import WideButton from "../../buttons/WideButton";
import storage from "../../../services/storage";
import { useAuthState } from "react-firebase-hooks/auth";

// takes a tape object and generates a shareable link to the tape
const GetShareLinkButton = ({ tape }) => {
  const [shareableLink, setShareableLink] = useState("");
  const [loading, setLoading] = useState(false);
  const { slug } = useParams();
  const [token, setToken] = useState("");
  const db = firebase.firestore();
  const [error, setError] = useState("");
  const [user] = useAuthState(firebase.auth());

  useEffect(() => {
    if (user) {
      console.log('tape', tape)
      user
        .getIdToken(/* forceRefresh */ true)
        .then(function (idToken) {
          setToken(idToken);
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  }, [user]);

  const getShareableLink = async () => {
    console.log('tape', tape)
    console.log('uid', tape.id)
    setLoading(true);
    const shareableLink = await storage.getShareableLink(tape, user.uid, token);
    setShareableLink(shareableLink);
    setLoading(false);
  };

  const getUidLink = () => {
    setLoading(true);
    const uidLink = `${window.location.origin}/tapes/${tape.id}`;
    navigator.clipboard.writeText(uidLink)
      .then(() => {
        setShareableLink(uidLink);
        setLoading(false);
      })
      .catch(error => {
        console.error(error);
        setError('Error copying to clipboard');
        setLoading(false);
      });
  };
  

  const share = () => {
    getShareableLink();
  };

  function getLoadingIndicatorView() {
    return <p>Loading...</p>;
  }

  const getShareableLinkView = () => {
    if (loading) {
      return getLoadingIndicatorView();
    } else {
      return (
        <div>
          <WideButton lambda={share} text="Share" />
          <WideButton lambda={getUidLink} text="Get UID Link" />
        </div>
      );
    }
  };

  return getShareableLinkView();
};

export default GetShareLinkButton;
