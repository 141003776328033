// tapes.js

// import APIConfig from "./APIConfig";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import APIConfig from "../APIConfig";
import utils from "../components/artist_dashboard/utilities/utils";
import { authMethods } from "../firebase/authmethods";
import { getDocs, onSnapshot } from "firebase/firestore";
import storageUtils from "./utils/storage_utils";
const token = localStorage.getItem("token");

const db = firebase.firestore();


const fetchTapes = (uid, setTapes, setLoading) => {
  const tapes_ref = getTapesRef(uid);
  const unsubscribe = onSnapshot(tapes_ref, (querySnapshot) => {
    const tapes = [];
    querySnapshot.forEach((doc) => {
      tapes.push({ ...doc.data(), id: doc.id });
    });
    setTapes(tapes);
    setLoading(false);
  });

  return unsubscribe;
};

const setStudioData = (downloadURL, uid, filename) => {
  db.collection("studio")
    .doc(uid)
    .collection("tapes")
    .add({
      filename: filename,
      url: downloadURL,
    })
    .then(function (docRef) {
      ////("Document written with ID: ", docRef.id);
    })
    .catch(function (error) {
      console.error("Error adding document: ", error);
    });
};

function parseTrackObjectFromChange(change) {
  const track_doc = change.doc;
  var track_data = track_doc.data();
  const track_obj = parseTrackData(track_data, track_doc.id);
  return track_obj;
}

function parseTapeData(tape_data, tape_doc_id) {
  var name = tape_data.name;
  var description = tape_data.description;
  const public_url = tape_data.public_url;
  console.log("the DOC ID ", tape_doc_id);
  var tape_obj = {
    name: name,
    description: description,
    id: tape_doc_id,
    public_url: public_url,
    tracks: [],
  };
  return tape_obj;
}
function parseTrackData(track_data) {
  var track_obj = {
    channel: track_data.channel,
    filename: track_data.filename,
    url: track_data.url,
    id: track_data.id,
  };
  return track_obj;
}

function getDriveRef(uid) {
  return db.collection("studio").doc(uid).collection("drive");
}

function getTapeTracksRef(user_uid, doc_id) {
  // console.log("the path is ", drive_ref.path);
  const tape_tracks_ref = db.collection("studio").doc(user_uid).collection("drive").doc(doc_id).collection("tracks")
  .orderBy("channel");
  return tape_tracks_ref;
}


function getTapeRef(user_uid, tape_uid) {
    
    const tape_ref = db.collection("studio").doc(user_uid).collection("drive").doc(tape_uid);
    return tape_ref;
  }


function getTapesRef(user_uid) {
  const tape_ref = db.collection("studio").doc(user_uid).collection("drive");
  return tape_ref;
}
function getDocsWrapper(tape_tracks_ref) {
  return getDocs(tape_tracks_ref);
}

function onTapeSnapshotWrapper(tape_ref, callback) {
  return onSnapshot(tape_ref, { includeMetadataChanges: true }, callback);
}
// ... (existing imports and code)
function handleTrackChanges(tape_tracks_ref, callback) {
  const unsub = onSnapshot(
    tape_tracks_ref,
    { includeMetadataChanges: true },
    (snapshot) => {
      let trackChanges = false;
      let newTrackList = [];

      snapshot.docChanges().forEach((change) => {
        if (change.type === "added") {
          console.log("track added");
          var track_obj_added = parseTrackObjectFromChange(change);
          newTrackList.push(track_obj_added);
          trackChanges = true;
        } else if (change.type === "modified") {
          var track_obj_mod = parseTrackObjectFromChange(change);
          const track_index = newTrackList.findIndex(
            (track) => track.id === track_obj_mod.id
          );
          newTrackList[track_index] = track_obj_mod;
          trackChanges = true;
        } else if (change.type === "removed") {
          var track_obj_rem = parseTrackObjectFromChange(change);
          const track_index = newTrackList.findIndex(
            (track) => track.id === track_obj_rem.id
          );
          newTrackList.splice(track_index, 1);
          trackChanges = true;
        } else {
          console.log("track change type not recognized");
        }
      });

      if (trackChanges) {
        callback(newTrackList);
      }
    }
  );

  return unsub;
}

const deleteTape = async (tapeUid) => {
  const userUid = getCurrentUserUid();
  if (!userUid) {
    // Handle the case when the user is not logged in
    console.log("not logged in")
    return;
  }
  // const tapeRef = getTapeRef(userUid, tapeUid);
  // const tapeTracksRef = getTapeTracksRef(userUid, tapeUid);
  // const tapeTracksSnapshot = await getDocsWrapper(tapeTracksRef);

  // tapeTracksSnapshot.forEach(async (trackDoc) => {
  //   await tapeTracksRef.doc(trackDoc.id).delete();
  // });

  // await tapeRef.delete();

  // Call StorageUtils delete_tape method
  await storageUtils.deleteTape(userUid, tapeUid);
};

const getCurrentUserUid = () => {
  const user = firebase.auth().currentUser;
  return user ? user.uid : null;
};


const tape_service = {
  getTapeRef: getTapeRef,
  getTapeTracksRef: getTapeTracksRef,
  getDriveRef: getDriveRef,
  parseTrackObjectFromChange: parseTrackObjectFromChange,
  parseTapeData: parseTapeData,
  getTapesRef: getTapesRef,
  getDocs: getDocsWrapper,
  onTapeSnapshot: onTapeSnapshotWrapper,
  handleTrackChanges: handleTrackChanges,
  fetchTapes,
  deleteTape,
};

export default tape_service;