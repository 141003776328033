// useImageUpload.js
import { useState } from "react";
import firebase from "firebase/compat/app";
import "firebase/compat/storage";
import storage from "../../../../../services/storage";

const useImageUpload = () => {
  const [progress, setProgress] = useState(0);
  const [uploadStatus, setUploadStatus] = useState(null);

  const uploadImage = async (file, album) => {
    setUploadStatus("uploading");
    const uploadTask = firebase.storage().ref(`studio/user_storage/${firebase.auth().currentUser.uid}/files/images/${file.name}`).put(file);

    return new Promise((resolve, reject) => {
      uploadTask.on(
        firebase.storage.TaskEvent.STATE_CHANGED,
        snapshot => setProgress((snapshot.bytesTransferred / snapshot.totalBytes) * 100),
        error => {
          console.error(error);
          reject(error);
          setUploadStatus("failed");
        },
        async () => {
          const downloadURL = await uploadTask.snapshot.ref.getDownloadURL();
          console.log("downloadURL: ", downloadURL);
          console.log("file.name: ", file.name);
          console.log("album: ", album);
          await storage.updateAlbumCover(album, downloadURL, file.name);
          setUploadStatus("success");
          resolve(downloadURL);
        }
      );
    });
  };

  return { uploadImage, progress, uploadStatus };
};

export default useImageUpload;
