// useStorageHooks.js
import { useState, useContext, useCallback, useEffect } from "react";
import drive_service from "../../../../services/drive_service";
import { useAuthState } from "react-firebase-hooks/auth";
import "firebase/compat/firestore";
import firebase from "firebase/compat/app";
import { getProfilePictureStorageRef, getStorageRef } from "../../../../services/storage_service";
import PathContext from "../contexts/PathContext";;

const auth = firebase.auth();
export const useUploadFile = (items, setItems) => {
  const [user] = useAuthState(auth);
  const [uploading, setUploading] = useState(0);
  const [uploadProgress, setUploadProgress] = useState([]);
  const [tasks, setTasks] = useState([]);
  // const currentPath = useContext(PathContext);
  const { currentPath, setCurrentPath } = useContext(PathContext);

  const uploadFile = async (file, onProgress = null) => {
    if (!user) return;

    setUploading((prevUploading) => prevUploading + 1);

    const fileName = `${Date.now()}-${file.name}`;
    const filePath = fileName;

    // Determine the file type based on the MIME type
    const fileType = file.type.split("/")[0];
    console.log("fileType: ", fileType);

    const storageRef = getStorageRef(user.uid, filePath);
    console.log("storageRef: ", storageRef.fullPath); // Add this line to check the value of storageRef.fullPath
    const task = storageRef.put(file);
    setTasks((prevTasks) => [...prevTasks, { id: fileName, task, storageRef }]);

    task.on(
      "state_changed",
      (snapshot) => {
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;

        if (onProgress) {
          onProgress(progress);
        } else {
          setUploadProgress((prevUploadProgress) => {
            const existingProgressIndex = prevUploadProgress.findIndex(
              (upload) => upload.id === fileName
            );

            if (existingProgressIndex !== -1) {
              const updatedUploadProgress = [...prevUploadProgress];
              updatedUploadProgress[existingProgressIndex] = {
                id: fileName,
                progress,
              };
              return updatedUploadProgress;
            } else {
              return [...prevUploadProgress, { id: fileName, progress }];
            }
          });
        }
      },
      (error) => {
        console.error("Error uploading file:", error);
        setUploading((prevUploading) => prevUploading - 1);
      },
      // Inside the uploadFile function, update the following block of code
      async () => {
        const downloadURL = await task.snapshot.ref.getDownloadURL();
        console.log("currentPath: ", currentPath);

        const newItem = {
          name: fileName,
          type: fileType,
          userId: user.uid,
          path: currentPath,
          storagePath: filePath,
          downloadURL,
        };

        const driveRef = drive_service.getDriveRef(user.uid);
        const docRef = await driveRef.add(newItem);

        // Initialize user's storage if needed
        const userStorageRef = drive_service.getUserStorageRef(user.uid);
        const userStorageDoc = await userStorageRef.get();

        if (!userStorageDoc.exists) {
          const initialValues = {
            usedStorage: file.size,
            storageLimit: 5 * 1024 * 1024 * 1024,
          };
          await initializeUserStorage(user.uid, initialValues);
        } else {
          const currentUsedStorage = userStorageDoc.data().usedStorage;
          const newUsedStorage = currentUsedStorage + file.size;
          await userStorageRef.update({ usedStorage: newUsedStorage });
        }

        setItems(
          items
            ? [...items, { ...newItem, id: docRef.id }]
            : [{ ...newItem, id: docRef.id }]
        );

        setUploading((prevUploading) => prevUploading - 1);
        setUploadProgress((prevUploadProgress) =>
          prevUploadProgress.filter((upload) => upload.id !== fileName)
        );
      }
    );
  };

  // Add this function inside useUploadFile, but before the uploadFile function
  const initializeUserStorage = async (userId, initialValues) => {
    const userStorageRef = drive_service.getUserStorageRef(userId);
    const userStorageDoc = await userStorageRef.get();

    if (!userStorageDoc.exists) {
      await userStorageRef.set(initialValues);
    }
  };

  const cancelUpload = (fileName) => {
    const taskObj = tasks.find((t) => t.id === fileName);
    if (taskObj) {
      const { task, storageRef } = taskObj;
      if (task.snapshot.state === "running") {
        task.cancel();
        storageRef.delete().catch((error) => {
          console.error("Error deleting file:", error);
        });
        setTasks((prevTasks) => prevTasks.filter((t) => t.id !== fileName));
        setUploadProgress((prevUploadProgress) =>
          prevUploadProgress.filter((upload) => upload.id !== fileName)
        );
      }
    }
  };

  const cancelAllUploads = () => {
    tasks.forEach((taskObj) => {
      if (taskObj.task.snapshot.state === "running") {
        taskObj.task.cancel();
      }
    });
    setTasks([]);
  };

  return [
    uploadFile,
    uploading,
    uploadProgress,
    cancelUpload,
    cancelAllUploads,
  ];
};

export const useDeleteFile = (items, setItems) => {
  const [user] = useAuthState(auth);

  const deleteFile = useCallback(
    async (fileId) => {
      if (!user) return;

      const driveRef = drive_service.getDriveRef(user.uid);
      const fileRef = driveRef.doc(fileId);
      const fileSnapshot = await fileRef.get();

      if (!fileSnapshot.exists) {
        console.error("File not found");
        return;
      }

      const fileData = fileSnapshot.data();
      const storageRef = getStorageRef(user.uid, fileData.storagePath);

      try {
        await storageRef.delete();
        await fileRef.delete();

        setItems(items.filter((item) => item.id !== fileId));
      } catch (error) {
        console.error("Error deleting file:", error);
      }
    },
    [user, items, setItems]
  );

  return deleteFile;
};


export const useUserStorage = (userId) => {
  const [userStorage, setUserStorage] = useState(null);
  const userStorageRef = drive_service.getUserStorageRef(userId);

  useEffect(() => {
    if (!userId) {
      setUserStorage(null);
      return;
    }

    const unsubscribe = userStorageRef.onSnapshot((doc) => {
      if (doc.exists) {
        setUserStorage({ id: doc.id, ...doc.data() });
      } else {
        setUserStorage(null);
      }
    });

    return () => unsubscribe();
  }, [userId]);

  return [userStorage];
};

// useStorageHooks.js

// ...Other imports and code
// useStorageHooks.js

export const useUploadProfilePicture = () => {
  const [user] = useAuthState(auth);
  const [uploading, setUploading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [uploadComplete, setUploadComplete] = useState(false);

  const uploadProfilePicture = async (file, onProgress = null) => {
    if (!user) return;

    setUploading(true);
    const storageRef = getProfilePictureStorageRef(user.uid);
    const task = storageRef.put(file);

    task.on(
      'state_changed',
      (snapshot) => {
        const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
        setProgress(progress);

        if (onProgress) {
          onProgress(progress);
        }
      },
      (error) => {
        console.error(error);
        setUploading(false);
      },
      async () => {
        const downloadURL = await task.snapshot.ref.getDownloadURL();
        console.log('File available at:', downloadURL);
        // Save the downloadURL to the user's profile in your database
        setUploading(false);
        setUploadComplete(true);
        setTimeout(() => setUploadComplete(false), 3000); // Reset after 3 seconds
      }
    );
  };

  return [uploadProfilePicture, uploading, progress, uploadComplete];
};



export const useUserProfilePicture = () => {
  const [user] = useAuthState(auth);
  const [profilePictureUrl, setProfilePictureUrl] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!user) return;

    const userRef = firebase.firestore().collection('users').doc(user.uid);
    
    const unsubscribe = userRef.onSnapshot((doc) => {
      if (doc.exists) {
        const userData = doc.data();
        setProfilePictureUrl(userData.profilePictureUrl);
      }
      setIsLoading(false);
    }, (err) => {
      setError(err);
      setIsLoading(false);
    });

    return () => unsubscribe();
  }, [user]);

  return { profilePictureUrl, isLoading, error };
};