import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@material-ui/core';

// Import Stripe module
import { loadStripe } from '@stripe/stripe-js';

const stripePromise = loadStripe('pk_test_51E1LQfJoqPTP5BBTNvUUzbXU9D2Y52poGmONgDZjAXY6aoba267rqipiHbP2rIWZmr9Qs2eufxrnKKVUVVuQUw7d00yxjSc4vJ'); // Replace with your Stripe Public Key

const DubbingModal = ({ item, onClose }) => {
    const handleDubbing = async () => {
        console.log("handleDubbing item:", item); // Add this line to log the item
        try {
            // Fetch Checkout Session via API
            console.log("Fetching checkout session..."); // Logging the API request
            // const response = await fetch('http://localhost:5001/create-checkout-session', { method: 'POST' });
            const response = await fetch('/stripe/create-checkout-session', { method: 'POST' }); 

            console.log("Response:", response); // Log the response from API
            const session = await response.json();
            console.log("Checkout session:", session); // Log the session object
    
            // Navigate to Checkout
            const stripe = await stripePromise;
            console.log("Stripe:", stripe); // Log the Stripe object
            const { error } = await stripe.redirectToCheckout({
                sessionId: session.sessionId,
            });
            console.log("Redirect error:", error); // Log any errors during redirect
    
            // If `redirectToCheckout` fails due to a browser or network
            // error, you should display the localized error message to your customer.
            if (error) {
                alert(`Error: ${error.message}`);
            }
        } catch (err) {
            console.error("Error in handleDubbing function:", err); // Log any errors in the function
        }
    };
    

    return (
        <Dialog open={true} onClose={onClose}>
            <DialogTitle>Dubbing to tape</DialogTitle>
            <DialogContent>
                <p>You're about to dub the following item to tape:</p>
                <p>Item name: {item.name}</p>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">
                    Cancel
                </Button>
                <Button onClick={handleDubbing} color="primary">
                    Confirm
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default DubbingModal;
