// OtariFilesView.jsx


// CreateCassetteView.jsx;
import React, { useEffect } from "react";

// import Column from "./column";
import Column from "../dashboard/column";
import { DragDropContext } from "react-beautiful-dnd";
import styled from "styled-components";
import storage from "../../../services/storage";
import APIConfig from "../../../APIConfig";
import GridContainer from "../../themes/GridContainer";
import api from "../../../api";
// import EditCassetteMetadataView from "./EditCassetteMetadataView";
import JCardImageUploader from "../../file_mgmt/JCardImageUploader";

const Container = styled.div`
  display: flex;
`;

const data = {
  tasks: {
    "task-1": { id: "task-1", content: "", url: "" },
  },
  columns: {
    "column-1": {
      id: "column-1",
      title: "/files",
      taskIds: ["task-1"],
    },
  },
  // Facilitate reordering of the columns
  columnOrder: ["column-1"],
};
class OtariFilesView extends React.Component {
  state = data;

  modalShow(taskId) {
    if (this.props.task == taskId) {
      //("alskdjasldkj");
      this.setState({
        showingModal: true,
      });
    }
  }
  setModalShow() {
    this.setState({
      showingModal: false,
    });
  }
  editMetaDataButton(task) {
    const btn = (
      <button onClick={() => this.modalShow(true)}>edit track metadata</button>
    );
    return btn;
  }

  isValidAlbum() {
    const column = this.state.columns["column-2"];
    const tasks = column.taskIds.map((taskId) => this.state.tasks[taskId]);
    const newTasks = tasks.map((task) => task);

    if (newTasks.length > 0 && this.props.album.name != "") {
      return true;
    }
    return false;
  }

  publish() {
    if (this.isValidAlbum()) {
      api.newAlbum(this.props.album.docId);
    } else {
      alert("please add tracks and an album title before publishing");
    }
  }
  componentDidMount() {
    // //(this.props.``location.state.album)  // for location state
    // //(location.search)  // for query strings;

    const token = localStorage.getItem("token");
    APIConfig.token = token;

    storage.getJustFiles(this.props.album).then((res) => {
      data.tasks = res.tasks;
      data.columns = res.columns;
      data.columnOrder = res.columnOrder;
      this.setState({
        state: data,
      });
    });

    storage.getOtariProject(this.props.album).then((res) => {
      const column = res.columns["column-2"];
      const tasks = column.taskIds.map((taskId) => res.tasks[taskId]);
      data.columns["column-2"] = column;
      tasks.map((task) => (data.tasks[task.id] = task));
      this.setState({
        state: data,
      });
    });
  }

  onDragStart = (start) => {
    const homeIndex = this.state.columnOrder.indexOf(start.source.droppableId);
    this.setState({
      homeIndex,
    });
  };

  updateIndices = (newState) => {
    var _tasks = [];
    var new_tasks_ids = newState.columns["column-2"].taskIds;

    const column = newState.columns["column-2"];
    const tasks = column.taskIds.map((taskId) => newState.tasks[taskId]);
    // for (task in tasks)
    const newTasks = tasks.map((task) => task);
    newTasks.map((task, index) => (task.index = index));

    if (!Array.prototype.last) {
      Array.prototype.last = function () {
        return this[this.length - 1];
      };
    }

    // var newTask = newTasks[newIndex];
    var _album = this.props.album;

    var updatedAlbum = {
      songs: newTasks,
      name: _album,
      docId: _album.docId,
    };
    storage.updateAlbumIndices(updatedAlbum);
  };

  onDragEnd = (result) => {
    this.setState({
      homeIndex: null,
    });

    const { destination, source, draggableId } = result;

    if (!destination) {
      return;
    }

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    const home = this.state.columns[source.droppableId];
    const foreign = this.state.columns[destination.droppableId];

    if (home === foreign) {
      const newTaskIds = Array.from(home.taskIds);
      newTaskIds.splice(source.index, 1);
      newTaskIds.splice(destination.index, 0, draggableId);

      const newHome = {
        ...home,
        taskIds: newTaskIds,
      };

      const newState = {
        ...this.state,
        columns: {
          ...this.state.columns,
          [newHome.id]: newHome,
        },
      };
      this.updateIndices(newState);
      this.setState(newState);

      return;
    }

    // moving from one list to another
    const homeTaskIds = Array.from(home.taskIds);
    homeTaskIds.splice(source.index, 1);
    const newHome = {
      ...home,
      taskIds: homeTaskIds,
    };

    const foreignTaskIds = Array.from(foreign.taskIds);
    foreignTaskIds.splice(destination.index, 0, draggableId);

    var newIndex = destination.index;
    const newForeign = {
      ...foreign,
      taskIds: foreignTaskIds,
    };

    const newState = {
      ...this.state,
      columns: {
        ...this.state.columns,
        [newHome.id]: newHome,
        [newForeign.id]: newForeign,
      },
    };

    this.setState(newState);

    var _tasks = [];
    var new_tasks_ids = newState.columns["column-2"].taskIds;

    const column = newState.columns["column-2"];
    const tasks = column.taskIds.map((taskId) => newState.tasks[taskId]);
    // for (task in tasks)
    const newTasks = tasks.map((task) => task);
    newTasks.map((task, index) => (task.index = index));

    // const taskObjects = column.taskIds.map((taskId, index) => ({
    //     task_id: tasks[taskId],
    //     index: index,
    //   }));
    //("the tasks", tasks);
    if (!Array.prototype.last) {
      Array.prototype.last = function () {
        return this[this.length - 1];
      };
    }

    var newTask = newTasks[newIndex];
    var _album = this.props.album;

    var res = storage.setAlbumTrack(newTask, this.props.album);
    res.then(function (result) {
      newTask.id = result;
      //update the doc id to the new id for the album
      var updatedAlbum = {
        songs: newTasks,
        name: _album,
        docId: _album.docId,
      };
      storage.updateAlbumIndices(updatedAlbum);

      // here you can use the result of promiseB
    });
  };

  render() {
    // const imageUploadView = (
    //   <JCardImageUploader album={this.props.album}></JCardImageUploader>
    // );

    return (
      <GridContainer>
        <div className="center">
          {/* <StandardButton mxt onClick={() => setEditingAlbumName(!editingAlbumName)}>
edit
</StandardButton> */}
          <div style={{ margin: 25 }}>
            {/* <EditCassetteMetadataView
              className="padded"
              album={this.props.album}
            ></EditCassetteMetadataView> */}

            {/* <DeleteAlbumButton album={this.props.album}></DeleteAlbumButton> */}
          </div>
          {/* {imageUploadView} */}
          <DragDropContext
            onDragStart={this.onDragStart}
            onDragEnd={this.onDragEnd}
          >
            <Container>
              {this.state.columnOrder.map((columnId, index) => {
                const column = this.state.columns[columnId];
                const tasks = column.taskIds.map(
                  (taskId) => this.state.tasks[taskId]
                );
                const taskObjects = column.taskIds.map((taskId, index) => ({
                  task_id: tasks[taskId],
                  index: index,
                }));
                //("objects: ", taskObjects);
                const isDropDisabled = index < this.state.homeIndex;

                return (
                  <Column
                    key={column.id}
                    column={column}
                    tasks={tasks}
                    album={this.props.album}
                    //uncomment below to make it impossible to move task from right to left
                    isDropDisabled={isDropDisabled}
                  />
                );
              })}
            </Container>
          </DragDropContext>
        </div>
        {/* <StandardButton onClick={() => this.publish()}>PUBLISH</StandardButton> */}
      </GridContainer>
    );
  }
}

export default OtariFilesView;
