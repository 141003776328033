import React from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import LogoView from '../components/LogoView';
import APIConfig from '../APIConfig';
import { useNavigate, useLocation } from 'react-router-dom';
import './firebaseui-dark-mode.css'; // Import the custom CSS

const emailProviderDict = {
  provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
  requireDisplayName: true,
};

const phoneProviderDict = {
  provider: firebase.auth.PhoneAuthProvider.PROVIDER_ID,
  requireDisplayName: true,
};

const getAuthProvider = () => {
  if (APIConfig.studio() === true) {
    return [phoneProviderDict];
  } else {
    return [phoneProviderDict];
  }
};

const FBSigninView = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || '/';

  const uiConfig = {
    signInFlow: 'popup',
    signInOptions: getAuthProvider(),
    callbacks: {
      signInSuccessWithAuthResult: () => {
        navigate(from, { replace: true }); // Use navigate with replace option
        return false; // Prevents the default redirect to signInSuccessUrl
      },
    },
  };

  return (
    <div className=" dark:text-white min-h-screen flex items-center justify-center">
      <div className="py-5 w-full max-w-md">
        <div className="flex justify-center mb-6">
          {/* <LogoView /> */}
        </div>
        <StyledFirebaseAuth
          uiConfig={uiConfig}
          firebaseAuth={firebase.auth()}
          className="firebaseui-styled" // Add a custom class for further styling if needed
        />
      </div>
    </div>
  );
};

export default FBSigninView;