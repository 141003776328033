import React, { useHook, useEffect, useState, useContext } from "react";
import { useParams } from "react-router-dom";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import storage from "../../../services/storage";

const initialData = {
  tasks: {
    "task-1": { id: "task-1", content: "space odyssey ref mix" },
    "task-2": { id: "task-2", content: "quaalude alt mix" },
    "task-3": { id: "task-3", content: "teenagers from mars (misfits cover)" },
    "task-4": { id: "task-4", content: "live jam in the studio" },
  },
  columns: {
    "column-1": {
      id: "column-1",
      title: "/files",
      taskIds: ["task-1", "task-2", "task-3", "task-4"],
    },
    "column-2": {
      id: "column-2",
      title: "/tapes/summer.mxt",
      taskIds: [],
    },
    "column-3": {
      id: "column-3",
      title: "/.mixtape",
      taskIds: [],
    },
  },
  // Facilitate reorderin g of the columns
  columnOrder: ["column-1", "column-2", "column-3"],
};

let db = firebase.firestore();

const fetchData = async (uid) => {
  var files_ref = db.collection("studio").doc(uid).collection("files");
  files_ref.onSnapshot(function (querySnapshot) {
    var files = [];
    querySnapshot.forEach(function (doc) {
      files.push(doc.data().filename);
    });
    return files;
  });
};
const getFiles = () => {
  firebase.auth().onAuthStateChanged((user) => {
    if (user) {
      // User is signed in, see docs for a list of available properties
      // https://firebase.google.com/docs/reference/js/firebase.User
      var uid = user.uid;
      //   storage.myTapesObserver(uid);
      fetchData(uid);
      // ...
    } else {
      //("signed out....");
      // User is signed out
      // ...
    }
  });
};

export default initialData;
