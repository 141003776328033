
// SearchBar.jsx
// SearchBar.js
import React, { useState, useEffect } from "react";
import Spinner from "../loading/Spinner"; // Adjust the import path as needed

const SearchInput = ({ searchTerm, setSearchTerm, placeholder }) => {
  return (
    <input
      type="text"
      value={searchTerm}
      onChange={(e) => setSearchTerm(e.target.value)}
      className="w-full px-4 py-2 border border-gray-300 dark:border-gray-600 bg-gray-800  dark:text-white placeholder-gray-400 dark:placeholder-gray-500"
      placeholder={placeholder}
    />
  );
};

export default SearchInput;
