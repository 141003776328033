import { useCallback } from "react";
import { useTrackNameInference } from "../../../drafting/hooks/useTrackNameInference";
import storage from "../../../../../services/storage";

// Custom hook to handle applying AI naming to all tracks
const useApplyAINaming = (data, album) => {
  const { runInference } = useTrackNameInference();

  // Save track name function to be used inside the loop
  const saveTrackName = useCallback((task, newTrackName) => {
    if (task.trackName !== newTrackName) {
      const updatedTask = { ...task, trackName: newTrackName };
      console.log(`Saving new track name: ${newTrackName} for task: ${task.id}`); // Log save action
      storage.updateAlbumDraftTrack(updatedTask, album, task.id); // Save the name in storage
    }
  }, [album]);

  // Avoid calling hooks in a loop
  const applyAINamingToAllTracks = useCallback(async () => {
    const updatedData = { ...data };
    console.log(updatedData); // Log the data
    for (const columnId of updatedData.columnOrder) {
      const column = updatedData.columns[columnId];
      for (const taskId of column.taskIds) {
        const task = updatedData.tasks[taskId];
        console.log(`Checking task: ${task.id}`);
        console.log(`Task type: ${task.taskType}`);
        
        if (task.url) {  // Assuming tasks with `task.url` are valid for inference
          console.log(`Running inference for track: ${task.url}`);
          const result = await runInference(task.url); // Run inference
          const inferredTrackName = result.trackName || result.result; // Adjust based on response structure
          
          console.log(`Inferred track name for task ${task.id}: ${inferredTrackName}`);
          
          if (inferredTrackName) {
            saveTrackName(task, inferredTrackName); // Save inferred name
          }
        }
      }
    }
  }, [data, runInference, saveTrackName]);

  return { applyAINamingToAllTracks };
};

export default useApplyAINaming;