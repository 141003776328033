// hooks/usePhotoDetail.js
import { useState, useEffect } from 'react';
import firebase from 'firebase/compat/app';

export const usePhotoDetail = (eventId, memoryId) => {
  const [photo, setPhoto] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchPhoto = async () => {
      setLoading(true);
      try {
        const photoRef = firebase.firestore()
          .collection('events')
          .doc(eventId)
          .collection('memories')
          .doc(memoryId);

        const doc = await photoRef.get();
        if (doc.exists) {
          setPhoto({ id: doc.id, ...doc.data() });
        } else {
          setError(new Error('Memory not found'));
        }
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    if (eventId && memoryId) {
      fetchPhoto();
    }
  }, [eventId, memoryId]);

  return { photo, loading, error };
};