import React from "react";
import APIConfig from "../APIConfig";
import phaze_logo from "../assets/phaze-logo-ii.svg"; // replace with actual path
import logo from "../assets/mxt_logo_small.png"; // replace with actual path

const LogoView = () => {
  // Define the default logo and text
  let svg_logo = "https://storage.googleapis.com/tuareg/media/mxt-logo.svg";
  let text = ".mixtape";

  // Switch logo and text if a different configuration is active
  if (APIConfig.phaze() === true) {
    svg_logo = phaze_logo;
    text = "phaze";
  } else if (APIConfig.airtrak() === true) {
    svg_logo = "https://firebasestorage.googleapis.com/v0/b/tuareg.appspot.com/o/app_assets%2Fimages%2Fairtrak-logo.svg?alt=media&token=4bec72f2-a13d-47a8-a27d-5ba10b5b10a9";
    text = "airtrak";
  }

  return (
    <div className="grid place-items-center">
      {/* Logo Image */}
      <img className="w-40 h-40" src={svg_logo} alt="Logo" />

      {/* Dynamic Text Color */}
      <div className="text-black dark:text-white font-theFutureMonoRegular py-5">
        {text}
      </div>
    </div>
  );
};

export default LogoView;