import React from 'react';

const ContentDisclaimer = () => {
  return (
    <div className="legal-section p-6">
      <h2>Content Disclaimer</h2>
      <p>.mixtape is not responsible for user-uploaded content and does not endorse its accuracy or legality.</p>
    </div>
  );
};

export default ContentDisclaimer;