import { Draggable } from "react-beautiful-dnd";
import DeleteFileButton from "../../file_mgmt/DeleteFileButton";

const FileObjectCell = ({ task, index, album, styleVariant = "default" }) => {
  const handleUploadComplete = () =>
    console.log("Upload complete for track:", task.trackName);
  const baseCellStyle =
    "p-2 mb-2 transition duration-200 ease-in-out transform-gpu";

  // Dynamic class based on styleVariant
  const cellStyle = `${baseCellStyle} ${
    styleVariant === "alternate"
      ? "bg-indigo-700 hover:bg-indigo-800"
      : "bg-inherit-800 hover:bg-gray-900"
  } min-h-[2rem]`;
  const isProcessing =
    !task.compressed_filename || task.compressed_filename === "";

  return (
    <Draggable draggableId={task.id} index={index}>
      {(provided, snapshot) => (
        <div
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
          className={`${cellStyle} ${
            snapshot.isDragging
              ? "border-blue-400 border-1 bg-gh_black-100"
              : " border-yellow-200"
          } shadow-lg`}
        >
          <div className="flex justify-between items-center">
            {/* Existing content */}
            <div className="cursor-pointer">
              <p
                className={`font-theFutureMonoRegular text-sm ${
                  styleVariant === "alternate"
                    ? "text-indigo-200"
                    : "text-yellow-200"
                }`}
              >
                {task.content}
              </p>
            </div>
            <DeleteFileButton
              track={task}
              albumId={album.id}
              onDeleteComplete={(deletedTrack) =>
                console.log("Track deleted:", deletedTrack)
              }
            />
          </div>
          {isProcessing && (
            <div className="absolute inset-0 bg-black bg-opacity-50 flex justify-center items-center">
              <p className="text-white text-opacity-75 text-xs font-light">
                processing...
              </p>
            </div>
          )}
        </div>
      )}
    </Draggable>
  );
};
export default FileObjectCell;
