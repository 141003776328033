// ModalContent.jsx

// ModalContent.jsx

import React from "react";

const ModalContent = ({ track, channel }) => {
  return (
    <div className="bg-gray-800 px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
      <div className="sm:flex sm:items-start">
        <div className="mt-3 text-center sm:mt-0 sm:text-left">
          <h3
            className="text-lg leading-6 font-medium text-white"
            id="modal-headline"
          >
            {track.name}
          </h3>
          <div className="mt-2">
            <p className="text-gray-300">Duration: {track.duration} seconds</p>
            <p className="text-gray-300">Volume: {track.volume}</p>
            <p className="text-gray-300">Pan: {track.pan}</p>
            <p className="text-gray-300">Channel: {channel}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalContent;
