import React, { useState } from "react";

const UnifiedButton = ({ onClick, disabled, children }) => {
  const [isHovered, setIsHovered] = useState(false);

  const buttonClass = `w-full text-lg sm:text-xl font-sansBold py-3 px-6 rounded-lg transition duration-300 ease-in-out ${
    isHovered ? "bg-blue-500 hover:bg-blue-600 text-white" : "bg-gray-200 hover:bg-gray-300 text-black"
  }`;

  return (
    <button
      disabled={disabled}
      className={buttonClass}
      onClick={onClick}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onFocus={() => setIsHovered(true)}
      onBlur={() => setIsHovered(false)}
      aria-label={children}
    >
      {children}
    </button>
  );
};

export default UnifiedButton;