// UploadContainer.js;
import styled from "styled-components";

const UploadContainer = styled.div`
  /* display: flex; */
  .container {
    /* display: flex; */
    /* or inline-flex */
    /* flex-basis: auto; */
    /* default auto */
  }
  ul {
    display: flex;

    border: 1px solid #91b3ff;
    flex: 0 1 auto; /* Default */
  }
  .box {
    display: flex;

    border: 1px solid #91b3ff;
    flex: 0 1 auto; /* Default */
  }
  .title {
    color: blue;
    text-align: center;
    font-size: 2vh;
    padding: 1vh;
  }

  .title2 {
    /* color: blue; */
    /* color: #495a80;
     */
     margin-top:4vh;
     color: green;
    text-align: center;
    font-size: 2vh;
    padding: 1vh;
  }
  .progress {
    color: blue;
  }
  .horizontal {
    flex-direction: row;  
    justify-content: stretch;
  }
  .center {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 2vh;
    align-items: center;
    /* height: 50vh; */
  }
`;

export default UploadContainer;
