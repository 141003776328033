import styled, { css } from "styled-components";
const MenuButton = styled.button`
  background: transparent;
  border-radius: 0px;
  /* border: 2px solid blue; */
  /* border: 2px solid blue; */
  border: 2px solid turquoise;
  /* color: palevioletred; */
  /* color: blue; */
  /* color: blue; */
  color: turquoise;

  margin: 0.5em 1em;
  padding: 0.25em 1em;
  /* font-size: 1em; */
  font-size: 1em;
  // font roboto mono
  font-family: "Menlo", monospace;
  

  ${(props) =>
    props.primary &&
    css`
      background: palevioletred;
      color: white;
    `}

  ${(props) =>
    props.apple_ii &&
    css`
      border: 2px solid #00c929;
      color: #00c929;
    `}
    ${(props) =>
    props.red &&
    css`
      border: 2px solid #fa5757;
      color: #fa5757;
    `}
`;

export default MenuButton;
