// MerchList.jsx

import React, { useEffect, useState } from "react";
import firebase from "firebase/compat/app";
import GridContainer from "../themes/GridContainer";
import merch from "../../services/merch";
import checkout from "../../services/checkout";
import { Link, useLocation } from "react-router-dom";
import MerchItemView from "./merch_item/MerchItemView";

const PublicMerchListView = ({ slug }) => {
  const [data, setData] = useState([]);
  const [active, setActive] = useState(true);
  const [queried, setQueryState] = useState(false);
  const [artistName, setArtistName] = useState("");
  const [selectedOption, setOption] = useState({ product: "", option: "" });
  // set has merch
  const [selectedMerchItem, setSelectedMerchItem] = useState({});
  const [hasMerch, setHasMerch] = useState(false);
  // loading
  const [loading, setLoading] = useState(false);
  let query = useQuery();

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  useEffect(() => {
    queryMerch();
  }, [queried, selectedOption]);
  function queryMerch() {
    if (!queried) {
      setQueryState(true);
      var i = merch.getMerch(slug);
      i.then(function (res) {
        res.json().then(function (data) {
          console.log("the deata", data);
          setData(data.merch_items);
          // if data.merch_items.length === 0
          if (data.merch_items.length === 0) {
            setHasMerch(false); // set hasMerch to false
          } else {
            setHasMerch(true);
          }
          setArtistName(data.artist_name);
        });
      });
    }
  }

  function isEmpty(obj) {
    for (var prop in obj) {
      if (obj.hasOwnProperty(prop)) {
        return false;
      }
    }
    return JSON.stringify(obj) === JSON.stringify({});
  }

  function buy(file) {
    setSelectedMerchItem(file);
    setLoading(true);
    setActive(false);

    var metadata = {
      artist: slug,
      quantity: 1,
    };
    checkout.buyNoAuth(file.pk, metadata);
  }

  const divStyle = {
    width: "em",
  };

  const loadView = () => {
    return (
      <div>
        <div p-6 plex-mono blue center>
          loading checkout...
        </div>
      </div>
    );
  };

  // file format to price function return a string with dollar sign
  const formatPrice = (price) => {
    return "$" + price / 100;
  };

  const merch_list = data.map((file, index) => (
    <div className="py-2 hover:opacity-75 grid grid-flow-row auto-rows-max space-y-4">
      <Link to={"#"} onClick={() => buy(file)}>
        {/* if selected merch item is equal to file and loading is true then return loading view */}
        {selectedMerchItem === file && loading ? (
          // overlay "loading checkout" over merch item image view
          <div className="flex flex-col items-center  ">
            {/* center it vertically */}
            <div className="flex flex-row items-center justify-center">
              {/* overlay text that says loading and center it vertically over the image view */}

              <div className="absolute z-20 text-center text-2xl font-theFutureMonoRegular text-slate-100 bg-slate-700 dark:text-violet-300 dark:border-violet-400 border-2 col-span-1">
                loading checkout...
              </div>
            </div>

            <img className="object-contain h-48 w-96" src={file.image}></img>
            <div className="flex flex-row items-center justify-center">
              <div className="p-5 font-theFutureMonoRegular">{file.name}</div>
              {/* <div className="text-red-600 font-theFutureExtraLight">pre-order</div> */}
            </div>
            {/* price view formatted for price usd using price func*/}
            <div className="font-theFutureMonoRegular ">{formatPrice(file.price)}</div>
            {/* <div className="p-5 font-theFutureMonoRegular ">{loadView()}</div> */}
          </div>
        ) : (
          <div className="flex flex-col items-center  ">
            <img className="object-contain h-48 w-96" src={file.image}></img>
            {/* horizontally arrange */}
            <div className="flex flex-row items-center justify-center">
              <div className="p-5 font-theFutureMonoRegular">{file.name}</div>
              {/* <div className="text-red-600 font-theFutureExtraLight">pre-order</div> */}
            </div>

            {/* price view formatted for price usd using price func*/}

            <div className="font-theFutureMonoRegular ">{formatPrice(file.price)}</div>
          </div>
        )}
      </Link>
    </div>
  ));
  if (data.length == 0) {
    if (!hasMerch) {
      return <div>{/* blank if no merch */}</div>;
    }
    return <div>loading merch...</div>;
  } else {
    return (
      // {loading ?}
      <div className="">
        <div className="flex flex-col items-center font-theFutureMonoRegular text-lg">
          merch
        </div>
        <div className="">
          {/* { loading ? null : merch_list } */}
          {merch_list}
        </div>
      </div>
    );
  }
};

export default PublicMerchListView;
