import React, { useState } from "react";
import SquareBannerView from "./SquareBannerView";
import HostedByView from "./HostedByView";
import EventTitleView from "./EventTitleView";
import EventDetailsView from "./EventDetailsView";
import RSVPButton from "../rsvp/RSVPButton";
import ModalAuthView from "../ModalAuthView";
import toast from "react-hot-toast";
import EventMemoriesView from "../memories/EventMemoriesView";
import firebase from "firebase/compat/app";
import EventWall from "../../wall/views/EventWall";

const EventContent = ({ event, user, token }) => {
  const [linkCopied, setLinkCopied] = useState(false);
  const [attendeeCount, setAttendeeCount] = useState(event.attending?.fs_uids?.length || 0);

  const presentEventCopySuccessToast = () => {
    setLinkCopied(true);
    toast("Copied link to clipboard!", {
      duration: 1500,
      position: "bottom-center",
      style: {
        border: "1px solid pink",
        backgroundColor: "black",
        padding: "16px",
        color: "pink",
        opacity: "0.5",
      },
      icon: "🎉",
      ariaProps: {
        role: "status",
        "aria-live": "polite",
      },
    });
  };

  const isUserAttending = () => {
    return (
      event?.attending?.fs_uids?.includes(user?.uid)
    );
  };

  const removeAttendee = () => {
    const uid = firebase.auth().currentUser.uid;
    event.attending.fs_uids = event.attending.fs_uids?.filter(
      (item) => item !== uid
    );
    setAttendeeCount(attendeeCount - 1);
  };

  const addAttendee = () => {
    setAttendeeCount(attendeeCount + 1);
  };

  return (
    <div className="flex flex-col min-h-screen justify-between">
      <div className="overflow-auto">
        <div className="py-6 px-4 md:px-8 lg:px-16">
          <div className="flex flex-col md:flex-row">
            <div className="w-full md:w-1/3 md:pr-4 flex flex-col items-start md:space-y-4">
              <SquareBannerView eventId={String(event.id)} className="mb-5" />
              <EventTitleView title={event.name} className="mb-2" />
              <HostedByView event={event} className="mb-5" />
            </div>
            <div className="w-full md:w-2/3 md:pl-4 text-left mt-4 md:mt-0">
              <EventDetailsView
                event={event}
                linkCopied={linkCopied}
                presentEventCopySuccessToast={presentEventCopySuccessToast}
                isAttending={isUserAttending()}
                className="mb-5"
              />
              {/* <EventMemoriesView event={event} className="mb-5" /> */}
              {/* <EventWall eventId={event.id} /> */}
            </div>
          </div>
        </div>
      </div>
      <RSVPSection
        event={event}
        user={user}
        token={token}
        addAttendee={addAttendee}
        removeAttendee={removeAttendee}
      />
    </div>
  );
};

const RSVPSection = ({ event, user, token, addAttendee, removeAttendee }) => {
  return (
    <div className="bg-opacity-80 z-10 sticky bottom-0 px-4 py-4 text-center">
      {user ? (
        <RSVPButton
          event={event}
          token={token}
          increment={addAttendee}
          decrement={removeAttendee}
        />
      ) : (
        <ModalAuthView event={event} className="mt-4" />
      )}
    </div>
  );
};

export default EventContent;