const token = localStorage.getItem("token");
const localhost = "http://localhost:8000";
const _tuareg = "https://dev.api.mixtape.ai";

const _env = {
  dev: "https://dev.api.mixtape.ai",
  prod: "https://api.mixtape.ai",
  local: localhost,
  tuareg: _tuareg,
};

const testflight = {
  studio: "https://testflight.apple.com/join/n2lPZcFS",
  phaze: "https://testflight.apple.com/join/fkQWk37B",
  mxt:"https://apps.apple.com/us/app/mixtape/id1391354414"
}

const domain = {
  dev: "https://dev.api.mixtape.ai",
  prod: "https://api.mixtape.ai",
  localhost: "http://localhost:3000",
}
const _api_ext = "/api/v1/";

const baseURL = _env.prod;

const APIConfig = {
  timeout: 5000,
  headers: {
    "Content-Type": "application/json",
    Authorization: "JWT " + token,
  },
  pageSize: 20,
  baseURL: baseURL + "/",
  serverURI: baseURL + _api_ext,

  apiURI: baseURL + _api_ext,

  tokenURI() {
    return `${this.apiURI}token/`;
  },
  authURI() {
    return `${this.apiURI}auth/`;
  },
  registerURI() {
    return `${this.authURI()}register/`;
  },
  feedURI() {
    return `${this.apiURI}feed/`;
  },
  albumsURI() {
    return `${this.apiURI}albums/`;
  },
  albums() {
    return `${this.apiURI}albums/`;
  },
  mixtapesURI() {
    return `${this.apiURI}mixtapes/`;
  },
  profiles() {
    return `${this.apiURI}profiles/`;
  },
  share_tape() {
    return `${this.apiURI}studio/get_link/`;
  },
  delete_tape() {
    return `${this.apiURI}studio/delete_tape/`;
  },
  artist(id) {
    return `${this.apiURI}artists/${id}/`;
  },
  artists(id) {
    if (id) {
      return `${this.apiURI}artists/${id}/`;
    } else {
      return `${this.apiURI}artists/`;
    }
  },
  fireURI(id) {
    return `${this.feedURI()}${id}fire/`;
  },
  profilesURI() {
    return `${this.apiURI}profiles/`;
  },
  followURI(id) {
    return `${this.profilesURI()}${id}/follow/`;
  },
  unfollowURI(id) {
    return `${this.profilesURI()}${id}/unfollow/`;
  },
  followersURI() {
    return `${this.profilesURI()}followers/`;
  },
  followingURI() {
    return `${this.apiURI}following/`;
  },
  publishURI() {
    return `${this.apiURI}profiles/publish/`;
  },
  test() {
    return `${this.apiURI}profiles/me/`;
  },
  connect() {
    return `${this.apiURI}profiles/connect/`;
  },
  payments() {
    return `${this.baseURL}payments/`;
  },
  merch() {
    return `${this.baseURL}merch/`;
  },
  store() {
    return `${this.baseURL}store/`;
  },
  stripePublicKey() {
    return `${this.STRIPE_PK}`;
  },
  stripeAccountId() {
    return;
  },
  events() {
    return `${this.apiURI}parties/`;
  },
  event() {
    return `${this.apiURI}parties/retrieve_party/`;
  },
  event_invites() {
    return `${this.apiURI}parties/get_invites/`;
  },
  event_no_auth() {
    return `${this.baseURL}event/`;
  },
  rsvp() {
    return `${this.apiURI}parties/rsvp/`;
  },
  me() {
    return `${this.apiURI}profiles/me/`;
  },
  phaze() {
    return false;
  },
  studio() {
    return true;
  },
  airtrak() {
    return false;
  },
  domain() {
    return domain.dev;
  },
  siteBaseURL() {
    return this.phaze() ? "https://phaze.rsvp" : "https://mixtape.ai";
  },
  testflight() {
    return this.phaze() ? testflight.phaze : this.studio() ? testflight.mxt : testflight.mxt;
  }
};

export default APIConfig;