import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div className="legal-section p-6">
      <h2>Privacy Policy</h2>
      <p>We collect user data to enhance our services. This includes email, IP addresses, and usage patterns.</p>
      <p>We retain data as per legal requirements and may share it with law enforcement if necessary.</p>
      <p>For full details, please <a href="/privacy">review our Privacy Policy</a>.</p>
    </div>
  );
};

export default PrivacyPolicy;