// VerticalFader.jsx

import React, { useState } from "react";
import { Stage, Layer, Rect, Circle } from "react-konva";

const VerticalFader = ({ value, onChange, min = 0, max = 100 }) => {
  const [handleY, setHandleY] = useState(15 + (1 - value) * 140);

  const handleDragMove = (e) => {
    const newY = e.target.y();
    setHandleY(newY);
    onChange(1 - newY / 100);
  };

  return (
    <Stage width={30} height={170}>
      <Layer>
        <Rect
          x={10}
          y={0}
          width={10}
          height={150}
          fill="#D1D1D6"
          cornerRadius={5}
        />
        <Rect
          x={10}
          y={handleY}
          width={10}
          height={135 - handleY}
          fill="#3B82F6"
          cornerRadius={[5, 5, 0, 0]}
        />
        <Circle
          x={15}
          y={handleY}
          radius={15}
          hitStrokeWidth={10}
          fill="white"
          draggable
          dragBoundFunc={(pos) => {
            const newY = pos.y;
            return {
              x: 15,
              y: Math.max(15, Math.min(newY, 135)),
            };
          }}
          onDragMove={handleDragMove}
        />
      </Layer>
    </Stage>
  );
};

export default VerticalFader;
