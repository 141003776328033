// ClipboardCopy.jsx
import React from "react";
import { useState, useEffect } from "react";
import toast, { Toaster, ToastBar } from "react-hot-toast";
import {
  MapPinIcon,
  CalendarIcon,
  InformationCircleIcon,
  CheckIcon,
  ClockIcon,
  LinkIcon,
} from "@heroicons/react/24/solid";
function ClipboardCopy({ copyText }) {
  const [isCopied, setIsCopied] = useState(false);

  // This is the function we wrote earlier

  async function copyTextToClipboard(text) {
    if ("clipboard" in navigator) {
      return await navigator.clipboard.writeText(text);
    } else {
      return document.execCommand("copy", true, text);
    }
  }

  // onClick handler function for the copy button
  const handleCopyClick = () => {
    // Asynchronously call copyTextToClipboard
    copyTextToClipboard(copyText)
      .then(() => {
        // If successful, update the isCopied state value
        setIsCopied(true);
        setTimeout(() => {
          setIsCopied(false);
        }, 1500);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getCopyLinkView = () => {
    if (!isCopied) {
      return (
        <button
          className=" hover:text-white  font-theFutureMonoRegular"
          onClick={() => {
            handleCopyClick();
            presentEventCopySuccessToast();
          }}
        >
          <div className="border-2 hover:bg-slate-400 ">
            <div className="px-1 flex flex-row p-1">
              <div className="p-1 hover:text-white">
                <LinkIcon className="h-4 w-4  " />
              </div>
              <div className=" px-1">share link</div>
            </div>
          </div>
        </button>
      );
    } else {
      // return version with solid background
      return (
        <button
          className=" hover:text-white  text-green-300 font-theFutureMonoRegular"
          onClick={() => {
            handleCopyClick();
            presentEventCopySuccessToast();
          }}
        >
          <div className="bg-green-400 hover:bg-green-600 ">
            <div className="px-1 flex flex-row p-1">
              <div className="p-1 hover:text-white">
                {isCopied ? (
                  <div className="text-white px-1 ">
                    <CheckIcon className="h-4 w-4 text-white  " />
                  </div>
                ) : (
                  <LinkIcon className="h-4 w-4 text-white  " />
                )}
              </div>
              <div className=" text-white px-1">link copied</div>
            </div>
          </div>
        </button>
      );
    }
  };

  const presentEventCopySuccessToast = () => {
    // const t = getLinkCopyToast();
    // toast.success(t);
    getLinkCopyToast();
  };

  const getLinkCopyToast = () => {
    const t = toast(getToastText(), {
      duration: 1500,
      position: "bottom-center",

      // Styling
      style: getStyle(),
      className: "",

      // Custom Icon
      icon: getEmoji(),

      // Change colors of success/error/loading icon
      iconTheme: {
        primary: "#000",
        secondary: "#fff",
      },

      // Aria
      ariaProps: {
        role: "status",
        "aria-live": "polite",
      },
    });

    return t;
  };

  const getToastText = () => {
    return "copied link to clipboard!";
  };
  const getEmoji = () => {
    // return success emoji
    return "🎉";
  };

  const getStyle = () => {
    return {
      border: "1px solid pink",
      backgroundColor: "black",
      padding: "16px",
      color: "pink",
      opacity: "0.5",
    };
  };
  return (
    <div>
      {/* <input type="text" value={copyText} readOnly /> */}
      {/* Bind our handler function to the onClick button property */}
      {/* <button >COPY LINK</button> */}

      {/*         
        <button className="box blue-background width-8" onClick={handleCopyClick}>
          <span>{isCopied ? 'COPIED!' : 'COPY LINK'}</span>
        </button> */}
      {getCopyLinkView()}
    </div>
  );
}

export default ClipboardCopy;
