// label_api.js

import APIConfig from "../APIConfig";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import { authMethods } from "../firebase/authmethods";
import { firebaseAuth } from "../provider/AuthProvider";

const token = localStorage.getItem("token");
var db = firebase.firestore();

const getLabelArtists = async (token) => {
  const r = await fetch(`${APIConfig.profiles()}get_label_artists/`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "JWT " + token,
    },
  });
  return r;
};

const getReleases = async (token, id) => {
  
  const r = await fetch(`${APIConfig.artist(154)}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "JWT " + token,
    },
  });
  console.log("the r ",r)
  return r;
};

const addArtistToLabel = async (name) => {
  firebase.auth().onAuthStateChanged((user) => {
    if (user) {
      const data = { name: name, uid: user.uid };
      const d = JSON.stringify(data);
      firebase
        .auth()
        .currentUser.getIdToken(/* forceRefresh */ true)
        .then(function (token) {
          const r = fetch(`${APIConfig.profiles()}add_new_artist/`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: "JWT " + token,
            },
            body: d,
          });
          return r;
        });
    } else {
    }
  });
};

const deleteArtist = async (id) => {
  const data = { artist_id: id};
  const d = JSON.stringify(data);
  firebase.auth().onAuthStateChanged((user) => {
    if (user) {
      firebase
        .auth()
        .currentUser.getIdToken(/* forceRefresh */ true)
        .then(function (token) {
          const r = fetch(`${APIConfig.profiles()}delete_artist/`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: "JWT " + token,
            },
            body: d,
          });

          return r;
        });
    } else {
    }
  });
};
const label_api = {
  getLabelArtists,
  addArtistToLabel,
  deleteArtist,
  getReleases
};

export default label_api;
