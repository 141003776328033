import React from 'react';
import { PauseIcon, PlayIcon } from '@heroicons/react/24/outline';

const PlayPauseButton = ({ playing, handlePlayPause }) => (
  <button title="play/pause" className="controls" onClick={handlePlayPause}>
    {playing ? (
      <PauseIcon className="h-5 w-5" />
    ) : (
      <PlayIcon className="h-5 w-5" />
    )}
  </button>
);

export default PlayPauseButton;
