import React, { useState, useEffect } from "react";
import { EventDetailService } from "../../file_mgmt/drive/hooks/loadBannerImage";
import ImageViewer from "../memories/ImageViewer";

const SquareBannerView = ({ eventId }) => {
  const [bannerImage, setBannerImage] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isImageViewerOpen, setIsImageViewerOpen] = useState(false);

  useEffect(() => {
    const loadBannerImage = async () => {
      console.log("Attempting to load banner image for event ID:", eventId);
      console.log("Event ID Type:", typeof eventId); // Log the type of eventId
      console.log("Event ID:", eventId); // Log the value of eventId

      if (typeof eventId !== 'string') {
        console.error("Event ID is not a string:", eventId);
        setIsLoading(false);
        return;
      }

      try {
        const bannerImageUrl = await EventDetailService.loadBannerImage(eventId);
        console.log("Banner Image URL retrieved:", bannerImageUrl);
        setBannerImage(bannerImageUrl);
        setIsLoading(false); // Ensure loading state is turned off after loading
      } catch (error) {
        console.error("Error loading banner image:", error);
        setIsLoading(false); // Ensure loading state is turned off even if there's an error
      }
    };

    loadBannerImage();
  }, [eventId]);

  const openImageViewer = () => {
    setIsImageViewerOpen(true);
  };

  const closeImageViewer = () => {
    setIsImageViewerOpen(false);
  };

  const handleImageLoad = () => {
    console.log("Image has loaded.");
    setIsLoading(false);
  };

  const handleImageError = () => {
    console.error("Error loading the image.");
    setIsLoading(false);
  };

  return (
    <>
      {bannerImage ? (
        <div className="relative w-full h-80 overflow-hidden rounded-lg mx-auto">
          {isLoading && (
            <div className="absolute inset-0 flex items-center justify-center">
              <div className="loader"></div> {/* Centered loading indicator */}
            </div>
          )}
          <button
            onClick={openImageViewer}
            className="w-full h-full flex items-center justify-center bg-transparent border-none"
          >
            <img
              src={bannerImage}
              alt="Event Banner"
              onLoad={handleImageLoad}
              onError={handleImageError}
              className={`object-cover w-full h-full ${isLoading ? "hidden" : ""}`}
            />
          </button>
        </div>
      ) : (
        <div className="w-48 h-48 bg-gray-200 rounded-lg mx-auto"></div>
      )}

      {bannerImage && (
        <ImageViewer
          isOpen={isImageViewerOpen}
          onRequestClose={closeImageViewer}
          imageUrl={bannerImage}
        />
      )}
    </>
  );
};

export default SquareBannerView;